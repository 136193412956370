import { type Options } from 'highcharts'

const baseChartOptions: Options = {
  title: { text: '' }, // No title
  credits: { enabled: false }, // Hide highcharts.com

  // For expoting to csv, pdf, printing, table, etc
  exporting: {
    buttons: {
      contextButton: {
        enabled: false
      }
    }
  },

  legend: {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    borderRadius: 4,
    padding: 8,
    itemMarginTop: 4,
    itemMarginBottom: 4
  },

  xAxis: {
    allowDecimals: false
  },

  yAxis: {
    allowDecimals: false,
    endOnTick: false,
    tickPixelInterval: 50,
    title: {
      margin: 24
    },
    showFirstLabel: false
  },

  series: []
}

export default baseChartOptions
