import React from 'react'
import moment from 'moment'
import { productConfig } from '../../../config/config-service'
import { getNoDataString, getWeightDeltaInRange } from '../../../utils/member/analyticsUtils'
import { getDisplayDelta, getDeltaArrow, momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

export default function WeightCell (props: { memberObj: IMemberSummary }): JSX.Element {
  if (!props.memberObj.user.has_weight && !props.memberObj.weight) {
    let message = !props.memberObj.user.bt_scale_imei ? 'Device not set up' : 'No weight data'
    if (productConfig().members.mainPage?.hasShippingData) {
      message = getNoDataString(props.memberObj.user, !!props.memberObj.user.bt_scale_imei, 'BodyTrace scale')
    }

    return <EmptyCell message={message} />
  }

  let latestCol = <></>
  const latestWeightData = props.memberObj.weight?.latest_measurement

  if (latestWeightData) {
    const lastWeightMeasurement = moment(latestWeightData.timestamp)

    latestCol = (
      <Box>
        <div className='member-row__latest'> {Math.round(latestWeightData.weight.value * 10) / 10} </div>
        <div className='member-row__latest-timestamp'> {lastWeightMeasurement.calendar(null, momentCalendarDateConfig)} </div>
      </Box>
    )
  }

  const weightDelta7d = getWeightDeltaInRange(props.memberObj) as number

  const deltaCol = (<span className='member-row__delta'> {getDisplayDelta(weightDelta7d)} {getDeltaArrow(weightDelta7d, false)} </span>)

  return (
    <Grid container>
      <Grid item xs={6}> {latestCol} </Grid>
      <Grid item xs={6}> {deltaCol} </Grid>
    </Grid>
  )
}
