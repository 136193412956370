import React, { useState, useEffect } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import restApi from '../../api'
import BasicFrame from '../layout/BasicFrame'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { productConfig } from '../../config/config-service'
import { type AppDispatch } from '../..'

function MemberGetAppPage (props: PropsFromRedux): JSX.Element {
  const appleStoreImage = 'https://s3-us-west-2.amazonaws.com/ejenta-images/ios-app-store-link.png'
  const androidStoreImage = 'https://s3-us-west-2.amazonaws.com/ejenta-images/google-play-link.png'
  const [appleLinkHelpText, setAppleLinkHelpText] = useState('')
  const [appleLink, setAppleLink] = useState('')
  const [androidLinkHelpText, setAndroidLinkHelpText] = useState('')
  const [androidLink, setAndroidLink] = useState('')
  const [informationText, setInformationText] = useState('')

  useEffect(() => {
    const config = productConfig().mobileApp
    setAppleLink(config.appleLink ?? '')
    setAppleLinkHelpText(config.appleLinkHelpText ?? '')
    setAndroidLink(config.androidLink ?? '')
    setAndroidLinkHelpText(config.androidLinkHelpText ?? '')
    setInformationText(config.informationText ?? '')
  }, [])

  const renderDownloadApp = (): JSX.Element => {
    const hasMobileApp = appleLink && androidLink
    const emptyFunction = (): void => {}
    const doLogout = props.handleLogout ?? emptyFunction

    return (
      <BasicFrame>
        <Box sx={{ alignItems: 'center', textAlign: 'center', width: '100vw' }}>
          <Grid container spacing={2}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <Card data-testid='member-page__card'>
                <CardContent>
                  <Typography variant='h5' gutterBottom>Welcome, member!</Typography>
                  <Typography variant='body1' data-testid='member-page__information'>{informationText}</Typography>
                  <br />
                  {hasMobileApp &&
                    <div className='login-form__app-links'>
                      <a className='appLink' href={appleLink} target='_blank' rel='noreferrer' data-testid='member-page__apple-store-link'>
                        <img src={appleStoreImage} alt={appleLinkHelpText} width='200' />
                      </a>
                      <a className='appLink' href={androidLink} target='_blank' rel='noreferrer' data-testid='member-page__android-store-link'>
                        <img src={androidStoreImage} alt={androidLinkHelpText} width='200' />
                      </a>
                    </div>}
                  <br />
                  <Button data-testid='member-page__sign-out-button' variant='outlined' color='secondary' onClick={() => { doLogout() }}>Sign Out</Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Box>
      </BasicFrame>
    )
  }

  return renderDownloadApp()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    handleLogout () {
      dispatch(restApi.actions.logout())
    }
  }
}
const connector = connect(null, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(MemberGetAppPage)
