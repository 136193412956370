
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { productConfig } from '../../config/config-service'

interface ITabPanelProps {
  [key: string]: any
  children: any
  value: number
  index: number
  namePrefix?: string
  isModal?: boolean
  panelHeight?: string
}

export default function TabPanel (props: ITabPanelProps): JSX.Element {
  const [panelHeight, setPanelHeight] = useState('100%')
  const { children, value, index, ...other } = props
  const config = productConfig().tabPanel
  const prefix = props.namePrefix ?? 'ejenta-tab'

  useEffect(() => {
    if (!props.isModal) return
    setPanelHeight(props.panelHeight ?? config.panelHeight ?? '')
  }, [props.isModal])

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`${prefix}panel-${index}`}
      aria-labelledby={`${prefix}-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: props.noPadding ? 0 : 3, height: panelHeight }}>
          {children}
        </Box>
      )}
    </Box>
  )
}
