
import React from 'react'
import { MS_IN_A_DAY } from '../../../../constants/constants'
import { renderLevel } from '../../../../utils/alertUtils'
import { productConfig } from '../../../../config/config-service'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { Box } from '@mui/material'

export default class BloodPressureRangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'bp_range'
  }

  rangeSystolicMinInputElement (): JSX.Element {
    return this.getInputElement(
      40, 110, 5,
      CAREPLAN_VALUE_TYPES.SYSTOLIC_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.SYSTOLIC_MIN.set(val => val)
    )
  }

  rangeSystolicMaxInputElement (): JSX.Element {
    return this.getInputElement(
      115, 200, 5,
      CAREPLAN_VALUE_TYPES.SYSTOLIC_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.SYSTOLIC_MAX.set(val => val)
    )
  }

  rangeDiastolicMinInputElement (): JSX.Element {
    return this.getInputElement(
      40, 70, 5,
      CAREPLAN_VALUE_TYPES.DIASTOLIC_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.DIASTOLIC_MIN.set(val => val)
    )
  }

  rangeDiastolicMaxInputElement (): JSX.Element {
    return this.getInputElement(
      75, 140, 5,
      CAREPLAN_VALUE_TYPES.DIASTOLIC_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.DIASTOLIC_MAX.set(val => val)
    )
  }

  rangeHeartRateMinInputElement (): JSX.Element {
    return this.getInputElement(
      30, 70, 5,
      CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.set(val => val)
    )
  }

  rangeHeartRateMaxInputElement (): JSX.Element {
    return this.getInputElement(
      75, 170, 5,
      CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.set(val => val)
    )
  }

  getTimeInput (): JSX.Element {
    return this.getInputElement(
      1, 14, 1,
      CAREPLAN_VALUE_TYPES.TIME.get(val => val as number / MS_IN_A_DAY),
      CAREPLAN_VALUE_TYPES.TIME.set(val => val as number * MS_IN_A_DAY)
    )
  }

  minCountIsPlural (): boolean {
    return CAREPLAN_VALUE_TYPES.MIN_COUNT.get(val => val)(this.state.itemData) > 1
  }

  minCountInputElement (): JSX.Element {
    return this.getInputElement(
      1, 5, 1,
      CAREPLAN_VALUE_TYPES.MIN_COUNT.get(val => val),
      CAREPLAN_VALUE_TYPES.MIN_COUNT.set(val => val)
    )
  }

  renderGoal (): JSX.Element {
    return <div>Keep BP and HR in range</div>
  }

  renderClinicianTrigger (): JSX.Element {
    const getNumDays = CAREPLAN_VALUE_TYPES.TIME.get(val => val as number / MS_IN_A_DAY)
    const numDays = this.getValue(getNumDays)
    const hasMinThreshold = this.hasTrigger('min_count')

    return (
      <div>If systolic BP is below {this.rangeSystolicMinInputElement()} or above {this.rangeSystolicMaxInputElement()} mmHg,
        {productConfig().carePlan.hasDiastolicThreshold &&
          <span>
            <br />diastolic BP is below {this.rangeDiastolicMinInputElement()} or above {this.rangeDiastolicMaxInputElement()} mmHg,
          </span>}
        <br />or heart rate is below {this.rangeHeartRateMinInputElement()} or above {this.rangeHeartRateMaxInputElement()} bpm{!hasMinThreshold ? '.' : ','}
        {hasMinThreshold && (
          <span>
            <br />for {this.minCountInputElement()} {this.minCountIsPlural() ? <span>consecutive readings within  {this.getTimeInput()} day{numDays === 1 ? '' : 's'}.</span> : <span>reading.</span>}
          </span>)}
      </div>
    )
  }

  renderCondensed (): JSX.Element {
    const level = this.state.itemData?.level ? renderLevel(this.state.itemData.level, true) : ''
    const className = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    return (
      <Box className={className}>{level} BP &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.SYSTOLIC_MIN.get(val => val))}/{this.getValueElement(CAREPLAN_VALUE_TYPES.DIASTOLIC_MIN.get(val => val))} or
        &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.SYSTOLIC_MAX.get(val => val))}/{this.getValueElement(CAREPLAN_VALUE_TYPES.DIASTOLIC_MAX.get(val => val))}
        <br />Heart rate &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.get(val => val))} or &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.get(val => val))} bpm
      </Box>
    )
  }
}
