import React, { useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import SchedulePause from './SchedulePause'
import { getCurrentOrFuturePause } from '../../../utils/member/baseProfileUtils'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { Checkbox, FormControlLabel } from '@mui/material'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type ISuspension } from '../../../interfaces/suspension.interface'
import { mapApiDispatch } from '../../../constants/reduxMappers'

interface IProfileStatusProps extends PropsFromRedux {
  memberObj: IMemberSummary
  onMemberArchived: () => void
}

function ProfileStatusPane (props: IProfileStatusProps): JSX.Element | null {
  const [isConfirmActive, setIsConfirmActive] = useState(false)
  const [isConfirmChecked, setIsConfirmChecked] = useState(false)

  const handleArchiveMember = (): void => {
    props.apiActions.archiveUser({ id: props.memberObj.user.id }, {})
    props.onMemberArchived()
  }

  const handleSetConfirmActive = (): void => {
    setIsConfirmActive(true)
  }

  const handleToggleConfirmCheckbox = (): void => {
    setIsConfirmChecked(!isConfirmChecked)
  }

  const handleCancelArchiveMember = (): void => {
    setIsConfirmChecked(false)
    setIsConfirmActive(false)
  }

  // Initial call to action, i.e. "Archive Mike's account"
  const renderArchiveInitialState = (): JSX.Element => {
    const memberName = props.memberObj.user.first_name

    return (
      <Button color='secondary' variant='contained' data-testid='edit-profile-pane__archive-button' onClick={handleSetConfirmActive}>
        Archive {memberName}'s account
      </Button>
    )
  }

  // The confirmation call to action, i.e. checkbox and "Permanently archive Mike's account"
  const renderArchiveConfirmation = (): JSX.Element => {
    return (
      <div className='edit-profile-pane__row'>
        <FormControlLabel
          sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
          control={
            <Checkbox
              size='small'
              data-testid='edit-profile-pane__archive-checkbox'
              name='archive-confirm'
              checked={isConfirmChecked}
              onChange={() => { handleToggleConfirmCheckbox() }}
            />
          }
          label='Yes, permanently archive'
        />

        <DialogActions data-testid='edit-profile-pane__buttons'>
          <Button variant='outlined' data-testid='edit-profile-pane__cancel-archive-button' onClick={handleCancelArchiveMember}>Cancel</Button>

          <Button variant='contained' data-testid='edit-profile-pane__confirm-archive-button' onClick={handleArchiveMember} disabled={!isConfirmChecked}>
            Confirm
          </Button>
        </DialogActions>
      </div>
    )
  }

  const renderArchive = (): JSX.Element => {
    return (
      <div>
        <div className='u-strong u-bottom-pad--small'>Archive Account</div>

        <div>Archiving a member means that data will no longer be collected. <span className='u-strong'>Archiving a member is permanent and cannot be undone!</span></div>

        <div className='u-top-pad'>
          {!isConfirmActive && renderArchiveInitialState()}
          {isConfirmActive && renderArchiveConfirmation()}
        </div>
      </div>
    )
  }

  const renderProfileStatusPane = (): JSX.Element | null => {
    // Don't show if the user is already archived
    if (props.memberObj.user.archived_since) return null
    const pause = getCurrentOrFuturePause(props.memberObj.user.pauses)

    return (
      <div>
        <SchedulePause pause={pause as ISuspension} memberObj={props.memberObj} apiActions={props.apiActions} />
        <hr />
        {renderArchive()}
      </div>
    )
  }

  return renderProfileStatusPane()
}

const connector = connect(null, mapApiDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ProfileStatusPane)
