import _ from 'lodash'
import React, { useEffect } from 'react'
import restApi from '../../api'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { chatsViewSlice, fetchPagingChats } from '../../store/chatsView'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../layout/ContentMain'
import LoadingIndicator from '../elements/LoadingIndicator'
import RefineChatsSidebar from './RefineChatsSidebar'
import AlertDetailModal from './AlertsPage/AlertDetailModal'
import { Alert, Button } from '@mui/material'
import { useNavigate } from 'react-router'
import { ALERTS_PAGE_SIZE } from '../../constants/constants'
import { type IAlertRow, type IAlertRows, type IAlert } from '../../interfaces/alert.interface'
import { CHATS_VIEW, DEFAULT_CHAT_FILTERS } from '../../utils/chatUtils'
import { type RootState, type AppDispatch } from '../..'
import { getHeaderColumns, getRowFromConfig, renderProfileCol } from '../../utils/alertUtils'
import AlertTable from './AlertsPage/AlertTable'
import { type IGenericTableHeaderColumn } from '../../interfaces/ejenta.interface'

function ChatsPage (props: PropsFromRedux): JSX.Element {
  const navigate = useNavigate()

  useEffect(() => {
    if (props.chatsViewStore?.searchQueryReset) props.fetchChats()
  }, [props.chatsViewStore?.searchQueryReset])

  useEffect(() => {
    props.chatsViewActions.setTotalFilterOptions(CHATS_VIEW.FILTER_GROUPS.ALERT_TYPE.filters?.length)
    props.fetchChats()
  }, [])

  const handleClearSearch = (): void => {
    props.chatsViewActions.resetView(false) // Resets the search query, sorts, and filters
    navigate('/chats') // reload this page, but without the query string
    props.fetchChats()
  }

  const handleChatDetailModalClose = (): void => {
    props.chatsViewActions.setSelectedChat(null)
  }

  const getRows = (headerColumns: IGenericTableHeaderColumn []): IAlertRows => {
    const rows: IAlertRows = {}

    props.chatsViewStore?.currentPage?.forEach((alert: IAlert) => {
      const row: IAlertRow = {
        member: renderProfileCol(alert)
      }

      headerColumns.forEach(column => {
        if (column.label) row[column.id] = getRowFromConfig(column.label ?? '', alert)
      })

      rows[alert.id] = row
    })

    return rows
  }

  const renderBody = (): JSX.Element => {
    if (props.chatsViewStore?.loadingChats) return <LoadingIndicator sidebarVisible />

    if (props.chatsViewStore?.total === 0 &&
      _.isEqual(props.chatsViewStore.filters, DEFAULT_CHAT_FILTERS) &&
      !props.chatsViewStore.searchQuery) {
      return (
        <Alert sx={{ mt: 1, mb: 1 }} severity='warning'> There are no chats. </Alert>
      )
    } else if (props.chatsViewStore?.total === 0) {
      return (
        <Alert
          sx={{ mt: 1 }}
          severity='warning'
          action={<Button color='inherit' size='small' onClick={() => { handleClearSearch() }}> Reset Filters </Button>}
        >
          No chats matched your search.
        </Alert>
      )
    }

    const columns = getHeaderColumns()
    const rows = getRows(columns)

    return <AlertTable
      rows={rows}
      columns={columns}
      pageStart={props.pageStart}
      pageEnd={props.pageEnd}
      page={props.chatsViewStore?.page ?? 1}
      total={props.chatsViewStore?.total ?? 0}
      viewActions={props.chatsViewStore}
      fetchPagingAlerts={props.fetchChats}
      handleItemClick={props.chatsViewActions.setSelectedChat}
      ></AlertTable>
  }

  const renderChatsPage = (): JSX.Element => {
    if (!props.clinicianChatsApi?.sync) return <LoadingIndicator sidebarVisible />

    const selectedChat = props.chatsViewStore?.currentPage.find(
      a => a.id === props.chatsViewStore?.selectedItemId
    )

    return (
      <>
        <AlertDetailModal
          maxWidth='md'
          selectedAlert={selectedChat}
          onModalClose={handleChatDetailModalClose}
        />

        <RefineChatsSidebar resetView={handleClearSearch} />

        <ContentMain drawerWidth={285}>
          <ContentMainIntro>
            <ContentMainIntroHeader title='Chats' />
          </ContentMainIntro>

          <ContentMainBody> {renderBody()} </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderChatsPage()
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    carePlanItems: state.clinicianCarePlan?.carePlanItems,
    clinicianChatsApi: state.api.clinicianChats,
    chatsViewStore: state.chatsView,
    pageStart: (state.chatsView.page - 1) * ALERTS_PAGE_SIZE,
    pageEnd: state.chatsView.page * ALERTS_PAGE_SIZE
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    chatsViewActions: bindActionCreators(chatsViewSlice.actions, dispatch),
    fetchChats: bindActionCreators(fetchPagingChats, dispatch),
    getDefaultCarePlan: () => {
      dispatch(restApi.actions.getClinicianDefaultCarePlan())
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ChatsPage)
