import React from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import ClinicianNav from './ClinicianNav'
import { type RootState } from '../../..'

interface INavProps extends PropsFromRedux {
  forDesktop: boolean
}

function UserNav (props: INavProps): JSX.Element | null {
  if (!props.userSession?.user || !props.userSession?.isClinician) {
    return null
    // @ts-expect-error TS doesn't like connect from react-redux
  } else return (<ClinicianNav forDesktop={props.forDesktop} filterCounts={props.filterCounts} />)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    userSession: state.userSession,
    filterCounts: state.alertsView.filterCounts
  }
}

const connector = connect(mapState)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(UserNav)
