import React, { useEffect } from 'react'
import { type Location, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { logGAPageView } from '../utils/googleAnalyticsUtils'
import { productConfig } from '../config/config-service'
import restApi from '../api'
import LoginForm from '../components/controllers/forms/LoginForm'
import ResetPasswordForm from '../components/controllers/forms/ResetPasswordForm'
import ForgotPasswordForm from '../components/controllers/forms/ForgotPasswordForm'
import PhoneLoginForm from '../components/controllers/forms/PhoneLoginForm'
import NotFoundPage from '../components/pages/NotFoundPage'
import OnboardingPage from '../components/pages/OnboardingPage'
import AccountSettingsPage from '../components/pages/AccountSettingsPage'
import HelpPage from '../components/pages/HelpPage'
import MemberGetAppPage from '../components/pages/MemberGetAppPage'
import GroupPickerPage from '../components/pages/GroupPickerPage'
import AlertsPage from '../components/pages/AlertsPage/Alerts'
import MembersPage from '../components/pages/MembersPage/Members'
import CarePlanPage from '../components/pages/CarePlanPage'
import ProfilePage from '../components/pages/ProfilePage'
import AnalyticsPage from '../components/pages/analytics/AnalyticsPage'

import SessionExpireWarning from '../components/controllers/SessionExpireWarning'
import SelfSignupDiscontinued from '../components/controllers/forms/SelfSignup/SelfSignupDiscontinued'
import { AppHeader } from '../components/layout/Header'
import { ejentaRoutes, isAllAccessRoute } from './browserRouter'
import { getHasValidConsentDocs } from '../utils/member/baseProfileUtils'
import { setEpicLoading, setEpicParams, setIsEmbedded } from '../store/epic'
import ChatsPage from '../components/pages/ChatsPage'
import EpicSSOPage from '../components/pages/EpicSSOPage'

function handlePageLoad (location: Location): void {
  logGAPageView(location)
  window.scrollTo(0, 0)
}

interface Props {
  store: any
}
/* *****************************************************************************
 * App router.
 * *****************************************************************************/
let loginTarget: string | null
export default function ({ store }: Props): JSX.Element {
  const state = store.getState()
  const dispatch = store.dispatch
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const searchParams = useSearchParams()
  const config = productConfig()
  const {
    userSession: { isLoggedIn, isClinician, activeGroup, user },
    epic: { isEmbedded }
  } = state

  const isMultiGroup = user?.allowedGroups?.length > 1
  const currentURLAvailableWithoutGroup = location.pathname.startsWith(ejentaRoutes.accountPage) || location.pathname.startsWith(ejentaRoutes.helpPage)
  const currentURLIsProfile = location.pathname.startsWith(ejentaRoutes.profilePage)

  const isEmbeddedInIFrame = (): boolean => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  useEffect(() => {
    handlePageLoad(location)
    dispatch(setIsEmbedded(isEmbeddedInIFrame()))
  }, [location])

  useEffect(() => { document.title = `${config.name} - Ejenta Dashboard` }, [])

  useEffect(() => {
    if (location.pathname === ejentaRoutes.singleSignOn) {
      dispatch(setEpicLoading(true))
      dispatch(setEpicParams({ iss: searchParams[0].get('iss'), launch: searchParams[0].get('launch') }))
      return // don't continue with standard loggedIn logic if SSO user
    }

    if (isLoggedIn) {
      if (!getHasValidConsentDocs(user)) {
        loginTarget = null
        navigate(ejentaRoutes.welcomePage, { replace: true })
        return
      }

      // if just logged in or at root
      if (location.pathname === ejentaRoutes.loginPage || location.pathname === '/') {
        // redirect to loginTarget after login
        if (loginTarget) {
          if (!isClinician) navigate(ejentaRoutes.memberDashboardPage, { replace: true })
          else navigate(loginTarget, { replace: true })
          loginTarget = null
        } else {
          // default inital screen after login
          if (isClinician) navigate(ejentaRoutes.alertsPage, { replace: true })
          else navigate(ejentaRoutes.memberDashboardPage, { replace: true })
        }
      }
    } else {
      const isSelfSignupPath = config.router.hasSelfSignup && location.pathname === ejentaRoutes.signupPage
      const allowedPathWhileLoggedOut = isAllAccessRoute(location.pathname) || isSelfSignupPath
      // save loginTarget until logged in
      if (!allowedPathWhileLoggedOut) {
        if (location.pathname === ejentaRoutes.logoutPage) loginTarget = null
        else loginTarget = `${location.pathname}${location.search}`
        navigate(ejentaRoutes.loginPage, { replace: true })
      }
    }
  }, [isLoggedIn, location.pathname])

  // sets group based on URL param
  useEffect(() => {
    if (isLoggedIn && location.search) {
      const url = new URL(window.location.href)
      const group = url.searchParams.get('group')
      if (group && group !== activeGroup) {
        const formData = { body: JSON.stringify({ activeGroupName: group }) }
        dispatch(restApi.actions.setActiveGroup(null, formData))
      }
    }
  }, [isLoggedIn, location.search])

  useEffect(() => {
    if (!isLoggedIn) return

    if (location.pathname === ejentaRoutes.logoutPage) {
      loginTarget = null
      dispatch(restApi.actions.logout())
      navigate(ejentaRoutes.loginPage, { replace: true })
      return
    }

    if (location.pathname !== ejentaRoutes.singleSignOn && isAllAccessRoute(location.pathname)) navigate(ejentaRoutes.alertsPage, { replace: true })
  }, [location.pathname])

  const getIsValidRouteRoot = (): boolean => {
    const secondSlashIndex = location.pathname.slice(1).indexOf('/')
    const locationSegment = secondSlashIndex >= 0 ? location.pathname.slice(0, secondSlashIndex + 1) : location.pathname
    return Object.values(ejentaRoutes).includes(locationSegment)
  }

  const shouldShowHeader = (): boolean => {
    if (!isLoggedIn) return false
    if (!getHasValidConsentDocs(user)) return false
    if (location.pathname.startsWith(ejentaRoutes.welcomePage) || location.pathname.startsWith(ejentaRoutes.memberDashboardPage)) return false
    if (isAllAccessRoute(location.pathname)) return false
    if (isEmbedded) return false

    return getIsValidRouteRoot()
  }

  const missingValidGroup = !activeGroup && isMultiGroup

  const getGroupBasedRoutes = (): JSX.Element => {
    return (
      <>
        <Route id='alerts' path={ejentaRoutes.alertsPage} element={<AlertsPage />} />
        <Route id='chat' path={ejentaRoutes.chatPage} element={<ChatsPage />} />
        <Route id='members' path={ejentaRoutes.membersPage} element={<MembersPage />} />
        <Route id='careplan' path={ejentaRoutes.careplanPage} element={<CarePlanPage />} />
        <Route id='analytics' path={ejentaRoutes.analyticsPage} element={<AnalyticsPage />} />
      </>
    )
  }

  const getGroupPickerRoute = (): JSX.Element => {
    let route = (<Route id='groupPicker' path='*' element={<GroupPickerPage />} />)
    if (currentURLAvailableWithoutGroup) route = <></>
    else if (currentURLIsProfile) route = <></>
    return (route)
  }

  return (
    <>
      {isLoggedIn && <SessionExpireWarning />}
      {shouldShowHeader() && <AppHeader />}

      <Routes>
        <Route id='onboarding' path={ejentaRoutes.welcomePage} element={<OnboardingPage />} />
        <Route id='download' path={ejentaRoutes.memberDashboardPage} element={<MemberGetAppPage />} />
        <Route id='account' path={ejentaRoutes.accountPage} element={<AccountSettingsPage />} />
        <Route id='help' path={ejentaRoutes.helpPage} element={<HelpPage />} />
        <Route id='profile' path={`${ejentaRoutes.profilePage}/:id`} element={<ProfilePage params={params} location={location} />} />

        {missingValidGroup ? getGroupPickerRoute() : getGroupBasedRoutes()}

        {/* Logged-out routes */}
        <Route id='login' path={ejentaRoutes.loginPage} element={<LoginForm />} />
        <Route id='sso' path={ejentaRoutes.singleSignOn} element={<EpicSSOPage />} />
        <Route id='forgotPassword' path={ejentaRoutes.forgotPasswordPage} element={<ForgotPasswordForm />} />
        <Route id='resetPassword' path={ejentaRoutes.resetPasswordPage} element={<ResetPasswordForm />} />
        <Route id='activateAccount' path={ejentaRoutes.activateAccountPage} element={<OnboardingPage />} />
        <Route id='phoneLogin' path={ejentaRoutes.phoneLoginPage} element={<PhoneLoginForm />} />
        {/* <Route id='signup' path={ejentaRoutes.signupPage} element={<SelfSignupForm />} /> */}
        <Route id='signup' path={ejentaRoutes.signupPage} element={<SelfSignupDiscontinued />} />
        {!getIsValidRouteRoot() && <Route path='*' element={<NotFoundPage />} />}
      </Routes>

    </>
  )
}
