import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface IAlertDialogProps {
  isOpen: boolean
  title: string
  message: string
  confirmText?: string
  rejectText?: string
  handleDialogClose: (isConfirmed: boolean) => void
}

export default function AlertDialog (props: IAlertDialogProps): JSX.Element {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(props.isOpen)
  }, [props.isOpen])

  const handleClose = (isConfirmed: boolean): void => {
    setOpen(false)
    props.handleDialogClose(isConfirmed)
  }

  return (
    <Dialog
      data-testid='alert-dialog'
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'> {props.title} </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'> {props.message} </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid='alert-dialog__confirm-button' onClick={() => { handleClose(true) }}>{props.confirmText ?? 'Yes'}</Button>
        <Button data-testid='alert-dialog__reject-button' onClick={() => { handleClose(false) }} autoFocus> {props.rejectText ?? 'No'} </Button>
      </DialogActions>
    </Dialog>
  )
}
