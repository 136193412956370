import React from 'react'
import moment from 'moment'
import { english as ordinal } from '../../../ordinal'
import { productConfig } from '../../../config/config-service'
import { getIOMRangeOnExpectedDeliveryDate, getMemberBMI, getMemberWeightCondition } from '../../../utils/member/analyticsUtils'
import { getWeekOfPregnancy, getTrimesterOfPregnancy, getDeliveryDate } from '../../../utils/baseDateUtils'
import { getDisplayStartWeight, getDisplayBMI } from '../../../utils/baseStringUtils'
import Tooltip from '../../elements/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

export default function ProfilePregnancyPane (props): JSX.Element {
  const delivered = !!props.memberObj.user.delivery_date
  const deliveryDate = delivered ? moment(props.memberObj.user.delivery_date) : null
  const archived = !delivered && !!props.memberObj.user.archived_since
  const preDelivery = !delivered && !archived
  const expectedDeliveryDate = getDeliveryDate(props.memberObj.user)
  const { min: minExpectedDeliveryDateIOM, max: maxExpectedDeliveryDateIOM } = getIOMRangeOnExpectedDeliveryDate(props.memberObj)

  const conceptionDate = moment(props.memberObj.user.conception_date)
  const isSidebarView = props.variant === 'narrow'
  const profileConfig = productConfig().profile

  const renderHealthStatusChangeItem = (): JSX.Element => {
    let statusChange = <span>None</span>
    const latestHealthStatusChange = props.memberObj.survey_responses?.latest_measurement

    if (latestHealthStatusChange) {
      // Note: this relies on the health status question being the 0th question (it should be the only question):
      const healthStatusChangeText = !!latestHealthStatusChange && latestHealthStatusChange.questions[0].answer.text
      statusChange = <span>{healthStatusChangeText} ({moment(latestHealthStatusChange.date).format('MMMM D, YYYY')})</span>
    }

    return (
      <ListItem disablePadding>
        <ListItemText primary={statusChange} secondary='Latest health status change' />
      </ListItem>
    )
  }

  const renderPreDelivery = (): JSX.Element => {
    return (
      <>
        {preDelivery && (
          <ListItem disablePadding>
            <ListItemText
              primary={`Week ${getWeekOfPregnancy(props.memberObj, Date.now())} (${ordinal(getTrimesterOfPregnancy(props.memberObj))} trimester)`}
              secondary='Pregnancy status'
            />
          </ListItem>
        )}

        {preDelivery && !profileConfig.hasPregnancyCharts && (
          <ListItem disablePadding>
            <ListItemText
              primary={expectedDeliveryDate.format('MMMM D, YYYY')}
              secondary='Expected delivery date'
            />
          </ListItem>
        )}

        {preDelivery && profileConfig.hasPregnancyCharts && (
          <ListItem disablePadding>
            <ListItemText
              primary={
                <>
                  {expectedDeliveryDate.format('MMMM D, YYYY')}
                  <Tooltip
                    content={
                      'Calculated using the provided pregnancy start date. \n\n' +
                      `IOM weight range on expected delivery date is between ${minExpectedDeliveryDateIOM} and ${maxExpectedDeliveryDateIOM} pounds.`
                    }
                  />
                </>
              }
              secondary='Expected delivery date'
            />
          </ListItem>
        )}
      </>
    )
  }

  const renderDeliveredArchived = (): JSX.Element => {
    const dateInWeeks = deliveryDate ? deliveryDate.diff(conceptionDate, 'weeks') : 0
    const dateInDays = deliveryDate ? deliveryDate.diff(conceptionDate, 'days') : 0
    return (
      <>
        {delivered && (
          <ListItem disablePadding>
            <ListItemText
              primary={deliveryDate?.format('MMM D, YYYY')}
              secondary='Delivered on'
            />
          </ListItem>
        )}
        {delivered && (
          <ListItem disablePadding>
            <ListItemText
              primary={`${dateInWeeks} weeks (${dateInDays} days)`}
              secondary='Pregnancy length'
            />
          </ListItem>
        )}
        {archived && (
          <ListItem disablePadding>
            <ListItemText
              primary={moment(props.memberObj.user.archived_since).format('MMM D, YYYY')}
              secondary='Archived on'
            />
          </ListItem>
        )}
      </>
    )
  }

  const renderProgramStep = (): JSX.Element => {
    return (
      <ListItem disablePadding>
        <ListItemText
          primary={`${props.memberObj.pregnancy_info.program_step as string} (since ${moment(props.memberObj.pregnancy_info.program_step_date).format('MMMM D, YYYY')})`}
          secondary='Program step'
        />
      </ListItem>
    )
  }

  const renderStartDate = (): JSX.Element | null => {
    if (!isSidebarView) return null

    return (
      <ListItem disablePadding>
        <ListItemText
          primary={moment.utc(props.memberObj.user.conception_date).format('MMM D, Y')}
          secondary='Pregnancy start date'
        />
      </ListItem>
    )
  }

  const renderPrePregnancy = (): JSX.Element => {
    return (
      <>
        {isSidebarView && (
          <ListItem disablePadding>
            <ListItemText
              primary={getDisplayStartWeight(props.memberObj.user)}
              secondary='Pre-pregnancy weight'
            />
          </ListItem>
        )}
        {isSidebarView && (
          <ListItem disablePadding>
            <ListItemText
              primary={getDisplayBMI(getMemberBMI(props.memberObj.user) as number, getMemberWeightCondition(props.memberObj.user))}
              secondary='Pre-pregnancy BMI'
            />
          </ListItem>
        )}
      </>
    )
  }

  function renderProfilePregnancyPane (): JSX.Element {
    const listStyle = {
      display: isSidebarView ? 'block' : 'flex',
      width: '100%',
      bgcolor: 'background.paper'
    }

    return (
      <List sx={listStyle} disablePadding>
        {profileConfig.hasPregnancyCharts && renderHealthStatusChangeItem()}
        {renderPreDelivery()}
        {renderDeliveredArchived()}
        {profileConfig.hasPregnancyCharts && renderProgramStep()}
        {renderStartDate()}
        {profileConfig.hasPregnancyCharts && renderPrePregnancy()}
      </List>
    )
  }

  return renderProfilePregnancyPane()
}
