import React from 'react'
import classnames from 'classnames'
import ProfileDetail from './ProfileDetail'
import Star from '../Star'
import { Box, Chip, Grid, IconButton } from '@mui/material'
import { getDisplayName } from '../../../utils/baseStringUtils'
import { isMemberPaused, isMemberAwaitingDevices } from '../../../utils/member/baseProfileUtils'
import { type IMember } from '../../../interfaces/user.interface'
import { type IOrderStatuses } from '../../../interfaces/order-status.interface'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { Link } from 'react-router-dom'

interface IProfileCellProps {
  user: IMember
  detailStr?: string
  memberStarred?: boolean
  handleStarClick?: (event: any) => void
  className?: string
  memberObj?: IMemberSummary
}

/* *****************************************************************************
 *  Profile cell, for use in the clinician alerts list and members list views
 * *****************************************************************************/
export default function ProfileCell (props: IProfileCellProps): JSX.Element {
  const getStatusText = (): JSX.Element => {
    if (props.user.archived_since) return (<Chip label='Archived' size='small' color='secondary' />)

    if (!props.user.signed_tos) return (<Chip label='Pending' size='small' variant='outlined' />)

    if (isMemberPaused(props.user.pauses)) return (<Chip label='Suspended' size='small' color='secondary' />)

    if (isMemberAwaitingDevices(props.user?.order_statuses as IOrderStatuses)) return (<Chip label='Onboarding' size='small' variant='outlined' />)

    return <></>
  }

  const renderDefault = (
    className,
    displayNameText,
    profileDetailText,
    statusText
  ): JSX.Element => {
    const starClasses = classnames('profile-cell__star', {
      'profile-cell__star--starred': props.memberStarred,
      'profile-cell__star--unstarred': !props.memberStarred
    })
    const name = `Star ${props.user.first_name as string} ${props.user.last_name as string}`

    return (
      <Grid container className={className} sx={{ minHeight: '4rem', alignItems: 'center' }}>
        {props.handleStarClick && <IconButton sx={{ maxHeight: '3rem' }} className='profile-cell__star' aria-label={name} onClick={e => { props.handleStarClick?.(e) }}>
          <Star className={starClasses} filled={props.memberStarred} />
        </IconButton>}

        <Grid item xs sx={{ alignSelf: 'center' }}>
          <Link to={`/profile/${props.user.id as string}`} data-testid='profile-cell__user-details' color='inherit' style={{ textDecoration: 'none' }}>
            <Box className='profile-cell__name' sx={{ fontSize: '1rem' }}>{displayNameText}</Box>
            <Box
              className='profile-cell__detail'
              sx={{ color: theme => theme.palette.secondary.light, display: 'flex', justifyContent: 'space-between' }}
            >
              <Box sx={{ fontWeight: 400 }}>{profileDetailText}</Box>
              <Box component='span'>{statusText}</Box>
            </Box>
          </Link>

        </Grid>

      </Grid>
    )
  }

  const renderProfileCell = (): JSX.Element => {
    const className = classnames(props.className, 'profile-cell')
    const displayNameText = getDisplayName(props.user)
    const profileDetailText = (
      <ProfileDetail user={props.user} memberObj={props.memberObj} detailStr={props.detailStr} />
    )

    return renderDefault(
      className,
      displayNameText,
      profileDetailText,
      getStatusText()
    )
  }

  return renderProfileCell()
}
