import React from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import Gender from './modules/Gender'
import Age from './modules/Age'
import Enrollment from './modules/Enrollment'
import LoadingIndicator from '../../elements/LoadingIndicator'
import { productConfig } from '../../../config/config-service'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { mapAnalyticsDispatch, mapAnalyticsState } from '../../../constants/reduxMappers'

function AnalyticsPopulation (props: PropsFromRedux): JSX.Element {
  const profileFormFields = productConfig().profile.customFormFields
  const [ageExpanded, setAgeExpanded] = React.useState(true)
  const [genderExpanded, setGenderExpanded] = React.useState(true)

  const handleChange = (callback: (isExpanded: boolean) => void) => (event, isExpanded) => {
    callback(isExpanded)
  }

  const renderEnrollmentSection = (): JSX.Element => {
    return (
      <div className='ax-subsection'>
        <div className='ax-subsection__header' data-testid='ax-subsection__header-enrollment'>Enrollment</div>

        <div>
          <div data-testid='ax-column__enrollment'>
            <Enrollment
              forPeriod
              data-testid='ax-subsection__enrollment'
              users={props.analyticsStore.allUsers}
              rangeSuspensions={props.analyticsStore.rangeSuspensions}
              currentSuspensions={props.analyticsStore.currentSuspensions}
              analyticsActions={props.analyticsActions}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderDemographicsSection = (): JSX.Element => {
    const hasGender = profileFormFields?.includes('gender')
    return (
      <div className='ax-subsection'>
        <div className='ax-subsection__header' data-testid='ax-subsection__header-demographics'>Demographics</div>

        <Accordion expanded={ageExpanded} onChange={handleChange(setAgeExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} id="accordion-age">
            Age
          </AccordionSummary>
          <AccordionDetails data-testid='ax-age'>
            <Age
              data-testid='ax-subsection__age'
              users={props.analyticsStore.onboardedUsers}
              analyticsActions={props.analyticsActions}
            />
          </AccordionDetails>
        </Accordion>

        {hasGender &&
          <Accordion expanded={genderExpanded} onChange={handleChange(setGenderExpanded)}>
            <AccordionSummary expandIcon={<ExpandMore />} id="accordion-gender">
              Gender
            </AccordionSummary>
            <AccordionDetails data-testid='ax-gender'>
              <Gender
                data-testid='ax-subsection__gender'
                users={props.analyticsStore.onboardedUsers}
                analyticsActions={props.analyticsActions}
              />
            </AccordionDetails>
          </Accordion>}
      </div>
    )
  }

  const renderAxPopulationScreen = (): JSX.Element => {
    return (
      <Box>
        {props.analyticsStore.isDateRangeLoading && <div className='ax-main__loading'> <LoadingIndicator /> </div>}

        {!props.analyticsStore.isDateRangeLoading &&
          <div data-testid='analytics-page__population-screen'>
            {renderEnrollmentSection()}
            {renderDemographicsSection()}
          </div>}
      </Box>
    )
  }

  return renderAxPopulationScreen()
}

const connector = connect(mapAnalyticsState, mapAnalyticsDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AnalyticsPopulation)
