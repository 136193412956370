import React, { useState } from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material'
import { productConfig } from '../../../../config/config-service'
import MemberDevicesFormFields from '../MemberDevicesFormFields'
import MemberAddressFields from './MemberAddressFields'
import { type IMemberFormStore } from '../../../../store/memberForm'

interface IMemberDeviceOrderProps {
  memberFormState: IMemberFormStore
  setOrderScale: (order: boolean) => void
  setOrderBloodPressure: (order: boolean) => void
}

export default function MemberDeviceOrderFields (props: IMemberDeviceOrderProps): JSX.Element {
  const [orderScale, setOrderScale] = useState(props.memberFormState.orderBodyTraceScale)
  const [orderBp, setOrderBP] = useState(props.memberFormState.orderBodyTraceBloodPressure)
  const profileConfig = productConfig().profile
  const scaleText = 'orderBodyTraceScale'
  const bpText = 'orderBodyTraceBloodPressure'

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, field: string): void => {
    switch (field) {
      case scaleText:
        props.setOrderScale(event.target.checked)
        break
      case bpText:
        props.setOrderBloodPressure(event.target.checked)
        break
      default:
    }
  }

  const renderOrderFields = (): JSX.Element | null => {
    if (!profileConfig.orderFields?.length) return null
    const hasScale = profileConfig.orderFields.includes(scaleText)
    const hasBP = profileConfig.orderFields.includes(bpText)

    return (
      <Box>
        <FormGroup>
          {hasScale && <FormControlLabel
            control={
              <Checkbox checked={props.memberFormState.orderBodyTraceScale}
                onChange={e => { setOrderScale(e.target.checked); handleCheckboxChange(e, scaleText) }}
                name={scaleText} />}
            label="Order and ship BodyTrace scale to this member" />
          }
          {hasBP && <FormControlLabel
            control={
              <Checkbox checked={props.memberFormState.orderBodyTraceBloodPressure}
                onChange={e => { setOrderBP(e.target.checked); handleCheckboxChange(e, bpText) }}
                name={bpText} />}
            label="Order and ship BodyTrace BP cuff to this member" />
          }
        </FormGroup>
        {(orderScale || orderBp) && <MemberAddressFields />}
      </Box>
    )
  }

  const renderProfileFormFields = (): JSX.Element => {
    return (
      <Grid container spacing={1} data-testid='member-profile-form-fields' sx={{ mb: 2 }}>
        {profileConfig.orderFields?.length === 0 && <MemberDevicesFormFields />}
        {renderOrderFields()}
      </Grid>
    )
  }

  return renderProfileFormFields()
}
