
import React from 'react'
import moment from 'moment'
import { momentCalendarConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

export default function RespiratoryRateCell (props: { memberObj: IMemberSummary }): JSX.Element {
  const respiratoryRate = props.memberObj.respiratory_rate
  if (!props.memberObj.user.has_respiratory_rate || !respiratoryRate) {
    return <EmptyCell message='No respiratory rate data' />
  }

  const lastMeasurement = moment(respiratoryRate.latest_measurement.timestamp)
  const unit = 'bpm' // TODO update backend to reflect - respiratoryRate.information.units.respiratory_rate as string
  const latestCol = (
    <Box>
      <div className='member-row__latest'> {`${Math.round(respiratoryRate.latest_measurement.respiratory_rate * 10) / 10} ${unit}`} </div>
      <div className='member-row__latest-timestamp'> {lastMeasurement.calendar(null, momentCalendarConfig)} </div>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}> {latestCol} </Grid>
    </Grid>
  )
}
