import moment from 'moment'
import { type IAlert } from '../interfaces/alert.interface'
import { type IFormValues, type IProfileFields } from '../interfaces/forms.interface'
import { type IMemberSummary } from '../interfaces/member-summary.interface'
import { type IMember } from '../interfaces/user.interface'
import {
  convertLbToKg,
  convertFtInToCm
} from '../utils/unitConversionUtils'
import { type IEpicPatient } from '../interfaces/store/epicStore.interface'
import { type DefaultFormFieldValues } from '../interfaces/product-config.interface'

export const SESSION_WARNING_LENGTH = 10 * 60 * 1000 // 10 minutes
export const SESSION_CHECK_INTERVAL = 2 * 1000 // 2 seconds

export const MS_IN_A_DAY = 86400000
export const NOTES_PAGE_SIZE = 10
export const MEMBERS_PAGE_SIZE = 20
export const ALERTS_PAGE_SIZE = 20

export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const DATE_DAY_FORMAT_SANS_YEAR = 'ddd, MMM D'
export const DATE_DAY_FORMAT_WITH_YEAR = 'ddd, MMM D Y'

export const FEATURE_GATES = {
  SHOW_ONLY_ANALYTICS: 'fg/showOnlyAnalytics'
}

export const COMMUNICATION_TYPES = {
  CLINICIAN: {
    email: 'Send individual alert and alert digest emails',
    none: 'Do not send emails'
  },

  MEMBER: {
    email: 'Send emails',
    text: 'Send texts / SMS',
    none: 'Do not send messages'
  }
}

export const CARE_MANAGER_DEFAULT_OPTIONS = [{
  id: 'OPTION_ALL',
  name: 'All'
}, {
  id: 'OPTION_NONE', // key matches IS
  name: 'None'
}]

export const SUSPEND_ALERT_OPTIONS = [
  { value: 'hospital', label: 'Hospitalization' },
  { value: 'vacation', label: 'Vacation/Travel' },
  { value: 'bed_rest', label: 'Bed rest' },
  { value: 'other', label: 'Other' }
]

export const descendingComparator = (a?: string | number, b?: string | number): number => {
  if (!a || !b) return 0
  if (b < a) return -1
  if (b > a) return 1
  return 0
}

export const getSSOPatientFormInitialValues = (memberFields: IProfileFields, defaultFormValues: DefaultFormFieldValues, epicPatient: IEpicPatient): IFormValues => {
  const initialValues: IFormValues = {}
  Object.entries(memberFields).forEach(([inputName, field]) => {
    switch (inputName) {
      case 'medicalId':
        initialValues[inputName] = epicPatient.medical_id
        break
      case 'fhirId':
        initialValues[inputName] = epicPatient.fhir_id
        break
      case 'streetAddress1':
        initialValues[inputName] = epicPatient.address?.line[0]
        break
      case 'streetAddress2':
        initialValues[inputName] = epicPatient.address?.line[1]
        break
      case 'city':
        initialValues[inputName] = epicPatient.address?.city
        break
      case 'state':
        initialValues[inputName] = epicPatient.address?.state
        break
      case 'zipCode':
        initialValues[inputName] = epicPatient.address?.postalCode
        break
      case 'phone':
        initialValues[inputName] = epicPatient.phone?.split('-').join('')
        break
      case 'gender':
        initialValues[inputName] = epicPatient.gender.toUpperCase()
        break
      case 'homePhone':
        initialValues[inputName] = epicPatient.home_phone?.split('-').join('')
        break
      case 'birthDate':
        initialValues[inputName] = epicPatient.date_of_birth
        break
      case 'firstName':
        initialValues[inputName] = epicPatient.first_name
        break
      case 'lastName':
        initialValues[inputName] = epicPatient.last_name
        break
      default: // birthDate, lastName, firstName
        if (!epicPatient[inputName] && defaultFormValues[inputName]) initialValues[inputName] = defaultFormValues[inputName]
        else initialValues[inputName] = epicPatient[inputName]
    }
  })

  return initialValues
}

export const getProfileFormInitialValues = (memberFields: IProfileFields, memberObj: IMemberSummary): IFormValues => {
  const initialValues: IFormValues = {}
  Object.entries(memberFields).forEach(([inputName, field]) => {
    const currentUserValue = field.apiField ? memberObj.user[field.apiField] : null
    const value = field.accessor ? field.accessor(memberObj.user) : currentUserValue
    initialValues[inputName] = value
  })

  return initialValues
}

export const getMemberApiBody = (memberFields: IProfileFields, formData: Record<string, string | number>, group: string): IMember => {
  const apiBody: IMember = {}

  Object.entries(formData).forEach(([formKey, formVal]) => {
    const apiField = memberFields[formKey].apiField
    if (apiField) apiBody[apiField] = formVal === '' ? null : formVal
  })

  Object.keys(memberFields).forEach(key => {
    // Get any keys missing from the formData, but present in the memberFields
    if (!Object.prototype.hasOwnProperty.call(formData, key)) {
      const apiField = memberFields[key].apiField
      if (apiField) apiBody[apiField] = null
    }
  })

  // Convert any measurements from IS to Metric
  if (formData.heightFeet) apiBody.height_in_cm = convertFtInToCm(formData.heightFeet, formData.heightInches)
  if (formData.dryWeight) apiBody.dry_weight_in_kg = convertLbToKg(+formData.dryWeight)
  if (formData.startWeight) apiBody.start_weight_in_kg = convertLbToKg(+formData.startWeight)
  if (moment.isMoment(apiBody.date_of_birth)) apiBody.date_of_birth = apiBody.date_of_birth.format('Y-MM-DD')

  // add any optional values
  if (formData.care_manager_id) {
    const nullOptions = ['OPTION_ALL', 'DEFAULT_OPTION', 'OPTION_NONE'] // DEFAULT_OPTION is legacy
    if (nullOptions.includes(formData.care_manager_id as string)) apiBody.care_manager_id = null
    else apiBody.care_manager_id = formData.care_manager_id.toString()
  }
  if (formData.in_care_management !== undefined) apiBody.in_care_management = +formData.in_care_management

  if (!apiBody.group) apiBody.group = group // if no group selected from dropdown, use users active group
  return apiBody
}

export const getActivityGoal = (member: IMemberSummary): number | undefined => {
  return member?.weekly_activity_goal?.current_progress?.value
}

export const CARE_PLAN_NOTIFY_OPTIONS = [
  {
    key: 'clinicians',
    value: 'Clinicians only'
  },
  {
    key: 'member',
    value: 'Members only'
  },
  {
    key: 'clinicians_and_member',
    value: 'Clinicians and Members'
  },
  {
    key: 'none',
    value: 'None (Dashboard Only)'
  }
]

export const isAlertOpen = (alertObj: IAlert): boolean => {
  return alertObj.status === 'OPEN'
}

export const a11yProps = (prefix: string, index: number): { id: string, 'aria-controls': string } => {
  return {
    id: `${prefix}-${index}`,
    'aria-controls': `${prefix}-${index}`
  }
}

export const getComparator = (order: 'desc' | 'asc'): (a: any, b: any) => number => {
  return order === 'desc' ? (a, b) => descendingComparator(a, b) : (a, b) => -descendingComparator(a, b)
}
