import React from 'react'
import moment from 'moment'
import { momentCalendarConfig } from '../../../utils/baseStringUtils'
import { getPregnancyWeightProfileStats } from '../../../utils/member/alertDetailUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

export default function PregnancyWeightCell (props: { memberObj: IMemberSummary }): JSX.Element {
  if (!props.memberObj.user.has_weight || !props.memberObj.weight) {
    const message = !props.memberObj.user.bt_bp_imei ? 'Device not set up' : 'No weight data'
    return <EmptyCell message={message} />
  }

  const { weightGain14D, weightIOMStatus } = getPregnancyWeightProfileStats(props.memberObj)
  const lastWeightMeasurement = moment(props.memberObj.weight.latest_measurement?.timestamp)

  const latestCol = (
    <Box>
      <span className='member-row__latest'>{Math.round(props.memberObj.weight.latest_measurement?.weight.value as number * 10) / 10}</span>
      <span className='member-row__latest-timestamp'><aside>{lastWeightMeasurement.calendar(null, momentCalendarConfig)}</aside></span>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={4}> {latestCol} </Grid>
      <Grid item xs={4} className='member-row__latest'> {weightGain14D} </Grid>
      <Grid item xs={4} className='member-row__latest'> {weightIOMStatus} </Grid>
    </Grid>
  )
}
