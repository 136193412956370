import React from 'react'
import Pagination from '../../elements/Pagination'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { type IAlertRows } from '../../../interfaces/alert.interface'
import { type IMemberHeaderColumn } from '../../../interfaces/member-summary.interface'

interface IMembersTableProps {
  rows: IAlertRows
  columns: { secondaryRow: IMemberHeaderColumn[], primaryRow: IMemberHeaderColumn[] }
  page: number
  pageStart: number
  pageEnd: number
  total: number
  viewActions: any
  handleItemClick: (id: string) => void
}

export default function MembersTable (props: IMembersTableProps): JSX.Element {
  const { rows, columns } = props
  if (!Object.keys(rows).length) return <></>

  const handleChangePage = (page: number): void => {
    props.viewActions.setPage(page)
  }

  const handlePagePrev = (): void => {
    props.viewActions.pagePrev()
  }

  const handlePageNext = (): void => {
    props.viewActions.pageNext()
  }

  const renderHeaderRow = (column: IMemberHeaderColumn, top?: number): JSX.Element => {
    return (
      <TableCell key={column.id} style={{ minWidth: column.minWidth, padding: 0, top }} align='center' colSpan={column.colSpan}>
        {column.label}
      </TableCell>
    )
  }

  const renderPaginator = (): JSX.Element => {
    return (
      <Pagination
        pageStart={props.pageStart + 1}
        pageEnd={props.pageEnd}
        itemCount={props.total ?? 0}
        handlePagePrev={handlePagePrev}
        handlePageNext={handlePageNext}
        page={props.page ?? 1}
        setPage={handleChangePage}
        custom
      />
    )
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {renderPaginator()}
      <TableContainer sx={{ maxHeight: '75vh' }}>
        <Table stickyHeader aria-label="members table">
          <TableHead sx={{ position: 'sticky' }}>
            <TableRow>{columns.primaryRow.map((column) => renderHeaderRow(column))}</TableRow>
            {columns.secondaryRow.length > 1 && <TableRow>{columns.secondaryRow.map((column) => renderHeaderRow(column, 25))}</TableRow>}
          </TableHead>
          <TableBody>
            {Object.keys(rows).map((memberId) =>
              <TableRow hover role="checkbox" tabIndex={-1} key={memberId} data-testid='member-row'>
                {columns.primaryRow.map((column) => {
                  const row = rows[memberId]
                  const width = column.maxWidth
                  const onClick = column.label ? () => { props.handleItemClick(memberId) } : () => {}
                  return (<TableCell key={column.id} onClick={onClick} colSpan={column.colSpan} style={{ padding: 0, cursor: 'pointer', width }}> {row[column.id]} </TableCell>)
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {renderPaginator()}
    </Paper>
  )
}
