import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { type IAnalyticsStore } from '../interfaces/store/analyticsStore.interface'
import moment from 'moment'

export const ANALYTICS_SCREENS = {
  POPULATION: 'Population',
  TRENDS: 'Trends [BETA]'
}

export const ANALYTICS_DRILLDOWN = {
  MEMBERS: 'member',
  SUSPENSIONS: 'suspension'
}

const initialState: IAnalyticsStore = {
  prevLocationKey: null,
  currentScreen: 0,
  allUsers: [],
  allAlerts: [],
  rangeSuspensions: [],
  currentSuspensions: [],
  onboardedUsers: [],
  dateRangeStart: moment().subtract(1, 'week').valueOf(),
  dateRangeEnd: moment().valueOf(),
  isDateRangeLoading: true,
  drilldown: null
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    resetView: (state: IAnalyticsStore) => {
      state.prevLocationKey = initialState.prevLocationKey
      state.currentScreen = initialState.currentScreen
      state.allUsers = initialState.allUsers
      state.allAlerts = initialState.allAlerts
      state.rangeSuspensions = initialState.rangeSuspensions
      state.currentSuspensions = initialState.currentSuspensions
      state.onboardedUsers = initialState.onboardedUsers
      state.dateRangeStart = initialState.dateRangeStart
      state.dateRangeEnd = initialState.dateRangeEnd
      state.drilldown = initialState.drilldown
      state.isDateRangeLoading = initialState.isDateRangeLoading
    },
    setLocationKey: (state: IAnalyticsStore, action) => {
      state.prevLocationKey = action.payload
    },
    populateAnalytics: (state: IAnalyticsStore, action) => {
      state.allUsers = action.payload.data.users
      state.allAlerts = action.payload.data.alerts
      state.rangeSuspensions = action.payload.data.suspensions
      state.currentSuspensions = action.payload.data.currentSuspensions
      state.onboardedUsers = _.filter(action.payload.data.users, u => !u.info.not_onboarded)
      state.isDateRangeLoading = false
    },
    setCurrentScreen: (state: IAnalyticsStore, action) => {
      state.currentScreen = action.payload
    },
    setDateRange: (state: IAnalyticsStore, action) => {
      state.dateRangeStart = action.payload.startDate
      state.dateRangeEnd = action.payload.endDate
      state.isDateRangeLoading = true
    },
    setDrilldown: (state: IAnalyticsStore, action) => {
      state.drilldown = action.payload
    }
  }
})

export const {
  resetView,
  setLocationKey,
  populateAnalytics,
  setCurrentScreen,
  setDateRange,
  setDrilldown
} = analyticsSlice.actions

export { analyticsSlice }
