import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { type ConnectedProps, connect } from 'react-redux'
import LoadingIndicator from '../elements/LoadingIndicator'
import { type AppDispatch, type RootState } from '../..'
import restApi from '../../api'
import { useNavigate } from 'react-router'
import AddMemberModal from './MembersPage/AddMemberModal'
import HeaderLogo from '../layout/HeaderLogo'

function EpicSSOPage (props: PropsFromRedux): JSX.Element {
  const navigate = useNavigate()

  useEffect(() => {
    const hasPatientContext = props.epic?.patientId !== '' || !!props.epic?.newPatient

    if (!props.epic?.iss || !props.epic?.launch) return
    if (!hasPatientContext) props.singleSignOn(props.epic.iss, props.epic.launch)
  }, [props.epic?.iss])

  useEffect(() => {
    const hasPatientContext = props.epic?.patientId !== '' || !!props.epic?.newPatient
    if (hasPatientContext && props.user && !props.user.signed_tos) navigate('/welcome', { replace: true })
  }, [props.user])

  useEffect(() => {
    const patient = props.epic?.patientId
    navigateToProfile(patient)
  }, [props.epic?.patientId])

  const navigateToProfile = (patientId?: string): void => {
    if (patientId) navigate(`/profile/${patientId}`, { replace: true })
  }

  const renderLoadingScreen = (): JSX.Element => {
    return (
      <Box>
        <HeaderLogo forDesktop></HeaderLogo>
        <Typography variant='h4' textAlign='center'> Just a second while we sign you in...</Typography>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <Box>
      {props.epic?.isLoading && renderLoadingScreen()}
      {props.epic?.newPatient && <AddMemberModal isOpen={true} onModalClose={() => {}} epicPatient={props.epic.newPatient} />}
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    epic: state.epic,
    user: state.userSession.user
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    singleSignOn (iss, launch) {
      dispatch(restApi.actions.sso({ iss, launch }))
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(EpicSSOPage)
