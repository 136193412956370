import _ from 'lodash'
import moment from 'moment'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { descendingComparator } from '../constants/constants'
import { productConfig } from '../config/config-service'
import { type IMemberCache } from '../interfaces/store/memberCache.interface'
import { type ICarePlanItem } from '../interfaces/careplan.interface'
import { type IMemberSummary } from '../interfaces/member-summary.interface'

interface ICPDataPayload {
  userId: string
  itemId: string
  data: ICarePlanItem
}

const getCareplanData = createAsyncThunk('membersCache/updateMemberCareplanRow', async (params: { dispatch: any, payload: ICPDataPayload }) => {
  const { dispatch, payload } = params
  const { userId, itemId, data } = payload
  const updateTimestamp = moment().valueOf()
  await dispatch(setMemberCareplanRow({ userId, itemId, data, updateTimestamp, clearUpdateTimestamp: false }))

  setTimeout(() => {
    // Clear {updatedTime} after two seconds
    dispatch(setMemberCareplanRow({ userId, itemId, data, updateTimestamp, clearUpdateTimestamp: true }))
  }, 2000)
})

export const updateMemberCareplanRow = (payload: ICPDataPayload) => {
  return (dispatch: any) => {
    dispatch(getCareplanData({ dispatch, payload }))
  }
}

const initialState: IMemberCache = {
  entities: {},
  lcuList: []
}

const membersCacheSlice = createSlice({
  name: 'membersCache',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCareplanData.fulfilled, (state, action) => {
      // todo could prob move the dispatch here from getCareplanData
    })

    builder.addCase(getCareplanData.rejected, (state, action) => {
      // noop
    })

    builder.addCase(getCareplanData.pending, (state, action) => {
      // todo could prob move the dispatch here from getCareplanData
    })
  },
  reducers: {
    insertSummaries: (state, action: { payload: { membersCache: IMemberSummary [] } }) => {
      const cache = {}
      const hasLCUFilter = productConfig().members.refineSidebar.hasLCUFilter

      const lcuList: string [] = hasLCUFilter
        ? action.payload.membersCache
          .filter(item => item.user?.lcu) // filter out empty lcu fields
          .map(item => item.user?.lcu ?? '')
          .sort((a, b) => -descendingComparator(a?.toLowerCase(), b?.toLowerCase()))
        : []

      action.payload.membersCache.forEach((s) => {
        if (s?.user?.id) {
          cache[s.user.id] = s
        } else {
          console.info('user field was null')
        }
      })

      if (lcuList?.length) state.lcuList = [...new Set<string>(lcuList)] // filter out any duplicates by converting to a Set
      state.entities = cache
    },
    updateMemberSummary: (state, action) => {
      if (!action.payload.data.user?.id) return
      const member = { ...state.entities[action.payload.data.user.id] } ?? {}
      const newMember = _.merge(member, action.payload.data)
      const carePlanItems = ['weight', 'activity', 'sleep', 'blood_pressure', 'pulse_ox', 'alerts', 'food',
        'temperature', 'heart_rate', 'respiratory_rate', 'activity_level', 'body_position', 'on_off_body',
        'survey_responses', 'blood_glucose', 'deleted_weight']

      carePlanItems.forEach((m) => {
        newMember[m] = action.payload.data[m]
      })

      state.entities[action.payload.data.user.id] = newMember
    },
    updateMemberUser: (state: IMemberCache, action) => {
      const member = state.entities[action.payload.data.id]
      if (member) member.user = action.payload.data
      else { // self signup case, where user not in cache
        const user: IMemberSummary = {
          user: action.payload.data,
          computed_timestamp: Date.now(),
          startdate: '',
          enddate: '',
          surveys: []
        }
        state.entities[action.payload.data.id] = user
      }
    },
    updateMemberSuggestedAddress: (state, action) => {
      if (!action.payload.data) state.suggestedAddress = null
      else state.suggestedAddress = action.payload.data
    },
    updateMemberCareplan: (state, action) => {
      // Note: this is set regardless of whether member exists, and ProfilePage expects that a member object always exists after the request, with [] in careplan prop
      const member = state.entities[action.payload.userId] ?? {}
      const newMember = { ...member, careplan: action.payload.data.data }
      state.entities[action.payload.userId] = newMember
    },
    setMemberCareplanRow: (state: any, action) => {
      // Get previous care plan
      const prevCareplan = state.entities[action.payload.userId].careplan // _.get(state, [action.payload.userId, 'careplan'])
      if (!prevCareplan) return

      // // Get previous care plan row
      let prevRowIndex = _.findIndex(prevCareplan,
        (row: any) => row.id === action.payload.itemId // If global->individual
      )
      if (prevRowIndex < 0) {
        prevRowIndex = _.findIndex(prevCareplan,
          (row: any) => row.id === action.payload.data.id // If individual->individual
        )
      }

      // Define the {updatedTime} of the new care plan row
      let updatedTime = action.payload.updateTimestamp

      if (action.payload.clearUpdateTimestamp) {
        // The care plan item might have been updated in the interim, so only clear this if the
        // timestamps match.
        if (prevCareplan[prevRowIndex].updatedTime === action.payload.updateTimestamp) {
          updatedTime = null
        } else { // Otherwise, don't update state at all
          return state
        }
      }

      prevCareplan[prevRowIndex] = { ...action.payload.data, updatedTime } // Update the row with new data
    },
    updateMemberStarredNotes: (state, action) => {
      const newMember = state.entities[action.payload.userId] ?? {}
      if (newMember.user) {
        newMember.user = { ...newMember.user, starred_notes: action.payload.data.notes }
      }
    },
    updateAlert: (state: any, action) => {
      // get the user associated with the updated alert
      const entity = state.entities[action.payload.alert.user_id]
      if (entity) {
        // find the original alert in the array
        const itemIndex = _.findIndex(entity.alerts, (item: any) => item.id === action.payload.alert.id)
        if (itemIndex >= 0) {
          // overwrite the existing alert with its updated information
          entity.alerts[itemIndex] = action.payload.alert
        }
      }
    },
    handleWeightDeletion: (state, action) => {
      const user = state.entities[action.payload.user_id]
      if (user?.weight?.data) {
        user.weight.data = user.weight.data.filter(w => w.timestamp !== action.payload.timestamp)
        // note: user.weight.latest_measurement is not updated
      }

      if (user?.deleted_weight?.data) {
        const newDeletedWeight = {
          timestamp: action.payload.timestamp,
          weight: {
            value: Math.round((action.payload.weight * 2.20462) * 100) / 100 // convert to lb and round; alternatively we could make the backend's response more amenable to a simple UI update
          },
          source: action.payload.source
        }

        user.deleted_weight.data.push(newDeletedWeight)
        user.deleted_weight.data.sort((w1, w2) => (w1.timestamp < w2.timestamp) ? 1 : -1) // descending
      }
    },
    handleWeightRestoration: (state, action) => {
      const observation = action.payload.observations[0]
      const user = state.entities[observation.user_id]

      if (user?.deleted_weight?.data) {
        user.deleted_weight.data = user.deleted_weight.data.filter(w => w.timestamp !== action.payload.observations[0].timestamp)
      }

      if (user?.weight?.data) {
        const newWeight = {
          timestamp: observation.timestamp,
          weight: {
            value: Math.round((observation.weight * 2.20462) * 10) / 10 // convert to lb and round; alternatively we could make the backend's response more amenable to a simple UI update
          },
          source: observation.source
        }

        user.weight.data.push(newWeight)
        user.weight.data.sort((w1, w2) => (w1.timestamp < w2.timestamp) ? 1 : -1) // descending
      }
    },
    changeStarStatus: (state, action) => {
      const entity = state.entities[action.payload.memberId]
      if (action.payload.starred) {
        // Placeholder star is inserted while we are waiting for server response
        const placeholderStar = [{ clinician_id: action.payload.clinicianId, timestamp: new Date().getTime() }]
        entity.user.stars = placeholderStar
      } else {
        entity.user.stars = []
      }
    }
  }
})

export const {
  insertSummaries,
  updateMemberSummary,
  updateMemberUser,
  updateMemberSuggestedAddress,
  updateMemberCareplan,
  setMemberCareplanRow,
  updateMemberStarredNotes,
  updateAlert,
  handleWeightDeletion,
  handleWeightRestoration,
  changeStarStatus
} = membersCacheSlice.actions
export { membersCacheSlice }
