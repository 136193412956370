import { createSlice } from '@reduxjs/toolkit'

const rootSlice = createSlice({
  initialState: {},
  name: 'root',
  reducers: {
    clear: (state: any) => {
      state = null
    }
  }
})

export const { clear } = rootSlice.actions
export { rootSlice }
