import React from 'react'
import AnalyticsChart from '../layout/AnalyticsChart'
import getGenderChartOptions from '../charts/genderChart'
import { type IAnalyticsModuleProps } from '../../../../interfaces/store/analyticsStore.interface'
import { Box } from '@mui/material'

export default function Gender (props: IAnalyticsModuleProps): JSX.Element {
  const containerId = 'ax-gender-chart'
  return (
    <Box className={`ax-chart-container ${containerId}`}>
      <AnalyticsChart
        containerId={containerId}
        chartOptions={getGenderChartOptions(props.users, props.analyticsActions)}
      />
    </Box>
  )
}
