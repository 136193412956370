
import React from 'react'
import moment from 'moment'
import { momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import Grid from '@mui/material/Grid'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

export default function ProgramStepCell (props: { memberObj: IMemberSummary }): JSX.Element {
  return (
    <Grid container>
      <Grid item xs={12}> {props.memberObj.pregnancy_info?.program_step} </Grid>
      <Grid item xs={12}> <aside>{moment(props.memberObj.pregnancy_info?.program_step_date).calendar(null, momentCalendarDateConfig)}</aside> </Grid>
    </Grid>
  )
}
