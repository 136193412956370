import reduxApi from 'redux-api'
import { customApiUrl } from './utils/apiUtils'
import authEndpoints from './api/authEndpoints'
import accountEndpoints from './api/accountEndpoints'
import populationEndpoints from './api/populationEndpoints'
import memberEndpoints from './api/memberEndpoints'

const customApiHeaders = {
  'X-App-Version': 2
}

/* ********************************************************************************
 *
 * Main reduxApi config object. All endpoint definitions live inside the submodules.
 *
 * ********************************************************************************/

const endpoints = {
  ...authEndpoints,
  ...accountEndpoints,
  ...populationEndpoints,
  ...memberEndpoints
}

const apiConfig = reduxApi(endpoints)
  .use('rootUrl', customApiUrl)
  .use('options', (url, params, getState) => {
    const opts = { credentials: 'include' }
    const baseApiHeaders = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getState().csrf,
      'X-Web-App': true
    }

    if (url.endsWith('csrf')) return opts

    return {
      ...opts,
      headers: {
        ...baseApiHeaders,
        ...customApiHeaders
      }
    }
  })

export default apiConfig
