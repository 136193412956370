
import _ from 'lodash'
import React from 'react'
import { RANGE_VIEWS, getRangeBounds } from '../member/dateUtils'
import moment from 'moment'
import { getWeekOfPregnancy, getTrimesterOfPregnancy, getTrimesterBounds, PREG_RANGE_VIEWS, BASE_RANGE_VIEWS } from '../baseDateUtils'
import { getColumnWidth as getColumnWidthBase, getXAxisForRange as getXAxisForRangeBase } from './baseChartUtils'
import { productConfig } from '../../config/config-service'
import { Box } from '@mui/material'
import WeightChart from '../../components/elements/Charts/WeightChart'
import BloodPressureChart from '../../components/elements/Charts/BloodPressureChart'
import BloodGlucoseChart from '../../components/elements/Charts/BloodGlucoseChart'
import BloodOxygenChart from '../../components/elements/Charts/BloodOxygenChart'
import PregnancyWeightChart from '../../components/elements/Charts/PregnancyWeightChart'
import ActivityChart from '../../components/elements/Charts/ActivityChart'
import SleepChart from '../../components/elements/Charts/SleepChart'
import FoodChart from '../../components/elements/Charts/FoodChart'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'
import { type IAlertClick } from '../../interfaces/alert.interface'
import { type IWeightClick } from '../../interfaces/weight-summary.interface'
import { type IChartType } from '../../interfaces/chart.interface'
import { type XAxisOptions, type Options } from 'highcharts'
import RespiratoryRateChart from '../../components/elements/Charts/RestingRespiratoryRate'
import HeartRateChart from '../../components/elements/Charts/RestingHeartRate'
import TemperatureChart from '../../components/elements/Charts/SkinTemperatureChart'

export default function renderProfileCharts (memberObj: IMemberSummary, dashboardViewStore: IDashboardStore, handleAlertOpen: IAlertClick, handleClickDeleteWeight: IWeightClick, handleClickRestoreWeight: IWeightClick): JSX.Element {
  const profileChartList = productConfig().profile.charts

  const getChartByName = (name: IChartType): JSX.Element | null => {
    switch (name) {
      case 'weight':
        return (
          <WeightChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleDeleteWeight={handleClickDeleteWeight}
            handleRestoreWeight={handleClickRestoreWeight}
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'blood_pressure':
        return (
          <BloodPressureChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'blood_glucose':
        return (
          <BloodGlucoseChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'pulse_ox':
        return (
          <BloodOxygenChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            handleAlertClick={handleAlertOpen}
            showMemberAlerts
          />
        )
      case 'pregnancyWeight':
        return (
          <PregnancyWeightChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleDeleteWeight={handleClickDeleteWeight}
            handleRestoreWeight={handleClickRestoreWeight}
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'activity':
        return (
          <ActivityChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'sleep':
        return (
          <SleepChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'food':
        return (
          <FoodChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'temperature':
        return (
          <TemperatureChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'heart_rate':
        return (
          <HeartRateChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'respiratory_rate':
        return (
          <RespiratoryRateChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )

      default:
        return null
    }
  }

  return (
    <Box>
      {profileChartList?.map((profileChart) => {
        return getChartByName(profileChart)
      })}
    </Box>
  )
}

export function getColumnWidth (dashboardViewStore: IDashboardStore, isMobile: boolean): number {
  if (productConfig().profile.hasPregnancyCharts) {
    if (dashboardViewStore.rangeZoom === BASE_RANGE_VIEWS.WEEK) return 40
    else if (dashboardViewStore.rangeZoom === PREG_RANGE_VIEWS.TRIMESTER) return 4
    else if (dashboardViewStore.rangeZoom === PREG_RANGE_VIEWS.WEEK_CONDENSED) return 20

    return 1
  } else {
    return getColumnWidthBase(dashboardViewStore, isMobile)
  }
}

export function getMarkerRadius (dashboardViewStore: IDashboardStore): number {
  if (productConfig().profile.hasPregnancyCharts) {
    if (dashboardViewStore.rangeZoom === BASE_RANGE_VIEWS.WEEK) return 4
    else if (dashboardViewStore.rangeZoom === PREG_RANGE_VIEWS.TRIMESTER) return 2
    return 0
  } else {
    if (dashboardViewStore.rangeZoom === RANGE_VIEWS.YEAR) return 0
    return 4
  }
}

export function getXAxisForRange (memberObj: IMemberSummary, dashboardViewStore: IDashboardStore, isMobile: boolean): any {
  if (productConfig().profile.hasPregnancyCharts) {
    return getPregnancyXAxisForRange(memberObj, dashboardViewStore)
  } else {
    return getXAxisForRangeBase(memberObj, dashboardViewStore, isMobile)
  }
}

/* ********************************************************************************
 * X-axis bounds and formatting
 * ********************************************************************************/
function getXAxisForPregnancy (memberObj: IMemberSummary): XAxisOptions {
  const xAxis: XAxisOptions = {
    labels: {}
  }

  const startDate = +moment(memberObj.user.conception_date)
  const endDate = +moment.max(moment(memberObj.user.conception_date).add(40, 'weeks'), moment())

  xAxis.min = startDate
  xAxis.max = endDate
  xAxis.minPadding = 0
  xAxis.maxPadding = 0

  xAxis.plotLines = _.map(_.range(3), (d) => {
    const plotLine = {
      value: +moment(getTrimesterBounds(memberObj, d).startDate),
      width: 2,
      className: 'trimester-plot-line'
    }

    return plotLine
  })

  xAxis.tickLength = 0
  xAxis.tickPositioner = () => _.map(
    _.range(3), d => +moment(getTrimesterBounds(memberObj, d).startDate).add(6.65, 'weeks')
  )

  if (xAxis.labels) {
    xAxis.labels.formatter = function format () {
      return `Trimester ${getTrimesterOfPregnancy(memberObj, this.value)}`
    }
  }

  return xAxis
}

function getPregnancyXAxisForRange (memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): XAxisOptions {
  // Base x-axis properties
  const xAxis: XAxisOptions = {
    type: 'category',
    labels: {
      y: 32
    },
    plotLines: [],
    plotBands: []
  }

  // Get start/end dates
  const { rangeZoom, rangeStart } = dashboardViewStore
  const { startDate, endDate } = getRangeBounds(memberObj, rangeZoom, rangeStart ?? '')

  const chartStartDate = +moment(startDate).subtract(1, 'days').startOf('day')
  const chartEndDate = +moment(endDate).endOf('day')

  xAxis.min = +chartStartDate
  xAxis.max = +chartEndDate
  xAxis.minPadding = 0
  xAxis.maxPadding = 0

  switch (rangeZoom) {
    case BASE_RANGE_VIEWS.WEEK:
      xAxis.tickPositioner = () => _.map(_.range(7), d => +moment(startDate).add(d, 'days'))

      if (xAxis.labels) {
        xAxis.labels.formatter = function format () {
          return moment(this.value).format('dd<br/>M/D')
        }
      }
      break

    case PREG_RANGE_VIEWS.TRIMESTER: {
      const { endDate: trimesterEndDate } = getTrimesterBounds(
        memberObj,
        getTrimesterOfPregnancy(memberObj, startDate)
      )

      xAxis.max = Number(trimesterEndDate?.valueOf())
      xAxis.tickPositioner = () => _.map(
        _.range(moment(trimesterEndDate).diff(startDate, 'weeks')),
        w => +moment(startDate).add(w, 'weeks')
      )
      if (xAxis.labels) {
        xAxis.labels.formatter = function format () {
          return `Week ${getWeekOfPregnancy(memberObj, this.value)}`
        }
      }
      break
    }

    case PREG_RANGE_VIEWS.PREGNANCY:
    default:
      _.merge(xAxis, getXAxisForPregnancy(memberObj))
      break
  }

  // Draw a line for the join date (presumably the first time we have data)
  const joinedDate = moment(memberObj.user.created)
  xAxis.plotLines?.push({
    value: +joinedDate,
    className: 'joined-plot-line'
  })

  if (xAxis.labels) xAxis.labels.useHTML = true

  return xAxis
}

export function getDefaultChartOptions (className: string): Options {
  return {
    chart: { className },

    legend: {
      symbolRadius: 0,
      itemMarginTop: 12
    },

    plotOptions: {
      line: {
        marker: { enabled: true }
      }
    },

    yAxis: [{
      title: { text: '' },
      labels: { format: '{value}' }
    }],

    tooltip: {
      useHTML: true,
      padding: 12,
      pointFormat: '<div>{point.description}</div>',
      shared: true // https://api.highcharts.com/highcharts/tooltip.shared; helps a lot with stickiness
    },

    series: []
  }
}
