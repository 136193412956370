import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { getSimpleDisplayName } from '../../../../utils/baseStringUtils'
import { useNavigate } from 'react-router'
import { type IAnalyticsUser } from '../../../../interfaces/store/analyticsStore.interface'
import { type Options } from 'highcharts'

export default function getSleepChartOptions (users: IAnalyticsUser []): Options {
  const navigate = useNavigate()
  const usersWithData = _.filter(users, u => !!u.sleep.avg_nightly_total_sleep)

  const chartOptions = {
    chart: {
      type: 'scatter'
    },

    legend: {
      enabled: false
    },

    xAxis: {
      title: {
        text: 'Average sleep length (hrs)'
      },
      minPadding: 0.2,
      maxPadding: 0.2
    },

    yAxis: {
      title: {
        text: 'Average sleep disruptions (#)'
      }
    },

    series: [{
      className: 'series-0',
      marker: {
        radius: 5
      },

      data: _.map(usersWithData, u => ({
        x: u.sleep.avg_nightly_total_sleep,
        y: u.sleep.avg_nightly_disruptions,
        name: getSimpleDisplayName(u.info),

        events: {
          click: () => {
            navigate(`/profile/${u.id}`)
          }
        }
      }))
    }],
    tooltip: {
      headerFormat: '',
      useHTML: true,
      borderColor: 'none',
      pointFormatter: function format () {
        const point = this as any
        return `
            <span class="ax-text-bold">${point.name as string}</span><br/>
            Avg. sleep length: <em>${point.x as number} hours</em><br/>
            Avg. nightly disruptions: <em>${point.y as number}</em><br/>
          `
      }
    }
  }

  return _.merge(_.cloneDeep(baseChartOptions), chartOptions)
}
