import moment, { type MomentInput } from 'moment'
import { BASE_RANGE_VIEWS, PREG_RANGE_VIEWS, getPregRangeBounds } from '../baseDateUtils'
import { productConfig } from '../../config/config-service'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDateRange } from '../../interfaces/ejenta.interface'

const profileConfig = productConfig().profile

export const RANGE_VIEWS = profileConfig.showPregnancyInfo ? PREG_RANGE_VIEWS : BASE_RANGE_VIEWS

export function getRangeBounds (member: IMemberSummary, rangeZoom: string, rangeStart: MomentInput): IDateRange {
  if (profileConfig.showPregnancyInfo) {
    return getPregRangeBounds(member, rangeZoom, rangeStart)
  }

  const momentFormat = (m: MomentInput): string => moment(m).format('YYYY-MM-DD')
  const duration = moment.duration(rangeZoom).subtract(1, 'days')
  let startDate: MomentInput
  let endDate: MomentInput

  if (!rangeStart) {
    startDate = moment().subtract(duration)
    endDate = moment()
  } else {
    startDate = rangeStart
    endDate = moment(rangeStart).add(duration)
  }

  return { startDate: momentFormat(startDate), endDate: momentFormat(endDate) }
}
