import _ from 'lodash'
import React from 'react'
import { Checkbox, Typography, FormGroup, FormControlLabel } from '@mui/material'
import { type IMemberSummary } from '../interfaces/member-summary.interface'
import { type IFilterGroup, type IDefaultStore } from '../interfaces/store/defaultStore.interface'
import { type ISidebarFilters, type ISidebarFilter } from '../interfaces/ejenta.interface'

/* ***************************************************************************************
 * Shared config & helpers between the alerts list and the members list.
 * ***************************************************************************************/
export function getInitialState ({ sortBy, filters }): IDefaultStore {
  return {
    sortBy,
    filters,
    page: 1, // this is the current page INDEX
    searchQueryReset: false,
    prevLocationKey: null,
    currentPage: [], // Only the current page of the list; this is the actual CONTENT in the list
    searchQuery: '',
    urlFilters: {},
    filterCounts: {},
    selectedItemId: null
  }
}

export function filterBySearchQuery (objList: IMemberSummary [], searchQuery: string): IMemberSummary[] {
  const normalizedSearchQuery = searchQuery.toLowerCase()
  const processedObjList: IMemberSummary [] = objList.slice(0)

  return processedObjList.filter((obj: IMemberSummary) => {
    const firstName = obj.user.first_name ?? ''
    const lastName = obj.user.last_name ?? ''
    const medicalId = obj.user.medical_id ?? ''
    const phone = obj.user.phone ?? ''
    const email = obj.user.email ?? ''
    const lcu = obj.user.lcu ?? ''
    const employerGroup = obj.user.employer_group ?? ''
    const normalizedMemberName = `${firstName} ${lastName} ${medicalId} ${phone} ${email} ${lcu} ${employerGroup}`
      .toLowerCase()
    return (normalizedMemberName.includes(normalizedSearchQuery))
  })
}

export function filterByFilters ({ currentList, selectedFilters, allFilters, clinicianId }): IMemberSummary [] {
  const filteredList = currentList.filter((obj: IMemberSummary) =>
    _.reduce(_.values(allFilters), (isAlertAllowed, filterGroup) => {
      // If no filters in a group are selected, allow everything through
      if (!_.intersection(selectedFilters, _.map(filterGroup.filters, f => f.id)).length) {
        return isAlertAllowed
      }

      // If >=1 filters in a group are selected, allow anything through that matches at least one
      const isAlertAllowedForGroup = _.reduce(filterGroup.filters, (acc, f) => {
        if (selectedFilters.indexOf(f.id) === -1) {
          return acc
        }

        return acc || f.test(obj, clinicianId)
      }, false)

      return isAlertAllowed && isAlertAllowedForGroup
    }, true)
  )

  return filteredList
}

export function filterByCareManager ({ currentList, careManagerId }): IMemberSummary [] {
  if (careManagerId === 'OPTION_ALL') return currentList
  else if (careManagerId === 'OPTION_NONE') return currentList.filter(obj => obj.user.care_manager_id === null)
  else return currentList.filter(obj => obj.user.care_manager_id === careManagerId)
}

export function filterByLCU ({ currentList, selectedLCU }): IMemberSummary [] {
  if (!selectedLCU || selectedLCU === 'All') return currentList
  else return currentList.filter(obj => obj.user.lcu === selectedLCU)
}

export function renderFilters (allFilters: Record<string, ISidebarFilters> | IFilterGroup, selectedFilters: string [], filterCounts: Record<string, number>, handleFilterChange: (filterId: string) => void): JSX.Element {
  const renderFiltersInGroup = (filters: ISidebarFilter []): JSX.Element [] => {
    return _.map(filters, (f: ISidebarFilter) => {
      const labelHtml = `${f.displayTitle} (${filterCounts[f.id] ?? 0})`
      const checkboxControl = <Checkbox size='small' onChange={() => { handleFilterChange(f.id) }} checked={selectedFilters.includes(f.id)} />

      return (
        <FormGroup key={f.id} sx={{ ml: 1 }}>
          <FormControlLabel sx={{ '& .MuiTypography-root': { fontSize: 14, fontWeight: 'light' } }} disabled={f.disabled} control={checkboxControl} label={labelHtml} />
        </FormGroup>
      )
    }
    )
  }

  const renderFilterGroup = (group: ISidebarFilters | Record<string, any>): JSX.Element | null => {
    if (!group?.displayTitle) return null

    const { displayTitle, filters } = group
    return (
      <div className='list-controls__group' key={displayTitle} data-testid='list-controls__group'>
        <Typography variant='subtitle2' color='#7f7f7f'>{displayTitle}</Typography>
        {renderFiltersInGroup(filters)}
      </div>
    )
  }

  return (
    <div className='list-controls__section' data-testid='list-controls__filter-section'>
      <Typography variant='subtitle2'>Filter by</Typography>
      {_.map(_.values(allFilters), group => renderFilterGroup(group))}
    </div>
  )
}
