import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { getAlertTypeCounts } from './alertChartConfig'
import { type IAnalyticsAlert } from '../../../../interfaces/store/analyticsStore.interface'
import { type Options } from 'highcharts'

function getAlertTypeChartOptions (alerts: IAnalyticsAlert []): Options {
  const alertTypeCounts = getAlertTypeCounts(alerts)

  const alertTypeChartOptions: Options = {
    plotOptions: {
      pie: {
        states: {
          hover: {
            brightness: 0,
            halo: {
              opacity: 0.1
            }
          }
        }
      }
    },

    series: [{
      name: 'Alerts',
      type: 'pie',
      data: _.map(alertTypeCounts, c => ({
        name: c.name,
        y: c.value,
        className: c.className
      }))
    }],
    tooltip: {
      headerFormat: '',
      useHTML: true,
      borderColor: 'none',
      pointFormatter: function format () {
        const point = this as any
        return `
          <span class="ax-text-bold">${point.name as string}</span><br/>
          Alerts: <em>${point.y as number}</em>
        `
      }
    }
  }

  return _.merge(_.cloneDeep(baseChartOptions), alertTypeChartOptions)
}

export default getAlertTypeChartOptions
