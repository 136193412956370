import _ from 'lodash'
import { type IAlertDayCount, type IAlertTypeCount, type IAnalyticsAlert } from '../../../../interfaces/store/analyticsStore.interface'
import { productConfig } from '../../../../config/config-service'
import { DAYS_OF_WEEK } from '../../../../constants/constants'

export const getAlertDayCounts = (alerts: IAnalyticsAlert []): IAlertDayCount [] => {
  if (productConfig().profile.showPregnancyInfo) return getPregnancyAlertDayCounts(alerts)
  else return getDefaultAlertDayCounts(alerts)
}

export const getAlertTypeCounts = (alerts: IAnalyticsAlert []): IAlertTypeCount [] => {
  if (productConfig().profile.showPregnancyInfo) return getPregnancyAlertTypeCounts(alerts)
  else return getDefaultAlertTypeCounts(alerts)
}

const sortByDay = (alerts: IAnalyticsAlert [], alertType: string): number [] => {
  const alertsOfType = _.filter(alerts,
    a => alertType === 'weight_tracking'
      ? (a.alert_type === alertType || a.alert_type === 'multi_tracking')
      : a.alert_type === alertType
  )

  return _.map(DAYS_OF_WEEK, day => _.filter(alertsOfType, a => a.day_of_week === day).length)
}

const generateAlertDayCount = (name: string, className: string, alertType: string, alerts: IAnalyticsAlert []): IAlertDayCount => {
  return {
    name,
    className,
    value: sortByDay(alerts, alertType)
  }
}

const generateAlertTypeCount = (name: string, className: string, alertType: string, alerts: IAnalyticsAlert []): IAlertTypeCount => {
  return {
    name,
    className,
    value: _.filter(alerts, a => alertType === 'weight_tracking' ? (a.alert_type === alertType || a.alert_type === 'multi_tracking') : a.alert_type === alertType).length
  }
}

const getMissingDataDayCounts = (alerts: IAnalyticsAlert []): IAlertDayCount [] => {
  return [
    generateAlertDayCount('No weight data', 'noData-0', 'weight_tracking', alerts),
    generateAlertDayCount('No activity data', 'noData-1', 'activity_tracking', alerts)
  ]
}

const getMissingDataTypeCounts = (alerts: IAnalyticsAlert []): IAlertTypeCount [] => {
  return [
    generateAlertTypeCount('No weight data', 'noData-0', 'weight_tracking', alerts),
    generateAlertTypeCount('No activity data', 'noData-1', 'activity_tracking', alerts)
  ]
}

/* ****************************************************************************
 * Configuration for alertDayChart.js and alertTypeChart.js
 * ****************************************************************************/
export function getDefaultAlertDayCounts (alerts: IAnalyticsAlert []): IAlertDayCount [] {
  return [
    generateAlertDayCount('Weight gain', 'series-0', 'weight_delta', alerts),
    generateAlertDayCount('Activity decline', 'series-1', 'activity_relative', alerts),
    ...getMissingDataDayCounts(alerts)
  ]
}

export function getDefaultAlertTypeCounts (alerts: IAnalyticsAlert []): IAlertTypeCount [] {
  return [
    generateAlertTypeCount('Weight gain', 'series-0', 'weight_delta', alerts),
    generateAlertTypeCount('Activity decline', 'series-1', 'activity_relative', alerts),
    ...getMissingDataTypeCounts(alerts)
  ]
}

export function getPregnancyAlertDayCounts (alerts: IAnalyticsAlert []): IAlertDayCount [] {
  return [
    generateAlertDayCount('Weight gain', 'series-0', 'weight_relative', alerts),
    generateAlertDayCount('Over recommended weight', 'series-1', 'pregnancy_iom', alerts),
    generateAlertDayCount('Low activity', 'series-2', 'activity_target', alerts),
    ...getMissingDataDayCounts(alerts)
  ]
}

export function getPregnancyAlertTypeCounts (alerts: IAnalyticsAlert []): IAlertTypeCount [] {
  return [
    generateAlertTypeCount('Weight gain', 'series-0', 'weight_relative', alerts),
    generateAlertTypeCount('Over recommended weight', 'series-1', 'pregnancy_iom', alerts),
    generateAlertTypeCount('Low activity', 'series-2', 'activity_target', alerts),
    ...getMissingDataTypeCounts(alerts)
  ]
}
