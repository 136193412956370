import React from 'react'
import { Grid } from '@mui/material'
import { productConfig } from '../../../../config/config-service'
import { getMemberAddressFields } from '../../../../constants/customMemberFields'

export default function MemberAddressFields (): JSX.Element {
  const memberFields = getMemberAddressFields()
  const profileConfig = productConfig().profile

  const getGridItem = (item: string, xs = 12, sm = 6): JSX.Element | null => {
    // @ts-expect-error undefined possible for formInput method
    return (<Grid item xs={xs} sm={sm} key={item}>{memberFields[item].formInput()}</Grid>)
  }

  const renderProfileFormFields = (): JSX.Element => {
    return (
      <Grid container spacing={1} data-testid='member-profile-form-fields' sx={{ mb: 2 }}>
        {profileConfig.addressFormFields?.map(item => getGridItem(item))}
      </Grid>
    )
  }

  return renderProfileFormFields()
}
