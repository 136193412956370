import React from 'react'
import restApi from '../../../api'
import { type ConnectedProps, connect } from 'react-redux'
import { customApiUrl } from '../../../utils/apiUtils'
import { Box, Button, Card, CardContent } from '@mui/material'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type AppDispatch } from '../../..'

interface ILinkedAccountsProps extends PropsFromRedux {
  memberObj: IMemberSummary
}

function LinkedAccountsPane (props: ILinkedAccountsProps): JSX.Element | null {
  const renderLinkedAccounts = (): JSX.Element | null => {
    if (!props.memberObj) return null

    let actionLink = (
      <Button color='primary' variant='contained' href={`${customApiUrl}/auth/fitbit?id=${props.memberObj.user?.id as string}&app=2`} target='_blank'>Link Fitbit account</Button>
    )

    if (props.memberObj.user.has_fitbit) {
      actionLink = (
        <Button color='secondary' variant='outlined' onClick={() => props.apiActions.removeDevice({ id: props.memberObj.user.id, sensorType: 'Fitbit' })}>
          Unlink Fitbit account
        </Button>
      )
    }

    return (
      <Card variant='outlined' data-testid='profile-devices__linked-accounts-pane' sx={{ mb: 2 }}>
        <CardContent>
          <div className='edit-profile-pane__header'>Linked accounts</div>

          {!!props.memberObj.user.has_fitbit &&
            <div>When a member's device account is linked, device data appears in Ejenta. <span className='u-strong'>We do not recommend unlinking accounts.</span></div>}

          <Box className='edit-profile-pane__row profile-devices'>
            <div className='profile-devices__status'>
              {!!props.memberObj.user.has_fitbit && <span className='is-linked'>Fitbit account <strong>linked</strong></span>}
              {!props.memberObj.user.has_fitbit && <span className='is-unlinked'>No Fitbit account linked</span>}
            </div>

            <div className='edit-profile-pane__row-action'> {actionLink} </div>
          </Box>
        </CardContent>
      </Card>
    )
  }

  return renderLinkedAccounts()
}

function mapDispatch (dispatch: AppDispatch): { apiActions: any } {
  return {
    apiActions: {
      removeDevice: (pathVars) => {
        dispatch(restApi.actions.removeDevice(pathVars))
      }
    }
  }
}

const connector = connect(null, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(LinkedAccountsPane)
