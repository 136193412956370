import { NavLink } from 'react-router-dom'
import React from 'react'
import ContentSidebar from './ContentSidebar'

/* *****************************************************************************
 * Sidebar on settings pages (default care plan, account settings, help)
 * *****************************************************************************/

const SettingsSidebar = (): JSX.Element => {
  return (
    <ContentSidebar drawerWidth={220}>
      <div className='content-sidebar__module'>
        <div className='content-sidebar__nav'>
          <NavLink
            className={({ isActive }) => 'content-sidebar__nav__item' + (isActive ? ' is-selected' : '')}
            to='/careplan'
          >Care plan
          </NavLink>

          <NavLink
            className={({ isActive }) => 'content-sidebar__nav__item' + (isActive ? ' is-selected' : '')}
            to='/account'
          >Account
          </NavLink>
          <NavLink
            className={({ isActive }) => 'content-sidebar__nav__item' + (isActive ? ' is-selected' : '')}
            to='/help'
          >Help
          </NavLink>
        </div>
      </div>
    </ContentSidebar>
  )
}

export default SettingsSidebar
