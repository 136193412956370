import ReactGA from 'react-ga'
import { type Location } from 'react-router'
import { type IMember } from '../interfaces/user.interface'

const DIMENSION_MAP = {
  TARGET_USER_ID: 'dimension1',
  ACTING_USER_ID: 'dimension2',
  POPULATION: 'dimension3',
  IS_EJENTA_EMPLOYEE: 'dimension4',
  IS_CLINICIAN: 'dimension5'
}

export function logGATargetUser (targetUserId: string): void {
  ReactGA.ga('set', DIMENSION_MAP.TARGET_USER_ID, targetUserId)
}

export function logGAPageView (location: Location): void {
  const query = new URLSearchParams(location.search)
  const queryUser = query.get('user')

  // Set target user (if the page is a user-specific page)
  if (location.pathname.startsWith('/profile')) {
    const targetUserId = location.pathname.split('/profile/')[1]
    logGATargetUser(targetUserId)
  } else if (location.pathname === '/alerts' && !!queryUser) {
    const targetUserId = queryUser
    logGATargetUser(targetUserId)
  }

  // Send pageview
  ReactGA.ga('set', 'page', location.pathname)
  ReactGA.ga('send', 'pageview', location.pathname)
}

export function logGAModalOpen (modalName: string, modalTargetUser: IMember): void {
  // Set target user (if the modal is a user-specific modal)
  if (['AlertDetail', 'EditMemberProfile', 'EditMemberCarePlan', 'EditMemberDevices'].includes(modalName)) {
    const targetUserId = modalTargetUser.id ?? ''
    logGATargetUser(targetUserId?.toString())
  }

  // Send pageview
  const modalPath = `/modal/${modalName}`
  ReactGA.ga('set', 'page', modalPath)
  ReactGA.ga('send', 'pageview', modalPath)
}

/*
 * NOTE: This method is only necessary when a modal is closed onto the same page it was launched
 * from. If the user is navigating to a new page, that pageview is already automatically logged.
 */
export function logGAModalClose (location: Location): void {
  logGAPageView(location)
}

export function setGAUserDimensions (user: IMember): void {
  const actingUserId = user.id
  const population = user.group
  const isEjentaEmployee = user.email?.endsWith('@ejenta.com')
  const isClinician = !!(user.clinician)

  ReactGA.ga('set', 'userId', actingUserId)
  ReactGA.ga('set', DIMENSION_MAP.ACTING_USER_ID, actingUserId)
  ReactGA.ga('set', DIMENSION_MAP.POPULATION, population)
  ReactGA.ga('set', DIMENSION_MAP.IS_EJENTA_EMPLOYEE, isEjentaEmployee)
  ReactGA.ga('set', DIMENSION_MAP.IS_CLINICIAN, isClinician)
}
