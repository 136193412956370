import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import {
  getBaseSeriesOptions,
  getPointDateString,
  getBaseChartOptions,
  getEmptyChart,
  addPausePlotBands
} from './baseChartUtils'
import {
  getMarkerRadius,
  getColumnWidth,
  getXAxisForRange,
  getDefaultChartOptions
} from './chartUtils'
import { isAlertOpen } from '../../constants/constants'
import { type SeriesLineOptions, type Options, type SeriesColumnrangeOptions, type SeriesScatterOptions } from 'highcharts'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'
import { type IBloodPressureData } from '../../interfaces/bp-summary.interface'
import { type IMarker, type IColumnRangeSeries, type ILineSeries, type IScatterSeries, type IChartOptionsParams } from '../../interfaces/chart.interface'
import { type IAlertClick } from '../../interfaces/alert.interface'

function getBloodPressureSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore, bloodPressureType: string): Options {
  if (!memberObj.blood_pressure) return options

  const newOptions = _.cloneDeep(options)
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))

  const bloodPressureMeasurements = _.reverse(
    _.cloneDeep(memberObj.blood_pressure.data)).map((m: IBloodPressureData) => {
    let unit = ''
    if (bloodPressureType === 'heart_rate') {
      unit = ' beats/min'
    }

    const point = {
      x: +moment(m.timestamp),
      y: m[bloodPressureType],
      dataSource: m.source,
      name: getPointDateString(moment(m.timestamp), true),
      description: `${Math.round(m[bloodPressureType])}${unit}`
    }
    return point
  })

  const bloodPressureMeasurementsSeries: SeriesLineOptions = _.extend({
    name: (bloodPressureType.charAt(0).toUpperCase() + bloodPressureType.slice(1)).split('_').join(' '),
    data: bloodPressureMeasurements,
    zIndex: 1,

    type: 'line' as ILineSeries,
    className: `series-${bloodPressureType}Measurements`
  }, getBaseSeriesOptions())

  newOptions.series?.push(bloodPressureMeasurementsSeries)
  return newOptions
}

export function getHeartRateSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): Options {
  let newOptions = _.cloneDeep(options)
  newOptions = getBloodPressureSeries(newOptions, memberObj, dashboardViewStore, 'heart_rate')
  return newOptions
}

export function getBloodPressureRangeSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): Options {
  if (!memberObj.blood_pressure) return options
  const newOptions = { ...options }

  const bloodPressureMeasurements = _.reverse(
    _.cloneDeep(memberObj.blood_pressure.data)).map((m) => {
    const point = {
      x: +moment(m.timestamp),
      dataSource: m.source,
      low: m.diastolic,
      high: m.systolic,
      name: getPointDateString(moment(m.timestamp), true),
      description: `${Math.round(m.systolic)}/${Math.round(m.diastolic)} mmHg`
    }
    return point
  })

  const bloodPressureMeasurementsSeries: SeriesColumnrangeOptions = _.extend({
    name: 'Blood pressure',
    data: bloodPressureMeasurements,
    zIndex: 1,
    pointWidth: Math.min(10, getColumnWidth(dashboardViewStore, false)),
    type: 'columnrange' as IColumnRangeSeries,
    className: 'series-bpMeasurements'
  }, getBaseSeriesOptions())

  newOptions.series?.push(bloodPressureMeasurementsSeries)
  return newOptions
}

/* ********************************************************************************
 * Blood pressure alerts series
 * ********************************************************************************/

export function getBloodPressureAlertsSeries (memberObj: IMemberSummary, options: Options, handleAlertClick: IAlertClick): Options {
  if (!memberObj.alerts?.length) return options
  const bpAlerts = _.filter(memberObj.alerts, a => a.metric_type === 'blood_pressure' || (a.metric_type === 'multi' && a.member_content.includes('blood pressure')))
  if (!bpAlerts.length) return options

  const alertsData = bpAlerts.map((alertObj) => {
    /*
     * Get the Y-value of the marker
     */
    let pointY = 0
    const bpMeasurement = memberObj.blood_pressure?.data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display alert marker above the highest submetric
    if (bpMeasurement !== undefined) {
      const systolic = bpMeasurement.systolic ?? 0
      const diastolic = bpMeasurement.diastolic ?? 0
      const heartRate = bpMeasurement.heart_rate ?? 0

      pointY = _.max([systolic, diastolic, heartRate]) ?? 0 * 1.05
    } else pointY = memberObj.blood_pressure?.data[0].systolic ?? 0 * 1.05 // Otherwise just use the first systolic value we have

    const marker: IMarker = {}
    if (alertObj.type === 'bp_tracking' || alertObj.type === 'multi_tracking') marker.symbol = 'circle'

    const isOpen = isAlertOpen(alertObj)

    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'bp_tracking' || alertObj.type === 'multi_tracking'
    })

    /*
     * Define Highcharts point
     */
    const point = {
      name: 'Blood pressure alert',
      description: alertObj.provider_content,
      x: +moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,
      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) {
            handleAlertClick(alertObj)
          }
        }
      }
    }

    return point
  })

  const alertsSeries: SeriesScatterOptions = _.extend({
    name: 'BP alerts',
    type: 'scatter' as IScatterSeries,
    className: 'series-bloodPressureAlerts',
    lineWidth: 0,
    data: alertsData,
    zIndex: 2, // so the alerts are on top of the data
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },
    tooltip: {
      useHTML: true,
      headerFormat: '',
      borderColor: 'rgb(0, 0, 0, 0)',
      pointFormatter: function format () {
        const point = this as any
        return `
          <strong>${point.name as string}</strong><br/>
          ${point.description as string}
        `
      }
    }
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series?.push(alertsSeries)
  return newOptions
}

export const baseBloodPressureChartOptions = {
  ...getDefaultChartOptions('blood_pressure-chart'),
  tooltip: {
    headerFormat: '',
    pointFormatter: function format () {
      const point = this as any
      return `
        <strong>${point.name as string}</strong><br/>
        ${point.description as string}<br />
        <small>Source: ${point.dataSource as string ?? 'Unknown'}</small>
        `
    },
    borderColor: 'rgb(0, 0, 0, 0)',
    useHTML: true,
    padding: 12
  }
}

export function getBloodPressureChartOptions ({
  memberObj, dashboardViewStore, showMemberAlerts,
  handleAlertClick
}: IChartOptionsParams): Options {
  if (!memberObj.blood_pressure) return getEmptyChart()

  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, false) }

  let options: Options = _.merge(
    getBaseChartOptions(),
    baseBloodPressureChartOptions,
    xAxisOptions
  )

  options = getBloodPressureRangeSeries(options, memberObj, dashboardViewStore)
  options = getHeartRateSeries(options, memberObj, dashboardViewStore)
  if (showMemberAlerts && !!handleAlertClick) options = getBloodPressureAlertsSeries(memberObj, options, handleAlertClick)
  options = addPausePlotBands(memberObj.user.pauses ?? [], options, true)

  return options
}
