import _ from 'lodash'
import moment from 'moment'
import restApi from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RANGE_VIEWS, getRangeBounds } from '../utils/member/dateUtils'
import { type IDashboardStore } from '../interfaces/store/dashboardStore.interface'
import { type IMemberSummary } from '../interfaces/member-summary.interface'

interface IRangeDataPayload {
  memberObj: IMemberSummary
  rangeZoom: string
  rangeStart: string
}

function getLastUpdated (prevUpdated: number | null, nextUpdated: number): number {
  if (!prevUpdated) return nextUpdated
  if (moment(prevUpdated).isAfter(moment(nextUpdated))) return prevUpdated
  return nextUpdated
}

const getRangeData = createAsyncThunk('dashboardView/setRange', async (params: { dispatch: any, getState: any, payload: IRangeDataPayload }) => {
  const { dispatch, getState } = params
  const { memberObj, rangeZoom, rangeStart } = params.payload

  if (rangeZoom) dispatch(setRangeZoom(rangeZoom))
  else if (rangeStart) dispatch(setRangeStart(rangeStart))
  else return

  const urlParams = { id: memberObj.user.id }
  const { startDate, endDate } = getRangeBounds(
    memberObj,
    rangeZoom || getState().dashboardView.rangeZoom,
    rangeStart || getState().dashboardView.rangeStart
  )

  if (startDate && endDate) {
    _.merge(urlParams, { startDate, endDate })
  }

  await dispatch(restApi.actions.memberSummary(urlParams))
})

export const setRange = (payload: IRangeDataPayload) => {
  return (dispatch: any, getState: any) => {
    dispatch(getRangeData({ dispatch, getState, payload }))
  }
}

const initialState: IDashboardStore = {
  lastUpdated: null,
  isRangeLoading: false,
  rangeZoom: RANGE_VIEWS.WEEK,
  rangeStart: null // {dateString}
}

const dashboardViewSlice = createSlice({
  name: 'dashboardView',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRangeData.fulfilled, (state, action) => {
      state.isRangeLoading = false
      state.lastUpdated = getLastUpdated(state.lastUpdated, moment().valueOf())
    })

    builder.addCase(getRangeData.rejected, (state, action) => {
      state.isRangeLoading = false
    })

    builder.addCase(getRangeData.pending, (state, action) => {
      state.isRangeLoading = true
    })
  },
  reducers: {
    setRangeLoading: (state, action) => {
      state.isRangeLoading = action.payload
    },
    setRangeZoom: (state, action) => {
      state.rangeZoom = action.payload || RANGE_VIEWS.WEEK
      state.rangeStart = null
    },
    setRangeStart: (state, action) => {
      state.rangeStart = action.payload
    },
    resetDashboardView: (state) => {
      state.lastUpdated = initialState.lastUpdated
      state.rangeStart = initialState.rangeStart
      state.isRangeLoading = initialState.isRangeLoading
      state.rangeZoom = initialState.rangeZoom
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = getLastUpdated(state.lastUpdated, action.payload.lastUpdated)
    }
  }
})

export const {
  setRangeLoading,
  setRangeZoom,
  setRangeStart,
  resetDashboardView,
  setLastUpdated
} = dashboardViewSlice.actions

export { dashboardViewSlice }
