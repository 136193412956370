import { createSlice } from '@reduxjs/toolkit'
import { type IEpicPatient, type IEpicStore } from 'src/interfaces/store/epicStore.interface'

const initialState: IEpicStore = {
  patientId: '',
  isLoading: false,
  isEmbedded: false,
  iss: null,
  launch: null
}

const epicSlice = createSlice({
  name: 'epic',
  initialState,
  reducers: {
    setEpicLoading: (state: IEpicStore, action: { payload: boolean }) => {
      state.isLoading = action.payload
    },
    populateEpicPatientId: (state: IEpicStore, action: { payload: string }) => {
      state.patientId = action.payload
    },
    populateEpicPatient: (state: IEpicStore, action: { payload: IEpicPatient }) => {
      state.newPatient = action.payload
    },
    setEpicParams: (state: IEpicStore, action: { payload: { iss: string | null, launch: string | null } }) => {
      state.iss = action.payload.iss
      state.launch = action.payload.launch
    },
    setIsEmbedded: (state: IEpicStore, action: { payload: boolean }) => {
      state.isEmbedded = action.payload
    }
  }
})

export const {
  populateEpicPatient,
  populateEpicPatientId,
  setEpicLoading,
  setEpicParams,
  setIsEmbedded
} = epicSlice.actions

export { epicSlice }
