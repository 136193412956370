import React, { useState } from 'react'
import classnames from 'classnames'
import includeFeatureGates from '../FeatureGates'
import UserNavLabel from './UserNavLabel'
import ChatMenuItem from './ChatMenuItem'
import MenuIcon from '@mui/icons-material/Menu'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { type ConnectedProps, connect } from 'react-redux'
import { productConfig } from '../../../config/config-service'
import { FEATURE_GATES } from '../../../constants/constants'
import { dispatchWithException } from '../../../api/baseUtils'
import { setActiveGroup } from '../../../store/userSession'
import { Box, Divider, Menu, MenuItem, IconButton, ListItemText, Typography } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import GroupIcon from '@mui/icons-material/Group'
import PieChartIcon from '@mui/icons-material/PieChart'
import { type IFeatureGates } from '../../../interfaces/store/sessionStore.interface'
import { mapUserState } from '../../../constants/reduxMappers'

interface IClinicianNavProps extends PropsFromRedux {
  featureGates?: IFeatureGates
  filterCounts?: Record<string, number>
  forDesktop?: boolean
}

function ClinicianNav (props: IClinicianNavProps): JSX.Element | null {
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const hasChat = productConfig().router.hasChat
  const pages = ['alerts', 'members', 'analytics']

  if (props.featureGates?.[FEATURE_GATES.SHOW_ONLY_ANALYTICS]) {
    if (location.pathname.includes('/analytics')) return null
  }

  const isMembersActive = ['/members', '/profile'].some(p =>
    location.pathname.includes(p)
  )

  const pathShouldNotShowGroup = location.pathname.startsWith('/groupPicker') || location.pathname.startsWith('/account') || location.pathname.startsWith('/help')
  const allowedGroupsCount = props.user?.allowedGroups?.length ?? 0
  const showGroupInfo = props.activeGroup && allowedGroupsCount > 1 && !pathShouldNotShowGroup

  const handleOpenNavMenu = (event: any): void => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null)
  }

  const handleGroupSwitchClicked = (): void => {
    handleCloseNavMenu()
    // important when on a profile, click switch group, we do not want to redirect back to the original profile in the old group
    dispatchWithException(setActiveGroup(null), props.dispatch)
    if (location.pathname.startsWith('/profile')) navigate('/', { replace: true })
  }

  const generateGroupInfo = (isMobile = false): JSX.Element | null => {
    if (!showGroupInfo) return null

    return (
      <Box sx={{ alignSelf: 'center' }}>
        {isMobile && <Divider />}

        <Box sx={{ alignSelf: 'center' }}>
          <div className='user-nav__group-info'>
            <Typography
              sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
              fontSize='1.5rem'
              minWidth='13rem'
              className='user-nav__active-group'
            >{props.activeGroup?.description}
            </Typography>
            <Typography
              sx={{ display: { xs: 'flex', md: 'none', lg: 'none', sm: 'flex' } }}
              fontSize='1rem'
              paddingLeft='1rem'
              paddingRight='1rem'
              paddingTop='.5rem'
            >{props.activeGroup?.description}
            </Typography>
            <MenuItem key='groupSwitch' onClick={handleGroupSwitchClicked} tabIndex={0}>
              <ListItemText sx={{ color: theme => theme.palette.primary.main }}>
                Switch group
              </ListItemText>
            </MenuItem>
          </div>
        </Box>
      </Box>
    )
  }

  const generateDefaultNav = (): JSX.Element => {
    return (
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

        <div className='user-nav' role='menu'>
          {generateGroupInfo()}
          <div className='user-nav__items'>
            <NavLink
              key='alerts'
              onClick={handleCloseNavMenu}
              to='/alerts'
              data-testid='user-nav__alerts'
              className={({ isActive }) => 'user-nav__item' + (isActive ? ' active' : '')}
            >
              <NotificationsIcon fontSize='large' />
              <UserNavLabel labelText='Alerts' />
            </NavLink>

            {hasChat && <ChatMenuItem handleCloseNavMenu={handleCloseNavMenu} badgeCount={props.filterCounts?.['FILTER/UNREAD_NEW_CHAT'] ?? 0} isMobile={false} />}

            <NavLink
              key='members'
              onClick={handleCloseNavMenu}
              to='/members'
              data-testid='user-nav__members'
              className={classnames('user-nav__item', { active: isMembersActive })}
            >
              <GroupIcon fontSize='large' />
              <UserNavLabel labelText='Members' />
            </NavLink>

            <NavLink
              key='analytics'
              onClick={handleCloseNavMenu}
              to='/analytics'
              data-testid='user-nav__analytics'
              className={({ isActive }) => 'user-nav__item' + (isActive ? ' active' : '')}
            >
              <PieChartIcon fontSize='large' />
              <UserNavLabel labelText='Analytics' />
            </NavLink>
          </div>
        </div>
      </Box>
    )
  }

  const generateMobileNav = (): JSX.Element => {
    return (
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar-mobile'
          aria-haspopup='true'
          onClick={handleOpenNavMenu}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id='menu-appbar-mobile'
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' }
          }}
        >

          {pages.map((page) => (
            <MenuItem
              className='user-nav-mobile'
              key={page}
              onClick={handleCloseNavMenu}
            >
              <NavLink to={'/' + page} className={({ isActive }) => 'user-nav-mobile__item' + (isActive ? ' active' : '')}>
                <Typography textAlign='center' fontSize='1.1rem'>
                  {page.charAt(0).toUpperCase() + page.slice(1)}
                </Typography>
              </NavLink>
            </MenuItem>
          ))}

          {hasChat && <ChatMenuItem badgeCount={props.filterCounts?.['FILTER/UNREAD_NEW_CHAT'] ?? 0} isMobile handleCloseNavMenu={handleCloseNavMenu} />}

          {generateGroupInfo(true)}
        </Menu>
      </Box>
    )
  }

  if (props.forDesktop) return generateDefaultNav()
  else return generateMobileNav()
}

const connector = connect(mapUserState)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(includeFeatureGates(ClinicianNav))
