import React from 'react'
import Loader from 'react-loaders'
import classnames from 'classnames'

interface ILoadingIndicatorProps {
  sidebarVisible?: boolean
}

function LoadingIndicator (props: ILoadingIndicatorProps): JSX.Element {
  const classNames = classnames('loading-indicator', {
    'loading-indicator--sidebar-visible': props.sidebarVisible
  })
  return (
    <div className={classNames}>
      <Loader type='ball-scale-ripple' active />
    </div>
  )
}

export default LoadingIndicator
