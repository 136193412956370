
/*
 * Rounds the value to the provided number of decimal places. Does not pad.
 *
 *   preciseRound(3.1415, 2) => 3.14
 *   preciseRound(3.1, 2) => 3.1
 */
export function preciseRound (value: number, numPlaces: number): number {
  const factor = Math.pow(10, numPlaces)
  return Math.round(value * factor) / factor
}

export function convertLbToKg (lbs: number): number {
  return Number(lbs) / 2.20462
}

export function convertKgToLb (kg: number): number {
  return Number(kg) * 2.20462
}

export function convertFtInToCm (heightFeet?: string | number, heightInches?: string | number): number {
  let cm = 0
  if (heightFeet) cm += Number(heightFeet) * 12 * 2.54
  if (heightInches) cm += Number(heightInches) * 2.54
  return cm
}
