import React from 'react'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import ProfilePregnancyPane from './ProfilePregnancyPane'

interface ICustomProfilePaneProps {
  hideBorder?: boolean
  hideTitle?: boolean
  memberObj: IMemberSummary
}

function CustomMemberProfilePane (props: ICustomProfilePaneProps): JSX.Element {
  const classItems = props.hideBorder ? '' : 'edit-profile-pane'

  const renderCustomProfilePane = (): JSX.Element => {
    return (
      <div className={classItems}>
        <div className='edit-profile-pane__header'>
          {props.memberObj.user.first_name}'s pregnancy
        </div>

        <ProfilePregnancyPane variant='wide' memberObj={props.memberObj} />
      </div>
    )
  }

  return renderCustomProfilePane()
}

export default CustomMemberProfilePane
