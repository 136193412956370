import _ from 'lodash'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { type ConnectedProps, connect } from 'react-redux'
import { COMMUNICATION_TYPES } from '../../../constants/constants'
import { EjentaForm, renderField, renderRadioInput } from '../../elements/EjentaForm'
import { Card, CardContent, Button, DialogActions, FormControl, RadioGroup, Typography } from '@mui/material'
import Snackbar from '../../elements/Snackbar'
import { mapUserDispatch, mapUserState } from '../../../constants/reduxMappers'
import { type IUser } from '../../../interfaces/user.interface'

interface IMessagePreferencesProps extends PropsFromRedux {
  hideBorder?: boolean
  hideTitle?: boolean
  onSubmit?: (user: IUser) => void
}

function MessagePrefs (props: IMessagePreferencesProps): JSX.Element {
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const getInitialValues = (): { communication?: string } => {
    return {
      communication: props.user?.communication
    }
  }

  const renderMessagePrefItem = (messageType, messageDesc): JSX.Element => {
    const userMessageType = props.user?.communication ?? 'email'
    const communicationRadioField = {
      name: 'communication',
      type: 'radio',
      label: messageDesc,
      key: messageType,
      value: messageType,
      checked: userMessageType === messageType,
      component: renderRadioInput
    }

    return (renderField({}, communicationRadioField))
  }

  const renderSubmitButton = ({ pristine, submitting, hasValidationErrors }): JSX.Element => {
    let submitButton = <Button variant='contained' type='submit' disabled={pristine || hasValidationErrors}>Update preferences</Button>

    if (submitting) submitButton = (<Button variant='contained' disabled type='submit'>Saving...</Button>)
    return (<DialogActions>{submitButton}</DialogActions>)
  }

  const handleSubmit = async (formData: any): Promise<any> => {
    if (props.onSubmit) {
      // use the callback function passed to the component and return
      props.onSubmit(formData)
      return
    }

    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions?.updateAccount(
        {
          id: props.user?.id,
          promise: { resolve, reject }
        },
        { body: JSON.stringify(formData) }
      )
    })

    await submitPromise.then(handleSubmitSuccess)
  }

  const handleSubmitSuccess = (data: any): void => {
    if (props.updateUser) props.updateUser(data)
    setSnackbarOpen(true)
  }

  const renderSubmitStatus = (): JSX.Element => {
    return (
      <Snackbar
        isOpen={snackbarOpen}
        dataKey='message-preferences__success__snackbar'
        handleClose={() => { setSnackbarOpen(false) }}
        severity='success'
        message='Message preferences updated successfully'
      />
    )
  }

  const renderMessagePreferenceForm = (): JSX.Element => {
    return (
      <>
          {!props.hideTitle && <Typography variant='h6' fontSize='1.1rem' sx={{ mb: 3 }}>Message Preferences</Typography>}
          {renderSubmitStatus()}

          <Form onSubmit={handleSubmit} initialValues={getInitialValues()}>
            {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
              <EjentaForm onSubmit={handleSubmit}>
                <FormControl>
                  <RadioGroup name='communication-radio-buttons-group'>
                    {_.map(_.keys(COMMUNICATION_TYPES.CLINICIAN), k =>
                      renderMessagePrefItem(k, COMMUNICATION_TYPES.CLINICIAN[k])
                    )}
                  </RadioGroup>
                </FormControl>

                {renderSubmitButton({ pristine, submitting, hasValidationErrors })}
              </EjentaForm>
            )}
          </Form>
      </>
    )
  }

  const renderMessagePreferences = (): JSX.Element => {
    if (props.hideBorder) return renderMessagePreferenceForm()

    return (
      <Card data-testid='message-prefs' variant='outlined' className='u-mobile-bottom-margin'>
        <CardContent> {renderMessagePreferenceForm()} </CardContent>
      </Card>
    )
  }

  return renderMessagePreferences()
}

const connector = connect(mapUserState, mapUserDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(MessagePrefs)
