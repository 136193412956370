import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Exporting from 'highcharts/modules/exporting.js'
import ExportData from 'highcharts/modules/export-data.js'
import Accessibility from 'highcharts/modules/accessibility.js'
import OfflineExporting from 'highcharts/modules/offline-exporting.js'
import HighchartsMore from 'highcharts/highcharts-more'
import { type IAnalyticsStore } from '../../../../interfaces/store/analyticsStore.interface'
import { type IAnalyticsChartOptionsParams } from '../../../../interfaces/chart.interface'
import { mapAnalyticsState } from '../../../../constants/reduxMappers'

Exporting(Highcharts)
OfflineExporting(Highcharts)
ExportData(Highcharts)
Accessibility(Highcharts)
HighchartsMore(Highcharts)

function AnalyticsChart (props: IAnalyticsChartOptionsParams): JSX.Element {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = _.debounce(() => { setInnerWidth(window.innerWidth - 200) })
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const renderAnalyticsChart = (): JSX.Element => {
    const {
      dateRangeStart: start,
      dateRangeEnd: end,
      isDateRangeLoading: loading
    } = props.analyticsStore as IAnalyticsStore

    return (
      <HighchartsReact
        classname='chart-container'
        highcharts={Highcharts}
        options={props.chartOptions}
        id={props.containerId}
        key={`${moment(start).format()}-${moment(end).format()}-${loading.toString()}-${innerWidth}`}
      />
    )
  }

  return renderAnalyticsChart()
}

const connector = connect(mapAnalyticsState)
export default connector(AnalyticsChart)
