import moment from 'moment'
import React from 'react'
import restApi from '../../api'
import Modal from '../elements/Modal'
import { type ConnectedProps, connect } from 'react-redux'
import { sessionExpire } from '../../store/userSession'
import { Box, Button, Typography } from '@mui/material'
import { type RootState, type AppDispatch } from '../..'

/*
 * Modal component that warns the user that their session is expiring soon.
 */
function SessionExpireWarning (props: PropsFromRedux): JSX.Element | null {
  const renderModal = (): JSX.Element => {
    return (
      <Modal
        isOpen
        name='SessionExpireWarning'
        modalTitle='Are you still there?'
        data-testid='session-expire-warning'
        onModalClose={() => props.extendSession}
        maxWidth='sm'
      >
        <Typography data-testid='session-expire-warning__text' gutterBottom>
          To protect your data, you will be signed out after a period of inactivity.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button variant='contained' onClick={() => { props.extendSession() }} data-testid='session-expire-warning__button'>
            Keep me signed in
          </Button>
        </Box>
      </Modal>
    )
  }

  if (!props.userSessionStore.showSessionExpireWarning) return null
  return renderModal()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: RootState) => {
  return {
    userSessionStore: state.userSession,
    user: state.userSession.user
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: AppDispatch) => {
  return {
    extendSession () {
      // Make sure that the session hasn't already expired
      // @ts-expect-error doesn't like the session store TODO
      const sessionExpireTime = moment(this.userSessionStore.sessionExpireTime)
      const now = moment()
      if (now.isAfter(sessionExpireTime)) {
        dispatch(sessionExpire())
        dispatch(restApi.actions.logout())
      } else {
        dispatch(restApi.actions.session()).then((data) => {
          // Handles the case of the web app already being logged out via another tab.
          // Without this clicking the button 'Keep me signed in' does nothing and the
          // user is forced to reload the page.
          if (!data?.logged_in) {
            dispatch(restApi.actions.logout())
          }
        })
      }
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(SessionExpireWarning)
