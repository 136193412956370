import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { customWebSocketUrl } from '../../../utils/apiUtils'
import { Box, Button, Card, CardContent, Grid, TextareaAutosize, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { type IChatMessage } from '../../../interfaces/chat.interface'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type IUser } from '../../../interfaces/user.interface'
import LoadingIndicator from '../LoadingIndicator'

const ignoreDuplicatesByKey = (array: any [], key = 'key'): any [] => {
  const obj = {}
  const addKey = (item: any): any => { if (!obj[item[key]]) obj[item[key]] = item }
  array.forEach(addKey)
  return Object.values(obj)
}

interface IChatModuleProps {
  memberObj: IMemberSummary
  user: IUser
}

export default function ChatModule (props: IChatModuleProps): JSX.Element | null {
  const [messages, setMessages] = useState<IChatMessage []>([])
  const [messagesEnd, setMessagesEnd] = useState<any>(null)
  const [messageInProgress, setMessageInProgress] = useState('')
  const [minimized, setMinimized] = useState(true)
  const [loading, setLoading] = useState(true)

  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(customWebSocketUrl, {
    onClose: () => { console.info('WebSocket connection closed.') },
    shouldReconnect: (_closeEvent) => true // ensures it reconnects when the chat window is opened again
  })

  useEffect(() => {
    if (minimized) {
      getWebSocket()?.close()
    } else {
      setLoading(true)
      const latestTimestamp = messages.length ? messages[0].timestamp : null
      const subscribeToMessages = { type: 'chat/recents', chatRoomId: props.memberObj.user.id, value: latestTimestamp }
      sendJsonMessage(subscribeToMessages)
    }
  }, [minimized])

  useEffect(() => {
    setLoading(false)
    if (lastJsonMessage?.type === 'chat/message') {
      setMessages(ignoreDuplicatesByKey([lastJsonMessage.value, ...messages], 'id'))
      setTimeout(scrollToBottom, 100)
    } else if (lastJsonMessage?.type === 'chat/recents') {
      setMessages(ignoreDuplicatesByKey([...lastJsonMessage.value, ...messages], 'id'))
      setTimeout(scrollToBottom, 100)
    }
  }, [lastJsonMessage, setMessages])

  const renderMessage = (message: IChatMessage): JSX.Element => {
    const m = moment(message.timestamp)

    return (
      <Grid container key={message.id} sx={{ mb: 2 }}>
        <Grid item xs={12}><Typography variant='caption' width='100%'> {m.calendar(null)} </Typography></Grid>
        <Grid item xs={12}><Typography><b>{`${message.user}`}</b>{`: ${message.content}`}</Typography></Grid>
      </Grid>
    )
  }

  const renderMessages = (): JSX.Element => {
    return (
      <Box sx={{ height: '18rem', paddingBottom: 0, overflowY: 'scroll' }}>
        {loading && <LoadingIndicator />}
        {messages.length
          ? (messages.map(renderMessage).reverse())
          : (
              readyState === ReadyState.OPEN
                ? (<Typography color='secondary' fontSize={16}> No messages yet... </Typography>)
                : (
                    readyState === ReadyState.CONNECTING
                      ? (<Typography color='secondary' fontSize={16}> Loading... </Typography>)
                      : (<Typography color='secondary' fontSize={16}> Connecting... </Typography>)
                  )
            )}

        <div style={{ height: '.1rem' }} ref={(el) => { setMessagesEnd(el) }} />
      </Box>
    )
  }

  const handleSendMessage = useCallback(() => {
    if (messageInProgress.trim()) {
      const newMessage = {
        id: uuidv4(),
        user: props.user.first_name,
        content: messageInProgress,
        timestamp: new Date().getTime()
      }

      setMessageInProgress('')
      sendJsonMessage({ type: 'chat/message', value: newMessage, chatRoomId: props.memberObj.user.id })
      setMessages(ignoreDuplicatesByKey([newMessage, ...messages], 'id'))
      setTimeout(scrollToBottom, 300)
    }
  }, [messageInProgress, messages])

  const renderControls = (): JSX.Element => {
    return (
      <Box sx={{ display: 'flex', height: 'auto', maxHeight: '14rem', mt: 1, mr: 1, justifyContent: 'space-between' }}>
        <TextareaAutosize
          minRows={3}
          maxRows={10}
          style={{ width: 200, height: 48, minWidth: 200, minHeight: 48, maxWidth: 220, maxHeight: 225 }}
          aria-label='maximum height'
          placeholder='Type message...'
          data-testid='chat__textarea'
          className='ejenta-textarea'
          value={messageInProgress}
          onChange={handleMessageInputChange}
          onKeyDown={handleMessageInputOnKeyDown}
        />

        {readyState === ReadyState.OPEN && messageInProgress.trim()
          ? <Button variant='contained' data-testid='chat__send-message-button' onClick={handlePressSend}> Send </Button>
          : <Button variant='contained' color='secondary' disabled> Send </Button>}
      </Box>
    )
  }

  // https://stackoverflow.com/questions/37620694/how-to-scroll-to-bottom-in-react
  const scrollToBottom = (): void => {
    messagesEnd?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleMessageInputChange = (e): void => {
    setMessageInProgress(e.target.value)
  }

  const handlePressSend = (e): void => {
    e.preventDefault()
    handleSendMessage()
  }

  const handleMessageInputOnKeyDown = (e): void => {
    if (e.key === 'Enter') {
      // Allow Shift + Enter to make a newline
      if (!e.shiftKey) {
        e.preventDefault()
        handleSendMessage()
      }
    }
  }

  const renderChatModule = (): JSX.Element => {
    return (
      <Box
        sx={{
          minHeight: minimized ? 45 : undefined,
          maxWidth: '20rem',
          borderColor: 'primary',
          position: 'fixed',
          right: '20px',
          bottom: 1,
          zIndex: 9
        }}
        className='no-print'
      >
        <Button
          fullWidth
          variant='contained'
          color='primary'
          size='small'
          sx={{ minWidth: '20rem', justifyContent: 'space-between' }}
          onClick={() => { setMinimized(!minimized) }}
        >
          <Typography fontWeight={400} ml='1.5rem'>Chat with {props.memberObj.user.first_name}</Typography>

          <div style={{ float: 'right', marginTop: 5, marginRight: 15 }}>
            {minimized
              ? (<ExpandLess fontSize='medium' />)
              : (<ExpandMore fontSize='medium' />)}
          </div>
        </Button>

        {!minimized &&
          <Card
            data-testid='chat__messages'
            sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              border: theme => `1px solid ${theme.palette.primary.main}`,
              right: 0,
              left: 0,
              p: 0
            }}
          >
            <CardContent sx={{ padding: '0 0 .5rem .25rem !important' }}>
              {renderMessages()}
              {renderControls()}
            </CardContent>
          </Card>}
      </Box>
    )
  }

  return renderChatModule()
}
