import _ from 'lodash'
import React, { useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { getMemberProfileFormErrors, getProfileMemberFields, validateMemberForm } from '../../../utils/member/baseProfileUtils'
import { EjentaForm } from '../../elements/EjentaForm'
import { Card, CardContent, Typography, Button, DialogActions, Alert } from '@mui/material'
import AccountInformationFormFields from './AccountInformationFormFields'
import Snackbar from '../../elements/Snackbar'
import { Form } from 'react-final-form'
import { mapUserDispatch, mapUserState } from '../../../constants/reduxMappers'
import { type IUser } from '../../../interfaces/user.interface'

const ACCOUNT_FIELDS = ['medicalId', 'birthDate', 'firstName', 'lastName', 'email', 'phone', 'bodyTraceScaleImei', 'bodyTraceBpImei', 'timezone']
const EDITABLE_ACCOUNT_FIELDS = ['firstName', 'lastName', 'email', 'phone', 'bodyTraceScaleImei', 'bodyTraceBpImei', 'timezone']

interface IAccountInformationProps extends PropsFromRedux {
  hideBorder?: boolean
  hideTitle?: boolean
  onSubmit?: (user: IUser) => void
}

function AccountInformationForm (props: IAccountInformationProps): JSX.Element {
  const memberFields = getProfileMemberFields()
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const getInitialValues = (): Record<string, string | number> => {
    const initialValues = {}

    _.forOwn(memberFields, (field, inputName) => {
      if (!ACCOUNT_FIELDS.includes(inputName)) {
        return
      }

      let value: string | number = ''
      if (field.accessor) value = field.accessor(props.user as IUser)
      else if (field.apiField) value = props.user?.[field.apiField]
      initialValues[inputName] = value
    })

    return initialValues
  }

  const handleSubmit = async (formData): Promise<any> => {
    const submitPromise = new Promise((resolve, reject) => {
      const formFields = _.pick(memberFields, EDITABLE_ACCOUNT_FIELDS)

      if (props.onSubmit) {
        // use the callback function passed to the component and return
        props.onSubmit(formFields)
        return
      }

      const apiFields = {}
      _.forOwn(formData, (val, key) => {
        const field = memberFields[key].apiField
        if (field) apiFields[field] = val
      })

      props.apiActions.updateAccount(
        {
          id: props.user?.id,
          promise: { resolve, reject },
          promiseTransformer: errors => getMemberProfileFormErrors(errors, formFields)
        },
        { body: JSON.stringify(apiFields) }
      )
    })

    return await submitPromise.then(handleSubmitSuccess).catch(data => data)
  }

  const handleSubmitSuccess = (data: any): void => {
    props.updateUser(data)
    setSnackbarOpen(true)
  }

  const renderSubmitButton = ({ pristine, submitting, errors }): JSX.Element => {
    const hasErrors = Object.keys(errors)?.length > 0
    let submitButton = (
      <Button variant='contained' color='primary' type='submit' data-testid='account-information__update-button' disabled={pristine || hasErrors}>Update account</Button>
    )

    if (submitting) {
      submitButton = (
        <Button variant='contained' color='primary' disabled type='submit' data-testid='account-information__in-progress-button'>Saving...</Button>
      )
    }
    return (<DialogActions>{submitButton}</DialogActions>)
  }

  const renderAccountInformation = (): JSX.Element | null => {
    if (!props.user) return null
    const formFields = _.pick(memberFields, EDITABLE_ACCOUNT_FIELDS)

    return (
      <Form
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        validate={values => validateMemberForm(values, formFields, false)}
      >
        {({ handleSubmit, pristine, submitting, submitError, errors }) => (
          <EjentaForm onSubmit={handleSubmit} data-testid='account-information__form'>
            {submitError && <Alert severity='error'>{submitError}</Alert>}
            <AccountInformationFormFields />

            {renderSubmitButton({ pristine, submitting, errors })}
          </EjentaForm>
        )}
      </Form>
    )
  }

  const renderFormContent = (): JSX.Element => {
    return (
    <>
    {!props.hideTitle && <Typography variant='h6' fontSize='1.1rem' sx={{ mb: 3 }}>Account Information</Typography>}
    <Snackbar
      isOpen={snackbarOpen}
      dataKey='account-information__success__snackbar'
      handleClose={() => { setSnackbarOpen(false) }}
      severity='success'
      message='Account updated successfully'
    />

    {renderAccountInformation()}</>
    )
  }

  const renderAccountInformationForm = (): JSX.Element => {
    if (props.hideBorder) return renderFormContent()

    return (
      <Card variant='outlined'>
        <CardContent> {renderFormContent()} </CardContent>
      </Card>
    )
  }

  return renderAccountInformationForm()
}

const connector = connect(mapUserState, mapUserDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AccountInformationForm)
