import moment from 'moment'
import React, { useEffect } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { ANALYTICS_SCREENS } from '../../../store/analytics'
import AnalyticsDrilldownModal from './layout/AnalyticsDrilldownModal'
import AnalyticsPopulationScreen from './AnalyticsPopulationScreen'
import AnalyticsTrendsScreen from './AnalyticsTrendsScreen'
import TabPanel from '../../layout/TabPanel'
import { useLocation } from 'react-router'
import { Box, Tabs, Tab } from '@mui/material'
import { a11yProps } from '../../../constants/constants'
import { ContentMain, ContentMainIntro, ContentMainBody } from '../../layout/ContentMain'
import AnalyticsDateRange from './layout/AnalyticsDateRange'
import { mapAnalyticsDispatch, mapAnalyticsState } from '../../../constants/reduxMappers'
import Enrollment from './modules/Enrollment'

function AnalyticsPage (props: PropsFromRedux): JSX.Element {
  const location = useLocation()
  const pagePrefix = 'analytics-page__tab'

  useEffect(() => {
    // User navigated here by hitting the back button, so don't reset
    const locKey = location?.key
    if (locKey && locKey === props.analyticsStore.prevLocationKey) return

    // Reset the analytics page
    props.analyticsActions.setLocationKey(locKey)
    props.analyticsActions.resetView()
    fetchAnalyticsOverview()
  }, [location])

  const handleTabChange = (_event: any, newValue: number): void => {
    props.analyticsActions.setCurrentScreen(newValue)
  }

  const fetchAnalyticsOverview = (): void => {
    const startDate = moment().subtract(1, 'week')
    const endDate = moment()

    props.analyticsActions.setDateRange({ startDate: startDate.valueOf(), endDate: endDate.valueOf() })
    props.apiActions.getAnalyticsOverview({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    })
  }

  const renderAxPage = (): JSX.Element => {
    return (
      <ContentMain drawerWidth={0}>
        <ContentMainIntro>
          <Box className='ax-subsection'>
            {/* <div className='ax-subsection__header' data-testid='ax-subsection__header-enrollment'>Total Enrollment</div> */}

            <div data-testid='ax-column__enrollment'>
              <Enrollment
                data-testid='ax-subsection__enrollment'
                users={props.analyticsStore.allUsers}
                rangeSuspensions={props.analyticsStore.rangeSuspensions}
                currentSuspensions={props.analyticsStore.currentSuspensions}
                analyticsActions={props.analyticsActions}
              />
            </div>
          </Box>

          <Box sx={{ borderBottom: 1, width: '100%', borderColor: 'divider', display: 'inline-flex', justifyContent: 'space-between' }} className='no-print'>
            <Tabs value={props.analyticsStore.currentScreen} onChange={handleTabChange} aria-label='Profile tabs'>
              <Tab data-testid='analytics-tabs__population-tab' label={ANALYTICS_SCREENS.POPULATION} {...a11yProps(pagePrefix, 0)} />
              <Tab data-testid='analytics-tabs__trends-tab' label={ANALYTICS_SCREENS.TRENDS} {...a11yProps(pagePrefix, 1)} />
            </Tabs>
            <AnalyticsDateRange />
          </Box>
        </ContentMainIntro>

        <ContentMainBody>
          <AnalyticsDrilldownModal />
          <Box>
            <TabPanel value={props.analyticsStore.currentScreen} index={0} noPadding>
              <AnalyticsPopulationScreen />
            </TabPanel>
            <TabPanel value={props.analyticsStore.currentScreen} index={1} noPadding>
              <AnalyticsTrendsScreen />
            </TabPanel>
          </Box>
        </ContentMainBody>
      </ContentMain>
    )
  }

  return renderAxPage()
}

const connector = connect(mapAnalyticsState, mapAnalyticsDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AnalyticsPage)
