import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import { isAlertOpen } from '../../constants/constants'
import {
  getBaseChartOptions,
  getBaseSeriesOptions,
  addPausePlotBands,
  getPointDateString
} from './baseChartUtils'

import {
  getXAxisForRange,
  getMarkerRadius,
  getDefaultChartOptions
} from './chartUtils'
import { type SeriesLineOptions, type Options, type SeriesScatterOptions } from 'highcharts'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'
import { type IMarker, type ILineSeries, type IScatterSeries, type IChartOptionsParams } from '../../interfaces/chart.interface'
import { type IAlertClick } from '../../interfaces/alert.interface'

const bloodGlucoseChartOptions: Options = {
  ...getDefaultChartOptions('bg-chart'),
  tooltip: {
    headerFormat: '',
    pointFormatter: function format () {
      const point = this as any
      return `
        <strong>${point.name as string}</strong><br/>
        ${point.description as string}
      `
    },
    useHTML: true,
    padding: 8,
    borderColor: 'rgb(0, 0, 0, 0)',
    borderWidth: 0
  },

  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
        menuItems: ['viewData', 'printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
      }
    },
    filename: `BloodGlucoseChart - ${Date.now()}`
  }
}

function getSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): Options {
  if (!memberObj.blood_glucose) return options

  const newOptions = _.cloneDeep(options)
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))

  const measurements = _.reverse(_.cloneDeep(memberObj.blood_glucose.data)).map((m) => {
    const point = {
      x: +moment(m.timestamp),
      y: m.blood_glucose,
      name: getPointDateString(moment(m.timestamp), true),
      description: `
        Blood Glucose: ${m.blood_glucose} mg/dL<br/>
        Before Meal: ${m.before_meal ? 'Yes' : 'No'}<br/>
        <small>Source: ${m.source ?? 'Unknown'}</small>
      `
    }
    return point
  })

  const measurementsSeries: SeriesLineOptions = _.extend({
    name: 'Blood glucose',
    data: measurements,
    zIndex: 1,
    type: 'line' as ILineSeries,
    className: 'series-glucoseMeasurements',
    turboThreshold: 10000
  }, getBaseSeriesOptions())

  newOptions.series?.push(measurementsSeries)
  return newOptions
}

function getYAxisForRanges (options: any): Options {
  if (!options.series || !options.yAxis) return options
  /*
    THIS ASSUMES THAT THE FIRST SERIES OF THE CHART CONTAINS MEASUREMENTS!
    Set the bounds for the yAxis, since for some reason it won't automatically adjust as of 5.0.10
  */
  const newOptions = _.cloneDeep(options)

  // Get min/max blood_glucose measurement
  const minMeasurement = _.min(_.map(options.series[0].data, 'y'))
  const maxMeasurement = _.max(_.map(options.series[0].data, 'y'))

  // Set min/max for yAxis
  newOptions.yAxis.min = Math.round(Math.min(minMeasurement, 0) * 0.98)
  newOptions.yAxis.max = Math.round(maxMeasurement * 1.02)

  return newOptions
}

function getGlucoseAlertsSeries (memberObj: IMemberSummary, options: Options, handleAlertClick: IAlertClick): Options {
  if (!memberObj.alerts?.length) return options

  const alerts = _.filter(memberObj.alerts, a => a.metric_type === 'blood_glucose' || (a.metric_type === 'multi' && a.member_content.includes('glucose')))
  if (!alerts.length) return options

  const alertsData = alerts.map((alertObj) => {
    // Get the Y-value of the marker
    let pointY = 0
    const measurement = memberObj.blood_glucose?.data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display the alert marker above the actual blood_glucose
    if (measurement) pointY = measurement.blood_glucose * 1.1

    // Otherwise just use the first blood_glucose value we have, but be more generous about padding
    else pointY = memberObj.blood_glucose?.data[0].blood_glucose ?? 0 * 1.2

    const isOpen = isAlertOpen(alertObj)
    const marker: IMarker = {}
    if (alertObj.type === 'bg_tracking' || alertObj.type === 'multi_tracking') marker.symbol = 'circle'

    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'bg_tracking' || alertObj.type === 'multi_tracking'
    })

    const point = {
      name: alertObj.provider_content,
      description: alertObj.provider_content,
      x: +moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,
      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) handleAlertClick(alertObj)
        }
      }
    }

    return point
  })

  const alertsSeries: SeriesScatterOptions = _.extend({
    name: 'Blood glucose alerts',
    type: 'scatter' as IScatterSeries,
    className: 'series-glucoseAlerts',
    lineWidth: 0,
    turboThreshold: 10000,
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<strong>Blood Glucose alert</strong>',
      pointFormatter: function (): string {
        const point = this as any
        return `<div>${point.name as string}</div>`
      }
    },
    data: alertsData
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series?.push(alertsSeries)
  return newOptions
}

export default function getGlucoseChartOptions ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}: IChartOptionsParams): Options {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, false) }

  let options: Options = _.merge(getBaseChartOptions(), bloodGlucoseChartOptions, xAxisOptions)
  options = getSeries(options, memberObj, dashboardViewStore)
  options = getYAxisForRanges(options)
  if (showMemberAlerts && !!handleAlertClick) options = getGlucoseAlertsSeries(memberObj, options, handleAlertClick)
  options = addPausePlotBands(memberObj.user.pauses ?? [], options, false)

  return options
}
