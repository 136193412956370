import { createSlice } from '@reduxjs/toolkit'
import { type ISidebarStore } from '../interfaces/store/sidebarStore.interface'

const initialState: ISidebarStore = {
  sidebarOpen: true,
  buttonOnlyWidth: 52
}

const sidebarSlice = createSlice({
  name: 'sidebarView',
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload
    },
    toggleSidebarOpen: (state) => {
      state.sidebarOpen = !state.sidebarOpen
    }
  }
})

export const {
  setSidebarOpen,
  toggleSidebarOpen
} = sidebarSlice.actions

export { sidebarSlice }
