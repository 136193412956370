import React from 'react'
import Grid from '@mui/material/Grid'
import { getProfileMemberFields } from '../../../utils/member/baseProfileUtils'
import { type IProfileField } from '../../../interfaces/forms.interface'

export default function AccountInformationFormFields (): JSX.Element {
  const memberFields = getProfileMemberFields()

  const getFormInput = (formField: IProfileField): JSX.Element => {
    if (formField.formInput) return formField.formInput()
    else return <></>
  }

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      <Grid item xs={12} sm={6} md={6}>{getFormInput(memberFields.firstName)}</Grid>
      <Grid item xs={12} sm={6} md={6}>{getFormInput(memberFields.lastName)}</Grid>
      <Grid item xs={12} sm={6} md={6}>{getFormInput(memberFields.email)}</Grid>
      <Grid item xs={12} sm={6} md={6}>{getFormInput(memberFields.phone)}</Grid>
      <Grid item xs={12} sm={6} md={6}>{getFormInput(memberFields.timezone)}</Grid>
    </Grid>
  )
}
