import React from 'react'
import { Field } from 'react-final-form'
import { productConfig } from '../../../config/config-service'
import { Grid } from '@mui/material'
import { renderCheckboxInput } from '../../elements/EjentaForm'
import MemberDevicesFormFields from './MemberDevicesFormFields'
import { getProfileMemberFields } from '../../../utils/member/baseProfileUtils'
import { type ICareManager } from '../../../interfaces/store/sessionStore.interface'
import { type IGroup } from '../../../interfaces/group.interface'

interface IMemberProfileFormFieldsProps {
  isNewMember?: boolean
  careManagers: ICareManager []
  allowedGroups?: IGroup []
  change?: any
}

export default function MemberProfileFormFields (props: IMemberProfileFormFieldsProps): JSX.Element {
  const memberFields = getProfileMemberFields()
  const profileConfig = productConfig().profile
  const hasInCareManagement = profileConfig.customFormFields?.includes('in_care_management')

  const isSmallField = (fieldName: string): boolean => {
    return fieldName.includes('height') || (fieldName.includes('Weight') && !profileConfig.showPregnancyInfo)
  }

  const getSmallColumn = (item: string): number => {
    return isSmallField(item) ? 3 : 6
  }

  const getXSmallColumn = (item: string): number => {
    return isSmallField(item) ? 6 : 12
  }

  const getGridItem = (item: string, xs = 12, sm = 6): JSX.Element | null => {
    if (item === 'fhirId') return null // ignore fhirId fields, as that needs not be shown

    if (item === 'care_manager_id') { // special case
    // @ts-expect-error undefined possible for formInput method
      return (<Grid item xs={12} sm={6} key={item}>{memberFields[item].formInput(props.careManagers, props.change, hasInCareManagement)}</Grid>)
    } else if (item === 'group') {
      // @ts-expect-error undefined possible for formInput method
      return props.allowedGroups?.length > 1 ? (<Grid item xs={xs} sm={sm} key={item}>{memberFields[item].formInput(props.allowedGroups)}</Grid>) : null
    } else {
      // @ts-expect-error undefined possible for formInput method
      return (<Grid item xs={xs} sm={sm} key={item}>{memberFields[item].formInput(props.change)}</Grid>)
    }
  }

  const renderDefaultFormFields = (): JSX.Element => {
    const defaultFormFields = profileConfig.defaultFormFields?.filter(field => !profileConfig.baseMemberFieldsToRemove.includes(field))
    return (
      <>
        {defaultFormFields?.map(item => {
          return getGridItem(item, getXSmallColumn(item), getSmallColumn(item))
        })}
      </>
    )
  }

  const renderCustomFormFields = (): JSX.Element => {
    return (
      <>
        {profileConfig.customFormFields?.map(item => {
          return getGridItem(item, getXSmallColumn(item), getSmallColumn(item))
        })}
      </>
    )
  }

  const renderPregnancyFormFields = (): JSX.Element => {
    return (
      <>
        {profileConfig.pregnancyFields?.map(item => {
          return getGridItem(item, getXSmallColumn(item), getSmallColumn(item))
        })}
      </>
    )
  }

  const renderOrderFields = (): JSX.Element | null => {
    if (!profileConfig.orderFields?.length) return null
    const hasScale = profileConfig.orderFields.includes('orderBodyTraceScale')
    const hasBP = profileConfig.orderFields.includes('orderBodyTraceBloodPressure')

    return (
      <>
        {hasScale &&
          <Grid item sm={12} xs={12}>
            <Field
              type='checkbox'
              name='orderBodyTraceScale'
              component={renderCheckboxInput}
              label='Order and ship BodyTrace scale to this member'
            />
          </Grid>}
        {hasBP &&
          <Grid item sm={12} xs={12}>
            <Field
              type='checkbox'
              name='orderBodyTraceBloodPressure'
              component={renderCheckboxInput}
              label='Order and ship BodyTrace BP cuff to this member'
            />
          </Grid>}
      </>
    )
  }

  const renderCheckboxes = (): JSX.Element => {
    if (!profileConfig.termsCheckbox) return <></>
    return (<> {getGridItem(profileConfig.termsCheckbox, 12, 6)} </>)
  }

  const renderProfileFormFields = (): JSX.Element => {
    const hasDevices = profileConfig.devices?.hasBodyTraceBP || profileConfig.devices?.hasBodyTraceScale || profileConfig.devices?.hasIGlucose || profileConfig.devices?.hasBiosticker

    return (
      <Grid container spacing={1} data-testid='member-profile-form-fields' sx={{ mb: 2 }}>
        {renderDefaultFormFields()}
        {props.isNewMember && renderPregnancyFormFields()}
        {renderCustomFormFields()}
        {props.isNewMember && profileConfig.orderFields?.length === 0 && hasDevices && <MemberDevicesFormFields />}
        {props.isNewMember && renderOrderFields()}
        {props.isNewMember && renderCheckboxes()}
      </Grid>
    )
  }

  return renderProfileFormFields()
}
