import React, { useState } from 'react'
import { productConfig } from '../../../config/config-service'
import { Grid } from '@mui/material'
import { getProfileMemberFields } from '../../../utils/member/baseProfileUtils'

export default function MemberDevicesFormFields (): JSX.Element {
  const [deviceConfig] = useState(productConfig().profile.devices)
  const memberFields = getProfileMemberFields()
  const hasDevices = deviceConfig?.hasBodyTraceBP || deviceConfig?.hasBodyTraceScale || deviceConfig?.hasIGlucose || deviceConfig?.hasBiosticker

  return (
    <>
      {deviceConfig?.hasBodyTraceScale &&
        <Grid item xs={12} md={6} data-testid='member-devices__weight'>
          {memberFields.bodyTraceScaleImei.formInput ? memberFields.bodyTraceScaleImei.formInput() : null}
        </Grid>}

      {deviceConfig?.hasBodyTraceBP &&
        <Grid item xs={12} md={6} data-testid='member-devices__blood-pressure'>
          {memberFields.bodyTraceBpImei.formInput ? memberFields.bodyTraceBpImei.formInput() : null}
        </Grid>}

      {deviceConfig?.hasIGlucose &&
        <Grid item xs={12} md={6} data-testid='member-devices__blood-glucose'>
          {memberFields.iGlucoseId.formInput ? memberFields.iGlucoseId.formInput() : null}
        </Grid>}

        {deviceConfig?.hasBiosticker && <>
        <Grid item xs={12} md={6} data-testid='member-devices__biosticker'>
          {memberFields.bioStickerId.formInput ? memberFields.bioStickerId.formInput() : null}
        </Grid>
        <Grid item xs={12} md={6} data-testid='member-devices__blood-glucose'>
          {memberFields.bioHubId.formInput ? memberFields.bioHubId.formInput() : null}
        </Grid>
        </>}
        {!hasDevices &&
        <Grid item xs={12} md={6} data-testid='member-devices__none'>
          Looks like this member doesn't have any devices to edit
        </Grid>}
    </>
  )
}
