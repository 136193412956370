import React from 'react'
import { getDisplayName } from '../../../utils/baseStringUtils'
import { Typography } from '@mui/material'
import Modal from '../../elements/Modal'
import { useLocation } from 'react-router'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

interface IAccountLinkedProps {
  queryKey: string
  memberObj: IMemberSummary
}

export default function AccountLinkedModal (props: IAccountLinkedProps): JSX.Element | null {
  const location = useLocation()

  const handleClose = (): void => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete(props.queryKey)
    location.search = queryParams.toString()
  }

  const renderModal = (): JSX.Element => {
    return (
      <Modal
        isOpen
        maxWidth='sm'
        name='DeviceLinkSuccess'
        modalTitle='Link external account'
        onModalClose={handleClose}
      >
        <Typography> Successfully linked account for {getDisplayName(props.memberObj.user)}. </Typography>
      </Modal>
    )
  }

  const renderAccountLinkedModal = (): JSX.Element | null => {
    const queryParams = new URLSearchParams(location.search)
    const isModalVisible = queryParams.has(props.queryKey)
    if (!isModalVisible) return null
    return renderModal()
  }

  return renderAccountLinkedModal()
}
