import React, { useEffect } from 'react'
import moment from 'moment'
import { type ConnectedProps, connect } from 'react-redux'
import restApi from '../../../api'
import AlertDetailNotes from './AlertDetailNotes'
import AlertDetailSidebar from './AlertDetailSidebar'
import Modal from '../../elements/Modal'
import { FormControlLabel, Switch } from '@mui/material'
import { isAlertOpen } from '../../../constants/constants'
import { useLocation } from 'react-router'
import { type IAlert } from '../../../interfaces/alert.interface'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type RootState, type AppDispatch } from '../../..'

interface IAlertDetailProps extends PropsFromRedux {
  selectedAlert: IAlert
  onModalClose: () => void
  maxWidth: 'sm' | 'md' | 'lg'
}

function AlertDetailModal (props: IAlertDetailProps): JSX.Element | null {
  const location = useLocation()

  useEffect(() => {
    if (props.selectedAlert) initAlert()
  }, [props.selectedAlert])

  const initAlert = (): void => {
    if (!props.member && props.apiActions?.fetchInitialMemberSummary) {
      props.apiActions.fetchInitialMemberSummary(props.selectedAlert.user?.id as string)
    }
    handleReadAlert(props.selectedAlert)
  }

  const handleReadAlert = (alertObj: IAlert): void => {
    // If alert is unread, mark it as read
    if (!alertObj.read_status && props.markAlertAsRead) {
      props.markAlertAsRead({
        alertId: alertObj.id,
        memberId: alertObj.user_id,
        clinicianId: props.clinicianId as string,
        isRead: true
      })
    }
  }

  const handleUpdateAlertStatus = (): void => {
    const alertObj = props.selectedAlert
    const isOpen = isAlertOpen(alertObj)

    if (props.updateAlertStatus) {
      props.updateAlertStatus({
        alertId: alertObj.id,
        memberId: alertObj.user_id,
        clinicianId: props.clinicianId as string,
        status: isOpen ? 'CLOSED' : 'OPEN'
      })
    }
  }

  const handleAddNote = (note: string): void => {
    const alertObj = props.selectedAlert

    if (props.addAlertNote) {
      props.addAlertNote({
        alertId: alertObj.id,
        memberId: alertObj.user_id,
        clinicianId: props.clinicianId as string,
        alertNote: note
      })
    }
  }

  const renderStatus = (alertObj: IAlert): JSX.Element => {
    const isOpen = isAlertOpen(alertObj)
    const label = isOpen ? 'Open' : 'Closed'
    return (
      <FormControlLabel
        control={
          <Switch
            id='alert-status'
            checked={isOpen}
            onChange={handleUpdateAlertStatus}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={label}
      />
    )
  }

  const renderDetails = (alertObj: IAlert): JSX.Element => {
    const endDateMoment = moment(alertObj.measurement_timestamp)
    const startDateMoment = moment(endDateMoment).subtract(alertObj.alert_period)
    const timePeriodStr = `${startDateMoment.format('MMM D, Y')} – ${endDateMoment.format('MMM D, Y')}`
    const sentDateStr = moment(alertObj.timestamp).format('MMM D, Y')

    return (
      <section className='alert-detail__details'>
        <div className='alert-detail__dates'>
          <div className='alert-detail__time-period'>
            <h3>Time period</h3>
            <div>{timePeriodStr}</div>
          </div>
          <div className=''>
            <h3>Alert sent on</h3>
            <div>{sentDateStr}</div>
          </div>
        </div>
        <div className='alert-detail__status'>
          <h3>Status</h3>
          {renderStatus(alertObj)}
        </div>
      </section>
    )
  }

  const renderModal = (): JSX.Element | null => {
    const alertObj = props.selectedAlert

    // If we're already on a member profile page, don't show the link to the member profile page
    let hideProfileLink = false
    if (location.pathname.startsWith('/profile')) {
      hideProfileLink = true
    }

    return (
      <Modal
        name='AlertDetail'
        targetUser={alertObj.user}
        maxWidth={props.maxWidth}
        isOpen
        onModalClose={props.onModalClose} // eslint-disable-line react/jsx-handler-names
        modalTitle='Alert detail'
      >
        <div className='alert-detail'>
          <div className='alert-detail__alert'>
            <div className='alert-detail__title' dangerouslySetInnerHTML={{ __html: alertObj.provider_content.replace(/\n/g, '<br />') }} />

            {renderDetails(alertObj)}

            <AlertDetailNotes
              alertObj={alertObj}
              clinicianId={props.clinicianId as string}
              memberObj={props.member as IMemberSummary}
              onAddNote={handleAddNote}
            />
          </div>

          <div className='alert-detail__profile'>
            <AlertDetailSidebar member={props.member as IMemberSummary} hideProfileLink={hideProfileLink} onModalClose={props.onModalClose} />
          </div>
        </div>
      </Modal>
    )
  }

  if (!props.selectedAlert) return null
  return renderModal()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState, ownProps: any) {
  return {
    member: ownProps.selectedAlert && state.membersCache.entities[ownProps.selectedAlert.user_id],
    clinicianId: state.userSession.user?.id
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    apiActions: {
      fetchInitialMemberSummary: (id: string) => dispatch(restApi.actions.memberSummary({ id }))
    },

    markAlertAsRead: ({ alertId, memberId, clinicianId, isRead }) => {
      dispatch(restApi.actions.updateAlert({ alertId }, {
        body: JSON.stringify({
          read_status: isRead ? 0 : 1,
          user_id: memberId,
          author_id: clinicianId
        })
      }))
    },

    updateAlertStatus: ({ alertId, memberId, clinicianId, status }) => {
      dispatch(restApi.actions.updateAlert({ alertId }, {
        body: JSON.stringify({
          status,
          user_id: memberId,
          author_id: clinicianId
        })
      }))
    },

    addAlertNote: ({ alertId, memberId, clinicianId, alertNote }) => {
      dispatch(restApi.actions.updateAlert({ alertId }, {
        body: JSON.stringify({
          user_id: memberId,
          author_id: clinicianId,
          provider_content: alertNote
        })
      }))
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AlertDetailModal)
