import React from 'react'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { MS_IN_A_DAY } from '../../../../constants/constants'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { Box } from '@mui/material'

export default class BioStickerTrackingItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'biosticker_tracking'
  }

  thresholdInputElement (): JSX.Element {
    return this.getInputElement(
      1, 7, 1,
      CAREPLAN_VALUE_TYPES.THRESHOLD.get(val => val as number / MS_IN_A_DAY),
      CAREPLAN_VALUE_TYPES.THRESHOLD.set(val => val as number * MS_IN_A_DAY)
    )
  }

  renderGoal (): JSX.Element {
    return <div>Wear BioButton/BioSticker at all times</div>
  }

  renderClinicianTrigger (): JSX.Element {
    const numDays = this.getValue(CAREPLAN_VALUE_TYPES.THRESHOLD.get(val => val as number / MS_IN_A_DAY))
    return <div>If no <b>Bio</b> data for {this.thresholdInputElement()} day{numDays === 1 ? '' : 's'}</div>
  }

  renderCondensed (): JSX.Element {
    const getNumDays = CAREPLAN_VALUE_TYPES.THRESHOLD.get(val => val as number / MS_IN_A_DAY)
    const numDays = this.getValue(getNumDays)
    const days = this.getValueElement(getNumDays)
    const className: string = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    return <Box className={className}>No Bio data for {days} day{numDays === 1 ? '' : 's'}</Box>
  }
}
