import React, { useEffect } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import LoadingIndicator from '../../elements/LoadingIndicator'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { type IMember } from '../../../interfaces/user.interface'
import { type SxProps } from '@mui/material'
import { type RootState } from '../../..'
import { mapApiDispatch } from '../../../constants/reduxMappers'

interface IProfileAlertsProps extends PropsFromRedux {
  user: IMember
  cardStyle: SxProps
}

function ProfileAlerts (props: IProfileAlertsProps): JSX.Element {
  useEffect(() => {
    props.apiActions.alertCounts({
      id: props.user.id
    })
  }, [])

  const renderAlertCounts = (): JSX.Element => {
    const numOpenAlerts = props.clinicianAlertCountsApi.data.open
    const numClosedAlerts = props.clinicianAlertCountsApi.data.closed

    return (
      <Box>
        {!numOpenAlerts && !numClosedAlerts &&
          <Button size='small' color='secondary' startIcon={<Brightness7Icon />} disabled>
            No alerts for {props.user.first_name as string}
          </Button>}

        {!!(numOpenAlerts || numClosedAlerts) &&
          <>
            <ButtonGroup variant='contained' size='small' sx={{ mb: 2 }} fullWidth aria-label='total alerts'>
              <Button sx={{ width: '50%', borderColor: 'unset !important' }} variant='outlined' disabled>
                <Box sx={{ color: theme => theme.palette.success.main }}>{numOpenAlerts} open</Box>
              </Button>
              <Button sx={{ width: '50%' }} color='secondary' disabled>
                <Box sx={{ color: theme => theme.palette.error.main }}>{numClosedAlerts} closed</Box>
              </Button>
            </ButtonGroup>

            <Button variant='text' href={`/alerts?user=${props.user.id as string}`} color='primary' startIcon={<NotificationsIcon />} sx={{ padding: 0 }}>
              View all alerts for {props.user.first_name}
            </Button>
          </>}
      </Box>
    )
  }

  const renderProfileAlerts = (): JSX.Element => {
    return (
      <Card sx={props.cardStyle}>
        <CardContent>
          <Typography
            variant='h6'
            component='div'
            gutterBottom
            color='primary'
            marginBottom='1rem'
          >
            Alerts
          </Typography>

          <Box>
            {!props.clinicianAlertCountsApi.sync && <LoadingIndicator />}

            {!!(props.clinicianAlertCountsApi.sync) && renderAlertCounts()}
          </Box>
        </CardContent>
      </Card>
    )
  }

  return renderProfileAlerts()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    clinicianAlertCountsApi: state.api.alertCounts
  }
}

const connector = connect(mapState, mapApiDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ProfileAlerts)
