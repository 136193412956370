import { Box } from '@mui/material'
import React from 'react'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'

export default class RespiratoryRateRangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'rr_range'
  }

  rangeRespiratoryRateMinInputElement (): JSX.Element {
    return this.getInputElement(
      5, 19, 1,
      CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MIN.set(val => val)
    )
  }

  rangeRespiratoryRateMaxInputElement (): JSX.Element {
    return this.getInputElement(
      20, 40, 1,
      CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MAX.set(val => val)
    )
  }

  renderGoal (): JSX.Element {
    return <div>Keep resting respiratory rate in range</div>
  }

  renderClinicianTrigger (): JSX.Element {
    return <div>If <b>resting respiratory rate</b> is below {this.rangeRespiratoryRateMinInputElement()} breaths/min or above {this.rangeRespiratoryRateMaxInputElement()} breaths/min</div>
  }

  renderCondensed (): JSX.Element {
    const className: string = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    return (
      <Box className={className}>Resp. rate &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MIN.get(val => val))} or
        &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MAX.get(val => val))} br/min
      </Box>
    )
  }
}
