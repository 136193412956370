
import React from 'react'
import moment from 'moment'
import { momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

export default function BloodOxygenCell (props: { memberObj: IMemberSummary }): JSX.Element {
  if (!props.memberObj.user.has_pulseox || !props.memberObj.pulse_ox) {
    return <EmptyCell isBloodOxygen />
  }

  const lastPulseOxMeasurement = moment(props.memberObj.pulse_ox.latest_measurement.date)
  const latestOxSaturation = props.memberObj.pulse_ox.latest_measurement?.oxygen_saturation as number

  const latestCol = (
    <Box>
      <div className='member-row__latest'> {`${latestOxSaturation.toLocaleString()}%`} </div>
      <div className='member-row__latest-timestamp'> {lastPulseOxMeasurement.calendar(null, momentCalendarDateConfig)} </div>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}> {latestCol} </Grid>
    </Grid>
  )
}
