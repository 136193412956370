import React from 'react'
import CarePlanItem from '../CarePlanItem'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { CARE_PLAN_NOTIFY_OPTIONS } from '../../../../constants/constants'
import { Checkbox, FormControlLabel } from '@mui/material'
import { type ICarePlanItem } from '../../../../interfaces/careplan.interface'

export default class StaticNewChatItem extends CarePlanItem {
  fetchItemData (): void {
    const basicCarePlanItem = { default: true, notify: 'clinicians' }
    this.setState({ itemData: basicCarePlanItem as ICarePlanItem })
  }

  renderEnabled (): JSX.Element {
    return (
      <FormControlLabel
        sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
        control={<Checkbox size='small' id='newChatCheckbox' checked disabled />}
        label=''
      />
    )
  }

  renderGoal (): JSX.Element {
    return (<div> Chat </div>)
  }

  renderClinicianTrigger (): JSX.Element {
    return (<div> If a new chat message is sent. </div>)
  }

  renderCondensed (): JSX.Element {
    return (<div>New chat message sent</div>)
  }

  renderNotify (): JSX.Element {
    const itemData = this.state.itemData

    return (
      <Select
        disabled
        fullWidth
        size='small'
        className='care-plan-item__select'
        value={itemData?.notify}
        id={itemData?.notify}
      >
        {CARE_PLAN_NOTIFY_OPTIONS.map(item => <MenuItem className='care-plan-item__select-item' key={item.key} value={item.key}>{item?.value}</MenuItem>)}
      </Select>
    )
  }
}
