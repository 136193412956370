import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import { RANGE_VIEWS, getRangeBounds } from '../member/dateUtils'
import { isAlertOpen } from '../../constants/constants'
import { type IMarker, type IEmptyChart, type ISimpleChartData, type ISimpleSeries, type ILineSeries, type IScatterSeries, type IAreaRangeSeries } from '../../interfaces/chart.interface'
import { type XAxisOptions, type Options, type SeriesLineOptions, type SeriesScatterOptions, type SeriesArearangeOptions } from 'highcharts'
import { type ISuspension } from '../../interfaces/suspension.interface'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IAlert } from '../../interfaces/alert.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'

export function getEmptyChart (): IEmptyChart {
  return {
    title: { text: 'No data for this period' }, // No title
    credits: { enabled: false } // Hide highcharts.com
  }
}

export function getBaseChartOptions (): Options {
  const marginLeft = 80

  return {
    title: { text: '' }, // No title
    credits: { enabled: false }, // Hide highcharts.com
    chart: { marginLeft },
    series: [],

    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    },

    yAxis: {
      title: { text: '' },
      labels: {}
    },

    legend: {
      itemStyle: {
        fontSize: '14px',
        fontWeight: '500',
        color: '#505050'
      }
    }
  }
}

export function getBaseSeriesOptions (): ISimpleSeries {
  return {
    stickyTracking: false,
    events: { legendItemClick: () => false }
  }
}

export function getPointDateString (momentDate: moment.Moment, includeTime: boolean): string {
  const now = moment()
  let formatString = 'dddd M/D'

  if (now.year() !== momentDate.year()) formatString += '/YY'
  if (includeTime) formatString += ' [at] h:mm A'
  return momentDate.format(formatString)
}

export function addPausePlotBands (pauses: ISuspension [], options: Options, isDateBased: boolean): Options {
  const newOptions = _.cloneDeep(options)
  const pauseOffset = isDateBased ? 43200000 : 0
  let addedBand = false

  pauses.forEach((pause) => {
    const pauseStart = +moment(pause.start) - pauseOffset

    let pauseResume = Number.POSITIVE_INFINITY
    if (pause.resume) pauseResume = +moment(pause.resume) - pauseOffset
    const xAxis = newOptions.xAxis as XAxisOptions
    if (pauseResume > pauseStart && pauseStart < (xAxis?.max ?? 0) && pauseResume > (xAxis?.min ?? 0)) {
      if (xAxis.plotBands) {
        xAxis?.plotBands.push({
          from: pauseStart,
          to: pauseResume,
          color: 'rgba(0, 0, 0, .1)'
        })
      }
      addedBand = true
    }
  })

  if (addedBand) {
    const areaRangeOptions: SeriesArearangeOptions = _.extend({
      name: 'Alerts suspended',
      data: [],
      yAxis: 0,
      color: '#e4e4e4',

      type: 'arearange' as IAreaRangeSeries,
      className: 'legend-suspended',
      zIndex: 0
    }, getBaseSeriesOptions())

    // if a pause band was added, add another empty series
    // so a legend item appears for the pause band
    newOptions.series?.push(areaRangeOptions)
  }

  return newOptions
}

export function getSimpleContinuousSeries (options: Options, memberObj: IMemberSummary, fieldName: string, friendlyName: string, unit: string): Options {
  const newOptions = _.cloneDeep(options)

  // Set marker radius to 0
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], 0)

  const measurements = _.reverse(
    _.cloneDeep(memberObj[fieldName].data)).map((m: ISimpleChartData) => {
    // uncomment when ready to add Expected to tooltips:
    const expectedString = (m.expected || m.expected === 0) ? `<br/><br/>Baseline: ${m.expected}` : ''

    const point = {
      x: +moment(m.timestamp),
      y: m[fieldName],
      name: getPointDateString(moment(m.timestamp), true),
      description: `Mean: ${m.mean}${unit}<br/>Min—Max: ${m.min}—${m.max}<br/>Median: ${m.median}<br/>St. Dev.: ${m.std_dev}<br/>Count: ${m.count}${expectedString}`
    }
    return point
  })

  const measurementsSeries: SeriesLineOptions = _.extend({
    name: friendlyName,
    data: measurements,
    zIndex: 1,
    turboThreshold: 10000,

    type: 'line' as ILineSeries,
    className: `series-${fieldName}Measurements`
  }, getBaseSeriesOptions())

  newOptions.series?.push(measurementsSeries)
  return newOptions
}

export function getBaselineContinuousSeries (options: Options, memberObj: IMemberSummary, fieldName: string, friendlyName: string, unit: string): Options {
  const newOptions = _.cloneDeep(options)

  // Set marker radius to 0
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], 0)

  const baselineMeasurements = _.reverse(
    _.cloneDeep(memberObj[fieldName].baseline)).map((m: ISimpleChartData) => {
    const point = {
      x: +moment(m.timestamp),
      y: m.expected,
      name: getPointDateString(moment(m.timestamp), true),
      description: `${(Math.round(m.expected * 10) / 10)}${unit}<br/>Baseline from ${m.count} past measurements at this hour of the day`
    }
    return point
  })

  const baselineSeries: SeriesLineOptions = _.extend({
    name: friendlyName,
    data: baselineMeasurements,
    zIndex: 1,
    turboThreshold: 10000,

    type: 'line' as ILineSeries,
    className: `series-${fieldName}Baseline`
  }, getBaseSeriesOptions())

  newOptions.series?.push(baselineSeries)
  return newOptions
}

export function getSimpleBioStickerAlertsSeries (memberObj: IMemberSummary, options: Options, handleAlertClick: (alert: IAlert) => void, metricType: string, friendlyMetricType: string, alertType?: string): Options {
  if (!(memberObj.alerts?.length)) return options
  const alerts = _.filter(memberObj.alerts, a => a.type === alertType || a.metric_type === 'biosticker')
  if (!alerts.length) return options

  const alertsData = alerts.map((alertObj) => {
    /*
     * Get the Y-value of the marker
     */
    let pointY = 0
    const measurement = memberObj[metricType].data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display alert marker above it
    if (measurement) {
      pointY = measurement[metricType] * 1.07
    } else pointY = memberObj[metricType].data[0][metricType] * 1.07 // Otherwise just use the first value we have

    // Get marker styling
    const marker: IMarker = {}
    const isTrackingAlert = alertObj.type === `${metricType}_tracking` || alertObj.type === 'biosticker_tracking'
    if (isTrackingAlert) marker.symbol = 'circle'

    const isOpen = isAlertOpen(alertObj)
    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': isTrackingAlert
    })

    const point = {
      name: alertObj.provider_content,
      x: +moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,

      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) {
            handleAlertClick(alertObj)
          }
        }
      }
    }

    return point
  })

  const alertsSeries: SeriesScatterOptions = _.extend({
    name: `${friendlyMetricType} alerts`,

    type: 'scatter' as IScatterSeries,
    className: `series-${metricType}Alerts`,
    lineWidth: 0,
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },

    tooltip: {
      useHTML: true,
      headerFormat: `<strong>${friendlyMetricType} alert</strong>`,
      pointFormat: `<div class="${metricType}-alert">{point.name}</div>`
    },

    data: alertsData,

    zIndex: 200 // so the alerts are on top of the data
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series?.push(alertsSeries)
  return newOptions
}

export function getColumnWidth (dashboardViewStore: IDashboardStore, isMobile: boolean): number {
  if (isMobile) {
    // NEEDS UPDATING
    if (dashboardViewStore.rangeZoom === RANGE_VIEWS.WEEK) return 15
    else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.MONTH) return 5

    return 1
  } else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.ONE_DAY) return 40
  else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.THREE_DAYS) return 40
  else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.WEEK) return 40
  else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.TWO_WEEKS) return 20
  else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.THREE_WEEKS) return 20
  else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.MONTH) return 10
  else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.THREE_MONTHS) return 5
  else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.SIX_MONTHS) return 3
  else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.YEAR) return 3

  return 1
}

export function getXAxisForRange (memberObj: IMemberSummary, dashboardViewStore: IDashboardStore, isMobile: boolean): XAxisOptions {
  const xAxis: XAxisOptions = {
    labels: {
      y: 32
    },
    plotLines: [],
    plotBands: []
  }

  if (isMobile && xAxis.labels) {
    xAxis.labels.y = 16
  }

  const { rangeZoom, rangeStart } = dashboardViewStore
  const { startDate, endDate } = getRangeBounds(memberObj, rangeZoom, rangeStart ?? '')

  const chartEndDate = +moment(endDate).endOf('day')

  xAxis.min = +moment(startDate).startOf('day')
  xAxis.max = +chartEndDate
  xAxis.minPadding = 0
  xAxis.maxPadding = 0

  // Draw ticks
  let tickMax: number
  let tickStep = 1
  let tickUnits: 'days' | 'hours' | 'weeks' | 'months' = 'days'
  let tickFormat = 'dd<br/>M/D'
  let tickFormatMobile = 'dd M/D'

  switch (rangeZoom) {
    case RANGE_VIEWS.ONE_DAY:
      xAxis.max = +chartEndDate + 1 // add a millisecond so end-of-day midnight shows up
      tickStep = 3
      tickUnits = 'hours'
      tickMax = 27
      tickFormat = 'h A'
      break

    case RANGE_VIEWS.THREE_DAYS:
      tickMax = 3
      break

    case RANGE_VIEWS.WEEK:
      tickMax = 7
      break

    case RANGE_VIEWS.TWO_WEEKS:
      tickMax = 14
      tickStep = 2
      break

    case RANGE_VIEWS.THREE_WEEKS:
      tickMax = 21
      tickStep = 3
      break

    case RANGE_VIEWS.MONTH:
      tickMax = 5
      tickUnits = 'weeks'
      break

    case RANGE_VIEWS.THREE_MONTHS:
      tickMax = 14
      tickStep = 2
      tickUnits = 'weeks'
      break

    case RANGE_VIEWS.SIX_MONTHS:
      tickMax = 7
      tickUnits = 'months'
      break

    case RANGE_VIEWS.YEAR:
    default:
      tickMax = 13
      tickUnits = 'months'
      tickFormat = 'MMM'
      tickFormatMobile = 'MMM'
      break
  }

  xAxis.tickPositioner = () => _.map(_.range(0, tickMax, tickStep),
    d => +moment(startDate).add(d, tickUnits))

  if (xAxis.labels) {
    xAxis.labels.formatter = function format () {
      if (isMobile) {
        return moment(this.value).format(tickFormatMobile)
      }

      return moment(this.value).format(tickFormat)
    }
  }

  // Draw a line for the join date (presumably the first time we have data)
  const joinedDate = moment(memberObj.user.created)
  xAxis.plotLines?.push({
    value: +joinedDate,
    className: 'joined-plot-line'
  })

  if (xAxis.labels) xAxis.labels.useHTML = true

  return xAxis
}
