import React, { useState } from 'react'
import WeightCard from './Cards/WeightCard'
import { productConfig } from '../../../config/config-service'
import BloodGlucoseCard from './Cards/BloodGlucoseCard'
import BloodPressureCard from './Cards/BloodPressureCard'
import BloodOxygenCard from './Cards/BloodOxygenCard'
import SymptomSurveyCard from './Cards/SymptomSurveyCard'
import MissingDataCard from './Cards/MissingDataCard'
import CalorieTargetItem from './LineItems/CalorieTargetItem'
import DailyActivityTargetItem from './LineItems/DailyActivityTargetItem'
import HealthStatusChangeItem from './LineItems/HealthStatusChangeItem'
import ProgramStepChangeItem from './LineItems/ProgramStepChangeItem'
import StaticNewChatItem from './LineItems/StaticNewChatItem'
import WeeklyActivityTargetItem from './LineItems/WeeklyActivityTargetItem'
import PulseOxRangeItem from './RangeItems/PulseOxRangeItem'
import HeartRateRangeItem from './RangeItems/HeartRateRangeItem'
import BioStickerTrackingItem from './TrackingItems/BioStickerTrackingItem'
import TemperatureRangeItem from './RangeItems/TemperatureRangeItem'
import TemperatureBaselineRangeItem from './RangeItems/TemperatureBaselineRangeItem'
import RespiratoryRateRangeItem from './RangeItems/RespiratoryRateRangeItem'
import MultiMetricTrackingItem from './TrackingItems/MultiMetricTrackingItem'
import { type SectionsEntity } from '../../../interfaces/product-config.interface'
import { type ICarePlanRowsProps } from '../../../interfaces/careplan.interface'

export default function CarePlanRows (props: ICarePlanRowsProps): JSX.Element {
  const className = props.shouldRenderCondensed ? 'care-plan--condensed' : 'care-plan--full'
  const [carePlan] = useState(productConfig().carePlan)

  const renderSectionByType = (section: SectionsEntity): JSX.Element => {
    switch (section.sectionType) {
      case 'weight':
        return <WeightCard key={section.sectionType} {...props} sectionData={section} />
      case 'bloodPressure':
        return <BloodPressureCard key={section.sectionType} {...props} sectionData={section} />
      case 'bloodGlucose':
        return <BloodGlucoseCard key={section.sectionType} {...props} sectionData={section} />
      case 'bloodOxygen':
        return <BloodOxygenCard key={section.sectionType} {...props} sectionData={section} />
      case 'symptomSurvey':
        return <SymptomSurveyCard key={section.sectionType} {...props} />
      case 'missingData':
        return <MissingDataCard key={section.sectionType} {...props} />
      default:
        return <></>
    }
  }

  const renderLineByType = (line: string, listHasMore: boolean): JSX.Element => {
    let lineItem: JSX.Element
    switch (line) {
      case 'programStep':
        lineItem = (<ProgramStepChangeItem key={line} {...props} />)
        break
      case 'healthStatus':
        lineItem = (<HealthStatusChangeItem key={line} {...props} />)
        break
      case 'dailyActivityTarget':
        lineItem = (<DailyActivityTargetItem key={line} {...props} />)
        listHasMore = false
        break
      case 'weeklyActivityTarget':
        lineItem = (<WeeklyActivityTargetItem key={line} {...props} />)
        break
      case 'calorieTarget':
        lineItem = (<CalorieTargetItem key={line} {...props} />)
        break
      case 'newChat':
        lineItem = (<StaticNewChatItem key={line} {...props} />)
        break
      case 'bioStickerTracking':
        lineItem = (<BioStickerTrackingItem key={line} {...props} />)
        break
      case 'tempertatureRange':
        lineItem = (<TemperatureRangeItem key={line} {...props} />)
        break
      case 'temperatureBaselineRange':
        lineItem = (<TemperatureBaselineRangeItem key={line} {...props} />)
        break
      case 'heartRateRange':
        lineItem = (<HeartRateRangeItem key={line} {...props} />)
        break
      case 'respiratoryRateRange':
        lineItem = (<RespiratoryRateRangeItem key={line} {...props} />)
        break
      case 'bloodOxygenRange':
        lineItem = (<PulseOxRangeItem key={line} {...props} />)
        break
      case 'missingData':
        lineItem = (<MultiMetricTrackingItem key={line} {...props} />)
        break

      default:
        lineItem = <></>
    }

    const divider = listHasMore ? (<div className='care-plan__divider' />) : null

    return (
      <React.Fragment key={`container__${line}`}>
        {lineItem}
        {divider}
      </React.Fragment>
    )
  }

  return (
    <div className={className}>
      {carePlan.sections?.map(section => renderSectionByType(section))}
      {carePlan.lines?.map((line, index) => renderLineByType(line, index < (carePlan.lines?.length as number) - 1))}
    </div>
  )
}
