import React from 'react'
import { Grid } from '@mui/material'
import { type IGroup } from '../../../../interfaces/group.interface'
import { type ICareManager } from '../../../../interfaces/store/sessionStore.interface'
import { productConfig } from '../../../../config/config-service'
import { getProfileMemberFields } from '../../../../utils/member/baseProfileUtils'

interface IMemberProfileFormFieldsProps {
  careManagers: ICareManager[]
  allowedGroups?: IGroup[]
  change?: any
}

export default function MemberInformationFields (props: IMemberProfileFormFieldsProps): JSX.Element {
  const memberFields = getProfileMemberFields()
  const profileConfig = productConfig().profile
  const hasInCareManagement = profileConfig.customFormFields?.includes('in_care_management')

  const getSmallColumn = (item: string): number => { return isSmallField(item) ? 3 : 6 }
  const getXSmallColumn = (item: string): number => { return isSmallField(item) ? 6 : 12 }
  const isSmallField = (fieldName: string): boolean => {
    return fieldName.includes('height') || (fieldName.includes('Weight') && !profileConfig.showPregnancyInfo)
  }

  const getGridItem = (item: string, xs = 12, sm = 6): JSX.Element | null => {
    if (item === 'fhirId') return null // ignore fhirId fields, as that needs not be shown

    if (item === 'care_manager_id') { // special case
      // @ts-expect-error undefined possible for formInput method
      return (<Grid item xs={12} sm={6} key={item}>{memberFields[item].formInput(props.careManagers, props.change, hasInCareManagement)}</Grid>)
    } else if (item === 'group') {
      // @ts-expect-error undefined possible for formInput method
      return props.allowedGroups?.length > 1 ? (<Grid item xs={xs} sm={sm} key={item}>{memberFields[item].formInput(props.allowedGroups)}</Grid>) : null
    } else {
      // @ts-expect-error undefined possible for formInput method
      return (<Grid item xs={xs} sm={sm} key={item}>{memberFields[item].formInput(props.change)}</Grid>)
    }
  }

  const renderMemberInformationFields = (): JSX.Element => {
    const defaultFormFields = profileConfig.defaultFormFields?.filter(field => !profileConfig.baseMemberFieldsToRemove.includes(field))

    return (
      <Grid container spacing={1} data-testid='member-profile-form-fields' sx={{ mb: 2 }}>
        {defaultFormFields?.map(item => getGridItem(item, getXSmallColumn(item), getSmallColumn(item)))}
        {profileConfig.pregnancyFields?.map(item => getGridItem(item, getXSmallColumn(item), getSmallColumn(item)))}
        {profileConfig.customFormFields?.map(item => getGridItem(item, getXSmallColumn(item), getSmallColumn(item)))}
      </Grid>

    )
  }

  return renderMemberInformationFields()
}
