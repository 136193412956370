import _ from 'lodash'
import moment from 'moment'
import {
  getBaseChartOptions,
  getBaseSeriesOptions,
  getPointDateString,
  addPausePlotBands,
  getEmptyChart
} from './baseChartUtils'
import { preciseRound } from '../unitConversionUtils'
import { getColumnWidth, getDefaultChartOptions, getXAxisForRange } from './chartUtils'
import { type IColumnSeries, type IChartOptionsParams, type ILineSeries } from '../../interfaces/chart.interface'
import { type SeriesColumnOptions, type Options, type SeriesLineOptions } from 'highcharts'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'

const baseFoodChartOptions = {
  ...getDefaultChartOptions('food-chart'),
  tooltip: {
    headerFormat: '',
    useHTML: true,
    shared: true,
    stickOnContact: true,
    borderWidth: 0
  }
}

function getFoodMeasurementsSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): Options {
  if (!memberObj.food) return options
  const newOptions = { ...options }

  const foodMeasurements = (_.cloneDeep(memberObj.food.data)).reverse().map((m) => {
    const date = moment(m.date)
    const point = {
      x: +date,
      y: m.calories,
      carbs: m.carbohydrates_grams,
      fat: m.fat_grams,
      fiber: m.fiber_grams,
      protein: m.protein_grams,
      sodium: m.sodium_milligrams,
      foods: m.foods,
      dataSource: m.source
    }
    return point
  })

  const foodMeasurementSeries: SeriesColumnOptions = _.extend({
    name: 'Calories',
    type: 'column' as IColumnSeries,
    data: foodMeasurements,
    zIndex: 1,
    className: 'food-series',
    color: '#27AAE1',

    tooltip: {
      pointFormatter: function format () {
        const point = this as any
        const nutrientsPortion = `
          <br/>
          Carbs: ${preciseRound(point.carbs, 0)} g<br/>
          Fat: ${preciseRound(point.fat, 0)} g <br/>
          Protein: ${preciseRound(point.protein, 0)} g <br/>
          Fiber: ${preciseRound(point.fiber, 0)} g <br/>
          Sodium: ${preciseRound(point.sodium, 0)} mg <br/>
        `

        let foodsPortion = ''
        if (point.foods?.length) {
          foodsPortion += '<br/>'
          point.foods.forEach((f: { name: string, calories: number }) => {
            foodsPortion += `${f.name} (${f.calories} cal)<br/>`
          })
        }

        return `
        <div style="max-height: 9rem; height: 9rem; overflow-y: auto; z-index: 9999999999">
          <strong>${getPointDateString(moment(point.x), false)}</strong><br/>
          ${(point.y as number).toLocaleString()} calories<br/>
          ${nutrientsPortion}
          ${foodsPortion}
          <small>Source: ${point.dataSource as string ?? 'Unknown'}</small>
          </div>
        `
      }
    },

    pointWidth: getColumnWidth(dashboardViewStore, false)
  }, getBaseSeriesOptions())

  newOptions.series?.push(foodMeasurementSeries)
  return newOptions
}

function getCalorieGoalSeries (options: Options, memberObj: IMemberSummary): Options {
  const calorieGoal = memberObj.careplan?.find(item => item.type === 'calorie_target')
  if (!calorieGoal || calorieGoal.disabled) return options

  const newOptions = _.cloneDeep(options)
  const calorieGoals = [calorieGoal]

  const calorieGoalData = calorieGoals.reduce((acc, goal) => {
    // Ignore calorie goals that were active for less than an hour
    if ((goal.end_date - goal.set_date) < 1000 * 60 * 60) return acc
    acc[acc.length - 1].push(goal.value)
    acc.push([+moment(goal.end_date), goal/* .target */.value])
    acc.push([+moment(goal.end_date) + 1])

    return acc
  }, [[+moment().subtract(1, 'year')]])

  calorieGoalData.pop() // Remove the last (incomplete) data point

  const calorieGoalSeries: SeriesLineOptions = {
    ...getBaseSeriesOptions,
    name: 'Daily calorie goal',
    type: 'line' as ILineSeries,
    data: calorieGoalData,
    dashStyle: 'LongDash',
    color: '#7DD3F6',
    enableMouseTracking: false
  }

  newOptions.series?.push(calorieGoalSeries)
  return newOptions
}

export default function getFoodChartOptions ({ memberObj, dashboardViewStore }: IChartOptionsParams): Options {
  if (!memberObj.food) return getEmptyChart()

  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, false) }

  let options: Options = _.merge(
    getBaseChartOptions(),
    baseFoodChartOptions,
    xAxisOptions)

  options = getFoodMeasurementsSeries(options, memberObj, dashboardViewStore)
  options = getCalorieGoalSeries(options, memberObj)
  options = addPausePlotBands(memberObj.user.pauses ?? [], options, true)

  return options
}
