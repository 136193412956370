import _ from 'lodash'
import { type Options } from 'highcharts'
import { type IAnalyticsUser } from '../../../../interfaces/store/analyticsStore.interface'
import baseChartOptions from './baseChartOptions'

function getGenderCounts (users: IAnalyticsUser []): { female: IAnalyticsUser [], male: IAnalyticsUser [] } {
  return {
    female: _.filter(users, u => u.info.gender === 'FEMALE'),
    male: _.filter(users, u => u.info.gender === 'MALE')
  }
}

function getGenderChartOptions (users: IAnalyticsUser [], analyticsActions: any): Options {
  const genderCounts = getGenderCounts(users)

  const genderChartOptions: Options = {
    plotOptions: {
      pie: {
        states: {
          hover: {
            brightness: 0,
            halo: {
              opacity: 0.1
            }
          }
        }
      }
    },

    series: [{
      name: 'Members',
      slicedOffset: 12,
      type: 'pie',
      data: [{
        name: 'Female',
        y: genderCounts.female.length,
        sliced: true,
        className: 'series-0',
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: 'Sex: Female',
              type: 'member',
              items: genderCounts.female
            })
          }
        }
      }, {
        name: 'Male',
        y: genderCounts.male.length,
        className: 'series-1',
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: 'Sex: Male',
              type: 'member',
              items: genderCounts.male
            })
          }
        }
      }],
      cursor: 'pointer'
    }],
    tooltip: {
      headerFormat: '',
      useHTML: true,
      borderColor: 'none',
      pointFormatter: function format () {
        const point = this as any
        return `
          <span class="ax-text-bold">${point.name as string}</span><br/>
          Members: <em>${point.y as number}</em>
        `
      }
    }
  }

  return _.merge(_.cloneDeep(baseChartOptions), genderChartOptions)
}

export default getGenderChartOptions
