import React, { useState } from 'react'
import ChartModule from './ChartModule'
import TabPanel from '../../layout/TabPanel'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import BloodPressureTable from '../Tables/BloodPressureTable'
import { getBloodPressureChartOptions } from '../../../utils/charts/baseBloodPressureChart'
import { a11yProps } from '../../../constants/constants'
import { type IChartOptionsParams } from '../../../interfaces/chart.interface'

export default function BloodPressureChart (props: IChartOptionsParams): JSX.Element {
  const [selectedBloodPressureTab, setSelectedBloodPressureTab] = useState(0)
  const pagePrefix = 'blood-pressure-metric__tab'

  const renderChartPanel = (): JSX.Element => {
    return (
      <Card className='chart-card' id='blood_pressure-card'>
        <CardContent sx={{ paddingTop: 0 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'end', mb: 2 }}>
            <Typography variant='h6' color='#7f7f7f'> Blood Pressure</Typography>
            <Tabs
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              value={selectedBloodPressureTab}
              onChange={(_e, val) => { setSelectedBloodPressureTab(val) }}
              aria-label='Profile tabs'
            >
              <Tab data-testid='blood-pressure__charts-tab' label='Graph' {...a11yProps(pagePrefix, 0)} />
              <Tab data-testid='blood-pressure__table-tab' label='Table' {...a11yProps(pagePrefix, 1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedBloodPressureTab} index={0} noPadding>
            <ChartModule
              hideHeader
              hideBorder
              showChartActions
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
              metricType='blood_pressure'
              getChartOptions={getBloodPressureChartOptions}
              showMemberAlerts={props.showMemberAlerts}
              handleAlertClick={props.handleAlertClick} // eslint-disable-line react/jsx-handler-names
            />
          </TabPanel>
          <TabPanel value={selectedBloodPressureTab} index={1} noPadding>
            <BloodPressureTable
              hideHeader
              hideBorder
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
            />
          </TabPanel>
        </CardContent>
      </Card>

    )
  }

  return renderChartPanel()
}
