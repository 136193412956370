import { renderField, renderInput, renderCheckboxInput, renderInputWithUnit, renderSelect } from '../components/elements/EjentaForm'
import { DEVICE_PLATFORM_MAP, GENDER_MAP } from '../utils/baseStringUtils'
import { baseMemberFields, getCareManagerOptions, getDateInputOptions, getSelectInputOptions } from '../utils/member/baseProfileUtils'
import { convertKgToLb, preciseRound } from '../utils/unitConversionUtils'
import { productConfig } from '../config/config-service'
import { type IProfileField, type IProfileFields } from '../interfaces/forms.interface'
import moment from 'moment'
import { type IMember } from '../interfaces/user.interface'
import _ from 'lodash'

export const IN_CM_MAP: Record<string, string> = {
  0: 'No',
  1: 'Yes'
}

export const COACH_MAP: Record<string, string> = {
  TS: 'TS',
  RN: 'RN',
  HU: 'HU'
}

export const genderSelect: IProfileField = {
  apiField: 'gender',

  formInput () {
    const fieldOptions = {
      name: 'gender',
      label: 'Sex',
      component: renderSelect,
      type: 'select',
      options: GENDER_MAP
    }

    return renderField(this, fieldOptions)
  }
}

export const diagnosisInput: IProfileField = {
  optional: true,
  apiField: 'summary',

  formInput () {
    const fieldOptions = {
      name: 'diagnosis',
      label: 'Diagnosis',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const dryWeightInputWithUnit: IProfileField = {
  optional: true,
  apiField: 'dry_weight_in_kg',

  accessor: (user: IMember) => {
    if (user.dry_weight_in_kg !== null && user.dry_weight_in_kg !== undefined) {
      return preciseRound(convertKgToLb(user.dry_weight_in_kg), 2)
    }
    return ''
  },

  formInput () {
    const fieldOptions = {
      name: 'dryWeight',
      label: 'Dry weight',
      component: renderInputWithUnit,
      type: 'text', // for compatibility with old browsers
      unit: 'lb',
      minValue: 1,
      maxValue: 999,
      step: 'any'
    }

    return renderField(this, fieldOptions)
  }
}

export const homePhoneInput: IProfileField = {
  apiField: 'home_phone',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'homePhone',
      label: 'Home phone',
      component: renderInput,
      type: 'tel'
    }

    return renderField(this, fieldOptions)
  }
}

export const streetAddress1Input: IProfileField = {
  apiField: 'street_address_1',

  formInput () {
    const fieldOptions = {
      name: 'streetAddress1',
      label: 'Address line 1',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const streetAddress2Input: IProfileField = {
  apiField: 'street_address_2',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'streetAddress2',
      label: 'Address line 2',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const cityInput: IProfileField = {
  apiField: 'city',

  formInput () {
    const fieldOptions = {
      name: 'city',
      label: 'City',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const stateInput: IProfileField = {
  apiField: 'state',

  formInput () {
    const fieldOptions = {
      name: 'state',
      label: 'State',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const zipCodeInput: IProfileField = {
  apiField: 'zip_code',

  formInput () {
    const fieldOptions = {
      name: 'zipCode',
      label: 'Zip code',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const pcpNameInput: IProfileField = {
  apiField: 'pcp',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'pcp',
      label: 'Primary care physician',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const pcpPhoneInput: IProfileField = {
  apiField: 'pcp_phone',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'pcpPhone',
      label: 'PCP phone number',
      component: renderInput,
      type: 'tel'
    }

    return renderField(this, fieldOptions)
  }
}

export const careManagerIdSelect: IProfileField = {
  apiField: 'care_manager_id',
  formInput (careManagers, change, hasInCareManagement) {
    const fieldOptions = {
      name: 'care_manager_id',
      label: 'Care Manager',
      component: renderSelect,
      type: 'select',
      options: getCareManagerOptions(careManagers),
      onChange: (value: string) => {
        if (hasInCareManagement) {
          if (value !== 'OPTION_NONE') {
            change('in_care_management', '1')
          } else {
            change('in_care_management', '0')
          }
        }
      }
    }

    return renderField(this, fieldOptions)
  },
  accessor: (user: IMember) => user.care_manager_id ?? 'OPTION_NONE'
}

export const inCareManagementSelect: IProfileField = {
  apiField: 'in_care_management',

  formInput (change) {
    const fieldOptions = {
      name: 'in_care_management',
      label: 'In care management',
      component: renderSelect,
      type: 'select',
      options: IN_CM_MAP,
      onChange: (value: string) => {
        if (value === '0') {
          change('care_manager_id', 'OPTION_NONE')
        }
      }
    }

    return renderField(this, fieldOptions)
  },

  accessor: (user: IMember) => {
    const hasCareManager = !_.isNull(user.care_manager_id) && user.care_manager_id !== 'OPTION_NONE'
    const inManagement = user.in_care_management === 1 ? '1' : '0'
    return hasCareManager ? '1' : inManagement
  }
}

export const lcuInput: IProfileField = {
  apiField: 'lcu',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'lcu',
      label: 'LCU',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const lifestyleCoachSelect: IProfileField = {
  apiField: 'lifestyle_coach',

  formInput () {
    const fieldOptions = {
      name: 'lifestyleCoach',
      label: 'Lifestyle Coach',
      component: renderSelect,
      type: 'select',
      options: COACH_MAP
    }

    return renderField(this, fieldOptions)
  }
}

export const medicalIdInput: IProfileField = {
  apiField: 'medical_id',

  formInput () {
    const fieldOptions = {
      name: 'medicalId',
      label: 'Study ID',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  },

  readOnlyFormInput () {
    const fieldOptions = {
      name: 'medicalId',
      label: 'Study ID',
      component: renderInput,
      type: 'text',
      disabled: true
    }

    return renderField(this, fieldOptions)
  }
}

export const fhirIdInput: IProfileField = {
  apiField: 'fhir_id',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'fhirId',
      label: 'FHIR ID',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  },

  readOnlyFormInput () {
    const fieldOptions = {
      name: 'fhirId',
      label: 'FHIR ID',
      component: renderInput,
      type: 'text',
      disabled: true
    }

    return renderField(this, fieldOptions)
  }
}

export const employerGroupInput: IProfileField = {
  apiField: 'employer_group',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'employer_group',
      label: 'Employer group',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const signedConsentCheckbox: IProfileField = {
  apiField: 'signed_consent',
  optional: false,
  formInput () {
    const fieldOptions = {
      name: 'signed_consent',
      label: 'Member has signed paper Consent Form.*',
      component: renderCheckboxInput,
      checked: false,
      required: true,
      type: 'checkbox'
    }

    return renderField(this, fieldOptions)
  }
}

export const signedTOSCheckbox: IProfileField = {
  apiField: 'signed_tos',
  optional: false,
  formInput () {
    const fieldOptions = {
      name: 'signed_tos',
      label: 'Member has agreed to <a href=\'//ejenta.com/terms.pdf\' target=\'blank\'> &nbsp; Terms of Service.*</a>',
      component: renderCheckboxInput,
      checked: false,
      required: true,
      type: 'checkbox'
    }

    return renderField(this, fieldOptions)
  }
}

export const signedConsentAndTosCheckbox: IProfileField = {
  optional: false,
  formInput () {
    const fieldOptions = {
      name: 'signedConsentAndTos',
      label: 'Member has signed paper Consent and <a href=\'//ejenta.com/terms.pdf\' target=\'_blank\'> &nbsp;Terms of Service.*</a>',
      component: renderCheckboxInput,
      checked: false,
      required: true,
      type: 'checkbox'
    }

    return renderField(this, fieldOptions)
  }
}

export const devicePlatformSelect: IProfileField = {
  apiField: 'device_platform',
  optional: true,
  formInput () {
    return getSelectInputOptions(this, 'devicePlatform', 'Mobile device type', DEVICE_PLATFORM_MAP)
  }
}

export const conceptionDateInput: IProfileField = {
  apiField: 'conception_date',
  accessor: user => moment(user.conception_date).format('Y-MM-DD'),
  formInput () {
    return getDateInputOptions(this, 'conceptionDate', 'Pregnancy start date (mm/dd/yyyy)', false, 'Use the first day of the last menstrual period.')
  }
}

export const customMemberFields: IProfileFields = {
  gender: genderSelect,
  diagnosis: diagnosisInput,
  dryWeight: dryWeightInputWithUnit,
  signedConsentAndTos: signedConsentAndTosCheckbox,
  medicalId: medicalIdInput,
  fhirId: fhirIdInput,
  lifestyleCoach: lifestyleCoachSelect,
  devicePlatform: devicePlatformSelect,
  conceptionDate: conceptionDateInput,
  signed_consent: signedConsentCheckbox,
  homePhone: homePhoneInput,
  streetAddress1: streetAddress1Input,
  streetAddress2: streetAddress2Input,
  city: cityInput,
  state: stateInput,
  zipCode: zipCodeInput,
  pcp: pcpNameInput,
  pcpPhone: pcpPhoneInput,
  care_manager_id: careManagerIdSelect,
  in_care_management: inCareManagementSelect,
  lcu: lcuInput,
  employer_group: employerGroupInput,
  signed_tos: signedTOSCheckbox
}

export const getCustomMemberFields = (): IProfileFields => {
  const profileConfig = productConfig().profile
  const configCustomFields = [...profileConfig.customFormFields ?? [], ...profileConfig.pregnancyFields ?? []]
  if (profileConfig.termsCheckbox) configCustomFields.push(profileConfig.termsCheckbox)
  const memberFields: IProfileFields = {}

  configCustomFields.forEach(field => {
    memberFields[field] = customMemberFields[field]
  })

  return memberFields
}

export const getMemberAddressFields = (): IProfileFields => {
  const profileConfig = productConfig().profile
  const memberFields: IProfileFields = {}

  profileConfig.addressFormFields.forEach(field => {
    memberFields[field] = customMemberFields[field]
  })

  return memberFields
}

export const getMemberOptionalFields = (): IProfileFields => {
  const profileConfig = productConfig().profile
  const memberFields: IProfileFields = {}
  const fieldLibrary = { ...customMemberFields, ...baseMemberFields }

  profileConfig.optionalFormFields.forEach(field => {
    memberFields[field] = fieldLibrary[field]
  })

  return memberFields
}
