import React, { useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import Tooltip from '../../../elements/Tooltip'
import AnalyticsChart from '../layout/AnalyticsChart'
import getWeightFluctuationChartOptions from '../charts/weightFluctuationChart'
import getActivityChartOptions from '../charts/activityChart'
import getSleepChartOptions from '../charts/sleepChart'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { productConfig } from '../../../../config/config-service'
import { mapAnalyticsDispatch, mapAnalyticsState } from '../../../../constants/reduxMappers'

function AnalyticsMeasurementsSection (props: PropsFromRedux): JSX.Element {
  const [weightExpanded, setWeightExpanded] = useState(true)
  const [activityExpanded, setActivityExpanded] = useState(true)
  const [sleepExpanded, setSleepExpanded] = useState(true)
  const config = productConfig().profile.charts

  const handleChange = (callback: (isExpanded: boolean) => void) => (event, isExpanded) => {
    callback(isExpanded)
  }

  const renderWeightFluctuationModule = (): JSX.Element => {
    const tooltipText = 'Explore how a patient\'s weight is correlated with their median weight change between measurements.'

    return (
      <Accordion expanded={weightExpanded} onChange={handleChange(setWeightExpanded)}>
        <AccordionSummary expandIcon={<ExpandMore />} id="accordion-age">
          Weight fluctuation
          <Tooltip tooltipId='ax-module__weight-fluctuation__tooltip' content={tooltipText} />
        </AccordionSummary>
        <AccordionDetails className='ax-chart-container'>
          <AnalyticsChart
            containerId='ax-weight-fluctuations-chart'
            chartOptions={getWeightFluctuationChartOptions(props.analyticsStore.onboardedUsers)}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderActivityModule = (): JSX.Element => {
    const activityTooltip = 'Every patient averages a different number of steps depending on the day of the week. This chart represents the distribution of those averages across the entire population.'

    return (
      <Accordion expanded={activityExpanded} onChange={handleChange(setActivityExpanded)}>
        <AccordionSummary expandIcon={<ExpandMore />} id="accordion-activity">
          Activity by day of week
          <Tooltip tooltipId='ax-module__activity-by-dow__tooltip' content={activityTooltip} />
        </AccordionSummary>
        <AccordionDetails className='ax-chart-container'>
          <AnalyticsChart
            containerId='ax-activity-by-dow'
            chartOptions={getActivityChartOptions(props.analyticsStore.onboardedUsers)}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderSleepModule = (): JSX.Element => {
    const sleepTooltip = 'Use this chart to investigate how long patients sleep, how many sleep disruptions they experience in a night, and potential relationships between the two.'

    return (
      <Accordion expanded={sleepExpanded} onChange={handleChange(setSleepExpanded)}>
        <AccordionSummary expandIcon={<ExpandMore />} id="accordion-sleep">
          Nightly sleep length and disruptions
          <Tooltip tooltipId='ax-module__sleep__tooltip' content={sleepTooltip} />
        </AccordionSummary>
        <AccordionDetails className='ax-chart-container'>
          <AnalyticsChart
            containerId='ax-sleep-chart'
            chartOptions={getSleepChartOptions(props.analyticsStore.onboardedUsers)}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderAxMeasurementsSection = (): JSX.Element => {
    return (
      <div className='ax-subsection' data-testid='ax-subsection__measurements'>
        <div className='ax-subsection__header'>Measurements</div>
        {(config?.includes('weight') === true || config?.includes('pregnancyWeight')) && renderWeightFluctuationModule()}
        {config?.includes('activity') && renderActivityModule()}
        {config?.includes('sleep') && renderSleepModule()}
      </div>
    )
  }

  return renderAxMeasurementsSection()
}

const connector = connect(mapAnalyticsState, mapAnalyticsDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AnalyticsMeasurementsSection)
