import React from 'react'
import { type ICarePlanCardProps } from '../../../../interfaces/careplan.interface'
import MultiMetricTrackingItem from '../TrackingItems/MultiMetricTrackingItem'

export default function MissingDataCard (props: ICarePlanCardProps): JSX.Element {
  return (
    <div className='care-plan__section' data-testid='care-plan__section__empty'>
      <div className='care-plan__section-header'>Missing Data</div>
      <MultiMetricTrackingItem {...props} />
    </div>
  )
}
