import { createSlice } from '@reduxjs/toolkit'

export interface IMemberFormStore {
  fieldValues: Record<string, string | number>
  isFormValid: boolean
  termsAccepted: boolean
  orderBodyTraceBloodPressure: boolean
  orderBodyTraceScale: boolean
}

const initialState: IMemberFormStore = {
  fieldValues: {},
  isFormValid: false,
  termsAccepted: false,
  orderBodyTraceBloodPressure: false,
  orderBodyTraceScale: false
}

const memberFormSlice = createSlice({
  name: 'memberForm',
  initialState,
  reducers: {
    updateField: (state: IMemberFormStore, action) => {
      const formField = action.payload
      state.fieldValues[formField.fieldName] = formField.fieldValue
    },
    setDefaultValues: (state: IMemberFormStore, action) => {
      state.fieldValues = {} // empty existing values
      Object.keys(action.payload).forEach(key => {
        state.fieldValues[key] = action.payload[key]
      })
    },
    resetForm: (state: IMemberFormStore) => {
      state = { ...initialState }
    },
    setFormValidity: (state: IMemberFormStore, action: { payload: boolean }) => {
      state.isFormValid = action.payload
    },
    setOrderScale: (state: IMemberFormStore, action: { payload: boolean }) => {
      state.orderBodyTraceScale = action.payload
    },
    setOrderBloodPressure: (state: IMemberFormStore, action: { payload: boolean }) => {
      state.orderBodyTraceBloodPressure = action.payload
    },
    setTermsAcceptance: (state: IMemberFormStore, action: { payload: { checked: boolean, field: string } }) => {
      state.termsAccepted = action.payload.checked
      state.fieldValues[action.payload.field] = action.payload.checked ? 1 : 0
    }
  }
})

export const {
  updateField,
  resetForm,
  setFormValidity,
  setOrderScale,
  setOrderBloodPressure,
  setTermsAcceptance
} = memberFormSlice.actions
export { memberFormSlice }
