import React from 'react'
import classnames from 'classnames'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import StarIcon from '@mui/icons-material/Star'

interface IStarProps {
  className: string
  filled?: boolean
  onClick?: (event: any) => void
}

function Star (props: IStarProps): JSX.Element {
  const noopClickHandler = (_e: any): void => {}
  const classNames = classnames(props.className)
  const callback = props.onClick ?? noopClickHandler

  if (props.filled) return (<StarIcon color='primary' name='filled-star' className={classNames} onClick={e => { callback(e) }} />)
  else return (<StarOutlineIcon color='primary' name='outlined-star' className={classNames} onClick={e => { callback(e) }} />)
}

export default Star
