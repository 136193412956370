import React, { useState } from 'react'
import moment from 'moment'
import restApi from '../../../api'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { profileViewSlice } from '../../../store/profileView'
import { momentCalendarConfig, cleanNoteContent } from '../../../utils/baseStringUtils'
import { Card, CardContent, Box, Grid, Typography, IconButton, Tooltip } from '@mui/material'
import AlertDialog from '../AlertDialog'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import PushPinIcon from '@mui/icons-material/PushPin'
import DeleteIcon from '@mui/icons-material/Delete'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { isAlertOpen } from '../../../constants/constants'
import { type INote, type INoteSnapshot } from '../../../interfaces/note.interface'
import { type IAlert } from '../../../interfaces/alert.interface'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type RootState, type AppDispatch } from '../../..'

interface INoteRowProps extends PropsFromRedux {
  key: string
  noteData: INote
  hideActions?: boolean
  callout?: boolean
  memberObj: IMemberSummary | null
}

function NoteRow (props: INoteRowProps): JSX.Element {
  const [replaceDialogOpen, setReplaceDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [dialogTitle, setDialogTitle] = useState<string>()
  const [dialogMessage, setDialogMessage] = useState<string>()
  const [selectedNote, setSelectedNote] = useState<INoteSnapshot>()

  const handleReplaceDialogClosed = (isConfirmed): void => {
    setReplaceDialogOpen(false)
    if (isConfirmed) {
      const newParams = {
        memberId: selectedNote?.memberId,
        noteToStarId: selectedNote?.noteId,
        noteToUnstarId: props.starredNotes?.[0].id
      }
      props.unstarAndStarNotes?.(newParams)
    }

    setSelectedNote(undefined)
  }

  const handleDeleteDialogClosed = (isConfirmed): void => {
    setDeleteDialogOpen(false)
    if (isConfirmed) props.deleteNote?.(selectedNote as INoteSnapshot)
    setSelectedNote(undefined)
  }

  const handleStarClick = (noteData: INote): void => {
    if (noteData) {
      const params = {
        memberId: props.memberObj?.user.id,
        noteId: noteData.id ?? noteData.note_id
      }
      if (props.starredNotes?.length > 0 && params.noteId !== props.starredNotes?.[0].id) {
        setDialogMessage('There can only be 1 pinnned note for this member. Replace the currently pinned note?')
        setDialogTitle('Replace Note')
        setSelectedNote(params)
        setReplaceDialogOpen(true)
      } else {
        noteData.starred === 1 ? props.unstarNote?.(params) : props.starNote?.(params)
      }
    }
  }

  const handleTrashClick = (noteData): void => {
    const params = {
      memberId: props.memberObj?.user.id,
      noteId: noteData.id || noteData.note_id
    }

    if (noteData) {
      setDialogMessage('Do you want to delete this note? Deleted notes will be permanently removed.')
      setDialogTitle('Delete Note')
      setSelectedNote(params)
      setDeleteDialogOpen(true)
    }
  }

  const handleNoteClick = (noteData): void => {
    if (noteData?.alert) props.storeActions.setSelectedAlertObject(noteData.alert)
  }

  const renderAlertPreview = (alert: IAlert): JSX.Element => {
    const alertContent = alert?.provider_content

    if (!alertContent) return <></>

    const alertTimestamp = moment(alert.timestamp)
    const measurementTimestamp = moment(alert.measurement_timestamp)
    const isLateMeasurement = !alertTimestamp.isSame(measurementTimestamp, 'day') &&
        !alert.type.endsWith('_tracking') &&
        alertTimestamp.diff(measurementTimestamp, 'hours') > 1
    const isOpen = isAlertOpen(alert)

    return (
      <Card>
        <CardContent>
          <Grid container columnSpacing={3} sx={{ alignItems: 'center' }}>
            <Grid item xs={1}>
              {isOpen && <Tooltip title='Alert Status: Open'><NotificationsActiveIcon color='primary' /></Tooltip>}
              {!isOpen && <Tooltip title='Alert Status: Closed'><NotificationsIcon color='secondary' /></Tooltip>}
            </Grid>
            <Grid item xs={11}>
              <Typography variant='body2'>{alertContent}</Typography>
              {isLateMeasurement && <Typography variant='caption' component='p'>Data from {measurementTimestamp.calendar(null, momentCalendarConfig)} arrived late</Typography>}
              <Typography variant='caption'>{alertTimestamp.calendar(null, momentCalendarConfig)}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const renderActions = (noteData: INote): JSX.Element => {
    const isStarred = noteData?.starred > 0
    const starClass = isStarred ? 'profile-note__star--starred' : 'profile-note__star--unstarred'

    return (
      <Box sx={{ alignItems: 'center', marginTop: '.22rem', display: showActions || props.callout ? 'inherit' : 'none' }}>
        <Box className='profile-note__star'>
          <IconButton color='primary' data-testid='profile-note__star-button' onClick={() => { handleStarClick(noteData) }}>
            {isStarred && <PushPinIcon className={starClass} />}
            {!isStarred && <PushPinOutlinedIcon className={starClass} />}
          </IconButton>
        </Box>
        <Box className='profile-note__trash'>
          <IconButton data-testid='profile-note__trash-button' onClick={() => { handleTrashClick(noteData) }}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    )
  }

  const renderNoteInfo = (noteData: INote): JSX.Element => {
    const noteContainerClass = noteData.alert ? 6 : 12
    const className = noteData.alert ? 'clickable' : ''
    const noteProviderContent = cleanNoteContent(noteData)

    let author = noteData.author
    if (noteData.person && !author) {
      author = `${noteData.person.first_name as string} ${noteData.person.last_name as string}`
    } else if (!author) author = 'Ejenta Agent'

    return (
      <div className={className} onClick={() => { handleNoteClick(noteData) }}>
        <Grid container>
          <Grid item xs={noteContainerClass}>
            <Typography variant='body1'>{noteProviderContent}</Typography>
            <Typography variant='body2' fontWeight={400} mt={1}>by {author}</Typography>
            <Typography variant='caption'>{moment(noteData.timestamp).calendar(null, momentCalendarConfig)}</Typography>
          </Grid>
          <Grid item xs={12 - noteContainerClass}>
            {renderAlertPreview(noteData.alert as IAlert)}
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderNoteRow = (): JSX.Element => {
    const noteData = props.noteData
    const columnSize = props.hideActions ? 12 : 11
    const className = props.callout ? 'profile-note-callout' : ''
    const testId = props.callout ? 'profile-note-callout' : 'profile-note'
    return (
      <>
        <AlertDialog isOpen={replaceDialogOpen} title={dialogTitle ?? ''} message={dialogMessage ?? ''} handleDialogClose={handleReplaceDialogClosed} />
        <AlertDialog isOpen={deleteDialogOpen} title={dialogTitle ?? ''} message={dialogMessage ?? ''} handleDialogClose={handleDeleteDialogClosed} />
        <Card
          sx={{ width: '100%', mt: 1, mb: 1, backgroundColor: '#fafafa', overflowY: 'auto', minHeight: '6.5rem' }}
          onMouseEnter={() => { setShowActions(true) }}
          onMouseLeave={() => { setShowActions(false) }}
        >
          <CardContent sx={{ padding: '.5rem !important' }}>
            <Grid container data-testid={testId} className={className}>
              <Grid item xs={12 - columnSize}> {!props.hideActions && renderActions(noteData)}</Grid>
              <Grid item xs={columnSize}>{renderNoteInfo(noteData)}</Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    )
  }

  return renderNoteRow()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  const memberObj = state.userSession.user && state.membersCache.entities[state.profileView.memberId ?? '']
  return {
    clinicianId: state.userSession.user?.id,
    memberObj,
    starredNotes: memberObj?.user?.starred_notes
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    storeActions: bindActionCreators(profileViewSlice.actions, dispatch),

    starNote: ({ memberId, noteId }) => {
      dispatch(restApi.actions.starNote({ id: memberId, noteId }))
    },

    unstarNote: ({ memberId, noteId }) => {
      dispatch(restApi.actions.unstarNote({ id: memberId, noteId }))
    },

    unstarAndStarNotes: ({ memberId, noteToStarId, noteToUnstarId }) => {
      dispatch(restApi.actions.unstarAndStarNotes({
        id: memberId,
        noteToStarId,
        noteToUnstarId
      }))
    },

    deleteNote: ({ memberId, noteId }: INoteSnapshot) => {
      dispatch(restApi.actions.deleteNote({ id: memberId, noteId }))
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(NoteRow)
