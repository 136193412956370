import React, { useState, useEffect } from 'react'
import Chart from '../Chart'
import LoadingIndicator from '../LoadingIndicator'
import MetricsSidebar from '../MetricsSidebar'
import { Button, Box, Typography } from '@mui/material'
import { type IChartOptionsParams, type IChartType } from '../../../interfaces/chart.interface'
import { type IDashboardStore } from '../../../interfaces/store/dashboardStore.interface'
import { type Options } from 'highcharts'

interface IChartModuleProps extends IChartOptionsParams {
  metricType: IChartType
  dashboardViewStore: IDashboardStore
  hideHeader?: boolean
  hideBorder?: boolean
  showChartActions?: boolean
  getChartOptions: (params: IChartOptionsParams) => Options
  showChartOptions?: boolean
}

function ChartModule (props: IChartModuleProps): JSX.Element {
  const [headerText, setHeaderText] = useState('')
  const [containerId, setContainerId] = useState(`${props.metricType}Chart`)
  const [friendlyMetricType, setFriendlyMetricType] = useState(props.metricType as string)
  const [printClicked, setPrintClicked] = useState(false)

  useEffect(() => {
    switch (props.metricType) {
      case 'blood_pressure':
        setContainerId('bloodPressureChart')
        setFriendlyMetricType('blood pressure')
        break
      case 'pulse_ox':
        setContainerId('pulseOxChart')
        setFriendlyMetricType('SpO₂')
        break
      case 'temperature':
        setHeaderText('Skin Temperature')
        setContainerId('temperatureChart')
        setFriendlyMetricType('skin temperature')
        break
      case 'heart_rate':
        setHeaderText('Resting Heart Rate')
        setContainerId('heartRateChart')
        setFriendlyMetricType('resting heart rate')
        break
      case 'respiratory_rate':
        setHeaderText('Resting Respiratory Rate')
        setContainerId('respiratoryRateChart')
        setFriendlyMetricType('resting respiratory rate')
        break
      case 'blood_glucose':
        setContainerId('bloodGlucoseChart')
        setFriendlyMetricType('blood glucose')
        break
      case 'weight':
      case 'activity':
      case 'food':
      case 'sleep':
        break
      default:
        setHeaderText(props.metricType.charAt(0).toUpperCase() + props.metricType.slice(1))
    }
  }, [])

  const renderLoadingIndicator = (): JSX.Element => {
    const className = headerText ? 'metrics-section' : ''
    return (
      <section data-testid='metrics-section' className={className}>
        <h3 className='metrics-section__header'>{headerText}</h3>
        <div className='metrics-section__inner'> <LoadingIndicator /> </div>
      </section>
    )
  }

  const renderEmptyChart = (): JSX.Element => {
    return (
      <Box data-testid='metrics-section'>
        {(!props.hideHeader && !props.hideBorder) &&
          <section className='metrics-section metrics-section--empty'>
            <h3 className='metrics-section__header'>{headerText}</h3>
            <Typography>No <span dangerouslySetInnerHTML={{ __html: friendlyMetricType }} /> data for this period</Typography>
          </section>}
        {(props.hideHeader === true || props.hideBorder === true) &&
          <Typography variant='body1' color='#7f7f7f' marginTop={1.5}>No {friendlyMetricType} data for this period</Typography>}
      </Box>
    )
  }

  const renderDataChart = (): JSX.Element => {
    const sectionClass = !props.hideBorder ? 'metrics-section' : ''

    return (
      <section data-testid='metrics-section' className={sectionClass}>
        {!props.hideHeader && <h3 className='metrics-section__header'>{headerText}</h3>}
        <div className='metrics-section__inner'>
          <div className='metrics-chart'>
            <Chart
              getOptions={props.getChartOptions}
              containerId={containerId}
              memberObj={props.memberObj}
              printed={printClicked}
              dashboardViewStore={props.dashboardViewStore}
              showMemberAlerts={props.showMemberAlerts}
              handlePrintCompleted={() => { setPrintClicked(false) }}
              handleAlertClick={props.handleAlertClick}
              handleDeleteWeight={props.handleDeleteWeight}
              handleRestoreWeight={props.handleRestoreWeight}
            />
          </div>
          <Box sx={{ padding: 0, display: 'column' }}>
            {props.showChartActions &&
              <Box sx={{ justifyContent: 'end', mb: 2, display: 'flex', width: '100%' }} className='no-print'>
                <Button variant='outlined' color='secondary' size='small' onClick={() => { setPrintClicked(true) }}>Print</Button>
              </Box>}
            <MetricsSidebar sidebarType={props.metricType.toUpperCase()} member={props.memberObj} />
          </Box>
        </div>
      </section>
    )
  }

  const renderChartModule = (): JSX.Element => {
    if (props.dashboardViewStore.isRangeLoading) return renderLoadingIndicator()

    const dataSection = props.memberObj[props.metricType]
    const deletedDataSection = props.memberObj[`deleted_${props.metricType}`]
    if (!dataSection?.data?.length && !deletedDataSection?.data?.length) return renderEmptyChart()

    return renderDataChart()
  }

  return renderChartModule()
}

export default ChartModule
