import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Link, Step, StepLabel, Stepper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { type IUser } from '../../../../interfaces/user.interface'
import OnboardAccountInfo from './OnboardAccountInfo'
import OnboardingMessagePrefs from './OnboardMessagePrefs'
import { useNavigate } from 'react-router'
import { allAccessRoutes } from '../../../../routes/browserRouter'

interface IClinicianOnboardingProps {
  user: IUser | null
  apiActions: any
  updateUser: any
}

export default function ClinicianOnboarding (props: IClinicianOnboardingProps): JSX.Element {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [isTosChecked, setIsTosChecked] = useState(false)
  const [onboardingUser, setOnboardingUser] = useState(props.user ?? {})
  const isFinalStep = (): boolean => activeStep === onboardingSteps.length - 1

  const handleSubmitSuccess = (data): void => {
    props.updateUser(data)
    navigate(allAccessRoutes.singleSignOn)
  }

  const submitForm = async (apiForm): Promise<any> => {
    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.updateAccount(
        { id: props.user?.id, promise: { resolve, reject } },
        { body: JSON.stringify(apiForm) }
      )
    })

    return await submitPromise.then(handleSubmitSuccess).catch(data => data)
  }

  const handleToggleTosChecked = (): void => {
    setIsTosChecked(!isTosChecked)
  }

  const handleNext = (): void => {
    if (!isFinalStep()) setActiveStep((prevActiveStep) => prevActiveStep + 1)
    else {
      // submit the form to the backend and redirect to the member's page in context, if it exists. Else, redirect to Alerts/Group Selector page
      const apiForm = {
        first_name: onboardingUser.first_name,
        last_name: onboardingUser.last_name,
        email: onboardingUser.email,
        phone: onboardingUser.phone,
        timezone: onboardingUser.timezone,
        communication: onboardingUser.communication,
        signed_tos: 1
      }

      submitForm(apiForm).then().catch(error => { console.error('Failed to update new clinician', error) })
    }
  }

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const onChange = (formFields: Record<string, string | number>): void => {
    setOnboardingUser((prevOnboardingUser: IUser) => ({ ...prevOnboardingUser, ...formFields }))
  }

  const onboardingSteps = [
    {
      label: 'Verify account information',
      content: (<OnboardAccountInfo onChange={onChange} onboardingUser={onboardingUser} />)
    },
    {
      label: 'Set message preferences',
      content: (<OnboardingMessagePrefs onChange={onChange} onboardingUser={onboardingUser} />)
    },
    {
      label: 'Complete signup',
      content: (
        <FormControlLabel
          sx={{ '& .MuiTypography-root': { fontSize: 14, fontWeight: 'light' } }}
          control={<Checkbox size='small' onClick={handleToggleTosChecked} checked={isTosChecked} />}
          label={
            <Typography variant='caption'>
              <span>I agree to Ejenta's </span>
              <Link href='//ejenta.com/terms.pdf' underline='none' target='_blank' data-testid='login-form__terms-of-service' rel='noreferrer'>Terms of Service</Link>
              <span> and </span>
              <Link href='//ejenta.com/privacy.pdf' underline='none' target='_blank' data-testid='login-form__privacy-policy' rel='noreferrer'>Privacy Policy</Link>.
            </Typography>
          }
        />
      )
    }
  ]

  const renderOnboardingSteps = (): JSX.Element => {
    return (
      <Card sx={{ width: '70%' }}>
        <CardContent>
          <Typography variant='h6' mb={1} textAlign='center'>Welcome to Ejenta!</Typography>

          <Stepper activeStep={activeStep}>
            {onboardingSteps.map((stepData, index) => {
              return (
                <Step key={stepData.label}>
                  <StepLabel>{stepData.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>

          <Box sx={{ m: 3 }}>
            {onboardingSteps[activeStep].content}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}> Back </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} disabled={isFinalStep() && !isTosChecked}>
                {isFinalStep() ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>

        </CardContent>
      </Card>
    )
  }

  return renderOnboardingSteps()
}
