
import React from 'react'
import moment from 'moment'
import { momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import { getSurveyResponseTooltipContent } from '../../../utils/member/analyticsUtils'

import EmptyCell from './EmptyCell'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type ISurveyData } from '../../../interfaces/survey-response.interface'

export default function SurveyResponseCell (props: { memberObj: IMemberSummary }): JSX.Element {
  if (!props.memberObj.survey_responses?.latest_measurement) {
    return <EmptyCell message='No scores' />
  }

  const renderTooltipContent = (): JSX.Element => {
    const latestSurveyData = props.memberObj.survey_responses?.latest_measurement as ISurveyData
    const splitData = getSurveyResponseTooltipContent(latestSurveyData)?.split('<br/>')?.filter(i => i)
    if (!splitData?.length) return <></>

    return (<> {splitData.map((item, id) => <Typography key={id}>{item}</Typography>)}</>)
  }

  const lastSurveyResponse = moment(props.memberObj.survey_responses.latest_measurement.date)
  const latestSrTooltipId = `tooltip-latest_score-${props.memberObj.user.id as string}`

  const latestCol = (
    <Box sx={{ textAlign: 'center' }}>
      <Tooltip data-testid={latestSrTooltipId} id={latestSrTooltipId} title={renderTooltipContent()}>
        <div>{`${props.memberObj.survey_responses.latest_measurement.total_score}`}</div>
      </Tooltip>
      <div className='member-row__latest-timestamp'> {lastSurveyResponse.calendar(null, momentCalendarDateConfig)} </div>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}> {latestCol} </Grid>
    </Grid>
  )
}
