import _ from 'lodash'
import { type IMemberSummary } from '../interfaces/member-summary.interface'

export const getSleepAverage = (member: IMemberSummary): number | null => {
  if (!member) return null

  return _.meanBy(member.sleep?.data, d => d.duration.value)
}

export const getCaloriesAverage = (member: IMemberSummary): number | null => {
  if (!member) return null

  const thisPeriod = member.food?.information.periods[0]
  if (thisPeriod?.avg_calories) return thisPeriod.avg_calories
  return null
}
