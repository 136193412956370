import React from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { drawerWidth } from './ContentSidebar'
import { type ConnectedProps, connect } from 'react-redux'
import { type IDefaultProps } from '../../interfaces/ejenta.interface'
import { type RootState } from '../..'
/*
  HANDY PAGE LAYOUT REFERENCE
   ____________________________________________________
  |                                                    |
  | <Header />                                         |
  |____________________________________________________|  _
  |                    |                               |   |
  | <ContentSidebar>   | <ContentMainIntro>            |   |
  |     <Footer />     |     <ContentMainHeader />     |   |
  | </ContentSidebar>  | </ContentMainIntro>           |   |
  |                    |_______________________________|   |
  |                    |                               |   | <ContentMain />
  |                    | <ContentMainBody />           |   |
  |                    |                               |   |
  |                    |                               |   |
  |                    |                               |   |
  |                    |                               |   |
  |____________________|_______________________________|  _|
*/

function Content (props: IDefaultProps): JSX.Element {
  return (<Box className='content content--desktop'> {props.children} </Box>)
}

interface IBaseContentProps extends IDefaultProps, PropsFromRedux {
  drawerWidth?: number
  dropsSidebar?: boolean
}

function BaseContentMain (props: IBaseContentProps): JSX.Element {
  let sidebarWidth = props.drawerWidth ?? drawerWidth
  if (!props.sidebarStore?.sidebarOpen) sidebarWidth = props.sidebarStore?.buttonOnlyWidth ?? 10
  return (
    <Box
      component='div'
      sx={{
        flexGrow: 1,
        marginLeft: props.dropsSidebar ? 0 : `${sidebarWidth}px`
      }}
    >
      {!props.epicStore?.isEmbedded && <Toolbar sx={{ mb: 1 }} disableGutters /> }
      <Box>
        <Box sx={{ pl: 1, pr: 1 }}>
          {props.children}
        </Box>
      </Box>
    </Box>
  )
}

function ContentMainIntroHeader (props: IDefaultProps): JSX.Element {
  return (
    <Box className='content-main__header'>
      <Box component='h2' className='content-main__header__text'>{props.title}</Box>
      <Box component='div' className='content-main__header__children'>{props.children}</Box>
    </Box>
  )
}

interface IContentIntroProps extends IDefaultProps, PropsFromRedux {}
function BaseContentMainIntro (props: IContentIntroProps): JSX.Element {
  return (
    <Box
      component='div' className='content-main__intro' sx={{
        position: 'sticky',
        top: props.epicStore?.isEmbedded ? 0 : '76px',
        zIndex: 1
      }}
    >
      {props.children}
    </Box>
  )
}

function ContentMainBody (props: IDefaultProps): JSX.Element {
  return (<Box data-testid='content-main__body'> {props.children} </Box>)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    sidebarStore: state.sidebar,
    epicStore: state.epic
  }
}
const connector = connect(mapState)
type PropsFromRedux = ConnectedProps<typeof connector>
const ContentMain = connector(BaseContentMain)
const ContentMainIntro = connector(BaseContentMainIntro)

export {
  Content,
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
}
