import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { getSimpleDisplayName } from '../../../../utils/baseStringUtils'
import { useNavigate } from 'react-router'
import { type IAnalyticsUser } from '../../../../interfaces/store/analyticsStore.interface'
import { type Options } from 'highcharts'

export default function getWeightFluctuationChartOptions (users: IAnalyticsUser []): Options {
  const usersWithData = _.filter(users, u => !!u.overall_stats.latest_weight_lbs)
  const navigate = useNavigate()

  const chartOptions: Options = {
    legend: {
      enabled: false
    },

    xAxis: {
      title: {
        text: 'Weight (lbs)'
      },
      minPadding: 0.2,
      maxPadding: 0.2
    },

    yAxis: {
      title: {
        text: 'Median weight fluctuation (lbs)'
      }
    },

    series: [{
      name: 'Weight Fluctuation',
      className: 'series-0',
      type: 'scatter',
      marker: {
        radius: 5
      },

      data: _.map(usersWithData, u => ({
        x: Number(u.overall_stats.latest_weight_lbs),
        y: Number(u.overall_stats.median_weight_fluctuation_lbs),
        name: getSimpleDisplayName(u.info),

        events: {
          click: () => {
            navigate(`/profile/${u.id}`)
          }
        }
      }))
    }],

    tooltip: {
      headerFormat: '',
      useHTML: true,
      borderColor: 'none',
      pointFormatter: function format () {
        const point = this as any
        return `
          <span class="ax-text-bold">${point.name as string}</span><br/>
          Weight: <em>${point.x as number} lb</em><br/>
          Median weight fluctuation: <em>${point.y as number} lb</em><br/>
        `
      }
    }
  }

  return _.merge(_.cloneDeep(baseChartOptions), chartOptions)
}
