import { type Options } from 'highcharts'
import _ from 'lodash'
import { type IChartOptionsParams } from '../../interfaces/chart.interface'
import {
  getBaseChartOptions,
  addPausePlotBands,
  getSimpleContinuousSeries,
  getSimpleBioStickerAlertsSeries,
  getEmptyChart
} from './baseChartUtils'
import {
  getDefaultChartOptions,
  getXAxisForRange
} from './chartUtils'

export default function getRespiratoryRateChartOptions ({ memberObj, dashboardViewStore, showMemberAlerts, handleAlertClick }: IChartOptionsParams): Options {
  if (!memberObj.respiratory_rate) return getEmptyChart()

  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, false) }

  let options: Options = _.merge(
    getBaseChartOptions(),
    getDefaultChartOptions('respiratory_rate-chart'),
    xAxisOptions
  )

  options = getSimpleContinuousSeries(options, memberObj, 'respiratory_rate', 'Resting respiratory rate',
    ` ${memberObj.respiratory_rate?.information.units.respiratory_rate as string}`)

  if (showMemberAlerts && !!handleAlertClick) {
    options = getSimpleBioStickerAlertsSeries(memberObj, options, handleAlertClick, 'respiratory_rate', 'Respiratory rate', 'rr_range')
  }

  options = addPausePlotBands(memberObj.user.pauses ?? [], options, true)

  return options
}
