import React from 'react'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import MemberDevicesFormFields from './MemberDevicesFormFields'
import { Form } from 'react-final-form'
import { Alert, Grid } from '@mui/material'
import { EjentaForm } from '../../elements/EjentaForm'
import { type IFormValues } from '../../../interfaces/forms.interface'
import { productConfig } from '../../../config/config-service'

interface IMemberDevicesFormProps {
  onCancel: () => void
  onSubmit: (formData: Record<string, string>) => Promise<any>
  getInitialValues: () => IFormValues
}

export default function MemberDevicesForm (props: IMemberDevicesFormProps): JSX.Element {
  const deviceConfig = productConfig().profile.devices
  const handleCancel = (e: any): void => {
    e.preventDefault()
    e.stopPropagation()
    props.onCancel()
  }

  const renderSubmit = ({ pristine, submitting }): JSX.Element => {
    const submitButtonText = submitting ? 'Updating...' : 'Save changes'

    return (
      <DialogActions>
        <Button variant='contained' color='primary' type='submit' disabled={pristine || submitting}>{submitButtonText}</Button>
        <Button variant='outlined' color='secondary' onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    )
  }

  const renderMemberDevicesForm = (): JSX.Element => {
    const hasDevices = deviceConfig?.hasBodyTraceBP || deviceConfig?.hasBodyTraceScale || deviceConfig?.hasIGlucose || deviceConfig?.hasBiosticker

    return (
      <Form onSubmit={props.onSubmit} initialValues={props.getInitialValues()}>
        {({ handleSubmit, pristine, submitting, submitError }) => (
          <EjentaForm onSubmit={handleSubmit}>
            {submitError && <Alert severity='error'>{submitError}</Alert>}
            <Grid container spacing={1} sx={{ p: 1 }}>
              <MemberDevicesFormFields />
            </Grid>

            {hasDevices && renderSubmit({ pristine, submitting })}
          </EjentaForm>
        )}
      </Form>
    )
  }

  return renderMemberDevicesForm()
}
