
import React from 'react'
import moment from 'moment'
import { momentCalendarConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

export default function HeartRateCell (props: { memberObj: IMemberSummary }): JSX.Element {
  if (!props.memberObj.user.has_heart_rate || !props.memberObj.heart_rate) {
    return <EmptyCell message='No heart rate data' />
  }

  const lastMeasurement = moment(props.memberObj.heart_rate.latest_measurement.timestamp)
  const latestCol = (
    <Box>
      <div className='member-row__latest'> {`${Math.round(props.memberObj.heart_rate.latest_measurement.heart_rate * 10) / 10} ${props.memberObj.heart_rate.information.units.heart_rate as string}`} </div>
      <div className='member-row__latest-timestamp'> {lastMeasurement.calendar(null, momentCalendarConfig)} </div>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}> {latestCol} </Grid>
    </Grid>
  )
}
