import React from 'react'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { Box } from '@mui/material'

export default
class HeartRateRangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'hr_range'
  }

  rangeHeartRateMinInputElement (): JSX.Element {
    return this.getInputElement(
      30, 60, 5,
      CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.set(val => val)
    )
  }

  rangeHeartRateMaxInputElement (): JSX.Element {
    return this.getInputElement(
      65, 150, 5,
      CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.set(val => val)
    )
  }

  renderGoal (): JSX.Element {
    return <div>Keep resting heart rate in range</div>
  }

  renderClinicianTrigger (): JSX.Element {
    return <div>If <b>resting heart rate</b> is below {this.rangeHeartRateMinInputElement()} bpm or above {this.rangeHeartRateMaxInputElement()} bpm.</div>
  }

  renderCondensed (): JSX.Element {
    const className: string = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    return (
      <Box className={className}>Heart rate &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.get(val => val))} or
        &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.get(val => val))} bpm
      </Box>
    )
  }
}
