import { type Options } from 'highcharts'
import _ from 'lodash'
import { type IAnalyticsUser } from '../../../../interfaces/store/analyticsStore.interface'
import { DAYS_OF_WEEK } from '../../../../constants/constants'
import baseChartOptions from './baseChartOptions'

const getPercentile = (nums: number [], percentile: number): number => {
  const sortedNums = _.clone(nums).sort((a, b) => a - b)
  const index = (percentile / 100) * sortedNums.length

  let result = sortedNums[Math.floor(index)]
  if (Math.floor(index) === index) result = (sortedNums[(index - 1)] + sortedNums[index]) / 2
  return result
}

const getMedian = (nums: number []): number => {
  if (!nums.length) return 0

  const sortedNums = _.clone(nums).sort((a, b) => a - b)
  const middle = Math.floor(sortedNums.length / 2)

  let median = sortedNums[middle]
  if (sortedNums.length % 2 === 0) median = (sortedNums[middle] + sortedNums[middle - 1]) / 2
  return median
}

export default function getActivityChartOptions (users: IAnalyticsUser []): Options {
  const dayActivityData = _.map(DAYS_OF_WEEK, (d) => {
    const stepsData = _.filter(_.map(users, u =>
      u.activity.avg_daily_steps[d.toLowerCase()]
    ), dData => !!dData)

    return [
      Math.min(...stepsData),
      getPercentile(stepsData, 25),
      getMedian(stepsData),
      getPercentile(stepsData, 75),
      Math.max(...stepsData)
    ]
  })

  const chartOptions: Options = {
    plotOptions: {
      boxplot: {
        medianWidth: 1,
        whiskerLength: '40%'
      }
    },

    legend: {
      enabled: false
    },

    xAxis: {
      categories: DAYS_OF_WEEK,
      labels: {
        formatter: function format () {
          return (this as any).value.slice(0, 2)
        }
      },
      tickLength: 0
    },

    yAxis: {
      title: {
        text: 'Average # of steps'
      },
      min: 0
    },

    series: [{
      data: dayActivityData,
      type: 'boxplot'
    }],
    tooltip: {
      borderColor: 'none',
      headerFormat: '',
      useHTML: true,
      pointFormatter: function format () {
        const point = this as any
        return `
            <span class="ax-text-bold">${point?.category as string}</span><br/>
            Median: <em>${Math.round(point.median).toLocaleString()} steps</em>
          `
      }
    }
  }

  return _.merge(_.cloneDeep(baseChartOptions), chartOptions)
}
