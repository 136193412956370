import React from 'react'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
export default class WeeklyActivityTargetItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'activity_target'
    this.subtype = 'weekly'
  }

  getValueInput (): JSX.Element {
    return this.getInputElement(
      10, 300, 5,
      CAREPLAN_VALUE_TYPES.VALUE.get(),
      CAREPLAN_VALUE_TYPES.VALUE.set()
    )
  }

  getThresholdInput (): JSX.Element {
    return this.getInputElement(
      5, 100, 5,
      CAREPLAN_VALUE_TYPES.THRESHOLD.get(val => val as number + 100),
      CAREPLAN_VALUE_TYPES.THRESHOLD.set(val => val as number - 100)
    )
  }

  renderGoal (): JSX.Element {
    return (
      <div>
        Moderate activity weekly for {this.getValueInput()} minutes
      </div>
    )
  }

  renderCondensed (): JSX.Element {
    return <></>
  }
}
