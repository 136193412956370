import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import ProfileStatusPane from './ProfileStatusPane'
import PregnancyInformationPane from './PregnancyInformationPane'
import ProfileInformationPane from './ProfileInformationPane'
import CustomMemberProfilePane from './CustomMemberProfilePane'
import TabPanel from '../../layout/TabPanel'
import { type ConnectedProps, connect } from 'react-redux'
import { getSimpleDisplayName } from '../../../utils/baseStringUtils'
import { isMemberPaused } from '../../../utils/member/baseProfileUtils'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import { productConfig } from '../../../config/config-service'
import Modal from '../../elements/Modal'
import { a11yProps } from '../../../constants/constants'
import { type IProfileStore } from '../../../interfaces/store/profileStore.interface'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { mapProfileDispatch, mapUserState } from '../../../constants/reduxMappers'
import CarePlanHeader from '../../elements/CarePlan/CarePlanHeader'
import CarePlanRows from '../../elements/CarePlan/CarePlanRows'
import DeviceStatusRows from '../../elements/Devices/DeviceStatusRows'
import DeviceInformationPane from './DeviceInformationPane'
import LinkedAccountsPane from './LinkedAccountsPane'
import { type IOrderStatuses } from '../../../interfaces/order-status.interface'

interface IEditMemberProfileProps extends PropsFromRedux {
  profileViewStore: IProfileStore
  memberObj: IMemberSummary
  apiActions: any
  profileViewActions: any
}

interface ITabIndex {
  profile: number
  careplan: number
  devices: number
  status: number | null
  pregnancy: number | null
}

function EditMemberProfileModal (props: IEditMemberProfileProps): JSX.Element | null {
  const defaultTabIndexes = { profile: 0, status: 1, pregnancy: 2, careplan: 3, devices: 4 }
  const [isOpen, setIsOpen] = useState(true)
  const [value, setValue] = React.useState(0)
  const [tabIndexes, setTabIndexes] = useState<ITabIndex>(defaultTabIndexes)
  const config = productConfig()
  const pagePrefix = 'edit-member-profile__tab'
  const devicesPanelHeight = Object.keys(props.memberObj?.user?.order_statuses as IOrderStatuses)?.length ? '25rem' : config.tabPanel.devicesTab

  useEffect(() => {
    setValue(isOpen ? tabIndexes[props.profileViewStore.activeModalTab] : 0)
  }, [props.profileViewStore.activeModalTab])

  useEffect(() => {
    let tabDifference = 0
    let status: number | null = 1
    let pregnancy: number | null = 2

    if (props.memberObj.user.archived_since) {
      setValue(0)
      tabDifference += 1
      status = null
    }

    if (!config.profile.showPregnancyInfo) {
      tabDifference += 1
      pregnancy = null
    }

    setTabIndexes({
      profile: 0,
      status,
      pregnancy: pregnancy ? pregnancy - tabDifference : null,
      careplan: defaultTabIndexes.careplan - tabDifference,
      devices: defaultTabIndexes.devices - tabDifference
    })
  }, [props.memberObj.user])

  const handleChange = (_event: any, newValue: number): void => {
    setValue(newValue)
  }

  const closeModal = (): void => {
    setIsOpen(false)
    props.profileViewActions.handleClearModal()
  }

  const memberHasOrderStatuses = (): boolean => {
    const orderStatuses = props.memberObj?.user?.order_statuses
    return !!orderStatuses && Object.keys(orderStatuses).length > 0
  }

  const renderModal = (): JSX.Element => {
    const user = props.memberObj.user
    const memberName = getSimpleDisplayName(user)

    const modalTitle = (
      <span>
        Member details for {memberName}
        {isMemberPaused(user.pauses) && <span className='profile__archived-text'>SUSPENDED</span>}
        {user.archived_since && <span className='profile__archived-text'>ARCHIVED</span>}
      </span>
    )

    const contentClass = classnames({
      'profile--archived': !!user.archived_since
    })

    return (
      <Modal
        name='editMemberProfile'
        maxWidth='lg'
        targetUser={user}
        isOpen={isOpen}
        onModalClose={closeModal}
        modalTitle={modalTitle}
      >
        <Box sx={{ width: '100%', height: '100%' }} className={contentClass}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label='Edit profile tabs'>
              <Tab label='Profile' {...a11yProps(pagePrefix, tabIndexes.profile)} />
              {tabIndexes.status && <Tab label='Status' {...a11yProps(pagePrefix, tabIndexes.status)} />}
              {tabIndexes.pregnancy && <Tab label='Pregnancy' {...a11yProps(pagePrefix, tabIndexes.pregnancy)} />}
              <Tab label='Careplan' {...a11yProps(pagePrefix, tabIndexes.careplan)} />
              <Tab label='Devices' {...a11yProps(pagePrefix, tabIndexes.devices)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={tabIndexes.profile} panelHeight={config.tabPanel.editMember} namePrefix={pagePrefix} isModal>

            <ProfileInformationPane
              careManagers={props.resources?.care_managers ?? []}
              allowedGroups={props.user?.allowedGroups}
              memberObj={props.memberObj}
              apiActions={props.apiActions}
              profileViewActions={props.profileViewActions}
            />
          </TabPanel>

          {tabIndexes.status &&
            <TabPanel value={value} index={tabIndexes.status} panelHeight={config.tabPanel.statusTab} namePrefix={pagePrefix} isModal>
              <ProfileStatusPane
                memberObj={props.memberObj}
                onMemberArchived={closeModal}
              />
            </TabPanel>}

          {tabIndexes.pregnancy &&
            <TabPanel value={value} index={tabIndexes.pregnancy} panelHeight={config.tabPanel.statusTab} namePrefix={pagePrefix} isModal sx={{ height: '100%' }}>
              <CustomMemberProfilePane
                hideTitle
                hideBorder
                memberObj={props.memberObj}
              />

              <Divider variant='middle' sx={{ m: 3 }} />

              <PregnancyInformationPane
                memberObj={props.memberObj}
                apiActions={props.apiActions}
                profileViewActions={props.profileViewActions}
              />
            </TabPanel>}

          <TabPanel value={value} index={tabIndexes.careplan} panelHeight={config.tabPanel.careplanTab} namePrefix={pagePrefix} isModal>
            <CarePlanHeader />
            <CarePlanRows
              carePlanItems={props.memberObj.careplan ?? []}
              updateCarePlan={props.updateMemberCarePlan}
            />
          </TabPanel>

          <TabPanel value={value} index={tabIndexes.devices} panelHeight={devicesPanelHeight} namePrefix={pagePrefix} isModal>
            {memberHasOrderStatuses() && <DeviceStatusRows memberObj={props.memberObj} fields={[]} />}
            {config.profile.devices?.hasLinkedAccounts && <LinkedAccountsPane memberObj={props.memberObj} />}
            <DeviceInformationPane
              memberObj={props.memberObj}
              apiActions={props.apiActions}
              profileViewActions={props.profileViewActions}
            />
          </TabPanel>

        </Box>
      </Modal>
    )
  }

  const renderEditProfile = (): JSX.Element | null => {
    const isModalVisible = props.profileViewStore.openModal === 'editMemberProfile'
    if (!isModalVisible) return null
    if (isModalVisible && !isOpen) setIsOpen(true)
    return renderModal()
  }

  return renderEditProfile()
}

const connector = connect(mapUserState, mapProfileDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(EditMemberProfileModal)
