/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import LoadingIndicator from '../LoadingIndicator'
import { descendingComparator, getComparator } from '../../../constants/constants'
import {
  IconButton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Collapse
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { type IChartTableRowProps, type IChartTableHeaderProps, type IChartTableProps } from '../../../interfaces/chart-table.interface'
import { type IFood, type IFoodData } from '../../../interfaces/food-summary.interface'
import { mapProfileDispatch } from '../../../constants/reduxMappers'

function FoodHeader (props: IChartTableHeaderProps): JSX.Element {
  const order = props.order

  const createSortHandler = (property) => (event): void => {
    props.onRequestSort(event, property)
  }

  const renderHeader = (): JSX.Element => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align='center' className='odd-col' />
          <TableCell key='date'>
            <TableSortLabel active direction={order} onClick={createSortHandler('date')}>
              Date
            </TableSortLabel>
          </TableCell>

          <TableCell align='center' className='odd-col'>Carbs <Typography variant='caption'>(g)</Typography></TableCell>
          <TableCell align='center'>Fat <Typography variant='caption'>(g)</Typography></TableCell>
          <TableCell align='center' className='odd-col'>Fiber <Typography variant='caption'>(g)</Typography></TableCell>
          <TableCell align='center'>Protein <Typography variant='caption'>(g)</Typography></TableCell>
          <TableCell align='center' className='odd-col'>Sodium <Typography variant='caption'>(mg)</Typography></TableCell>
          <TableCell align='center'>Water <Typography variant='caption'>(ml)</Typography></TableCell>
          <TableCell align='center' className='odd-col'>Calories</TableCell>
        </TableRow>

      </TableHead>
    )
  }

  return renderHeader()
}

interface IFoodRowProps extends IChartTableRowProps {
  data: {
    measurements: IFoodData []
  }
}

function FoodRow (props: IFoodRowProps): JSX.Element {
  const [open, setOpen] = React.useState(false)
  const dateAsMoment = moment(props.date)
  const formattedDate = moment().isSame(dateAsMoment, 'year') ? dateAsMoment.format('MMM DD') : dateAsMoment.format('MMM DD, YYYY')

  // sort the measurements in asc or desc based on date sort
  props.data.measurements.sort((a, b) => props.order === 'desc' ? descendingComparator(a.date, b.date) : -descendingComparator(a.date, b.date))

  const renderFoodCaption = (foods: IFood []): JSX.Element => {
    return (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Foods
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Serving</TableCell>
                    <TableCell align='right'>Meal</TableCell>
                    <TableCell align='right'>Calories</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {foods.map((food: IFood, index) => (
                    <TableRow key={`${food.name}_${index}`}>
                      <TableCell component='th' scope='row'>
                        {food.name}
                      </TableCell>
                      <TableCell>{food.serving_qty} {food.serving_unit}</TableCell>
                      <TableCell align='right'>{food.meal}</TableCell>
                      <TableCell align='right'> {food.calories} </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  const renderRow = (): JSX.Element => {
    return (
      <>
        {props.data?.measurements.map(measurement => {
          return (
            <React.Fragment key={measurement.date}>
              <TableRow sx={{ backgroundColor: theme => `${theme.palette.background.paper}!important` }}>
                <TableCell className='odd-col'>
                  {measurement.foods &&
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => { setOpen(!open) }}
                    >
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>}
                </TableCell>
                <TableCell> <Typography display='flex'> {formattedDate} </Typography> </TableCell>
                <TableCell align='center' className='odd-col'>{measurement.carbohydrates_grams}</TableCell>
                <TableCell align='center'>{measurement.fat_grams}</TableCell>
                <TableCell align='center' className='odd-col'>{measurement.fiber_grams}</TableCell>
                <TableCell align='center'>{measurement.protein_grams}</TableCell>
                <TableCell align='center' className='odd-col'>{measurement.sodium_milligrams}</TableCell>
                <TableCell align='center'>{measurement.water_milliliters}</TableCell>
                <TableCell align='center' className='odd-col'>{measurement.calories}</TableCell>
              </TableRow>
              {measurement.foods && renderFoodCaption(measurement.foods)}
            </React.Fragment>
          )
        })}
      </>
    )
  }

  return renderRow()
}

type IGroupedData = Record<string, {
  measurements: IFoodData []
}>

function FoodTable (props: IChartTableProps): JSX.Element {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const handleRequestSort = (): void => { setOrder(order === 'asc' ? 'desc' : 'asc') }

  const groupByDate = (data: IFoodData []): IGroupedData => {
    const groupedData = {}

    data.forEach((d) => {
      if (!groupedData[d.date]) groupedData[d.date] = { measurements: [] }
      const newItem = {
        ...d
      }
      groupedData[d.date].measurements.unshift(newItem)
    })
    return groupedData
  }

  const renderTable = (): JSX.Element => {
    if (props.dashboardViewStore.isRangeLoading) {
      return (<div className='metrics-section__inner'> <LoadingIndicator /> </div>)
    }

    const dataSection = props.memberObj.food
    if (!dataSection?.data?.length) {
      return (
        <Box>
          <Typography variant='body1' color='#7f7f7f' marginTop={1.5}>No food data for this period</Typography>
        </Box>
      )
    }

    const dataByDate = groupByDate(dataSection.data)
    const sectionClass = !props.hideBorder ? 'metrics-section' : ''
    return (
      <TableContainer className={sectionClass} sx={{ height: 408 }}>
        {!props.hideHeader && <h3 className='metrics-section__header'>Food</h3>}
        <Table className='blood-pressure-table__container' stickyHeader>
          <FoodHeader order={order} onRequestSort={handleRequestSort} />
          <TableBody>
            {Object.keys(dataByDate).slice().sort(getComparator(order)).map((date, i) => {
              return <FoodRow order={order} date={date} data={dataByDate[date]} key={i} actions={props.profileViewActions} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return renderTable()
}

const connector = connect(null, mapProfileDispatch)
export default connector(FoodTable)
