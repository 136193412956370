import React from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import Modal from '../../../elements/Modal'
import { type IAnalyticsSuspension, type IAnalyticsInfo, type IAnalyticsUser } from '../../../../interfaces/store/analyticsStore.interface'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router'
import { mapAnalyticsDispatch, mapAnalyticsState } from '../../../../constants/reduxMappers'
import { SUSPEND_ALERT_OPTIONS } from '../../../../constants/constants'
import { ANALYTICS_DRILLDOWN } from '../../../../store/analytics'

interface IAnalyticsProfileRow extends IAnalyticsInfo {
  id: string
  start?: string
  end?: string
  reason?: string
}

function AnalyticsDrilldownModal (props: PropsFromRedux): JSX.Element {
  const navigate = useNavigate()
  const drilldown = props.analyticsStore.drilldown

  const renderMemberDrilldown = (analyticsUsers: IAnalyticsUser []): JSX.Element | JSX.Element [] => {
    const columns = [
      { field: 'first_name', headerName: 'First Name', minWidth: 120, flex: 1 },
      { field: 'last_name', headerName: 'Last Name', minWidth: 120, flex: 1 },
      { field: 'gender', headerName: 'Sex', width: 80 },
      { field: 'age', headerName: 'Age', width: 50, type: 'number' },
      { field: 'medical_id', headerName: 'Medical ID', minWidth: 150, flex: 1, type: 'number' },
      { field: 'archived', headerName: 'Archived', width: 80, type: 'boolean' }
    ]

    const rows = analyticsUsers.map((member: IAnalyticsUser): IAnalyticsProfileRow => {
      return {
        ...member.info,
        id: member.id
      }
    })

    return (
        <div style={{ height: '600px', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={((event) => {
              navigate(`/profile/${event.id as string}`)
            })}
          />
        </div>
    )
  }

  const renderSuspensionDrilldown = (suspensions: IAnalyticsSuspension [], allUsers: IAnalyticsUser []): JSX.Element | JSX.Element [] => {
    const columns = [
      { field: 'first_name', headerName: 'First Name', minWidth: 60, flex: 1 },
      { field: 'last_name', headerName: 'Last Name', minWidth: 60, flex: 1 },
      { field: 'reason', headerName: 'Reason', minWidth: 120, flex: 1 },
      { field: 'start', headerName: 'Start', minWidth: 100, flex: 1 },
      { field: 'end', headerName: 'End', minWidth: 100, flex: 1 },
      { field: 'medical_id', headerName: 'Medical ID', minWidth: 75, flex: 1, type: 'number' }
    ]

    const rows = suspensions.map((member: IAnalyticsSuspension): IAnalyticsProfileRow | null => {
      const foundUser = allUsers.find(user => user.id === member.user_id)

      if (foundUser) {
        const randomNumString = (length: number): string => Math.random().toString().slice(2, 2 + length)
        return {
          ...foundUser.info,
          id: member.user_id + ',' + randomNumString(7), // can have multiple suspensions for same user
          start: member.start,
          end: member.resume,
          reason: SUSPEND_ALERT_OPTIONS.find(x => x.value === member.reason)?.label as string
        }
      } else return null
    })

    if (!rows) return <></>

    return (
        <div style={{ height: '600px', width: '100%' }}>
          <DataGrid
            rows={rows as IAnalyticsProfileRow []}
            columns={columns}
            onRowClick={((event) => {
              const eventId = event.id as string
              navigate(`/profile/${eventId.split(',')[0]}`)
            })}
          />
        </div>
    )
  }

  const renderDrilldown = (): JSX.Element | JSX.Element [] => {
    const analyticsUsers = drilldown.items
    if (!analyticsUsers.length) return (<div>No members to show.</div>)

    if (drilldown.type === ANALYTICS_DRILLDOWN.MEMBERS) {
      return renderMemberDrilldown(analyticsUsers)
    } if (drilldown.type === ANALYTICS_DRILLDOWN.SUSPENSIONS) {
      return renderSuspensionDrilldown(analyticsUsers, props.analyticsStore.allUsers)
    }

    return <></>
  }

  const renderModalTitle = (): JSX.Element => {
    let drilldownCount = drilldown.items.length

    if (drilldown.type === ANALYTICS_DRILLDOWN.SUSPENSIONS) {
      drilldownCount = drilldown.items.filter((obj, index) => {
        return index === drilldown.items.findIndex(o => obj.user_id === o.user_id)
      }).length
    }

    return (
      <span className='ax-drilldown__title'>
        {drilldown.title}
        <span className='ax-drilldown__title__count'>({drilldownCount} members)</span>
      </span>
    )
  }

  const renderModal = (): JSX.Element => {
    return (
      <Modal
        name='AnalyticsDrilldown'
        isOpen
        modalTitle={renderModalTitle()}
        onModalClose={() => props.analyticsActions.setDrilldown(null)}
      >
        <div className='ax-drilldown__body'>
          {renderDrilldown()}
        </div>
      </Modal>
    )
  }

  const isModalVisible = !!drilldown
  if (!isModalVisible) return <></>
  return renderModal()
}

const connector = connect(mapAnalyticsState, mapAnalyticsDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AnalyticsDrilldownModal)
