import React from 'react'
import Typography from '@mui/material/Typography'
import { drawerWidth } from './ContentSidebar'
import { Box } from '@mui/material'
import logo from 'static/ejenta-logo.png'

interface IHeaderProps {
  isAnalyticsActive?: boolean
  forDesktop?: boolean
}

export default function HeaderLogo (props: IHeaderProps): JSX.Element {
  const renderLogoContent = (): JSX.Element => {
    return (
      <div className='logo'>
        <Box className='logo__base' sx={{ display: 'flex' }}>
          <img src={logo} alt='Ejenta' width="120px" height="23px" /> {props.isAnalyticsActive && <div className='logo__suffix'>Analytics</div>}
        </Box>
      </div>
    )
  }

  const renderDesktopVersion = (): JSX.Element => {
    return (
      <Typography
        variant='h6'
        noWrap
        component='a'
        href='/'
        sx={{
          width: drawerWidth - 24, // account for left padding
          display: { xs: 'none', md: 'flex' },
          fontFamily: 'monospace',
          fontWeight: 700,
          textDecoration: 'none',
          left: 0
        }}
      >
        {renderLogoContent()}
      </Typography>
    )
  }

  const renderMobileVersion = (): JSX.Element => {
    return (
      <Typography
        variant='h5'
        noWrap
        component='a'
        href='/'
        sx={{
          mr: 2,
          display: { xs: 'flex', md: 'none' },
          flexGrow: 1,
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none'
        }}
      >
        {renderLogoContent()}
      </Typography>
    )
  }

  if (!props.forDesktop) {
    return renderMobileVersion()
  }

  return renderDesktopVersion()
}
