import { type Options } from 'highcharts'
import _ from 'lodash'
import { type IAnalyticsUser } from '../../../../interfaces/store/analyticsStore.interface'
import baseChartOptions from './baseChartOptions'

const ADHERENCE_BUCKETS = {
  low: 'Low (under 30%)',
  med: 'Medium (30%–70%)',
  high: 'High (above 70%)'
}

const getAdherenceCounts = (users: IAnalyticsUser [], metric: string): { low: IAnalyticsUser [], med: IAnalyticsUser [], high: IAnalyticsUser [] } => {
  return {
    low: _.filter(users, u => u[metric].adherence < 0.30),
    med: _.filter(users, u => u[metric].adherence >= 0.30 && u[metric].adherence < 0.70),
    high: _.filter(users, u => u[metric].adherence >= 0.70)
  }
}

const getAdherenceChartOptions = (users: IAnalyticsUser [], analyticsActions): Options => {
  const activityAdherenceCounts = getAdherenceCounts(users, 'activity')
  const weightAdherenceCounts = getAdherenceCounts(users, 'weight')

  const chartOptions: Options = {
    chart: {
      marginBottom: 100
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      padding: 10
    },

    xAxis: {
      categories: _.values(ADHERENCE_BUCKETS),
      tickLength: 0
    },

    yAxis: {
      title: {
        text: '# of members'
      },
      labels: { format: '{value}' }
    },

    series: [{
      name: 'Tracker',
      className: 'series-0',
      type: 'column',

      data: _.map(activityAdherenceCounts, (a, key) => ({
        y: a.length,
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: `Tracker adherence - ${ADHERENCE_BUCKETS[key] as string}`,
              type: 'member',
              items: a
            })
          }
        }
      }))

    }, {
      name: 'Scale',
      className: 'series-1',
      type: 'column',
      data: _.map(weightAdherenceCounts, (a, key) => ({
        y: a.length,
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: `Scale adherence - ${ADHERENCE_BUCKETS[key] as string}`,
              type: 'member',
              items: a
            })
          }
        }
      }))
    }],

    tooltip: {
      headerFormat: '',
      useHTML: true,
      borderColor: 'none',
      pointFormatter: function format () {
        const point = this as any
        return `
          <span class="ax-text-bold">
            ${point.series.name as string} adherence - ${point.category as string}
          </span><br/>
          Members: <em>${point.y as string}</em><br/>
        `
      }
    }

  }

  return _.merge(_.cloneDeep(baseChartOptions), chartOptions)
}

export default getAdherenceChartOptions
