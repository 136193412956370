import { productConfig } from '../config/config-service'
declare const INTERACTION_SERVER: string
declare const EJENTA_APP_VERSION: string

let apiHost: string
let webSocketUrl: string
const apiConfig = productConfig().api

switch (INTERACTION_SERVER) {
  case 'staging':
    apiHost = apiConfig.stagingServer.url
    webSocketUrl = apiConfig.stagingServer.webSocketUrl
    break

  case 'production':
    apiHost = apiConfig.productionServer.url
    webSocketUrl = apiConfig.productionServer.webSocketUrl
    break

  case 'local':
  default:
    apiHost = apiConfig.localServer.url
    webSocketUrl = apiConfig.localServer.webSocketUrl
    break
}

export const customApiUrl = `${apiHost}/${apiConfig.urlSuffix}`
export const customWebSocketUrl = webSocketUrl
export const ejentaAppVersion = EJENTA_APP_VERSION
export const customApiHeaders = apiConfig.apiHeaders
