import React from 'react'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'

export default class TemperatureBaselineRangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'temp_baseline_range'
  }

  rangeTemperatureDeltaInputElement (): JSX.Element {
    return this.getInputElement(
      1, 10, 1,
      CAREPLAN_VALUE_TYPES.TEMPERATURE_DELTA.get(val => val),
      CAREPLAN_VALUE_TYPES.TEMPERATURE_DELTA.set(val => val),
      true
    )
  }

  renderGoal (): JSX.Element {
    return <div className='disabled'>Keep skin temperature in range compared to personal baseline</div>
  }

  renderClinicianTrigger (): JSX.Element {
    return <div className='disabled'>If <b>skin temperature</b> is more than {this.rangeTemperatureDeltaInputElement()}°F above or below personal baseline. [Coming soon]</div>
  }

  renderCondensed (): JSX.Element {
    return (
      <div className='disabled'>Skin temp. &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.TEMPERATURE_DELTA.get(val => val))} °F from personal baseline</div>
    )
  }
}
