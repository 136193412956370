import React from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import LoadingIndicator from '../../elements/LoadingIndicator'
import AnalyticsAdherenceSection from './modules/AnalyticsAdherenceSection'
import AnalyticsMeasurementsSection from './modules/AnalyticsMeasurementsSection'
import AnalyticsAlertsSection from './modules/AnalyticsAlertsSection'
import { Box } from '@mui/material'
import { mapAnalyticsState } from '../../../constants/reduxMappers'

function AnalyticsTrendsScreen (props: PropsFromRedux): JSX.Element {
  const renderTrendsScreen = (): JSX.Element => {
    return (
      <Box data-testid='analytics-page__trends-screen'>
        {props.analyticsStore?.isDateRangeLoading && <div className='ax-main__loading'> <LoadingIndicator /> </div>}

        {!props.analyticsStore?.isDateRangeLoading &&
          <div>
            <AnalyticsAdherenceSection />
            <AnalyticsMeasurementsSection />
            <AnalyticsAlertsSection />
          </div>}
      </Box>
    )
  }

  return renderTrendsScreen()
}

const connector = connect(mapAnalyticsState)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AnalyticsTrendsScreen)
