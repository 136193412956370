import React, { useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import restApi from '../../api'
import BasicFrame from '../layout/BasicFrame'
import ResetPasswordForm from '../controllers/forms/ResetPasswordForm'
import FirstTimePasswordForm from '../controllers/forms/FirstTimePasswordForm'
import ConsentForm from '../elements/ConsentForm'
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { productConfig } from '../../config/config-service'
import { type AppDispatch } from '../..'
import { mapUserState } from '../../constants/reduxMappers'
import ClinicianOnboarding from '../controllers/forms/OnboardClinician/ClinicianOnboarding'
import { bindActionCreators } from 'redux'
import { updateUser } from '../../store/userSession'

/**
 * New user workflow:
 * 1) User clicks activate account link in email.
 * 2) User sets password, and is automatically logged in.
 * 3) User signs consent (if product required, if they are not a clinician).
 * 4) User signs terms of service.
 *
 * User will also be directed here if they have logged-in successfully
 * but haven't yet signed TOS/consent.
 */

function OnboardingPage (props: PropsFromRedux): JSX.Element {
  const [isConsentChecked, setIsConsentChecked] = useState(false)

  const handleToggleConsentChecked = (): void => {
    setIsConsentChecked(!isConsentChecked)
  }

  const handleConsentContinue = (): void => {
    if (!isConsentChecked) return
    props.signConsent(props.user)
  }

  const renderConsent = (): JSX.Element => {
    return (
      <div className='welcome-page__tos'>
        <Typography variant='h6'>Welcome to Ejenta!</Typography>
        <Typography variant='body1' mb={2}>Please agree to the Consent Form to continue.</Typography>

        <Box className='welcome-page__tos-actions'>
          <FormControlLabel
            sx={{ '& .MuiTypography-root': { fontSize: 14, fontWeight: 'light' } }}
            control={<Checkbox size='small' onClick={handleToggleConsentChecked} checked={isConsentChecked} />}
            label='I have read and agree to the Consent Form'
          />

          <Box className='welcome-page__tos-continue'>
            {!isConsentChecked && <Button variant='contained' color='secondary' disabled>Continue</Button>}
            {isConsentChecked && <Button variant='contained' onClick={handleConsentContinue}>Continue</Button>}
          </Box>
        </Box>

        <div className='welcome-page__tos-container'>
          <ConsentForm />
        </div>
      </div>
    )
  }

  const renderFirstTimePasswordForm = (): JSX.Element => {
    return (
      <Box data-testid='welcome-page__pw'>
        <FirstTimePasswordForm />
      </Box>
    )
  }

  const renderDone = (): JSX.Element => {
    return (
      <Box
        sx={{ textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '9rem' }}
        className='login-form'
      >
        <Typography variant='h6'>Welcome to Ejenta!</Typography>

        <Box>
          <Typography variant='body1' mb={2}>You've successfully set up your account.</Typography>
          <Button variant='contained' href='/alerts' fullWidth>Continue to dashboard</Button>
        </Box>
      </Box>
    )
  }

  const renderOnboardingPage = (): JSX.Element => {
    let content: JSX.Element
    const needsToLogin = !props.user
    const needsTOS = !props.user?.signed_tos
    const needsPasswordChange = !props.user?.changed_password
    const needsSignedConsent = productConfig().onboarding.hasConsentForm ? !props.user?.clinician && !props.user?.signed_consent : false

    if (needsToLogin) {
      content = <ResetPasswordForm />
    } else if (needsSignedConsent) {
      content = renderConsent()
    } else if (needsTOS) {
      content = <ClinicianOnboarding user={props.user} apiActions={props.apiActions} updateUser={props.updateUser} />
    } else if (needsPasswordChange) {
      content = renderFirstTimePasswordForm()
    } else {
      content = renderDone()
    }

    return (
      <BasicFrame>
        <div className='welcome-page'> {content} </div>
      </BasicFrame>
    )
  }

  return renderOnboardingPage()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    signToS (user) {
      dispatch(restApi.actions.signToS({ id: user.id }))
    },
    signConsent (user) {
      dispatch(restApi.actions.signConsent({ id: user.id }))
    },
    changePassword (user) {
      dispatch(restApi.actions.changePassword({ id: user.id }))
    },
    apiActions: bindActionCreators(restApi.actions, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch)
  }
}

const connector = connect(mapUserState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(OnboardingPage)
