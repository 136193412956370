import _ from 'lodash'
import React from 'react'
import LoadingIndicator from '../../elements/LoadingIndicator'
import { productConfig } from '../../../config/config-service'
import { getDisplayName } from '../../../utils/baseStringUtils'
import {
  getActivityProfileStats,
  getBloodGlucoseProfileStats,
  getBloodPressureProfileStats,
  getPulseOxProfileStats,
  getWeightProfileStats,
  getPregnancyWeightProfileStats
} from '../../../utils/member/alertDetailUtils'
import { Card, CardContent } from '@mui/material'
import { Link } from 'react-router-dom'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import ProfileDetail from '../../elements/ProfileCell/ProfileDetail'

interface IAlertDetailProps {
  member: IMemberSummary
  hideProfileLink: boolean
  onModalClose: () => void
}

export default function AlertDetailSidebar (props: IAlertDetailProps): JSX.Element {
  const renderProfileLink = (): JSX.Element => {
    if (props.hideProfileLink) return <></>

    return (
      <Link
        to={`/profile/${props.member.user?.id as string}`}
        style={{ fontWeight: 500, textDecoration: 'none', color: '#EF6C00' }}
        onClick={props.onModalClose}
      >
        View member profile
      </Link>
    )
  }

  const renderWeight = (): JSX.Element => {
    if (!props.member.weight?.latest_measurement) return <></>
    const { latestWeight } = getWeightProfileStats(props.member)

    return (
      <section data-testid='profile__section-weight'>
        <div className='profile__section-header'>Weight</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Latest weight</span> {latestWeight} lb.</div>
        </div>
      </section>
    )
  }

  const renderPregnancyWeight = (): JSX.Element => {
    const {
      weightGain14D,
      weightIOMDelta,
      weightGainTotal
    } = getPregnancyWeightProfileStats(props.member)

    return (
      <section data-testid='profile__section-pregnancy-weight'>
        <div className='profile__section-header'>Weight</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>14d gain rate</span> {weightGain14D}</div>
          <div><span className='profile__data-label'>IOM delta</span> {weightIOMDelta}</div>
          <div><span className='profile__data-label'>Total gain</span> {weightGainTotal}</div>
        </div>
      </section>
    )
  }

  const renderBloodPressure = (): JSX.Element => {
    if (!props.member.blood_pressure?.latest_measurement) return <></>
    const { latestBloodPressure } = getBloodPressureProfileStats(props.member)

    return (
      <section data-testid='profile__section-blood-pressure'>
        <div className='profile__section-header'>Blood Pressure</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Latest blood pressure</span> {latestBloodPressure?.systolic}/{latestBloodPressure?.diastolic}</div>
          <div><span className='profile__data-label'>Latest heart rate</span> {latestBloodPressure?.heart_rate}</div>
        </div>
      </section>
    )
  }

  const renderBloodGlucose = (): JSX.Element => {
    if (!props.member.blood_glucose?.latest_measurement) return <></>
    const { latestBloodGlucose } = getBloodGlucoseProfileStats(props.member)

    return (
      <section data-testid='profile__section-blood-glucose'>
        <div className='profile__section-header'>Blood Glucose</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Latest blood glucose</span> {latestBloodGlucose?.blood_glucose}</div>
        </div>
      </section>
    )
  }

  const renderPulseOx = (): JSX.Element => {
    if (!props.member.pulse_ox?.latest_measurement) return <></>
    const { latestPulseOx } = getPulseOxProfileStats(props.member)

    return (
      <section data-testid='profile__section-pulse-ox'>
        <div className='profile__section-header'>SpO₂</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Latest SpO₂</span> {latestPulseOx?.oxygen_saturation}%</div>
        </div>
      </section>
    )
  }

  const renderActivity = (): JSX.Element => {
    const {
      activityTotal,
      activityProgress
    } = getActivityProfileStats(props.member)

    return (
      <section data-testid='profile__section-activity'>
        <div className='profile__section-header'>Activity</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Last 7d</span> {activityTotal}</div>
          <div><span className='profile__data-label'>Goal %</span> {activityProgress}</div>
        </div>
      </section>
    )
  }

  const renderAlertDetailSidebar = (): JSX.Element => {
    const detailSidebar = productConfig().alerts.detailSidebar
    if (!detailSidebar?.exists) return <></>

    if (_.isEmpty(props.member)) {
      return <LoadingIndicator />
    }

    return (
      <Card sx={{ m: 1 }} variant='outlined'>
        <CardContent>
          <div className='profile__header'> {getDisplayName(props.member.user)} </div>
          <div className='profile__subheader' />

          {detailSidebar.hasPregnancyWeight &&
            <div className='profile__subheader'> <ProfileDetail user={props.member.user} memberObj={props.member} /> </div>}

          {renderProfileLink()}
          {detailSidebar.hasWeight && renderWeight()}
          {detailSidebar.hasPregnancyWeight && renderPregnancyWeight()}
          {detailSidebar.hasActivity && renderActivity()}
          {detailSidebar.hasBloodPressure && renderBloodPressure()}
          {detailSidebar.hasBloodGlucose && renderBloodGlucose()}
          {detailSidebar.hasPulseOx && renderPulseOx()}
        </CardContent>
      </Card>
    )
  }

  return renderAlertDetailSidebar()
}
