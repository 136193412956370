
import {
  MEMBER_STATUS_FILTERS,
  ALERT_STATUS_FILTERS
} from './baseAlertUtils'
import { productConfig } from '../config/config-service'
import { humanizeAlertType } from '../constants/strings'
import { LIFESTYLE_COACH, PREGNANCY_STATUS } from '../constants/memberFilterGroups'
import { type IFilterGroup } from '../interfaces/store/defaultStore.interface'
import { type ISidebarFilters } from '../interfaces/ejenta.interface'

const chatConfig = productConfig().chats.refineSidebar

export const CHAT_SORTS = {
  SORT_STATUS: 'STATUS',
  DATE_DESC: 'DATE_DESC',
  DATE_ASC: 'DATE_ASC'
}

export const CHAT_NOTES_FILTERS: ISidebarFilters = {
  displayTitle: 'Notes',
  filters: [
    {
      id: 'FILTER/WITH_NOTE',
      displayTitle: 'Chats with notes'
    }
  ]
}

export const CHAT_STATUS_FILTERS: ISidebarFilters = {
  ...ALERT_STATUS_FILTERS,
  displayTitle: 'Chat status'
}

export const CHATS_VIEW: { FILTER_GROUPS: IFilterGroup } = {
  FILTER_GROUPS: {
    ...(chatConfig.hasPregnancyStatus && { PREGNANCY_STATUS }),
    ...(chatConfig.hasLifestyleCoach && { LIFESTYLE_COACH }),

    MEMBER_STATUS: MEMBER_STATUS_FILTERS,
    ALERT_STATUS: CHAT_STATUS_FILTERS,
    NOTES: CHAT_NOTES_FILTERS,
    ALERT_TYPE: {
      displayTitle: 'Type',
      filters: chatConfig.typeFilters?.map(typeFilter => {
        return {
          id: `FILTER/${typeFilter.toUpperCase()}`,
          displayTitle: humanizeAlertType(typeFilter),
          disabled: true
        }
      })
    }
  }
}

export const DEFAULT_CHAT_FILTERS = ['FILTER/OPEN_UNREAD', 'FILTER/OPEN', 'FILTER/NEW_CHAT']

export const DEFAULT_CHAT_SORT = CHAT_SORTS.SORT_STATUS
