import React from 'react'
import { productConfig } from '../../../config/config-service'
import { getDisplayAge, getDisplayGestationalWeeks } from '../../../utils/baseStringUtils'
import { english as ordinal } from '../../../ordinal'
import { getTrimesterOfPregnancy } from '../../../utils/baseDateUtils'
import moment from 'moment'
import { type IMember } from '../../../interfaces/user.interface'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

interface IProfileDetailProps {
  user: IMember
  detailStr?: string
  memberObj?: IMemberSummary
}

function ProfileDetail (props: IProfileDetailProps): JSX.Element {
  const config = productConfig()
  const hasPregnancyInfo = config.profile.showPregnancyInfo

  const pregnancyProfileDetail = (): JSX.Element => {
    if (props.user.delivery_date) {
      return <span>Delivered {moment(props.user.delivery_date).format('M/D/YY')}</span>
    } else if (props.user.archived_since) {
      return <span>Archived {moment(props.user.archived_since).format('M/D/YY')}</span>
    }

    let detailStr = props.detailStr
    if (!detailStr) detailStr = getDisplayGestationalWeeks(props.user)

    if (props.memberObj) {
      // Trimester ends up only being shown on the members page, not alerts page, to avoid extra plumbing
      const trimesterStr = `${ordinal(getTrimesterOfPregnancy(props.memberObj))} trimester`
      return <span>{detailStr} &middot; {trimesterStr}</span>
    }

    return <span>{detailStr}</span>
  }

  const defaultDetail = (): JSX.Element => {
    const detailStr = getDisplayAge(props.user)
    return <span>{detailStr} yrs</span>
  }

  return hasPregnancyInfo ? pregnancyProfileDetail() : defaultDetail()
}

export default ProfileDetail
