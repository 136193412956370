import React, { useContext } from 'react'
import Tooltip from './Tooltip'
import { FormGroup, Checkbox, FormControlLabel, Select, MenuItem, FormControl, OutlinedInput, FormHelperText, InputAdornment, Typography, Radio } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Field } from 'react-final-form'
import moment from 'moment'
import { ReactReduxContext } from 'react-redux'
import { memberFormSlice } from '../../store/memberForm'
export function EjentaForm (props: any): JSX.Element {
  return (
    <form method='post' {...props}>
      {props.children}
    </form>
  )
}

export function renderField (config: any, options: any): JSX.Element {
  return <Field optional={config?.optional} {...options} />
}

/* *****************************************************************************
 * Render methods for forms decorated with react final form
 * *****************************************************************************/
function renderTooltip (tooltip: string): JSX.Element | null {
  if (!tooltip) return null
  const id = `rendered__tooltip__${Date.now()}`

  return (<Tooltip tooltipId={id} content={tooltip} />)
}

function renderRequiredIndicator (optional: boolean): JSX.Element {
  if (optional) return <></>
  else return (<span><em>*</em></span>)
}

export function renderDateInput ({ input, label, optional, disabled, dateFormat, testId, meta: { error, submitError, dirty }, tooltip }): JSX.Element {
  const value = input.value !== '' ? moment(input.value) : null
  const store = useContext(ReactReduxContext).store

  let helperText: string = submitError
  if (!value && dirty) helperText = error
  else if (typeof value !== 'string' && !value?.isValid?.() && dirty) helperText = 'Date is invalid'

  return (
    <FormControl fullWidth size='small' error={!!helperText} variant='outlined'>
      {label && <Typography variant='body1'>{label} {!disabled && renderRequiredIndicator(optional)} {renderTooltip(tooltip)}</Typography>}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          {...input}
          value={value}
          disabled={disabled}
          PopperProps={{ placement: 'bottom-start' }}
          onChange={(dateAsMoment: moment.Moment) => {
            input.onChange(dateAsMoment)
            if (dateAsMoment.isValid()) {
              store.dispatch(memberFormSlice.actions.updateField({ fieldName: input.name, fieldValue: dateAsMoment.format(), invalid: !!helperText }))
            }
          }}
          slotProps={{
            textField: {
              'data-testid': testId,
              id: input.name,
              size: 'small',
              error: !!helperText,
              helperText
            }
          }}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export function renderRadioInput ({ input, label, testId }): JSX.Element {
  const store = useContext(ReactReduxContext).store
  return (
    <FormControlLabel
      key={input.value}
      value={input.value}
      label={label}
      checked={input.checked}
      data-testid={testId}
      onClick={() => {
        input.onChange(input.value)
        store.dispatch(memberFormSlice.actions.updateField({ fieldName: input.name, fieldValue: input.value }))
      }}
      control={<Radio data-testid={testId} />}
    />
  )
}

export function renderCheckboxInput ({ input, label, testId, meta: { error, touched, submitError } }): JSX.Element {
  const store = useContext(ReactReduxContext).store
  const checkboxControl = (
    <Checkbox
      checked={input.checked}
      name={input.name}
      required={input.required}
      data-testid={testId}
      onChange={() => {
        store.dispatch(memberFormSlice.actions.updateField({ fieldName: input.name, fieldValue: !input.checked, invalid: error || submitError }))
        input.onChange(!input.checked)
      }}
      sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: 25 } }}
    />
  )
  const labelControl = <Typography className='no-underline' variant='body1' dangerouslySetInnerHTML={{ __html: label }} />

  return (
    <FormGroup sx={{ ml: 1 }}>
      <FormControlLabel sx={{ '& .MuiTypography-root': { fontSize: 15, fontWeight: 'light' } }} control={checkboxControl} label={labelControl} />
      {touched && (error || submitError) && <FormHelperText id='form__error-text' error>{error ?? submitError}</FormHelperText>}
    </FormGroup>
  )
}

export function renderInput ({ input, label, optional, disabled, placeholder, autoComplete, testId, meta: { error, submitError, touched }, tooltip }): JSX.Element {
  const store = useContext(ReactReduxContext).store
  return (
    <FormControl fullWidth size='small' error={!!error && touched} variant='outlined'>
      {label && <Typography variant='body1' id={`${input.name as string}_label`}>{label} {!disabled && renderRequiredIndicator(optional)} {renderTooltip(tooltip)}</Typography>}

      <OutlinedInput {...input} autoComplete={autoComplete} id={input.name} data-testid={testId} disabled={disabled} placeholder={placeholder} type={input.type}
        inputProps={{ 'aria-labelledby': `${input.name as string}_label` }}

        onChange={(event) => {
          input.onChange(event)
          store.dispatch(memberFormSlice.actions.updateField({ fieldName: input.name, fieldValue: event.target.value, invalid: error || submitError }))
        }}
        />
      {touched && (error || submitError) && <FormHelperText id='form__error-text' error>{error ?? submitError}</FormHelperText>}
    </FormControl>
  )
}

export function renderInputWithUnit ({
  input, label, optional, meta: { error, touched, submitError },
  autoComplete, unit, minValue, maxValue, step
}): JSX.Element {
  const store = useContext(ReactReduxContext).store
  const hasErrors = touched && (!!error || !!submitError)
  return (
    <FormControl fullWidth size='small' error={hasErrors} variant='outlined'>
      {label && <Typography variant='body1' id={`${input.name as string}_label`}>{label} {renderRequiredIndicator(optional)}</Typography>}

      <OutlinedInput
        {...input} id={input.name} type='number' autoComplete={autoComplete}
          min={minValue}
          max={maxValue}
          step={step}
          onChange={(event) => {
            input.onChange(event)
            store.dispatch(memberFormSlice.actions.updateField({ fieldName: input.name, fieldValue: event.target.value, invalid: hasErrors }))
          }}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', 'aria-labelledby': `${input.name as string}_label` }}
        endAdornment={<InputAdornment position='end'>{unit}</InputAdornment>}
        />
      {hasErrors && <FormHelperText id='form__error-text' error>{error ?? submitError}</FormHelperText>}
    </FormControl>
  )
}

export function renderSelect ({ input, label, optional, meta: { error, touched, submitError }, options, testId, onChange }): JSX.Element {
  const store = useContext(ReactReduxContext).store
  const selectOptions = Object.keys(options).map(key => {
    return { key, value: options[key] }
  })
  const selectedOption = selectOptions.find(option => option.key === input?.value)?.key ?? ''
  const handleChange = (e: any): void => {
    if (onChange) onChange(e.target.value)
    input.onChange(e)
    store.dispatch(memberFormSlice.actions.updateField({ fieldName: input.name, fieldValue: e.target.value, invalid: error || submitError }))
  }
  return (
    <div>
      <FormControl fullWidth size='small' error={!!error}>
        {label && <Typography variant='body1'>{label} {renderRequiredIndicator(optional)}</Typography>}

        <Select value={selectedOption} onChange={handleChange} id={input.name} name={input.name} data-testid={testId}>
          {optional && <MenuItem value='' />}
          {selectOptions.map(item => <MenuItem key={item.key} value={item.key}>{item?.value}</MenuItem>)}
        </Select>
        {touched && (error || submitError) && <FormHelperText id='form__error-text' error>{error ?? submitError}</FormHelperText>}
      </FormControl>
    </div>
  )
}
