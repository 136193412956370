import React, { useState } from 'react'
import MemberProfileForm from '../../controllers/forms/MemberProfileForm'
import { getMemberProfileFormErrors, getProfileMemberFields } from '../../../utils/member/baseProfileUtils'
import { getMemberApiBody, getProfileFormInitialValues } from '../../../constants/constants'
import { Alert, type AlertColor, Box, Snackbar } from '@mui/material'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type ICareManager } from '../../../interfaces/store/sessionStore.interface'
import { type IFormValues } from '../../../interfaces/forms.interface'
import { type IGroup } from '../../../interfaces/group.interface'
import { getMemberAddressFields, getMemberOptionalFields } from '../../../constants/customMemberFields'

interface IProfileInfoProps {
  memberObj: IMemberSummary
  careManagers: ICareManager []
  allowedGroups?: IGroup []
  profileViewActions: any
  apiActions: any
}

function ProfileInformationPane (props: IProfileInfoProps): JSX.Element {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const memberFields = { ...getProfileMemberFields(), ...getMemberAddressFields(), ...getMemberOptionalFields() }

  const getInitialValues = (): IFormValues => {
    return getProfileFormInitialValues(memberFields, props.memberObj)
  }

  const handleFormSubmit = async (formData: any): Promise<any> => {
    const submitPromise = new Promise((resolve, reject) => {
      const apiBody = getMemberApiBody(memberFields, formData, props.memberObj.user.group as string)

      props.apiActions.updateUser(
        {
          id: props.memberObj.user.id,
          promise: { resolve, reject },
          promiseTransformer: errors => getMemberProfileFormErrors(errors, memberFields)
        },
        apiBody
      )
    })

    return await submitPromise.then(handleFormSubmitSuccess).catch(data => data)
  }

  const handleFormSubmitSuccess = (): void => {
    setSnackbarMessage('Successfully updated member.')
    setSnackbarSeverity('success')
    setSnackbarOpen(true)
  }

  const handleCloseSnackbar = (): void => {
    setSnackbarOpen(false)
  }

  const renderForm = (): JSX.Element => {
    return (
      <MemberProfileForm
        isNewMember={false}
        careManagers={props.careManagers}
        allowedGroups={props.allowedGroups}
        onSubmit={handleFormSubmit}
        onCancel={() => props.profileViewActions.handleClearModal()}
        getInitialValues={getInitialValues}
      />
    )
  }

  const renderFeedback = (): JSX.Element => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        key='edit-profile-pane__success__snack-bar'
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity as AlertColor} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    )
  }

  const renderProfileInformationPage = (): JSX.Element => {
    return (
      <Box>
        {renderForm()}
        {renderFeedback()}
      </Box>
    )
  }

  return renderProfileInformationPage()
}

export default ProfileInformationPane
