import React, { useState } from 'react'
import getGlucoseChartOptions from '../../../utils/charts/baseGlucoseChart'
import ChartModule from './ChartModule'
import BloodGlucoseTable from '../Tables/BloodGlucoseTable'
import TabPanel from '../../layout/TabPanel'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { a11yProps } from '../../../constants/constants'
import { type IChartOptionsParams } from '../../../interfaces/chart.interface'

export default function BloodGlucoseChart (props: IChartOptionsParams): JSX.Element {
  const [selectedBloodGlucoseTab, setSelectedBloodGlucoseTab] = useState(0)
  const pagePrefix = 'blood-glucose-metric__tab'

  const renderChartPanel = (): JSX.Element => {
    return (
      <Card className='chart-card' id='blood_glucose-card'>
        <CardContent sx={{ paddingTop: 0 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'end', mb: 2 }}>
            <Typography variant='h6' color='#7f7f7f'> Blood Glucose</Typography>
            <Tabs
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              value={selectedBloodGlucoseTab}
              onChange={(_e, val) => { setSelectedBloodGlucoseTab(val) }}
              aria-label='Profile tabs'
            >
              <Tab data-testid='blood-glucose__charts-tab__bg' label='Graph' {...a11yProps(pagePrefix, 0)} />
              <Tab data-testid='blood-glucose__table-tab__bg' label='Table' {...a11yProps(pagePrefix, 1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedBloodGlucoseTab} index={0} noPadding>
            <ChartModule
              hideHeader
              hideBorder
              showChartActions
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
              metricType='blood_glucose'
              getChartOptions={getGlucoseChartOptions}
              showMemberAlerts={props.showMemberAlerts}
              handleAlertClick={props.handleAlertClick} // eslint-disable-line react/jsx-handler-names
            />
          </TabPanel>
          <TabPanel value={selectedBloodGlucoseTab} index={1} noPadding>
            <BloodGlucoseTable
              hideHeader
              hideBorder
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
            />
          </TabPanel>
        </CardContent>
      </Card>

    )
  }

  return renderChartPanel()
}
