import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { getAgeBuckets, getAgeCounts } from './ageChartConfig'
import { type Options } from 'highcharts'
import { type IAnalyticsUser } from '../../../../interfaces/store/analyticsStore.interface'

export default function getAgeChartOptions (users: IAnalyticsUser [], analyticsActions): Options {
  const ageCounts = getAgeCounts(users)

  const ageChartOptions: Options = {
    chart: {
      type: 'column'
    },

    xAxis: {
      title: {
        text: 'Age'
      },

      categories: _.values(getAgeBuckets())
    },

    yAxis: {
      title: {
        text: '# of members'
      }
    },

    legend: {
      enabled: false
    },

    series: [{
      name: 'Members',
      className: 'series-0',
      cursor: 'pointer',
      type: 'column',
      data: _.map(ageCounts, (a, key) => ({
        y: a.length,
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: `Age: ${getAgeBuckets()[key] as string}`,
              type: 'member',
              items: a
            })
          }
        }
      }))
    }],

    tooltip: {
      useHTML: true,
      headerFormat: '',
      borderColor: 'none',
      pointFormatter: function format () {
        const point = this as any
        return `
            <span class="ax-text-bold">${point.category as string}</span><br/>
            Members: <em>${point.y as number}</em>
          `
      }
    }
  }

  return _.merge(_.cloneDeep(baseChartOptions), ageChartOptions)
}
