import React from 'react'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { Box } from '@mui/material'

export default class TemperatureRangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'temp_range'
  }

  rangeTemperatureMinInputElement (): JSX.Element {
    return this.getInputElement(
      80, 92, 1,
      CAREPLAN_VALUE_TYPES.TEMPERATURE_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.TEMPERATURE_MIN.set(val => val)
    )
  }

  rangeTemperatureMaxInputElement (): JSX.Element {
    return this.getInputElement(
      93, 105, 1,
      CAREPLAN_VALUE_TYPES.TEMPERATURE_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.TEMPERATURE_MAX.set(val => val)
    )
  }

  renderGoal (): JSX.Element {
    return <div>Keep skin temperature in range</div>
  }

  renderClinicianTrigger (): JSX.Element {
    return <div>If <b>skin temperature</b> is below {this.rangeTemperatureMinInputElement()}°F or above {this.rangeTemperatureMaxInputElement()}°F.</div>
  }

  renderCondensed (): JSX.Element {
    const className: string = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    return (
      <Box className={className}>Skin temp. &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.TEMPERATURE_MIN.get(val => val))} or
        &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.TEMPERATURE_MAX.get(val => val))} °F
      </Box>
    )
  }
}
