import { type Options } from 'highcharts'
import _ from 'lodash'
import { type IChartOptionsParams } from '../../interfaces/chart.interface'
import {
  getBaseChartOptions,
  addPausePlotBands,
  getSimpleContinuousSeries,
  getSimpleBioStickerAlertsSeries,
  getBaselineContinuousSeries,
  getEmptyChart
} from './baseChartUtils'
import {
  getDefaultChartOptions,
  getXAxisForRange
} from './chartUtils'

export default function getTemperatureChartOptions ({ memberObj, dashboardViewStore, showMemberAlerts, handleAlertClick }: IChartOptionsParams): Options {
  if (!memberObj.temperature) return getEmptyChart()

  let options = _.merge(getBaseChartOptions(), getDefaultChartOptions('temperature_chart'))
  options.xAxis = getXAxisForRange(memberObj, dashboardViewStore, false)

  options = getSimpleContinuousSeries(options, memberObj, 'temperature', 'Skin temperature',
    memberObj.temperature.information.units.temperature)

  if (showMemberAlerts && !!handleAlertClick) {
    options = getSimpleBioStickerAlertsSeries(memberObj, options, handleAlertClick, 'temperature', 'Temperature', 'temp_range')
  }

  if (memberObj.temperature.baseline) {
    options = getBaselineContinuousSeries(options, memberObj, 'temperature', 'Baseline skin temperature',
      memberObj.temperature.information.units.temperature)
  }

  options = addPausePlotBands(memberObj.user.pauses ?? [], options, true)
  return options
}
