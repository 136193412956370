import React, { useState } from 'react'
import MemberDevicesForm from '../../controllers/forms/MemberDevicesForm'
import { Alert, type AlertColor, Snackbar, Box } from '@mui/material'
import { getMemberProfileFormErrors, getProfileMemberFields } from '../../../utils/member/baseProfileUtils'
import { getMemberApiBody, getProfileFormInitialValues } from '../../../constants/constants'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type IFormValues } from '../../../interfaces/forms.interface'

interface IDeviceInformationProps {
  apiActions: any
  memberObj: IMemberSummary
  profileViewActions: any
}

function DeviceInformationPane (props: IDeviceInformationProps): JSX.Element {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const memberFields = getProfileMemberFields()

  const getInitialValues = (): IFormValues => {
    return getProfileFormInitialValues(memberFields, props.memberObj)
  }

  const handleFormSubmit = async (formData: Record<string, string>): Promise<any> => {
    const submitPromise = new Promise((resolve, reject) => {
      const apiBody = getMemberApiBody(memberFields, formData, props.memberObj.user?.group as string)

      props.apiActions.updateUser(
        {
          id: props.memberObj.user.id,
          promise: { resolve, reject },
          promiseTransformer: (errors: Record<string, string>) => getMemberProfileFormErrors(errors, memberFields)
        },
        apiBody
      )
    })

    return await submitPromise
      .then(handleFormSubmitSuccess)
      .catch(data => data)
  }

  const handleFormSubmitSuccess = (): any => {
    setSnackbarMessage('Successfully updated member.')
    setSnackbarSeverity('success')
    setSnackbarOpen(true)
  }

  const handleCloseSnackbar = (): any => {
    setSnackbarOpen(false)
  }

  const renderForm = (): JSX.Element => {
    return (
      <MemberDevicesForm
        onSubmit={handleFormSubmit}
        onCancel={() => props.profileViewActions.handleClearModal()}
        getInitialValues={getInitialValues}
      />
    )
  }

  const renderFeedback = (): JSX.Element => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        key='edit-profile-pane__success__snack-bar'
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity as AlertColor} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    )
  }

  const renderDeviceInformationPane = (): JSX.Element => {
    return (
      <Box>
        {renderForm()}
        {renderFeedback()}
      </Box>
    )
  }

  return renderDeviceInformationPane()
}

export default DeviceInformationPane
