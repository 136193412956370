import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Exporting from 'highcharts/modules/exporting.js'
import ExportData from 'highcharts/modules/export-data.js'
import Accessibility from 'highcharts/modules/accessibility.js'
import OfflineExporting from 'highcharts/modules/offline-exporting.js'
import HighchartsMore from 'highcharts/highcharts-more'
import LoadingIndicator from './LoadingIndicator'
import { type IChartOptionsParams } from '../../interfaces/chart.interface'

Exporting(Highcharts)
OfflineExporting(Highcharts)
ExportData(Highcharts)
Accessibility(Highcharts)
HighchartsMore(Highcharts)

interface IChartProps extends IChartOptionsParams {
  printed: boolean
  containerId: string
  getOptions: (args: IChartOptionsParams) => Highcharts.Options
  handlePrintCompleted: () => void
}

export default function Chart (props: IChartProps): JSX.Element {
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth)
  const [chart, setChart] = useState<Highcharts.Chart | null>(null)

  useEffect(() => {
    const handleWindowResize = _.debounce(() => { setInnerWidth(window.innerWidth) })
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (props.printed) printChart()
  }, [props.printed])

  const updateChart = (newChart: Highcharts.Chart): void => {
    if (!chart) setChart(newChart)
  }

  const printChart = (): void => {
    if (chart) chart.print()
    props.handlePrintCompleted()
  }

  const renderChart = (): JSX.Element => {
    if (props.dashboardViewStore.isRangeLoading) {
      return <LoadingIndicator />
    }

    if (props.showMemberAlerts && (props.memberObj.alerts === null)) {
      return <LoadingIndicator />
    }

    const chartOptions = props.getOptions({
      dashboardViewStore: props.dashboardViewStore,
      memberObj: props.memberObj,
      showMemberAlerts: props.showMemberAlerts,
      handleAlertClick: props.handleAlertClick,
      handleDeleteWeight: props.handleDeleteWeight,
      handleRestoreWeight: props.handleRestoreWeight
    })
    const keyPrefix = props.dashboardViewStore.lastUpdated ?? Date.now().toString()

    return (
      <HighchartsReact className='chart-container' highcharts={Highcharts} options={chartOptions} id={props.containerId} callback={(iChart) => { updateChart(iChart) }} key={`${keyPrefix}-${innerWidth}`} />
    )
  }

  return renderChart()
}
