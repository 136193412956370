import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import restApi from '../../../api'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { Alert, Button } from '@mui/material'
import { membersViewSlice } from '../../../store/membersView'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../layout/ContentMain'
import LoadingIndicator from '../../elements/LoadingIndicator'
import AddMemberModal from './AddMemberModal'
import RefineMembersSidebar from './RefineMembersSidebar'

import { useLocation, useNavigate } from 'react-router'
import { MEMBERS_PAGE_SIZE } from '../../../constants/constants'
import { type RootState, type AppDispatch } from '../../..'
import { getMemberHeaderColumns, renderColumnMetric, renderExtendedBPMetric, renderMemberProfileCol } from '../../../utils/baseMembersListUtils'
import MembersTable from './MembersTable'
import { type IAlertRows } from '../../../interfaces/alert.interface'
import { type IMemberHeaderColumn, type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { isMemberStarred } from '../../../utils/member/baseProfileUtils'
import { productConfig } from '../../../config/config-service'
import { type IUser } from '../../../interfaces/user.interface'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    memberSummariesApi: state.api.memberSummaries,
    membersViewStore: state.membersView,
    pageStart: (state.membersView.page - 1) * MEMBERS_PAGE_SIZE,
    pageEnd: state.membersView.page * MEMBERS_PAGE_SIZE,
    clinicianId: state.userSession.user?.id
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    storeActions: bindActionCreators(membersViewSlice.actions, dispatch),
    apiActions: bindActionCreators(restApi.actions, dispatch),
    starUser: (user) => {
      dispatch(restApi.actions.starUser({ id: user.id }))
    },
    unstarUser: (user) => {
      dispatch(restApi.actions.unstarUser({ id: user.id }))
    }
  }
}

function MembersPage (props: PropsFromRedux): JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()
  const membersConfig = productConfig().members.mainPage
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false)
  const [locationKey, setLocationKey] = useState('')

  useEffect(() => {
    if (location) props.storeActions.setUrlFilters(location.search)
    props.apiActions.memberSummaries()
  }, [])

  useEffect(() => {
    setLocationKey(location.key)

    if (props.membersViewStore.locationKey !== locationKey) {
      props.storeActions.setLocationKey(locationKey)
      props.apiActions.memberSummaries()
    }
  }, [location])

  // Resets the search query, sorts, and filters
  const handleResetMembersList = (): void => {
    props.storeActions.resetView()

    if (!_.isEmpty(location.search)) {
      props.storeActions.setUrlFilters(location.search)
    }
  }

  const handleOpenAddMemberModal = (): void => {
    setIsAddMemberModalOpen(true)
  }

  const handleCloseAddMemberModal = (): void => {
    setIsAddMemberModalOpen(false)
  }

  const renderHeader = (): JSX.Element => {
    return (
      <ContentMainIntroHeader title='Members'>
        <Button variant='contained' size='small' data-testid='clinician-members__add-member-button' onClick={handleOpenAddMemberModal}>
          Add member
        </Button>
      </ContentMainIntroHeader>
    )
  }

  // use the primary column for now, we can change this to give all secondary columns independent sort
  const getRows = (headerColumns: IMemberHeaderColumn []): IAlertRows => {
    const rows: IAlertRows = {}

    props.membersViewStore?.currentPage?.forEach((member: IMemberSummary) => {
      const isExtendedBPMetric = membersConfig?.isExtendedBloodPressureMetric

      const handleStarClick = (e: { preventDefault: () => void }, user: IUser): void => {
        const currentlyStarred = isMemberStarred(props.clinicianId ?? '', member.user.stars ?? [])
        if (currentlyStarred) props.unstarUser(user)
        else props.starUser(user)
        e.preventDefault()
      }

      const row = {
        member: renderMemberProfileCol(member, props.clinicianId ?? '', handleStarClick)
      }

      if (isExtendedBPMetric) {
        headerColumns.forEach(column => {
          if (column.label) row[column.id] = renderExtendedBPMetric(column.id, member)
        })
      } else {
        headerColumns.forEach(column => {
          if (column.label) row[column.id] = renderColumnMetric(column.id, member)
        })
      }

      rows[member.user.id ?? Date.now()] = row
    })

    return rows
  }

  const renderBody = (): JSX.Element => {
    if (!props.membersViewStore.allItems.length) {
      return (<div className='clinician-members__message'> There are no members in your group. </div>)
    }

    if (!props.membersViewStore.currentPage.length) {
      return (
        <Alert
          sx={{ mt: 1 }}
          severity='warning'
          action={<Button color='inherit' size='small' onClick={handleResetMembersList}> Reset Filters </Button>}
        >
          No members matched your search.
        </Alert>
      )
    }

    const columns = getMemberHeaderColumns()
    const rows = getRows(columns.primaryRow)

    return (
        <MembersTable
          rows={rows}
          columns={columns}
          pageStart={props.pageStart + 1}
          pageEnd={props.pageEnd}
          page={props.membersViewStore?.page ?? 1}
          total={props.membersViewStore.processedItems.length}
          viewActions={props.storeActions}
          handleItemClick={(memberId: string) => { navigate(`/profile/${memberId}`) }}
        ></MembersTable>
    )
  }

  const renderMembersPage = (): JSX.Element => {
    if (!props.memberSummariesApi.sync) return <LoadingIndicator sidebarVisible />

    return (
      <>
        <RefineMembersSidebar resetView={handleResetMembersList} />

        <ContentMain drawerWidth={285}>
          <ContentMainIntro> {renderHeader()} </ContentMainIntro>

          <ContentMainBody>
            <AddMemberModal isOpen={isAddMemberModalOpen} onModalClose={handleCloseAddMemberModal} />
            {renderBody()}
          </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderMembersPage()
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(MembersPage)
