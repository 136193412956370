import React from 'react'
import { Box } from '@mui/system'
import BasicFrame from '../../../layout/BasicFrame'
import SelfSignupFooter from './SelfSignupFooter'
import { Typography, useMediaQuery, useTheme } from '@mui/material'

export default function SelfSignupDiscontinued (): JSX.Element {
  const theme = useTheme()
  const moduleWidth = useMediaQuery(theme.breakpoints.up('lg')) ? '50vw' : '100%'
  const renderSelfSignupDiscontinued = (): JSX.Element => {
    return (
      <BasicFrame>
        <Box className='login-form self-signup' sx={{ width: moduleWidth, maxWidth: '75vw' }}>
          <Typography variant='h4' textAlign='center' mb={3} gutterBottom>Heart Healthy sign-up has ended</Typography>
          <Typography variant='body1' gutterBottom>Thank you for your interest in participating in the Heart Healthy Program.
          We are no longer accepting sign-ups at this time. We may contact you again to participate in another program in the future.</Typography>
        </Box>
        <SelfSignupFooter width={moduleWidth} />
      </BasicFrame>
    )
  }

  return renderSelfSignupDiscontinued()
}
