import React from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { EjentaForm, renderField, renderInput } from '../../elements/EjentaForm'
import { Box, Button, Typography, Alert } from '@mui/material'
import { Form } from 'react-final-form'
import { mapApiDispatch, mapUserState } from '../../../constants/reduxMappers'

function FirstTimePasswordForm (props: PropsFromRedux): JSX.Element {
  const handleFormSubmit = async (formData): Promise<any> => {
    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.firstTimePassword(
        {
          id: props.user?.id,
          promise: { resolve, reject }
        },
        { body: JSON.stringify({ password: formData.newpassword }) }
      )
    })

    return await submitPromise.catch(data => data)
  }

  const renderForm = (): JSX.Element => {
    const newPasswordField = {
      name: 'newpassword',
      type: 'password',
      label: 'New password',
      component: renderInput
    }

    const newPasswordConfirmField = {
      name: 'newPasswordConfirm',
      type: 'password',
      label: 'Confirm new password',
      component: renderInput
    }

    return (
      <div>
        <Typography variant='h6' textAlign='center'>Welcome to Ejenta!</Typography>
        <Box sx={{ textAlign: 'center', width: '100%', mb: 2 }} data-testid='login-form__intro'>
          <Typography>To get started, create a new password.</Typography>
        </Box>
        <Form
          onSubmit={handleFormSubmit}
          validate={values => {
            const errors: { newpassword?: string, newPasswordConfirm?: string } = {}
            if (!values.newpassword) errors.newpassword = 'Must enter a new password'
            if (values.newpassword !== values.newPasswordConfirm) errors.newPasswordConfirm = 'Passwords must match'
            return errors
          }}
        >
          {({ handleSubmit, pristine, submitting, submitError, hasValidationErrors }) => (
            <EjentaForm onSubmit={handleSubmit}>
              {submitError && <Alert severity='error'>{submitError}</Alert>}

              {renderField({}, newPasswordField)}
              {renderField({}, newPasswordConfirmField)}

              <Box mt={2} />

              {submitting && <Button variant='contained' color='secondary' disabled> Loading... </Button>}
              {!submitting && <Button variant='contained' fullWidth size='small' type='submit' disabled={pristine || hasValidationErrors}> Create password </Button>}
            </EjentaForm>
          )}
        </Form>
      </div>
    )
  }

  const renderFirstTimePasswordForm = (): JSX.Element => {
    return (<div className='login-form'> {renderForm()} </div>)
  }

  return renderFirstTimePasswordForm()
}

const connector = connect(mapUserState, mapApiDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(FirstTimePasswordForm)
