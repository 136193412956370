import React from 'react'
import { EjentaForm } from '../../elements/EjentaForm'
import MemberProfileFormFields from './MemberProfileFormFields'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { Form } from 'react-final-form'
import { Alert, Box, Divider, Typography } from '@mui/material'
import { getProfileMemberFields, validateMemberForm } from '../../../utils/member/baseProfileUtils'
import { type ICareManager } from '../../../interfaces/store/sessionStore.interface'
import { type DefaultFormFieldValues } from '../../../interfaces/product-config.interface'
import { type IGroup } from '../../../interfaces/group.interface'
import MemberAddressFields from './AddMember/MemberAddressFields'
import MemberOptionalFields from './AddMember/MemberOptionalFields'
import { productConfig } from '../../../config/config-service'
import { getMemberAddressFields } from '../../../constants/customMemberFields'

interface IMemberProfileFormProps {
  careManagers: ICareManager[]
  allowedGroups?: IGroup[]
  onCancel: () => void
  onSubmit: (formData: any) => Promise<any>
  getInitialValues: () => DefaultFormFieldValues
  isNewMember?: boolean
}

function MemberProfileForm (props: IMemberProfileFormProps): JSX.Element {
  const profileConfig = productConfig().profile
  const handleCancel = (e): void => {
    e.preventDefault()
    e.stopPropagation()
    props.onCancel()
  }

  const renderSubmit = ({ pristine, submitting, errors }): JSX.Element => {
    const submitButtonText = props.isNewMember ? 'Add member' : 'Save changes'
    const hasErrors = Object.keys(errors)?.length > 0
    let submitButton = <Button variant='contained' type='submit' data-testid='member-profile__add-member-button' disabled={pristine || hasErrors}>{submitButtonText}</Button>
    if (submitting) submitButton = <Button variant='contained' disabled>Updating...</Button>

    return (
      <DialogActions data-testid='add-member__buttons'>
        {submitButton} <Button variant='outlined' onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    )
  }

  const renderMemberProfileForm = (): JSX.Element => {
    // TODO Update address fields to show for any product that allows devices (epic)
    const showAddress = profileConfig.devices?.hasShippableDevices
    const memberFields = showAddress ? { ...getProfileMemberFields(), ...getMemberAddressFields() } : getProfileMemberFields()
    return (
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.getInitialValues()}
        validate={values => validateMemberForm(values, memberFields, props.isNewMember)}
      >
        {({ handleSubmit, pristine, submitting, submitError, errors, form }) => (
          <EjentaForm onSubmit={handleSubmit}>
            {submitError && <Alert severity='error'>{submitError}</Alert>}
            <MemberProfileFormFields isNewMember={props.isNewMember} change={form.change} careManagers={props.careManagers} allowedGroups={props.allowedGroups} />
            {profileConfig.optionalFormFields.length > 0 && <>
              <Typography variant='subtitle2'>Optional fields</Typography>
              <Divider />
              <MemberOptionalFields />
            </>}
            {showAddress && <Box id='address-fields'>
              <Typography variant='subtitle2'>Address</Typography>
              <Divider />
              <MemberAddressFields />
            </Box>}
            {renderSubmit({ pristine, submitting, errors })}
          </EjentaForm>
        )}
      </Form>
    )
  }

  return renderMemberProfileForm()
}

export default MemberProfileForm
