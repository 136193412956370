import restApi from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getInitialState } from '../utils/baseListUtils'
import { NOTES_PAGE_SIZE } from '../constants/constants'
import { type IProfileStore } from '../interfaces/store/profileStore.interface'

const initialState: IProfileStore = {
  ...getInitialState({
    sortBy: '',
    filters: []
  }),
  openModal: null,
  selectedAlertId: null,
  selectedAlert: null,
  memberId: null,
  isLoading: true,
  activeTab: 0,
  activeModalTab: 0,
  totalCount: 0
}

const getNotesData = createAsyncThunk('profileView/fetchPagingNotes', async (params: { dispatch: any, getState: any }) => {
  const { dispatch, getState } = params
  return dispatch(restApi.actions.getMemberNotes({ id: getState().profileView.memberId, page: getState().profileView.page, per_page: NOTES_PAGE_SIZE })).then()
})

const getStarredNotesData = createAsyncThunk('profileView/fetchStarredNotes', async (params: { dispatch: any, getState: any }) => {
  const { dispatch, getState } = params
  return dispatch(restApi.actions.getMemberStarredNotes({ id: getState().profileView.memberId })).then()
})

export const fetchPagingNotes = () => {
  return (dispatch: any, getState: any) => {
    dispatch(getNotesData({ dispatch, getState }))
  }
}

export const fetchStarredNotes = () => {
  return (dispatch: any, getState: any) => {
    dispatch(getStarredNotesData({ dispatch, getState }))
  }
}

const profileViewSlice = createSlice({
  name: 'profileView',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotesData.fulfilled, (state, _action) => {
      state.isLoading = false
    })
    builder.addCase(getStarredNotesData.fulfilled, (state, _action) => {
      state.isLoading = false
    })

    builder.addCase(getNotesData.rejected, (state, _action) => {
      state.isLoading = false
    })
    builder.addCase(getStarredNotesData.rejected, (state, _action) => {
      state.isLoading = false
    })

    builder.addCase(getNotesData.pending, (state, _action) => {
      state.isLoading = true
    })
    builder.addCase(getStarredNotesData.pending, (state, _action) => {
      state.isLoading = true
    })
  },
  reducers: {
    setLoading: (state: IProfileStore, action) => {
      state.isLoading = action.payload.isLoading
    },
    setMemberId: (state: IProfileStore, action) => {
      state.memberId = action.payload.memberId
    },
    setOpenModal: (state: IProfileStore, action) => {
      state.openModal = action.payload.name
      state.activeModalTab = action.payload.tabLabel
    },
    handleClearModal: (state: IProfileStore, _action) => {
      state.openModal = null
      state.activeModalTab = 0
    },
    setSelectedAlert: (state: IProfileStore, action) => {
      state.selectedAlertId = action.payload
    },
    handleClearSelectedAlert: (state: IProfileStore) => {
      state.selectedAlert = null
      state.selectedAlertId = null
    },
    setSelectedAlertObject: (state: IProfileStore, action) => {
      state.selectedAlertId = action.payload.id
      state.selectedAlert = action.payload
    },
    setActiveTab: (state: IProfileStore, action) => {
      state.activeTab = action.payload.tabIndex
      state.currentPage = []
      state.totalCount = 0
    },
    populateNotes: (state: IProfileStore, action) => {
      state.currentPage = action.payload.notes
      state.totalCount = action.payload.total
    },
    resetTabView: (state: IProfileStore) => {
      state.page = 1
      state.currentPage = []
      state.totalCount = 0
      state.isLoading = true
      state.activeTab = 0
    },
    pagePrev: (state: IProfileStore) => {
      state.page = Math.max(1, state.page - 1)
    },
    pageNext: (state: IProfileStore) => {
      const maxPage = Math.ceil(state.totalCount / NOTES_PAGE_SIZE)
      state.page = Math.min(maxPage, state.page + 1)
    }
  }
})

export const {
  setLoading,
  setMemberId,
  setOpenModal,
  handleClearModal,
  setSelectedAlert,
  handleClearSelectedAlert,
  setSelectedAlertObject,
  setActiveTab,
  populateNotes,
  resetTabView,
  pagePrev,
  pageNext
} = profileViewSlice.actions
export { profileViewSlice }
