import React from 'react'
import classnames from 'classnames'

import { BasicHeader } from './Header'
import { AppFooter } from './Footer'
import { Box } from '@mui/material'

import '../../static/bg2_kpcovid19.jpeg'
import '../../static/bg_uams.jpg'
import '../../static/bg_point32.jpg'
import '../../static/bg_preg.jpg'
import { productConfig } from '../../config/config-service'
import { useLocation } from 'react-router'
import { type IDefaultProps } from '../../interfaces/ejenta.interface'

export default function BasicFrame (props: IDefaultProps): JSX.Element {
  const backgroundImage = productConfig().home.background ?? ''
  const location = useLocation()
  const pagesWithBackground = ['/login', '/forgot', '/reset', '/phone', '/activate', '/signup']

  const shouldDisplayBackgroundImage = (): boolean => {
    return pagesWithBackground.includes(location.pathname)
  }

  const renderBasicFrame = (): JSX.Element => {
    const classes = classnames({
      container: true,
      'container--home': true,
      'container--landing': shouldDisplayBackgroundImage(),
      'container--desktop': true
    })

    return (
      <div className={classes} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <BasicHeader />
        <Box className='content-basic'> {props.children} </Box>
        <AppFooter isFullscreen />
      </div>
    )
  }

  return renderBasicFrame()
}
