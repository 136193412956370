import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { membersViewSlice, MEMBERS_LIST } from '../../../store/membersView'
import { renderFilters } from '../../../utils/baseListUtils'
import { CARE_MANAGER_DEFAULT_OPTIONS } from '../../../constants/constants'
import { productConfig } from '../../../config/config-service'
import { Autocomplete, Box, Button, MenuItem, Select, type SelectChangeEvent, TextField, Typography } from '@mui/material'
import ContentSidebar from '../../layout/ContentSidebar'
import SearchBar from '../../elements/SearchBar'
import { type DefaultFilterState } from '../../../interfaces/product-config.interface'
import { type RootState, type AppDispatch } from '../../..'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    membersCacheStore: state.membersCache,
    membersViewStore: state.membersView,
    userSessionStore: state.userSession
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    membersViewActions: bindActionCreators(membersViewSlice.actions, dispatch)
  }
}

interface IMembersSidebarProps extends PropsFromRedux {
  resetView: () => void
}

function MembersSidebar (props: IMembersSidebarProps): JSX.Element {
  const [careManagerOptions, setCareManagerOptions] = useState([...CARE_MANAGER_DEFAULT_OPTIONS])
  const [searchQuery, setSearchQuery] = useState('')
  const config = productConfig().members.refineSidebar

  useEffect(() => {
    setSearchQuery(props.membersViewStore.searchQuery)
  }, [props.membersViewStore.searchQuery])

  const onSearchChange = (searchQuery: string): void => {
    setSearchQuery(searchQuery)
    props.membersViewActions.setSearchQuery(searchQuery)
  }

  const handleSortChange = (e: SelectChangeEvent): void => {
    props.membersViewActions.setSort(e.target.value)
  }

  const handleCareManagerChange = (e: SelectChangeEvent): void => {
    props.membersViewActions.setCareManagerId(e.target.value)
  }

  const handleLCUChange = (value: string): void => {
    props.membersViewActions.setSelectedLCU(value)
  }

  const handleFilterChange = (filterId: string): void => {
    props.membersViewActions.setFilter(filterId)
  }

  useEffect(() => {
    const fetchedCareManagers = props.userSessionStore.resources?.care_managers ?? []
    setCareManagerOptions([...CARE_MANAGER_DEFAULT_OPTIONS, ...fetchedCareManagers])
  }, [props.userSessionStore.resources])

  const renderResetFilterButton = (): JSX.Element => {
    const { careManagerId, selectedLCU, filters, searchQuery, sortBy } = config.defaultFilterState as DefaultFilterState

    const isDefaultState =
      _.isMatch(props.membersViewStore, { careManagerId, searchQuery, sortBy, selectedLCU }) &&
      _.isEqual(props.membersViewStore.filters, filters)

    return (
      <Box sx={{ width: '100%', textAlign: 'right', minHeight: '24.5px' }}>
        {!isDefaultState && <Button sx={{ padding: 0 }} onClick={() => { onSearchChange(''); props.resetView() }}>Reset Filters</Button>}
      </Box>
    )
  }

  const renderSearchBar = (): JSX.Element => {
    return (
      <section className='list-controls__section' data-testid='list-controls__search-section'>
        <Typography variant='subtitle2'>Search</Typography>
        <SearchBar
          handleChange={onSearchChange}
          placeholder='Member name or study ID'
          value={searchQuery}
          resetSearch={props.membersViewStore.searchQueryReset}
        />
      </section>
    )
  }

  const renderSortSection = (): JSX.Element => {
    const options = MEMBERS_LIST.SORTS.map(s =>
      <MenuItem data-testid='refine-members__sort-by-option' key={s.id} value={s.id}>{s.displayTitle}</MenuItem>
    )

    return (
      <section className='list-controls__section' data-testid='list-controls__sort-section'>
        <Typography variant='subtitle2'>Sort by</Typography>
        <Select
          fullWidth
          size='small'
          color='primary'
          id='refine-members__sort-by'
          value={props.membersViewStore.sortBy}
          onChange={handleSortChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </Select>
      </section>
    )
  }

  const renderLCUSection = (): JSX.Element | null => {
    if (!productConfig().members.refineSidebar.hasLCUFilter) return null
    const lcuOptions = ['All', ...props.membersCacheStore.lcuList]

    return (
      <section className='list-controls__section' data-testid='list-controls__lcu-section'>
        <Typography variant='subtitle2'>LCU</Typography>
        <Autocomplete
          disablePortal
          fullWidth
          size='small'
          id='refine-members__lcu-autocomplete'
          className='list-controls__autocomplete'
          data-testid='refine-members__lcu-autocomplete'
          options={lcuOptions}
          value={props.membersViewStore.selectedLCU}
          onChange={(_e, value) => { handleLCUChange(value ?? '') }}
          onBlur={(e) => {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            if (!e.target['value']) handleLCUChange('All')
          }}
          sx={{ backgroundColor: 'white' }}
          renderInput={(params) => <TextField sx={{ border: 'none!important' }} {...params} />}
        />
      </section>
    )
  }

  const renderCareManagerSection = (): JSX.Element | null => {
    if (!productConfig().members.refineSidebar.hasCareManagerFilter) return null

    const options = careManagerOptions.map(s =>
      <MenuItem key={s.id ?? s.name} value={s.id ?? s.name}>{s.name}</MenuItem>
    )

    return (
      <section className='list-controls__section' data-testid='list-controls__care-manager-section'>
        <Typography variant='subtitle2'>Care Manager</Typography>
        <Select
          fullWidth
          size='small'
          color='primary'
          id='refine-members__care-manager-select'
          data-testid='refine-members__care-manager-select'
          value={props.membersViewStore.careManagerId}
          onChange={handleCareManagerChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </Select>
      </section>
    )
  }

  const renderFilterSection = (): JSX.Element => {
    return renderFilters(
      MEMBERS_LIST.FILTER_GROUPS,
      props.membersViewStore.filters,
      props.membersViewStore.filterCounts,
      handleFilterChange
    )
  }

  const renderRefineMembersSidebar = (): JSX.Element => {
    return (
      <ContentSidebar drawerWidth={285}>
        {renderResetFilterButton()}
        <div className='content-sidebar__module'>
          {renderSearchBar()}
          {renderSortSection()}
          {renderLCUSection()}
          {renderCareManagerSection()}
          {renderFilterSection()}
        </div>
      </ContentSidebar>
    )
  }

  return renderRefineMembersSidebar()
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(MembersSidebar)
