import _ from 'lodash'
import {
  getEmptyChart,
  getBaseChartOptions,
  addPausePlotBands
} from './baseChartUtils'

import {
  baseWeightChartOptions,
  getWeightMeasurementsSeries,
  getWeightAlertsSeries,
  getDeletedWeightMeasurementsSeries,
  get2LbRangeSeries,
  get5LbLastWeekSeries
} from './baseWeightChart'
import { RANGE_VIEWS } from '../member/dateUtils'
import { getXAxisForRange } from './chartUtils'
import { type Options } from 'highcharts'
import { type IChartOptionsParams } from '../../interfaces/chart.interface'

export default function getWeightChartOptions ({
  memberObj, dashboardViewStore, isMobile, showMemberAlerts,
  handleAlertClick, handleDeleteWeight, handleRestoreWeight
}: IChartOptionsParams): Options {
  if (!memberObj.weight) return getEmptyChart()

  let options: Options = _.merge(getBaseChartOptions(), baseWeightChartOptions)
  options.xAxis = getXAxisForRange(memberObj, dashboardViewStore, isMobile ?? false)

  if (handleDeleteWeight) options = getWeightMeasurementsSeries(options, memberObj, dashboardViewStore, handleDeleteWeight)
  if (handleRestoreWeight)options = getDeletedWeightMeasurementsSeries(options, memberObj, dashboardViewStore, handleRestoreWeight)
  options = get2LbRangeSeries(memberObj, options)

  if (dashboardViewStore.rangeZoom === RANGE_VIEWS.WEEK) options = get5LbLastWeekSeries(memberObj, options)
  if (showMemberAlerts && !!handleAlertClick) options = getWeightAlertsSeries(memberObj, options, handleAlertClick)

  options = addPausePlotBands(memberObj.user.pauses ?? [], options, false)
  return options
}
