
import React from 'react'
import moment from 'moment'
import {
  MEMBER_STATUS_FILTERS,
  ALERT_STATUS_FILTERS,
  ALERT_NOTES_FILTERS,
  ALERT_SORTS,
  countHumanNotes, getBPRangeAlertDisplay
} from './baseAlertUtils'
import { productConfig } from '../config/config-service'
import { humanizeAlertLevel, humanizeAlertType } from '../constants/strings'
import { LIFESTYLE_COACH, PREGNANCY_STATUS } from '../constants/memberFilterGroups'
import { type IFilterGroup } from '../interfaces/store/defaultStore.interface'
import { type IAlert } from '../interfaces/alert.interface'
import ProfileCell from '../components/elements/ProfileCell/ProfileCell'
import BloodPressureIcon from '../components/elements/icons/BloodPressureIcon'
import PulseOxIcon from '../components/elements/icons/PulseOxIcon'
import OpacityIcon from '@mui/icons-material/Opacity'
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight'
import AirIcon from '@mui/icons-material/Air'
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import StickyNote2SharpIcon from '@mui/icons-material/StickyNote2Sharp'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import PersonAddSharpIcon from '@mui/icons-material/PersonAddSharp'
import { Circle, Fingerprint } from '@mui/icons-material'
import ChatIcon from '@mui/icons-material/Chat'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import { Tooltip, Box, Typography } from '@mui/material'
import { momentCalendarConfig } from './baseStringUtils'
import { type IMember } from '../interfaces/user.interface'
import { type IGenericTableHeaderColumn } from '../interfaces/ejenta.interface'

const alertConfig = productConfig().alerts.refineSidebar

export const ALERTS_VIEW: { FILTER_GROUPS: IFilterGroup } = {
  FILTER_GROUPS: {
    ...(alertConfig.hasPregnancyStatus && { PREGNANCY_STATUS }),
    ...(alertConfig.hasLifestyleCoach && { LIFESTYLE_COACH }),
    ...(alertConfig.hasLevelSort &&
      {
        ALERT_LEVEL: {
          displayTitle: 'Alert Level',
          filters: alertConfig.levelFilters?.map(levelFilter => {
            return {
              id: `FILTER/${levelFilter.toUpperCase()}`,
              displayTitle: humanizeAlertLevel(levelFilter)
            }
          })
        }
      }),

    MEMBER_STATUS: MEMBER_STATUS_FILTERS,
    ALERT_STATUS: ALERT_STATUS_FILTERS,
    NOTES: ALERT_NOTES_FILTERS,
    ALERT_TYPE: {
      displayTitle: 'Alert type',
      filters: alertConfig.typeFilters?.map(typeFilter => {
        return {
          id: `FILTER/${typeFilter.toUpperCase()}`,
          displayTitle: humanizeAlertType(typeFilter)
        }
      })
    }
  }
}

export const DEFAULT_ALERT_SORT = ALERT_SORTS.SORT_STATUS

export const renderLevel = (level: number, hideText: boolean): JSX.Element => {
  if (!level) return <div />

  let colorText = ''
  let colorClass: 'error' | 'primary' | 'warning' = 'warning'

  if (level === 3.0) {
    colorText = 'Red'
    colorClass = 'error'
  } else if (level === 2.0) {
    colorText = 'Orange'
    colorClass = 'primary'
  } else if (level === 1.0) {
    colorText = 'Yellow'
    colorClass = 'warning'
  }

  return (
    <Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Circle color={colorClass} sx={{ fontSize: 16, mr: 0.5 }} />
      {!hideText && <Typography component='span' variant='body2' fontSize={15}> {colorText}</Typography>}
    </Box>
  )
}

export const getHeaderColumns = (): IGenericTableHeaderColumn[] => {
  const alertsConfig = productConfig().alerts
  const COL_MULTIPLIER = 20
  const columns: IGenericTableHeaderColumn[] = [
    {
      id: 'member',
      label: '',
      minWidth: (alertsConfig.mainPage?.memberNameColumnSize ?? 1) * COL_MULTIPLIER
    }
  ]

  alertsConfig.mainPage?.alertData?.forEach((item, index) => {
    columns.push({
      id: item.header?.split(' ')[0].toLowerCase() ?? index.toString(),
      label: item.header ?? '',
      minWidth: (item.columnSize ?? 1) * COL_MULTIPLIER
    })
  })

  return columns
}

export const renderStatusCol = (statusClass = '', readClass = ''): JSX.Element => {
  return <Box className={`alert-row__status ${statusClass} ${readClass}`} />
}

export const renderProfileCol = (alertObj: IAlert): JSX.Element => {
  const alertDate = moment(alertObj.measurement_timestamp)
  const numPregWeeks = alertDate.diff(moment(alertObj.user?.conception_date as string), 'weeks')
  const numPregWeeksStr = (numPregWeeks === 1) ? '1 week' : `${numPregWeeks} weeks`

  return (
    <Box className='alert-row__member' sx={{ padding: 0.75 }}>
      <ProfileCell user={alertObj.user as IMember} detailStr={numPregWeeksStr} />
    </Box>
  )
}

export const getRowFromConfig = (headerName: string, alert: IAlert): JSX.Element => {
  const isAlertClosed = (alert.status.toUpperCase() === 'CLOSED')
  const isAlertRead = alert.read_status
  const statusClass = isAlertClosed ? 'is-closed' : 'is-open'
  const readClass = (isAlertClosed || isAlertRead) ? 'is-read' : 'is-unread'
  const color = isAlertClosed ? '#b2b2b2' : 'default'

  if (headerName.includes('Level')) return renderLevelCol(alert, false)
  if (headerName.includes('Status')) return renderStatusCol(statusClass, readClass)
  if (headerName.includes('Measurement')) return renderMeasurementCol(alert, color)
  if (headerName.includes('Type')) return renderTypeCol(alert, color)
  if (headerName.includes('Date')) return renderDateCol(alert, color)
  if (headerName.includes('Notes')) return renderNotesCol(alert, color)
  return <></>
}

const renderMeasurementCol = (alertObj: IAlert, color: string): JSX.Element => {
  let measurementValStr: string = ''
  let measurementMetric: string = ''
  const measurementVal = Math.round(alertObj.alert_value * 10) / 10

  switch (alertObj.type) {
    case 'weight_tracking':
    case 'activity_tracking':
    case 'bp_tracking':
    case 'bg_tracking':
    case 'pulse_ox_tracking':
    case 'multi_tracking':
    case 'survey_tracking':
      return (
        <Typography data-testid='alert-row__no-data' variant='body2' fontSize={15}>
          No data for <strong>{moment(alertObj.alert_value).diff(moment(0), 'days')}d</strong>
        </Typography>
      )

    case 'survey_range':
      return (
        <Typography data-testid='alert-row__survey-range' variant='body2' fontSize={15}>
          Total score of <strong>{measurementVal}</strong>
        </Typography>
      )

    case 'bp_range':
      measurementValStr = `${getBPRangeAlertDisplay(alertObj)}`
      break

    case 'activity_relative':
      measurementValStr = `–${Math.abs(measurementVal)}${alertObj.alert_unit}`
      measurementMetric = '7d'
      break

    case 'weight_delta':
      measurementValStr = `+${measurementVal} ${alertObj.alert_unit}`
      measurementMetric = `${alertObj.alert_period / 86400000}d`
      break

    case 'weight_relative':
      measurementValStr = `+ ${measurementVal} ${alertObj.alert_unit}`
      measurementMetric = '7d'
      break

    case 'pregnancy_iom':
      measurementValStr = `+ ${measurementVal} ${alertObj.alert_unit}`
      measurementMetric = 'IOM'
      break

    case 'activity_target':
      measurementValStr = `${measurementVal}${alertObj.alert_unit}`
      measurementMetric = '7d'
      break

    case 'temp_range': // 87.6°F
      measurementValStr = 'Temp and HR'
      measurementMetric = `${alertObj.member_content.includes('elevated') ? 'elevated' : 'detected'}`
      break

    case 'hr_range': // 73.21 bpm // beats/min
    case 'rr_range': // 17.43 bpm // breaths/min
      if (alertObj.metric_type === 'heart_rate') {
        if (alertObj.provider_content.includes('data for ')) {
          measurementValStr = `No data ${alertObj.provider_content.slice(alertObj.provider_content.indexOf('for'))}`
          measurementMetric = ''
        } else measurementValStr = `${measurementVal} ${alertObj.alert_unit}`
      } else if (alertObj.metric_type === 'blood_pressure') {
        measurementValStr = `${getBPRangeAlertDisplay(alertObj)}`
      } else {
        measurementValStr = `${measurementVal} ${alertObj.alert_unit}`
      }
      break

    case 'pulse_ox_range': // 92%
      if (alertObj.metric_type === 'vomit') {
        measurementValStr = 'Vomiting detected'
        measurementMetric = ''
      } else {
        measurementValStr = `${measurementVal}${alertObj.alert_unit}`
      }
      break

    case 'health_status_change': // Health Status: Bed Rest
      measurementValStr = alertObj.provider_content
      break

    case 'program_step_change': // Program step 3
      measurementValStr = `Program step ${alertObj.alert_value}`
      break

    case 'new_chat':
      measurementValStr = 'New chat message'
      break

    case 'bg_range':
      measurementValStr = `${measurementVal} ${alertObj.alert_unit}`
      break

    case 'new_member':
      measurementValStr = 'New member'
      break

    case 'biosticker_tracking':
      if (alertObj.provider_content.includes('data for ')) {
        measurementValStr = `No data ${alertObj.provider_content.slice(alertObj.provider_content.indexOf('for'))}`
        measurementMetric = ''
      } else {
        measurementValStr = `${measurementVal} ${alertObj.alert_unit}`
      }
      break

    default:
      console.warn(`Unexpected alert type: ${alertObj.type}`)
      break
  }

  return (
    <Box data-testid='alert-row__measurement' sx={{ display: 'inline-flex', color }}>
      <Typography data-testid='alert-row__measurement__val' variant='body2' fontSize={15}>{measurementValStr}</Typography>
      <Typography data-testid='alert-row__measurement__metric' variant='body2' fontSize={15} ml={1}>{measurementMetric}</Typography>
    </Box>
  )
}

const renderTypeIcon = (alertObj: IAlert): JSX.Element => {
  switch (alertObj.metric_type) {
    case 'weight':
      return <Tooltip title='Weight' arrow><MonitorWeightIcon /></Tooltip>
    case 'activity':
      return <Tooltip title='Activity' arrow><DirectionsRunIcon /></Tooltip>
    case 'blood_pressure':
      return <Tooltip title='Blood Pressure' arrow><BloodPressureIcon /></Tooltip>
    case 'temperature':
      return <Tooltip title='Temperature' arrow><ThermostatIcon /></Tooltip>
    case 'heart_rate':
      return <Tooltip title='Heart Rate' arrow><MonitorHeartRoundedIcon /></Tooltip>
    case 'respiratory_rate':
      return <Tooltip title='Respiratory Rate' arrow><AirIcon /></Tooltip>
    case 'blood_glucose':
      return <Tooltip title='Blood Glucose' arrow><OpacityIcon /></Tooltip>
    case 'pulse_ox':
      return <Tooltip title='SpO2' arrow><PulseOxIcon /></Tooltip>
    case 'survey_response':
      return <Tooltip title='Survey Out of Range' arrow><FormatListNumberedRoundedIcon /></Tooltip>
    case 'chat':
      return <Tooltip title='Chat' arrow><ChatIcon /></Tooltip>
    case 'multi':
      if (alertObj.type === 'temp_range') return <Tooltip title='Temperature' arrow><ThermostatIcon /></Tooltip>
      else return <Tooltip title='Missing Data' arrow><DoNotDisturbIcon /></Tooltip>
    case 'new_member':
      return <Tooltip title='New Member' arrow><PersonAddSharpIcon /></Tooltip>
    case 'vomit':
      return <Tooltip title='SpO2' arrow><PulseOxIcon /></Tooltip> // TODO Find a new icon for vomit
    case 'biosticker':
      return <Tooltip title='BioSticker'><Fingerprint></Fingerprint></Tooltip>
    default:
      return <></>
  }
}

const renderTypeCol = (alertObj: IAlert, color: string): JSX.Element => {
  return <Typography data-testid='alert-row__type' component='span' variant='body2' fontSize={15} sx={{ color }}> {renderTypeIcon(alertObj)} </Typography>
}

const renderDateCol = (alertObj: IAlert, color: string): JSX.Element => {
  const dateTimeData = moment(alertObj.timestamp).calendar(null, momentCalendarConfig)

  return (<Typography data-testid='alert-row__date' component='span' variant='body2' fontSize={15} sx={{ color }}> {dateTimeData} </Typography>)
}

const renderNotesCol = (alertObj: IAlert, color: string): JSX.Element => {
  const noteData = countHumanNotes(alertObj) ? <StickyNote2SharpIcon /> : '·'

  return (
    <Typography component='span' variant='body2' fontSize={15} sx={{ color }}> {noteData} </Typography>
  )
}

const renderLevelCol = (alertObj: IAlert, hideText: boolean): JSX.Element => {
  const level = renderLevel(alertObj.level as number, hideText)

  return (
    <Typography component='span' variant='body2' fontSize={15} ml='-5px'>  {level} </Typography>
  )
}
