import React, { useEffect } from 'react'
import PDFObject from 'pdfobject'
import consentPdf from 'static/consent-uams-htn.pdf'

// eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-webpack-loader-syntax
const consentHtml = require('html-loader!static/consent-uams-htn.html')
const consentHtmlObj = { __html: consentHtml }

export default function ConsentForm (): JSX.Element {
  const showPdf = !!(PDFObject.supportsPDFs)

  useEffect(() => {
    if (showPdf) {
      PDFObject.embed(consentPdf, '#tos-container')
    }
  }, [showPdf])

  const renderConsentForm = (): JSX.Element => {
    // Fall back to displaying our amazingly crufty HTML consent
    if (!showPdf) {
      return (
        <div id='tos-container' style={{ height: '100%', width: '100%' }} dangerouslySetInnerHTML={consentHtmlObj} />
      )
    }

    return (<div id='tos-container' style={{ height: '100%', width: '100%' }} />)
  }

  return renderConsentForm()
}
