import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const PulseOxIcon = React.forwardRef(function PulseOxIcon (props, ref) {
  return (
    // @ts-expect-error TS doesn't like ... object spread
    <SvgIcon viewBox='0 0 512 512' {...props} ref={ref}>
      <g transform='translate(0,512) scale(.1,-.1)'>
        <path d='M1793 5101 c-17 -5 -46 -17 -65 -27 -94 -55 -636 -806 -1005 -1394 -346 -551 -583 -1055 -685 -1455 -25 -102 -31 -145 -35 -287 -6 -194 5 -309 49 -482 85 -340 242 -614 497 -866 293 -290 661 -467 1070 -515 256 -30 324 -16 389 79 63 94 35 220 -62 280 -41 26 -61 30 -154 37 -143 11 -266 33 -387 70 -583 179 -998 740 -998 1349 0 107 5 142 30 240 105 398 393 956 846 1635 215 322 504 738 548 788 8 10 106 -114 258 -328 349 -489 724 -1101 927 -1511 74 -149 142 -278 152 -287 72 -63 215 -63 285 1 46 41 70 123 57 192 -18 97 -227 513 -418 835 -389 653 -1022 1548 -1146 1619 -47 28 -107 38 -153 27z' />
        <path d='M2835 2323 c-70 -10 -195 -47 -262 -78 -319 -147 -524 -452 -584 -866 -17 -123 -15 -384 5 -504 97 -580 513 -906 1091 -855 173 15 280 43 405 105 276 138 464 439 525 840 19 129 19 400 0 525 -50 328 -183 540 -430 687 -186 111 -347 154 -575 151 -80 0 -158 -3 -175 -5z m355 -402 c190 -59 309 -209 362 -456 8 -39 12 -137 12 -280 0 -209 -1 -225 -27 -320 -39 -140 -114 -291 -177 -354 -89 -89 -210 -131 -374 -131 -281 1 -466 156 -548 460 -21 77 -23 106 -22 305 0 206 2 226 27 319 73 270 226 441 427 475 83 15 244 5 320 -18z' />
        <path d='M4505 1420 c-96 -20 -156 -51 -229 -119 -75 -70 -116 -163 -116 -264 0 -77 26 -122 86 -149 84 -37 165 10 200 115 22 68 68 125 118 148 50 23 68 23 134 4 45 -14 55 -22 73 -57 25 -49 22 -133 -6 -188 -23 -44 -120 -139 -230 -225 -133 -104 -326 -295 -355 -352 -35 -67 -35 -154 -1 -202 43 -60 52 -61 462 -61 411 0 422 2 460 64 26 42 25 119 -1 161 -38 62 -74 68 -330 60 l-225 -7 25 27 c14 15 90 80 170 144 212 169 286 252 323 361 59 175 0 373 -140 467 -101 67 -286 100 -418 73z' />
      </g>
    </SvgIcon>
  )
})

export default PulseOxIcon
