import React, { useState } from 'react'
import Snackbar from '../../elements/Snackbar'
import { type ConnectedProps, connect } from 'react-redux'
import { EjentaForm, renderField, renderInput } from '../../elements/EjentaForm'
import { Typography, Card, CardContent, Button, DialogActions, Alert, Stack } from '@mui/material'
import { Form } from 'react-final-form'
import { mapApiDispatch, mapUserState } from '../../../constants/reduxMappers'

function ChangePasswordForm (props: PropsFromRedux): JSX.Element {
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const handleSubmit = async (fields: any): Promise<any> => {
    const submitPromise = new Promise((resolve, reject) => {
      const apiFields = {
        oldpassword: fields.currentPassword,
        newpassword: fields.newPassword
      }

      props.apiActions.changePassword(
        {
          id: props.user?.id,
          promise: { resolve, reject }
        },
        { body: JSON.stringify(apiFields) }
      )
    })

    return await submitPromise.then(handleSubmitSuccess).catch(data => {
      return data
    })
  }

  const handleSubmitSuccess = (): void => {
    setSnackbarOpen(true)
  }

  const validateChangePasswordForm = (fields: any): Record<string, string> => {
    const errors: any = {}
    if ((fields.newPassword || fields.newPasswordConfirm) && !fields.currentPassword) errors.currentPassword = 'Must enter current password.'

    if (fields.currentPassword && !fields.newPassword) errors.newPassword = 'Must enter a new password.'
    else if (fields.currentPassword && fields.currentPassword === fields.newPassword) errors.newPassword = 'Current and new passwords must be different.'

    if (fields.newPassword !== fields.newPasswordConfirm) errors.newPasswordConfirm = 'New password fields must match.'

    return errors
  }

  const renderSubmitButton = ({ pristine, submitting, errors }): JSX.Element => {
    const hasErrors = Object.keys(errors)?.length > 0
    if (submitting) return (<Button variant='contained' disabled> Saving... </Button>)
    return (<Button variant='contained' type='submit' disabled={pristine || hasErrors}> Change password </Button>)
  }

  const renderChangePasswordForm = (): JSX.Element => {
    const currentPasswordField = {
      name: 'currentPassword',
      type: 'password',
      label: 'Current password',
      autoComplete: 'off',
      component: renderInput
    }

    const newPasswordField = {
      name: 'newPassword',
      type: 'password',
      label: 'New password',
      autoComplete: 'off',
      component: renderInput
    }

    const newPasswordConfirmField = {
      name: 'newPasswordConfirm',
      type: 'password',
      label: 'Confirm new password',
      autoComplete: 'off',
      component: renderInput
    }

    return (
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='h6' fontSize='1.1rem' sx={{ mb: 3 }}>Change Password</Typography>
          <Snackbar
            isOpen={snackbarOpen}
            dataKey='change-password__success__snackbar'
            handleClose={() => { setSnackbarOpen(false) }}
            severity='success'
            message='Password updated successfully'
          />

          <Form
            onSubmit={handleSubmit}
            initialValues={() => {
              return {
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: ''
              }
            }}
            validate={values => validateChangePasswordForm(values)}
          >
            {({ handleSubmit, pristine, submitting, submitError, errors }) => (
              <EjentaForm onSubmit={handleSubmit}>
                {submitError && <Alert severity='error'>{submitError}</Alert>}
                <Stack spacing={2}>
                  {renderField({}, currentPasswordField)}
                  {renderField({}, newPasswordField)}
                  {renderField({}, newPasswordConfirmField)}
                </Stack>
                <DialogActions>
                  {renderSubmitButton({ pristine, submitting, errors })}
                </DialogActions>
              </EjentaForm>
            )}
          </Form>
        </CardContent>
      </Card>
    )
  }

  return renderChangePasswordForm()
}

const connector = connect(mapUserState, mapApiDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ChangePasswordForm)
