import _ from 'lodash'
import { ALERT_URL_FILTERS, DEFAULT_ALERT_FILTERS } from '../utils/baseAlertUtils'
import {
  ALERTS_VIEW,
  DEFAULT_ALERT_SORT
} from '../utils/alertUtils'
import { getInitialState } from '../utils/baseListUtils'
import restApi from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ALERTS_PAGE_SIZE } from '../constants/constants'
import { type ICarePlanItem } from '../interfaces/careplan.interface'
import { type IAlertsStore } from '../interfaces/store/alertsStore.interface'
import { type ISidebarFilters, type ISidebarFilter } from '../interfaces/ejenta.interface'

const newChatPersistentFilter = 'FILTER/NEW_CHAT'
// these are used to make the multi_tracking filter able to get old-style tracking alerts in addition to multi_tracking alerts:
const oldTrackingAlertTypes = ['weight_tracking', 'bp_tracking', 'survey_tracking', 'bg_tracking']
const initialState: IAlertsStore = {
  ...getInitialState({
    sortBy: DEFAULT_ALERT_SORT,
    filters: DEFAULT_ALERT_FILTERS
  }),
  totalFilterOptions: 0,
  loadingAlerts: true,
  total: 0,
  careManagerId: 'OPTION_ALL',
  careManagerFilters: [],
  carePlanFilters: [],
  urlFilters: {}
}

const getData = createAsyncThunk('alertsView/fetchPagingAlerts', async (params: { dispatch: any, getState: any }) => {
  const { dispatch, getState } = params
  const { searchQuery, sortBy, filters, urlFilters, page, careManagerFilters } = getState().alertsView
  const urlParams = {
    per_page: ALERTS_PAGE_SIZE,
    page,
    search_query: searchQuery,
    alert_sorting: sortBy.toLowerCase(),
    care_manager_filters: [] as any []
  }

  // Loop through all possible filters
  _.forOwn(ALERTS_VIEW.FILTER_GROUPS, (value: ISidebarFilters | Record<string, any>, filterType: string): void => {
    const availableFilters: string [] = value.filters.map((f: ISidebarFilter) => {
      return f.id
    })
    let enabledFilters: string [] = _.intersection(availableFilters, filters)

    enabledFilters = _.map(enabledFilters, (filter: string) => {
      // e.g. FILTER/ACTIVE => active
      return filter.replace('FILTER/', '').toLowerCase()
    })

    if (filterType === 'ALERT_TYPE' && enabledFilters.includes('multi_tracking')) {
      enabledFilters = enabledFilters.concat(oldTrackingAlertTypes)
    }

    urlParams[`${filterType.toLowerCase()}_filters`] = enabledFilters
  })

  if (careManagerFilters.length) urlParams.care_manager_filters = careManagerFilters

  const additionalUrlParams = _.mapKeys(urlFilters, (value, key) => {
    const transformations = {
      user: 'user',
      start_date: 'start',
      end_date: 'end',
      type: 'strict_alert_type_filter'
    }
    return transformations[key]
  })

  _.merge(urlParams, additionalUrlParams)

  await dispatch(restApi.actions.clinicianAlerts(urlParams))
})

export const fetchPagingAlerts = () => {
  return (dispatch, getState) => {
    dispatch(getData({ dispatch, getState }))
  }
}

const alertsViewSlice = createSlice({
  name: 'alertsView',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state: IAlertsStore, action) => {
      state.loadingAlerts = false
    })
    builder.addCase(getData.rejected, (state: IAlertsStore, action) => {
      state.loadingAlerts = false
    })
    builder.addCase(getData.pending, (state: IAlertsStore, action) => {
      state.loadingAlerts = true
    })
  },
  reducers: {
    setLoadingAlerts: (state: IAlertsStore, action) => {
      state.loadingAlerts = action.payload
    },
    resetView: (state: IAlertsStore, action) => {
      state.careManagerFilters = initialState.careManagerFilters
      state.sortBy = initialState.sortBy
      state.filters = [...initialState.filters].sort()
      state.careManagerId = initialState.careManagerId
      state.searchQuery = ''
      state.searchQueryReset = action.payload ?? true
      state.urlFilters = initialState.urlFilters
      state.page = 1
    },
    setSearchQuery: (state: IAlertsStore, action) => {
      state.searchQuery = action.payload
      state.searchQueryReset = false
      state.page = 1
    },
    setSort: (state: IAlertsStore, action) => {
      state.sortBy = action.payload
      state.page = 1
      state.searchQueryReset = false
    },
    setFilter: (state: IAlertsStore, action) => {
      if (state.filters.includes(action.payload)) {
        state.filters = _.without(state.filters, action.payload)
        state.page = 1
      } else {
        state.filters = state.filters.concat(action.payload).sort()
        state.page = 1
      }

      state.searchQueryReset = false
    },
    setTotalFilterOptions: (state: IAlertsStore, action) => {
      state.totalFilterOptions = action.payload
    },
    setCarePlanFilter: (state: IAlertsStore, action) => {
      const carePlanItems = action.payload
      const prefix = 'FILTER/'
      const presetFilters: string [] = [newChatPersistentFilter]
      const hasChatFilter = state.filters?.includes(newChatPersistentFilter)
      let enabledCount = 0

      carePlanItems?.forEach((item: ICarePlanItem) => {
        if (!item.disabled && item.notify?.includes('clinicians')) {
          enabledCount++
          const filterName = `${prefix}${item.type.toUpperCase()}`
          if (!presetFilters.includes(filterName)) presetFilters.push(filterName)
        }
      })

      // if all are enabled, or none are disabled, don't bother setting a filter
      if (presetFilters.length > 0 && enabledCount !== carePlanItems?.length) {
        // do nothing at all if the available filters are all enabled
        if (presetFilters.length - 1 < state.totalFilterOptions) {
          state.filters = !hasChatFilter ? state.filters.concat(presetFilters).sort() : state.filters
          state.carePlanFilters = presetFilters
          state.page = 1
        }
      }
    },
    setPage: (state: IAlertsStore, action) => {
      const maxPage = Math.ceil(state.total / ALERTS_PAGE_SIZE)

      if (action.payload < 1) state.page = 1
      else if (action.payload > maxPage) state.page = maxPage
      else state.page = action.payload
    },
    pagePrev: (state) => {
      state.page = Math.max(1, state.page - 1)
    },
    pageNext: (state) => {
      const maxPage = Math.ceil(state.total / ALERTS_PAGE_SIZE)
      state.page = Math.min(maxPage, state.page + 1)
    },
    setCareManagerId: (state: IAlertsStore, action) => {
      const careManagerId = action.payload
      state.careManagerId = careManagerId

      let careManagerFilters = [careManagerId]
      if (careManagerId === 'OPTION_ALL' || careManagerId === null) careManagerFilters = []

      state.careManagerFilters = careManagerFilters
      state.searchQueryReset = false
    },
    setUrlFilters: (state: IAlertsStore, action) => {
      const applyFilter = (nFilter: string): void => {
        if (state.filters.includes(nFilter)) {
          state.filters = _.without(state.filters, nFilter)
        } else {
          state.filters = state.filters.concat(nFilter).sort()
        }
      }

      const query = Object.fromEntries(new URLSearchParams(action.payload).entries())

      if (query.type) {
        state.filters = [] // if type changes, clear the filters first

        // these fields use a lowercase name (workaround without a reverse lookup)
        const filter = `FILTER/${query.type.toUpperCase()}`
        applyFilter(filter)
      }

      // set checkboxes from the URL using the actual filter name
      if (query.filters) {
        // takes comma separated list of filters
        for (const filter of query.filters.split(',')) {
          applyFilter(filter)
        }
      }

      state.urlFilters = _.pick(query, _.values(ALERT_URL_FILTERS))
      state.page = 1
    },
    populateAlerts: (state: IAlertsStore, action) => {
      const filterCounts = {}

      _.forOwn(ALERTS_VIEW.FILTER_GROUPS, (value, filterType) => {
        _.forOwn(action.payload.filters[filterType.toLowerCase()], (count, key) => {
          filterCounts[`FILTER/${key.toUpperCase()}`] = count
        })
      })

      state.currentPage = action.payload.alerts
      state.total = action.payload.total
      state.filterCounts = filterCounts
    },
    setSelectedAlert: (state: IAlertsStore, action) => {
      state.selectedItemId = action.payload
    },
    setLocationKey: (state: IAlertsStore, action) => {
      state.prevLocationKey = action.payload
    },
    updateAlert: (state: IAlertsStore, action) => {
      if (state.currentPage) {
        // Find original alert in alert array
        const itemIndex = _.findIndex(state.currentPage, item => item.id === action.payload.alert.id)

        if (itemIndex >= 0) {
          const updatedItem = { ...action.payload.alert } // Define new alert
          state.currentPage[itemIndex] = updatedItem // Update alert array
        }
      }
    }
  }
})

export { ALERTS_VIEW, alertsViewSlice }
export const {
  setLoadingAlerts,
  resetView,
  setSearchQuery,
  setSort,
  setFilter,
  setCareManagerId,
  setCarePlanFilter,
  pagePrev,
  pageNext,
  setPage,
  setUrlFilters,
  populateAlerts,
  setSelectedAlert,
  setLocationKey,
  updateAlert
} = alertsViewSlice.actions
