import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import {
  getBaseChartOptions,
  getBaseSeriesOptions,
  getPointDateString,
  addPausePlotBands
} from './baseChartUtils'
import { getXAxisForRange, getColumnWidth, getDefaultChartOptions } from './chartUtils'
import { type IActivityGoal, type IActivityData } from '../../interfaces/activity-summary.interface'
import { type IPoint, type IMarker, type IChartOptionsParams } from '../../interfaces/chart.interface'
import { type SeriesLineOptions, type SeriesColumnOptions, type Options, type XAxisOptions, type PointOptionsObject, type SeriesScatterOptions } from 'highcharts'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'
import { isAlertOpen } from '../../constants/constants'
import { type IAlertClick } from '../../interfaces/alert.interface'

const baseActivityOptions = {
  ...getDefaultChartOptions('activity-chart'),
  tooltip: {
    headerFormat: '',
    useHTML: true,
    shared: true,
    stickOnContact: true,
    borderWidth: 0
  }
}

const compareByDate = (a: IActivityGoal, b: IActivityGoal): number => {
  if (a.set_date < b.set_date) return -1
  if (a.set_date > b.set_date) return 1
  return 0
}

function getActivityMeasurementsSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): Options {
  const newOptions = _.cloneDeep(options)
  const activityData = memberObj.activity ? [...memberObj.activity.data] : []

  const activityMeasurements = activityData.reverse().map((m: IActivityData): IPoint => {
    return {
      x: +moment(m.date),
      y: Math.round((m.intensities.moderate.value + m.intensities.intense.value) / 60),
      dataSource: m.source,
      description: `${m.steps.toLocaleString()} steps`
    }
  })

  const activityMeasurementSeries: SeriesColumnOptions = {
    ...getBaseSeriesOptions(),
    name: 'Moderate activity',
    type: 'column',
    data: activityMeasurements,
    zIndex: 1,
    className: 'activity-series',
    color: '#8DC63F',

    tooltip: {
      headerFormat: '',
      pointFormatter: function format (): string {
        const point = this as any
        const header: string = getPointDateString(moment(point.x), false)
        return `
          <strong>${header}</strong><br/>
          ${point.y.toLocaleString() as string} minutes<br/>
          ${point.description as string} <br />
          <small>Source: ${point.dataSource as string ?? 'Unknown'}</small>
          `
      }
    },

    pointWidth: getColumnWidth(dashboardViewStore, false)
  }

  newOptions.series?.push(activityMeasurementSeries)
  return newOptions
}

function getActivityGoalSeries (options: Options, memberObj: IMemberSummary): Options {
  const dailyActivityGoal = memberObj.careplan?.find(item => item.type === 'activity_target' && item.subtype === 'daily')
  if (!dailyActivityGoal || dailyActivityGoal.disabled) return options

  const newOptions = _.cloneDeep(options)
  if (!memberObj.activity) return newOptions

  const dailyActivityGoals = [...memberObj.activity.information.periods[0].daily_activity_goals]
  const activityGoals = dailyActivityGoals.sort(compareByDate).reverse()

  const activityGoalData = activityGoals.reduce((acc, goal) => {
    // Ignore activity goals that were active for less than an hour
    if ((goal.end_date - goal.set_date) < 1000 * 60 * 60) return acc
    acc[acc.length - 1].push(goal.target.value)
    acc.push([+moment(goal.end_date), goal.target.value])
    acc.push([+moment(goal.end_date) + 1])

    return acc
  }, [[+moment().subtract(1, 'year')]])

  activityGoalData.pop() // Remove the last (incomplete) data point

  const activityGoalSeries: SeriesLineOptions = {
    ...getBaseSeriesOptions(),
    name: 'Daily activity goal',
    type: 'line',
    data: activityGoalData,
    dashStyle: 'Dash',
    color: '#D7DF23',

    enableMouseTracking: false
  }

  newOptions.series?.push(activityGoalSeries)
  return newOptions
}

function getActivityAlertsSeries (options: Options, memberObj: IMemberSummary, handleAlertClick: IAlertClick, getAlertUnits: (x: IActivityData) => number): Options {
  // Grab activity alerts
  if (!memberObj.alerts?.length) return options
  const activityAlerts = _.filter(memberObj.alerts, a => a.metric_type === 'activity')
  if (!activityAlerts.length) return options

  const alertsData = activityAlerts.map((alertObj): PointOptionsObject => {
    // Find the activity measurement corresponding to this alert (if it exists)
    const activityMeasurement = memberObj.activity?.data.find(
      d => moment(d.date).isSame(moment(alertObj.measurement_date), 'day')
    )

    // Get the appropriate base y-value for the alert
    let alertY = 0
    if (getAlertUnits && activityMeasurement) alertY += getAlertUnits(activityMeasurement)

    // Get marker styling
    const marker: IMarker = {}
    if (alertObj.type === 'activity_tracking') marker.symbol = 'circle'

    const isOpen = isAlertOpen(alertObj)
    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'activity_tracking'
    })

    const point: PointOptionsObject = {
      name: alertObj.provider_content,
      x: +moment(alertObj.measurement_date).startOf('day'),
      y: alertY + 10,
      marker,
      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) {
            handleAlertClick(alertObj)
          }
        }
      }
    }

    return point
  })

  type scatterType = 'scatter' // weird requirement from highcharts, else type fails
  const alertsSeries: SeriesScatterOptions = _.extend({
    name: 'Activity alerts',
    type: 'scatter' as scatterType,
    className: 'series-activityAlerts',
    lineWidth: 0,
    color: '#EF6C00',
    zIndex: 10, // Places the alert triangles above everything else so that they are viewable/clickable
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<strong>Activity alert</strong>',
      pointFormat: '<div class="activity-alert">{point.name}</div>'
    },
    data: alertsData
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series?.push(alertsSeries)

  return newOptions
}

export default function getActivityChartOptions ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}: IChartOptionsParams): Options {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, false) as XAxisOptions }

  let options: Options = _.merge(
    getBaseChartOptions(),
    baseActivityOptions,
    xAxisOptions
  )

  options = getActivityMeasurementsSeries(options, memberObj, dashboardViewStore)
  options = getActivityGoalSeries(options, memberObj)
  if (showMemberAlerts && !!handleAlertClick) {
    const getAlertUnits = (measurement: IActivityData): number => {
      let result = 0
      result += measurement.intensities.moderate.value / 60
      result += measurement.intensities.intense.value / 60
      return result
    }

    options = getActivityAlertsSeries(options, memberObj, handleAlertClick, getAlertUnits)
  }

  options = addPausePlotBands(memberObj.user.pauses ?? [], options, true)
  return options
}
