import { type Options } from 'highcharts'
import _ from 'lodash'
import { type IChartOptionsParams } from '../../interfaces/chart.interface'
import {
  getBaseChartOptions,
  addPausePlotBands,
  getSimpleContinuousSeries,
  getSimpleBioStickerAlertsSeries,
  getEmptyChart
} from './baseChartUtils'
import {
  getDefaultChartOptions,
  getXAxisForRange
} from './chartUtils'

export default function getHeartRateChartOptions ({ memberObj, dashboardViewStore, showMemberAlerts, handleAlertClick }: IChartOptionsParams): Options {
  if (!memberObj.heart_rate) return getEmptyChart()

  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, false) }
  let options: Options = _.merge(
    getBaseChartOptions(),
    getDefaultChartOptions('heart_rate-chart'),
    xAxisOptions
  )

  options = getSimpleContinuousSeries(options, memberObj, 'heart_rate', 'Resting heart rate',
    ` ${memberObj.heart_rate.information.units.heart_rate as string}`)

  if (showMemberAlerts && !!handleAlertClick) {
    options = getSimpleBioStickerAlertsSeries(memberObj, options, handleAlertClick, 'heart_rate', 'Heart rate', 'hr_range')
  }

  options = addPausePlotBands(memberObj.user.pauses ?? [], options, true)

  return options
}
