import React from 'react'
import moment, { type MomentInput } from 'moment'
import { type ConnectedProps, connect } from 'react-redux'
import { Box } from '@mui/material'
import DateRangeController from '../../../elements/DateRangeController'
import { mapAnalyticsDispatch, mapAnalyticsState } from '../../../../constants/reduxMappers'

function AnalyticsDateRange (props: PropsFromRedux): JSX.Element {
  const handleFetchAnalyticsDateRange = (selectedStartDate: MomentInput, selectedEndDate: MomentInput): void => {
    const startDate = moment(selectedStartDate)
    const endDate = moment(selectedEndDate)

    if (startDate.isSame(moment(props.analyticsStore?.dateRangeStart), 'day') &&
      endDate.isSame(moment(props.analyticsStore?.dateRangeEnd), 'day')) {
      return
    }

    props.analyticsActions.setDateRange({ startDate: startDate.valueOf(), endDate: endDate.valueOf() })
    props.apiActions.getAnalyticsOverview({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    })
  }

  const renderAxDateRange = (): JSX.Element => {
    return (
      <Box data-testid='analytics-date-range'>
        <DateRangeController
          onRangeSelect={handleFetchAnalyticsDateRange}
          startDate={moment(props.analyticsStore?.dateRangeStart)}
          endDate={moment(props.analyticsStore?.dateRangeEnd)}
        />
      </Box>
    )
  }

  return renderAxDateRange()
}

const connector = connect(mapAnalyticsState, mapAnalyticsDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AnalyticsDateRange)
