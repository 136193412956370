import React from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import { productConfig } from '../../../config/config-service'

function HelpContactSection (): JSX.Element {
  const helpConfig = productConfig().help.contact

  const renderEjentaContact = (): JSX.Element => {
    return (
      <>
        <Typography>
          If you have any questions about using Ejenta or would like to provide feedback about the
          service, please contact us at  <Link underline='none' fontWeight={500} color='primary' href='mailto:help@ejenta.com'>help@ejenta.com</Link>.
        </Typography>

        <Typography mt={2} variant='subtitle1'>
          <strong>Important note:</strong> We cannot answer medical questions. If you have a
          question about your health or treatment, contact your care team.
        </Typography>
      </>
    )
  }

  const renderProductContact = (): JSX.Element => {
    return (
      <Typography>
        {helpConfig?.productContactDetail} <strong>{helpConfig?.productContactNumber}</strong>.
      </Typography>
    )
  }

  return (
    <Card data-testid='help-page__contact' sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant='h6' fontSize='1.1rem' mb={2}>Contact us</Typography>
        {helpConfig?.hasEjentaContact && renderEjentaContact()}
        {!!helpConfig?.productContactDetail && renderProductContact()}
      </CardContent>
      {helpConfig?.hasEjentaContact &&
        <CardActions sx={{ float: 'right' }}>
          <Button variant='contained' href='mailto:help@ejenta.com'>Contact us</Button>
        </CardActions>}
    </Card>
  )
}

export default HelpContactSection
