import React from 'react'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
export default class DailyActivityTargetItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'activity_target'
    this.subtype = 'daily'
  }

  getValueInput (): JSX.Element {
    return this.getInputElement(
      5, 60, 5,
      CAREPLAN_VALUE_TYPES.VALUE.get(),
      CAREPLAN_VALUE_TYPES.VALUE.set()
    )
  }

  renderGoal (): JSX.Element {
    return (<div> Moderate activity daily for {this.getValueInput()} minutes </div>)
  }

  renderCondensed (): JSX.Element {
    return <></>
  }
}
