
import _ from 'lodash'
import { type IAlertTriggerModifier, type IAlertTrigger, type ICarePlanItem } from '../interfaces/careplan.interface'

const getAlertTriggerByType = (alertTriggers: IAlertTrigger [], alertTriggerType: string): IAlertTrigger => {
  return alertTriggers.find((item: IAlertTrigger) => item.type === alertTriggerType) ?? { threshold: 0 }
}

const generateTriggerModifiers = (alertTriggerType: string): IAlertTriggerModifier => {
  return {
    get: (transform = _.identity) => item => {
      const threshold = getAlertTriggerByType(item.alert_triggers, alertTriggerType)?.threshold
      return threshold ? transform(threshold > -1 ? threshold : 0) : 0
    },
    set: (transform = _.identity) =>
      (item: ICarePlanItem, value?: number) => {
        getAlertTriggerByType(item.alert_triggers, alertTriggerType).threshold = transform(value)
        return item
      }
  }
}

const generateCarePlanModifiers = (field: string): IAlertTriggerModifier => {
  return {
    get: (transform = _.identity) => item => transform(item[field]),
    set: (transform = _.identity) => (item: ICarePlanItem, value?: number) => _.set(item, field, transform(value))
  }
}

/*
 * Define the different properties we need to access/update in care plan objects
 */
export const CAREPLAN_VALUE_TYPES = {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  THRESHOLD: {
    get: (transform = _.identity) => (item: ICarePlanItem) => transform(item.alert_triggers[0].threshold),
    set: (transform = _.identity) =>
      (item: ICarePlanItem, value: number) => _.set(item, ['alert_triggers', '0', 'threshold'], transform(value))
  } as IAlertTriggerModifier,

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  VALUE_DECLINE: { // display a positive value in the dropdown; post a negative value to the server
    get: (transform = _.identity) => item => transform(Math.abs(item.value)),
    set: (transform = _.identity) => (item, value) => _.set(item, 'value', transform(-Math.abs(value as number)))
  } as IAlertTriggerModifier,

  TIME: generateCarePlanModifiers('time'),
  VALUE: generateCarePlanModifiers('value'),
  LEVEL: generateCarePlanModifiers('level'),

  SYSTOLIC_MIN: generateTriggerModifiers('systolic_min'),
  SYSTOLIC_MAX: generateTriggerModifiers('systolic_max'),
  DIASTOLIC_MIN: generateTriggerModifiers('diastolic_min'),
  DIASTOLIC_MAX: generateTriggerModifiers('diastolic_max'),
  HEART_RATE_MIN: generateTriggerModifiers('heart_rate_min'),
  HEART_RATE_MAX: generateTriggerModifiers('heart_rate_max'),
  MIN_COUNT: generateTriggerModifiers('min_count'),
  BLOOD_GLUCOSE_MIN: generateTriggerModifiers('blood_glucose_min'),
  BLOOD_GLUCOSE_MAX: generateTriggerModifiers('blood_glucose_max'),
  SPO2_MIN: generateTriggerModifiers('oxygen_saturation_min'),
  TEMPERATURE_MIN: generateTriggerModifiers('temperature_min'),
  TEMPERATURE_MAX: generateTriggerModifiers('temperature_max'),
  TEMPERATURE_DELTA: generateTriggerModifiers('temperature_delta'),
  RESPIRATORY_RATE_MIN: generateTriggerModifiers('respiratory_rate_min'),
  RESPIRATORY_RATE_MAX: generateTriggerModifiers('respiratory_rate_max'),
  YELLOW_SURVEY_MIN: generateTriggerModifiers('default_yellow_score'),
  ORANGE_SURVEY_MIN: generateTriggerModifiers('default_orange_score'),
  MULTITRACKING_WEIGHT: generateTriggerModifiers('weight'),
  MULTITRACKING_BLOOD_PRESSURE: generateTriggerModifiers('blood_pressure'),
  MULTITRACKING_BLOOD_GLUCOSE: generateTriggerModifiers('blood_glucose'),
  MULTITRACKING_SURVEY: generateTriggerModifiers('survey_response'),
  MIN_TIME_BETWEEN: generateTriggerModifiers('min_time_between'),
  STOP_AFTER: generateTriggerModifiers('stop_after')
}
