import React from 'react'
import HeaderLogo from './HeaderLogo'
import HeaderLogoSecondary from './HeaderLogoSecondary'
import UserNav from '../controllers/UserNav'
import SettingsMenuItem from '../controllers/UserNav/SettingsMenuItem'
import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { useLocation } from 'react-router'

const BasicHeader = (): JSX.Element => {
  return (
    <Box className='header header--basic no-print'>
      <HeaderLogo forDesktop />
      <HeaderLogoSecondary />
    </Box>
  )
}

const AppHeader = (): JSX.Element => {
  const location = useLocation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <AppBar
      className='no-print'
      position='fixed'
      sx={{
        backgroundColor: '#fcfcfc',
        zIndex: theme => theme.zIndex.drawer + 1,
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 6%)'
      }}
    >
      <Box className='header--responsive'>
        <Toolbar disableGutters sx={{ mt: 0.5, mb: 0.5, pb: 0.5 }}>
          {!isDesktop && <UserNav forDesktop={isDesktop} />} {/* Menu first if mobile */}

          <HeaderLogo
            forDesktop={isDesktop}
            isAnalyticsActive={location.pathname === '/analytics'}
          />

          {isDesktop && <UserNav forDesktop={isDesktop} />} {/* Menu second if desktop */}
          <SettingsMenuItem />
        </Toolbar>
      </Box>
    </AppBar>
  )
}

export { BasicHeader, AppHeader }
