
import React from 'react'
import moment from 'moment'
import { getActivityProfileStats } from '../../../utils/member/alertDetailUtils'
import { momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

export default function ActivityCell (props: { memberObj: IMemberSummary }): JSX.Element {
  if (!props.memberObj.user.has_activity && !props.memberObj.activity?.latest_measurement) {
    const message = !props.memberObj.user.has_fitbit ? 'Device not set up' : 'No activity data'
    return <EmptyCell message={message} />
  }

  const { activityProgress } = getActivityProfileStats(props.memberObj)
  const lastActivityMeasurement = moment(props.memberObj.activity?.latest_measurement.date)

  return (
    <Grid container>
      <Grid item xs={12} className='member-row__latest'> {activityProgress} </Grid>
      <Grid item xs={12}> <aside>{lastActivityMeasurement.calendar(null, momentCalendarDateConfig)}</aside> </Grid>
    </Grid>
  )
}
