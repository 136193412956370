import { Button, Grid, Tooltip, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { type IAnalyticsSuspension, type IAnalyticsUser } from '../../../../interfaces/store/analyticsStore.interface'
import { ANALYTICS_DRILLDOWN } from '../../../../store/analytics'

interface ITotalUserCounts {
  onboarded: IAnalyticsUser[]
  not_onboarded: IAnalyticsUser[]
  archived: IAnalyticsUser[]
}

interface IPeriodUserCounts {
  new: IAnalyticsUser[]
  archived: IAnalyticsUser[]
  active: IAnalyticsUser[]
  enrolled_no_data: IAnalyticsUser[]
}

const hasAnyMeasurements = (user: IAnalyticsUser): boolean => {
  const userMeasurements = {
    activity: user.activity?.measurement_count > 0,
    sleep: user.sleep?.measurement_count > 0,
    weight: user.weight?.measurement_count > 0
  }

  return userMeasurements.activity || userMeasurements.sleep || userMeasurements.weight
}

function filterUsers (users: IAnalyticsUser[]): ITotalUserCounts {
  return {
    onboarded: _.filter(users, u => !u.info.not_onboarded && !u.info.archived),
    not_onboarded: _.filter(users, u => u.info.not_onboarded && !u.info.archived), // pending enrollment
    archived: _.filter(users, u => u.info.archived)
  }
}

function filterPeriodUsers (users: IAnalyticsUser[]): IPeriodUserCounts {
  return {
    new: _.filter(users, u => u.info.onboarded_in_range),
    archived: _.filter(users, u => u.info.archived_in_range),
    active: _.filter(users, u => hasAnyMeasurements(u)),
    enrolled_no_data: _.filter(users, u => !hasAnyMeasurements(u) && !u.info.not_onboarded && !u.info.archived)
  }
}

interface IEnrollmentProps {
  forPeriod?: boolean
  users: IAnalyticsUser[]
  rangeSuspensions: IAnalyticsSuspension[]
  currentSuspensions: IAnalyticsSuspension[]
  analyticsActions: any
}

export default function Enrollment (props: IEnrollmentProps): JSX.Element {
  const handleUserDrilldown = (title: string, members: IAnalyticsUser[]): void => {
    props.analyticsActions.setDrilldown({
      title,
      type: ANALYTICS_DRILLDOWN.MEMBERS,
      items: members
    })
  }

  const handleSuspensionDrilldown = (title: string, suspensions: IAnalyticsSuspension[]): void => {
    props.analyticsActions.setDrilldown({
      title,
      type: ANALYTICS_DRILLDOWN.SUSPENSIONS,
      items: suspensions
    })
  }

  const renderTotalEnrollment = (): JSX.Element => {
    const filteredUsers = filterUsers(props.users)
    const uniqueSuspensions = props.currentSuspensions.filter((obj, index) => {
      return index === props.currentSuspensions.findIndex(o => obj.user_id === o.user_id)
    }).length

    return (
      <Grid container spacing={1}>
        <Grid item sm={3}>
          <Tooltip title='Members who have not yet received all devices'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__small-button' onClick={() => { handleUserDrilldown('Pending members', filteredUsers.not_onboarded) }}>
              <Typography fontSize='1.5rem'> {filteredUsers.not_onboarded.length} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  pending enrollment </Typography>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={3}>
          <Tooltip title='Members who have been temporarily suspended from the program e.g. bedrest, vacation'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__small-button' onClick={() => { handleSuspensionDrilldown('Currently suspended members', props.currentSuspensions) }}>
              <Typography fontSize='1.5rem'> {uniqueSuspensions} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  suspended members </Typography>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={3}>
          <Tooltip title='Members who have been permanently removed from the program'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__small-button' onClick={() => { handleUserDrilldown('Archived members', filteredUsers.archived) }}>
              <Typography fontSize='1.5rem'> {filteredUsers.archived.length} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  archived members </Typography>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={3}>
          <Tooltip title='Members who have received all devices'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__small-button' onClick={() => { handleUserDrilldown('Onboarded members', filteredUsers.onboarded) }}>
              <Typography fontSize='1.5rem'> {filteredUsers.onboarded.length} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  onboarded members </Typography>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  const renderPeriodEnrollment = (): JSX.Element => {
    const filteredUsers = filterPeriodUsers(props.users)
    const uniqueSuspensions = props.rangeSuspensions.filter((obj, index) => {
      return index === props.rangeSuspensions.findIndex(o => obj.user_id === o.user_id)
    }).length

    return (
      <Grid container spacing={3}>
        <Grid item sm={2}>
          <Tooltip title='Members added to the program this period'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__button' onClick={() => { handleUserDrilldown('New members', filteredUsers.new) }}>
              <Typography fontSize='2rem'> {filteredUsers.new.length} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  new this period </Typography>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={2}>
          <Tooltip title='Members who have all devices and have submitted at least 1 measurement this period'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__button' onClick={() => { handleUserDrilldown('Active members this period', filteredUsers.active) }}>
              <Typography fontSize='1.5rem'> {filteredUsers.active.length} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  active members </Typography>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={2}>
          <Tooltip title='Members who have all devices but have not submitted any measurements this period'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__button' onClick={() => { handleUserDrilldown('Enrolled members, but no data this period', filteredUsers.enrolled_no_data) }}>
              <Typography fontSize='1.5rem'> {filteredUsers.enrolled_no_data.length} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  enrolled, no data </Typography>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={2}>
          <Tooltip title='Members who were temporarily suspended from the program during this period'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__button' onClick={() => { handleSuspensionDrilldown('Suspensions this period', props.rangeSuspensions) }}>
              <Typography fontSize='2rem'> {uniqueSuspensions} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  suspended this period </Typography>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={2}>
          <Tooltip title='Members who were permanently removed from the program this period'>
            <Button variant='outlined' color='secondary' className='ax-drilldown__button' onClick={() => { handleUserDrilldown('Archived members this period', filteredUsers.archived) }}>
              <Typography fontSize='2rem'> {filteredUsers.archived.length} </Typography>
              <Typography variant='subtitle1' fontSize='.6rem' fontWeight={600}>  archived this period </Typography>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  return props.forPeriod ? renderPeriodEnrollment() : renderTotalEnrollment()
}
