
import React from 'react'
import { preciseRound } from '../unitConversionUtils'
import { getDisplayDelta } from '../baseStringUtils'
import { getIOMDelta, getIOMRange, getWeightGainInPregnancy, getActivityMinutesInRange } from './analyticsUtilityHelper'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IActivityStats } from '../../interfaces/activity-summary.interface'
import { type IWeightStats } from '../../interfaces/weight-summary.interface'
import { type IBloodGlucoseData } from '../../interfaces/bg-summary.interface'
import { type IBloodPressureData } from '../../interfaces/bp-summary.interface'

export function getActivityProfileStats (memberObj: IMemberSummary): IActivityStats {
  const activityTotal = (
    <span><strong>{getActivityMinutesInRange(memberObj)}</strong> min</span>
  )

  // Activity goal progress
  const activityProgressNum = memberObj.weekly_activity_goal?.current_progress.value
  const activityProgress = (
    <span>{activityProgressNum}%</span>
  )

  // Activity performance
  const activityPerfRaw = memberObj.weekly_activity_goal?.activity_performance ?? 0
  const activityPerf = getDisplayDelta(preciseRound(activityPerfRaw, 2))

  return {
    activityTotal,
    activityProgress,
    activityProgressNum,
    activityPerf
  }
}

export function getBloodGlucoseProfileStats (memberObj: IMemberSummary): { latestBloodGlucose?: IBloodGlucoseData } {
  return {
    latestBloodGlucose: memberObj?.blood_glucose?.latest_measurement
  }
}

export function getBloodPressureProfileStats (memberObj: IMemberSummary): { latestBloodPressure?: IBloodPressureData } {
  return {
    latestBloodPressure: memberObj?.blood_pressure?.latest_measurement
  }
}

export function getPulseOxProfileStats (memberObj: IMemberSummary): { latestPulseOx: { oxygen_saturation: number } } {
  return {
    latestPulseOx: memberObj?.pulse_ox?.latest_measurement
  }
}

export function getWeightProfileStats (memberObj: IMemberSummary): { latestWeight?: number } {
  return {
    latestWeight: memberObj?.weight?.latest_measurement?.weight?.value
  }
}

/* ******************************************************************************
 * Profile Preg Weight stats (latest, average, delta over time, performance, etc.)
 * ******************************************************************************/

export function getPregnancyWeightProfileStats (memberObj: IMemberSummary): IWeightStats {
  const noWeightData = !memberObj.weight?.data?.length || !memberObj.weight.latest_measurement

  const gainRateObj = memberObj.weight?.information.gain_rate
  const weightGain14Dnum = gainRateObj ? preciseRound(gainRateObj.value, 1) : null
  const weightGain14D = gainRateObj ? `${weightGain14Dnum ?? ''} ${gainRateObj.unit}` : '—'

  // Weight IOM delta
  let weightIOMDelta
  if (noWeightData) weightIOMDelta = '—'
  else {
    const weightIOMDeltaRaw = getDisplayDelta(getIOMDelta(memberObj)?.delta)

    if (weightIOMDeltaRaw !== '0') {
      weightIOMDelta = <span>{weightIOMDeltaRaw} lb</span>
    } else {
      weightIOMDelta = <span className='in-range'>in range</span>
    }
  }

  let weightIOMStatus: string
  let weightIOMStatusNum: number = 0
  if (noWeightData || !memberObj.weight?.latest_measurement) weightIOMStatus = '—'
  else {
    const latestMeasurement = memberObj.weight?.latest_measurement
    const { min: minIOM, max: maxIOM } = getIOMRange(memberObj, latestMeasurement?.timestamp)
    weightIOMStatusNum = preciseRound(((latestMeasurement.weight.value - minIOM) / (maxIOM - minIOM)) * 100, 0)
    weightIOMStatus = `${weightIOMStatusNum}%`
  }

  // Weight gain total
  const weightGainRaw = preciseRound(getWeightGainInPregnancy(memberObj) ?? 0, 2)
  const weightGainTotal = (<span><strong>{getDisplayDelta(weightGainRaw)}</strong> lb</span>)

  return {
    weightGain14D,
    weightGain14Dnum,
    weightIOMDelta,
    weightGainTotal,
    weightIOMStatus,
    weightIOMStatusNum
  }
}
