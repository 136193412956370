import React, { useState } from 'react'
import getHeartRateChartOptions from '../../../utils/charts/baseHeartRateChart'
import ChartModule from './ChartModule'
import TabPanel from '../../layout/TabPanel'
import { Box, Card, CardContent, Tab, Tabs, Typography } from '@mui/material'
import { a11yProps } from '../../../constants/constants'
import { type IChartOptionsParams } from '../../../interfaces/chart.interface'
import EmptyTable from '../Tables/EmptyTable'

export default function HeartRateChart (props: IChartOptionsParams): JSX.Element {
  const [selectedHeartRateTab, setSelectedHeartRateTab] = useState(0)

  const renderChartPanel = (): JSX.Element => {
    return (
      <Card className='chart-card' id='heart_rate-card'>
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'end', m: 0 }}>
            <Typography variant='h6' color='#7f7f7f'> Resting Heart Rate </Typography>
            <Tabs
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              value={selectedHeartRateTab}
              onChange={(_e, val) => { setSelectedHeartRateTab(val) }}
              aria-label='Profile tabs'
            >
              <Tab data-testid='heart_rate__charts-tab' label='Graph' {...a11yProps('heartrate-metric__tab', 0)} />
              <Tab data-testid='heart_rate__table-tab' label='Table' {...a11yProps('heartrate-metric__tab', 1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedHeartRateTab} index={0} noPadding>
            <ChartModule
              hideHeader
              hideBorder
              showChartActions
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
              metricType='heart_rate'
              getChartOptions={getHeartRateChartOptions}
              showMemberAlerts={props.showMemberAlerts}
              handleAlertClick={props.handleAlertClick} // eslint-disable-line react/jsx-handler-names
            />
          </TabPanel>
          <TabPanel value={selectedHeartRateTab} index={1} noPadding>
            {/* TODO Replace with real table */}
            <EmptyTable hideHeader hideBorder dashboardViewStore={props.dashboardViewStore} memberObj={props.memberObj}></EmptyTable>
          </TabPanel>
        </CardContent>
      </Card>
    )
  }

  return renderChartPanel()
}
