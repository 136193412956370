import { Box, Divider, Link } from '@mui/material'
import React from 'react'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

export default function AddMemberFooter (): JSX.Element {
  return (
    <Box sx={{ width: '90%', mb: 2, margin: 'auto' }}>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ width: '100%', alignItems: 'center', display: 'inline-flex' }}>
        <QuestionAnswerIcon sx={{ mr: 1 }} />
        <Box>
          Need help? Contact us by email at <Link underline='none' color='dark-orange' href='mailto:help@ejenta.com'>help@ejenta.com</Link>&nbsp;
          or by phone at <Link underline='none' color='blue' href='tel:4154830304'>415-483-0304</Link>
        </Box>
      </Box>
    </Box>
  )
}
