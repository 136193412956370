import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { COMMUNICATION_TYPES } from '../../../../constants/constants'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { type IUser } from '../../../../interfaces/user.interface'

interface IMessagePreferencesProps {
  onChange: (user: Record<string, string | number>) => void
  onboardingUser: IUser
}

export default function OnboardingMessagePrefs (props: IMessagePreferencesProps): JSX.Element {
  const [communication, setCommunication] = useState(props.onboardingUser.communication ?? 'email')

  useEffect(() => {
    props.onChange({ communication })
  }, [communication])

  const renderMessagePrefItem = (messageType, messageDesc): JSX.Element => {
    return (
      <FormControlLabel
        key={messageType}
        value={messageType}
        label={messageDesc}
        checked={communication === messageType}
        onClick={() => { setCommunication(messageType) }}
        control={<Radio />}
      />
    )
  }

  const renderMessagePreferenceForm = (): JSX.Element => {
    return (
      <RadioGroup name='communication-radio-buttons-group'>
        {_.map(_.keys(COMMUNICATION_TYPES.CLINICIAN), k =>
          renderMessagePrefItem(k, COMMUNICATION_TYPES.CLINICIAN[k])
        )}
      </RadioGroup>
    )
  }

  return renderMessagePreferenceForm()
}
