import React from 'react'
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import { productConfig } from '../../../../config/config-service'
import { type IMemberFormStore } from '../../../../store/memberForm'
import { Field } from 'react-final-form'

interface IMemberTermsProps {
  setTermsAcceptance: ({ checked, field }) => void
  memberFormState: IMemberFormStore
}

export default function MemberTermsFields (props: IMemberTermsProps): JSX.Element {
  const profileConfig = productConfig().profile

  const renderProfileFormFields = (): JSX.Element => {
    const termsType = profileConfig.termsCheckbox
    let label = 'Member has signed paper Consent and <a href=\'//ejenta.com/terms.pdf\' target=\'_blank\'> &nbsp;Terms of Service.*</a>'
    if (termsType === 'signed_tos') {
      label = 'Member has agreed to <a href=\'//ejenta.com/terms.pdf\' target=\'blank\'> &nbsp; Terms of Service.*</a>'
    } else if (termsType === 'signed_consent') {
      label = 'Member has signed paper Consent Form.*'
    }

    const labelControl = <Typography className='no-underline' variant='body1' dangerouslySetInnerHTML={{ __html: label }} />

    return (
      <Grid container spacing={1} data-testid='member-profile-form-fields'>
        <Grid item xs={12}>
          <Field name={termsType}>
            {fieldProps => (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={props.memberFormState.termsAccepted} onChange={e => { props.setTermsAcceptance({ checked: e.target.checked, field: termsType }) }} name={fieldProps.input.name} />}
                  label={labelControl} />
              </FormGroup>
            )}

          </Field>
        </Grid>
      </Grid>
    )
  }

  return renderProfileFormFields()
}
