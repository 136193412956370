import { bindActionCreators } from 'redux'
import { type AppDispatch, type RootState } from '..'
import restApi from '../api'
import { updateUser } from '../store/userSession'
import { profileViewSlice } from '../store/profileView'
import { analyticsSlice } from '../store/analytics'
import { type IMemberSummary } from '../interfaces/member-summary.interface'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mapUserState = (state: RootState) => {
  return {
    user: state.userSession.user,
    activeGroup: state.userSession.activeGroup,
    resources: state.userSession.resources
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mapAnalyticsState = (state: RootState) => {
  return {
    analyticsStore: state.analytics
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mapUserDispatch = (dispatch: AppDispatch) => {
  return {
    ...mapApiDispatch(dispatch),
    updateUser: bindActionCreators(updateUser, dispatch)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mapAnalyticsDispatch = (dispatch: AppDispatch) => {
  return {
    ...mapApiDispatch(dispatch),
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mapApiDispatch = (dispatch: AppDispatch) => {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mapProfileDispatch = (dispatch: AppDispatch, ownProps: { memberObj: IMemberSummary }) => {
  return {
    profileViewActions: bindActionCreators(profileViewSlice.actions, dispatch),
    updateMemberCarePlan: body => dispatch(
      restApi.actions.updateMemberCarePlan(
        { id: ownProps.memberObj.user.id },
        { body: JSON.stringify(body) }
      )
    )
  }
}
