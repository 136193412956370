import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { productConfig } from '../../../config/config-service'
import OrderStatus from './OrderStatus'
import { Box, IconButton, Fade, Paper, Tooltip, Popper, Divider } from '@mui/material'
import MonitorIcon from '@mui/icons-material/Monitor'
import DeviceUsageData from './DeviceUsageData'
import { generateEmptySpan } from '../../../utils/baseStringUtils'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type IDisplayField } from '../../../interfaces/forms.interface'
import { type IOrderStatuses } from '../../../interfaces/order-status.interface'

interface IDeviceStatusProps {
  shouldRenderCondensed?: boolean
  fields: IDisplayField []
  memberObj: IMemberSummary
}

export default function DeviceStatusRows (props: IDeviceStatusProps): JSX.Element {
  const className = props.shouldRenderCondensed ? 'devices--condensed' : 'devices--full'
  const orderFields = productConfig().profile.orderFields

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const toggleDevicePopper = () => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }

  const fields = props.fields.map((f, fIndex) =>
    <div className='device__section' key={fIndex}>
      <div className='device__section-meta-text'>{f.fieldDisplayStr}</div>
      <div className='device__section-data'>{f.accessor?.(props.memberObj?.user)}</div>
    </div>
  )

  const orderStatuses = Object.keys(props.memberObj?.user?.order_statuses as IOrderStatuses).map(device => {
    return (
      <div key={device}>
        <OrderStatus
          device={device}
          orderStatuses={props.memberObj.user.order_statuses?.[device]}
          shouldRenderCondensed={props.shouldRenderCondensed}
        />
      </div>
    )
  })

  const hasDeviceUsageMeasurement = (): boolean => {
    const user = props.memberObj.user

    const isDefined = (item: any): boolean => item !== undefined

    if (isDefined(user.has_wa_weight) || isDefined(user.has_healthkit_weight) || isDefined(user.has_manual_weight) ||
      isDefined(user.has_wa_bp) || isDefined(user.has_healthkit_bp) || isDefined(user.has_manual_bp) ||
      isDefined(user.has_manual_bg)) return true
    else return false
  }

  const deviceUsagePopper = (): JSX.Element => {
    return (
      <Popper open={open} anchorEl={anchorEl} placement='right-start' transition sx={{ zIndex: 9999 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <DeviceUsageData memberObj={props.memberObj} />
            </Paper>
          </Fade>
        )}
      </Popper>
    )
  }

  const renderDeviceStatusRows = (): JSX.Element => {
    const hasDeviceMeasurements = hasDeviceUsageMeasurement()
    const devicesSection = fields.length > 0 && (
      <div>
        <Box className='device__section-header' sx={{ display: 'inline-flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
          {hasDeviceMeasurements && deviceUsagePopper()}
          <span>Devices</span>
          {hasDeviceMeasurements &&
            <Tooltip title='Click to toggle view of devices used'>
              <IconButton onClick={toggleDevicePopper()} id='device-usage__button'>
                <MonitorIcon />
              </IconButton>
            </Tooltip>}
        </Box>

        {fields}
      </div>
    )

    return (
      <div className={className}>
        {devicesSection}
        {(orderFields as string []).length > 0 && renderOrderFields()}
      </div>
    )
  }

  const renderOrderFields = (): JSX.Element => {
    const condensedStatusSection = (
      <>
        <Box className='device__section-header' sx={{ mb: 1 }}>Order shipment status</Box>
        {orderStatuses.length ? orderStatuses : generateEmptySpan()}
      </>
    )

    const orderStatusSection = (
      <Card variant='outlined' sx={{ mb: 2 }}>
        <CardContent>
          {condensedStatusSection}
        </CardContent>
      </Card>
    )

    return (
      <>
        {props.shouldRenderCondensed && <Divider sx={{ m: 1 }} />}
        {(orderFields as string[]).length > 0 && (props.shouldRenderCondensed ? condensedStatusSection : orderStatusSection)}
      </>
    )
  }

  return renderDeviceStatusRows()
}
