import React from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../layout/ContentMain'
import SettingsSidebar from '../../layout/SettingsSidebar'
import AccountInformation from './AccountInformation'
import MessagePreferences from './MessagePreferences'
import ChangePasswordForm from './ChangePassword'
import Grid from '@mui/material/Grid'
import { mapUserState } from '../../../constants/reduxMappers'

function AccountSettingsPage (props: PropsFromRedux): JSX.Element | null {
  const renderDesktop = (): JSX.Element => {
    return (
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}> <AccountInformation /> </Grid>
        <Grid item sm={12} md={6}> <ChangePasswordForm /> </Grid>
        <Grid item sm={12} md={6}> <MessagePreferences /> </Grid>
      </Grid>
    )
  }

  const renderAccountSettingsPage = (): JSX.Element | null => {
    if (!props.user) return null

    return (
      <>
        <SettingsSidebar />

        <ContentMain drawerWidth={220}>
          <ContentMainIntro>
            <ContentMainIntroHeader title='Account' />
          </ContentMainIntro>

          <ContentMainBody>
            <div className='account-settings u-top-page-margin'>
              {renderDesktop()}
            </div>
          </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderAccountSettingsPage()
}

const connector = connect(mapUserState)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AccountSettingsPage)
