import { Box } from '@mui/material'
import React from 'react'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'

export default class ProgramStepChangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'program_step_change'
  }

  renderGoal (): JSX.Element {
    return (<div> Program Step </div>)
  }

  renderClinicianTrigger (): JSX.Element {
    return (<div>If program step changes.</div>)
  }

  renderCondensed (): JSX.Element {
    const className: string = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    return <Box className={className}>Program step changes</Box>
  }
}
