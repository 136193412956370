import React, { useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import restApi from '../../../api'
import classnames from 'classnames'
import UserNavLabel from './UserNavLabel'
import SettingsIcon from '@mui/icons-material/Settings'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import { type AppDispatch } from '../../..'

function mapDispatch (dispatch: AppDispatch): { handleLogout: () => void } {
  return {
    handleLogout () {
      dispatch(restApi.actions.logout())
    }
  }
}

function SettingsMenuItem (props: PropsFromRedux): JSX.Element {
  const [anchorElUser, setAnchorElUser] = useState(null)
  const open = Boolean(anchorElUser)
  const location = useLocation()

  const handleOpenSettingsMenu = (event: any): void => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseSettingsMenu = (): void => {
    setAnchorElUser(null)
  }

  const handleSignOutClicked = (): void => {
    handleCloseSettingsMenu()
    if (props.handleLogout) props.handleLogout()
  }

  const renderTarget = (): JSX.Element => {
    const isSettingsActive = ['/careplan', '/account', '/help'].some(p => location.pathname === p)

    const targetClassNames = classnames({
      'user-nav__item': true,
      'user-nav__item--is-selected': open,
      active: isSettingsActive
    })

    return (
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        className={targetClassNames}
        onMouseEnter={handleOpenSettingsMenu}
        aria-haspopup='true'
        data-testid='settings-menu-item__menu-button'
      >
        <SettingsIcon fontSize='large' />
        <UserNavLabel labelText='Settings' />
      </div>
    )
  }

  const renderCareplanLink = (): JSX.Element => {
    return (
      <NavLink to='/careplan' data-testid='settings-menu-item__careplan-link' onClick={handleCloseSettingsMenu} className='no-decoration'>
        <li className='user-nav__dropdown__item'>
          Care plan
        </li>
      </NavLink>
    )
  }

  const renderDropdown = (): JSX.Element => {
    return (
      <Box onMouseLeave={handleCloseSettingsMenu}>
        {renderCareplanLink()}

        <NavLink to='/account' data-testid='settings-menu-item__account-link' onClick={handleCloseSettingsMenu} className='no-decoration'>
          <li className='user-nav__dropdown__item'>Account</li>
        </NavLink>

        <NavLink to='/help' data-testid='settings-menu-item__help-link' onClick={handleCloseSettingsMenu} className='no-decoration'>
          <li className='user-nav__dropdown__item'>Help</li>
        </NavLink>

        <li
          className='user-nav__dropdown__item'
          data-testid='settings-menu-item__sign-out-link'
          onClick={handleSignOutClicked}
        >
          Sign out
        </li>
      </Box>
    )
  }

  const renderResponsiveSettingsMenu = (): JSX.Element => {
    return (
      <Box sx={{ flexGrow: 0 }} className='user-nav'>
        {renderTarget()}

        <Menu
          sx={{
            mt: '45px'
          }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handleCloseSettingsMenu}
          open={open}
          className='user-nav__items'
          disableRestoreFocus
        >
          {renderDropdown()}
        </Menu>
      </Box>
    )
  }

  return renderResponsiveSettingsMenu()
}

const connector = connect(null, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(SettingsMenuItem)
