import _ from 'lodash'
import moment from 'moment'
import { preciseRound, convertKgToLb } from '../unitConversionUtils'

import {
  TIMEZONE_DISPLAY_MAP,
  COMMUNICATION_PREFERENCE_MAP,
  getSimpleDisplayName,
  getDisplayAge,
  getDisplayHeight,
  getDisplayPhoneNumber,
  getDisplayEmail,
  getDisplayTimezone,
  getDisplayPlatform,
  getDisplaySyncStatus,
  getDisplayBodyTraceBpStatus,
  getDisplayBodyTraceScaleStatus,
  getDisplayIGlucoseStatus,
  getDisplayCaregiverName,
  getDisplayCaregiverEmail,
  getDisplayBioStickerStatus,
  getDisplayStreetAddress,
  generateEmptySpan,
  GENDER_MAP
} from '../baseStringUtils'

import {
  renderField,
  renderInput,
  renderDateInput,
  renderInputWithUnit,
  renderSelect
} from '../../components/elements/EjentaForm'
import {
  biohubTooltip,
  biostickerTooltip,
  bodytraceBpTooltip,
  bodytraceScaleTooltip,
  iGlucoseTooltip
} from '../../constants/strings'
import {
  COACH_MAP,
  getCustomMemberFields,
  IN_CM_MAP
} from '../../constants/customMemberFields'
import { productConfig } from '../../config/config-service'
import { type IOrderStatuses } from '../../interfaces/order-status.interface'
import { type IMember, type IUser, type IUserStar } from '../../interfaces/user.interface'
import { type ISuspension } from '../../interfaces/suspension.interface'
import {
  type IDisplayField,
  type IDisplayFields,
  type IProfileField,
  type IProfileFields
} from '../../interfaces/forms.interface'
import React from 'react'
import { Typography } from '@mui/material'
import { type INameID } from '../../interfaces/resource.interface'
import { type DefaultFormFieldValues } from '../../interfaces/product-config.interface'
import { SUSPEND_ALERT_OPTIONS } from '../../constants/constants'
import { type IGroup } from '../../interfaces/group.interface'

export function isMemberStarred (
  clinicianId: string,
  memberStarInfo: IUserStar[]
): boolean {
  const hasInfo = memberStarInfo?.find(x => x.clinician_id === clinicianId)
  return !!hasInfo
}

export function getCurrentOrFuturePause (pauses?: ISuspension[]): ISuspension | null {
  if (pauses != null && pauses !== undefined) {
    for (const pause of pauses) {
      const cond1 = pause.start != null
      // resume date either null or in the future
      const cond2 =
        pause.resume == null || moment().isBefore(moment(pause.resume))
      if (cond1 && cond2) {
        return pause
      }
    }
  }
  return null
}

export function isMemberPaused (pauses?: ISuspension[]): boolean {
  const pause = getCurrentOrFuturePause(pauses)
  if (pause != null) {
    return moment().isSameOrAfter(pause.start)
  }
  return false
}

export function isMemberAwaitingDevices (
  orderStatuses: IOrderStatuses
): boolean {
  if (orderStatuses && Object.keys(orderStatuses).length > 0) {
    for (const deviceOrderStatuses of Object.values(orderStatuses)) {
      const deviceDelivered = deviceOrderStatuses?.some(orderStatus => orderStatus.status === 'Delivered')
      if (!deviceDelivered) return true
    }
  }
  return false
}

export function getPauseDescription (
  pause: ISuspension
): React.ReactElement | null {
  if (!pause) return null

  const startDate = pause.start ? moment(pause.start).format('M/DD/YY') : null
  const endDate = pause.resume ? moment(pause.resume).subtract(1, 'days').format('M/DD/YY') : null

  const suspendedMapping = SUSPEND_ALERT_OPTIONS.find(x => x.value === pause.reason)?.label as string
  const reason = `due to ${suspendedMapping === 'Other' ? '"other"' : suspendedMapping.toLowerCase()}`

  const presentOrFutureTense = moment().isSameOrAfter(pause.start)
    ? 'are'
    : 'will be'
  if (!pause.resume) {
    return (
      <Typography component='span'>
        {' '}
        Alerts {presentOrFutureTense} suspended {reason} starting{' '}
        <strong>{startDate}</strong> until canceled.
      </Typography>
    )
  } else {
    return (
      <Typography component='span'>
        {' '}
        Alerts {presentOrFutureTense} suspended {reason} starting{' '}
        <strong>{startDate}</strong> up to and including{' '}
        <strong>{endDate}</strong>.
      </Typography>
    )
  }
}

/* *****************************************************************************
 * Member field validation (when creating a new member)
 * *****************************************************************************/

export function validateMemberForm (formData: Record<string, number>, fields: IProfileFields, isNewMember = false, checkConsent = true): Record<string, string> {
  let errors: Record<string, string> = {}

  _.forOwn(fields, (val: IProfileField, key: string) => {
    // If the field is not optional, require the a value
    if (!val.optional && !formData[key] && formData[key] !== 0) {
      // allow 0 as a valid value
      errors[key] = 'This field is required.'
    }
  })
  if (errors.care_manager_id) delete errors.care_manager_id // not required, but we don't want empty option either

  if (checkConsent) errors = validateMemberConsent(isNewMember, errors)
  else {
    delete errors.signed_consent
    delete errors.signed_tos
    delete errors.signedConsentAndTos
  }

  return errors
}

function validateMemberConsent (isNewMember, errors): Record<string, string> {
  if (errors.signed_consent) errors.signed_consent = 'Consent is required.'
  if (errors.signed_tos) { errors.signed_tos = 'Terms of Service consent is required.' }

  if (errors.signedConsentAndTos) {
    if (isNewMember) errors.signedConsentAndTos = 'Consent is required.'
    else delete errors.signedConsentAndTos
  }

  return errors
}

// Transform keys from e.g. 'start_weight_in_kg' to 'startWeight'
export function getMemberProfileFormErrors (
  errors: Record<string, string>,
  memberFields: IProfileFields
): Record<string, string> {
  const formErrors: Record<string, string> = {}

  _.forOwn(errors, (errorText: string, errorField: string) => {
    const formFieldName = _.findKey(
      memberFields,
      (f: IProfileField) => f.apiField === errorField
    )
    if (!formFieldName) return

    formErrors[formFieldName] = errorText
  })

  return formErrors
}

/* *****************************************************************************
 * Member fields (when creating a new member)
 *
 * Fields:
 *  - apiField: {string} name for the field in the JSON object sent to the server
 *  - required: {bool} whether or not this field is required, defaults to true
 * *****************************************************************************/

const getTextInputOptions = (
  target: any,
  name: string,
  label: string,
  disabled = false,
  type = 'text',
  normalize?: (value: any) => any,
  tooltip?: string
): JSX.Element => {
  const fieldOptions = {
    name,
    label,
    disabled,
    type,
    normalize,
    tooltip,
    autoComplete: 'off',
    component: renderInput
  }
  return renderField(target, fieldOptions)
}

export const getSelectInputOptions = (
  target: any,
  name: string,
  label: string,
  options: Record<string, string>,
  disabled = false
): JSX.Element => {
  const fieldOptions = {
    name,
    label,
    options,
    disabled,
    type: 'select',
    component: renderSelect
  }
  return renderField(target, fieldOptions)
}

export const getDateInputOptions = (
  target: any,
  name: string,
  label: string,
  disabled = false,
  tooltip?: string
): JSX.Element => {
  const fieldOptions = {
    name,
    label,
    disabled,
    tooltip,
    type: 'date',
    dateFormat: 'Y-MM-DD',
    autoComplete: 'off',
    component: renderDateInput
  }
  return renderField(target, fieldOptions)
}

const getUnitInputOptions = (
  target: any,
  name: string,
  label: string,
  unit: string,
  maxValue?: number,
  minValue = 1,
  disabled = false
): JSX.Element => {
  const fieldOptions = {
    name,
    label,
    unit,
    disabled,
    minValue,
    maxValue,
    autoComplete: 'off',
    component: renderInputWithUnit,
    type: 'number'
  }
  return renderField(target, fieldOptions)
}

export const baseMemberFields: IProfileFields = {
  medicalId: {
    apiField: 'medical_id',

    formInput () {
      return getTextInputOptions(this, 'medicalId', 'MRN')
    },
    readOnlyFormInput () {
      return getTextInputOptions(this, 'medicalId', 'MRN', true)
    }
  },

  group: {
    apiField: 'group',
    optional: true,
    formInput (allowedGroups) {
      const fieldOptions = {
        name: 'group',
        label: 'Group',
        component: renderSelect,
        type: 'select',
        options: getGroupSelectOptions(allowedGroups)
      }

      return renderField(this, fieldOptions)
    },
    accessor: (user: IMember) => user.group ?? ''
  },

  firstName: {
    apiField: 'first_name',
    formInput () {
      return getTextInputOptions(this, 'firstName', 'First name')
    }
  },

  lastName: {
    apiField: 'last_name',
    formInput () {
      return getTextInputOptions(this, 'lastName', 'Last name')
    }
  },

  birthDate: {
    apiField: 'date_of_birth',

    // Parse the date of birth in UTC timezone instead of browser timezone, since the server passes
    // down the date with a superfluous timestamp
    accessor: user => moment.utc(user.date_of_birth).format('Y-MM-DD'),

    formInput () {
      return getDateInputOptions(
        this,
        'birthDate',
        'Date of birth (mm/dd/yyyy)'
      )
    },
    readOnlyFormInput () {
      return getDateInputOptions(
        this,
        'birthDate',
        'Date of birth (mm/dd/yyyy)',
        true
      )
    }
  },

  heightFeet: {
    optional: !productConfig().profile.hasHeightRequired,
    accessor: user =>
      user.height_in_cm ? preciseRound(Math.floor(Number(user.height_in_cm) / 2.54 / 12), 2) : '',
    formInput () {
      return getUnitInputOptions(this, 'heightFeet', 'Height (ft)', 'ft')
    }
  },

  heightInches: {
    optional: !productConfig().profile.hasHeightRequired,
    apiField: 'height_in_cm',
    accessor: user =>
      user.height_in_cm ? preciseRound((user.height_in_cm / 2.54) % 12, 2) : '',
    formInput () {
      return getUnitInputOptions(
        this,
        'heightInches',
        'Height (in)',
        'in',
        11,
        0
      )
    }
  },

  startWeight: {
    apiField: 'start_weight_in_kg',
    optional: !productConfig().profile.showPregnancyInfo,

    accessor: (user) =>
      user.start_weight_in_kg
        ? preciseRound(convertKgToLb(user.start_weight_in_kg), 2)
        : '',
    formInput () {
      const label = productConfig().profile.showPregnancyInfo ? 'Pre-pregnancy weight' : 'Start weight'
      return getUnitInputOptions(this, 'startWeight', label, 'lb', 999)
    }
  },

  deliveryDate: {
    optional: true,
    apiField: 'delivery_date',
    accessor: (user) =>
      user.delivery_date ? moment(user.delivery_date).format('Y-MM-DD') : '',
    formInput () {
      return getDateInputOptions(
        this,
        'deliveryDate',
        'Actual delivery date (mm/dd/yyyy)',
        false,
        'Update once the member has delivered her baby.'
      )
    }
  },

  email: {
    apiField: 'email',
    formInput () {
      return getTextInputOptions(this, 'email', 'Email address', false, 'email')
    }
  },

  phone: {
    apiField: 'phone',
    optional: productConfig().profile.hasOptionalPhoneNumber,
    formInput () {
      const label = productConfig().profile.hasCellPhoneNumber ? 'Cell phone' : 'Phone number'
      return getTextInputOptions(this, 'phone', label, false, 'tel')
    }
  },

  timezone: {
    apiField: 'timezone',
    formInput () {
      return getSelectInputOptions(
        this,
        'timezone',
        'Timezone',
        TIMEZONE_DISPLAY_MAP
      )
    }
  },

  caregiverName: {
    optional: true,
    apiField: 'caregiver_name',

    formInput () {
      return getTextInputOptions(this, 'caregiverName', 'Caregiver name')
    }
  },

  caregiverEmail: {
    optional: true,
    apiField: 'caregiver_email',

    formInput () {
      const normalize = (value: string): string | null => value ?? null
      return getTextInputOptions(
        this,
        'caregiverEmail',
        'Caregiver email address',
        false,
        'email',
        normalize
      )
    }
  },

  caregiverPhone: {
    optional: true,
    apiField: 'caregiver_phone',

    formInput () {
      return getTextInputOptions(
        this,
        'caregiverPhone',
        'Caregiver phone number',
        false,
        'tel'
      )
    }
  },

  bodyTraceScaleImei: {
    optional: true,
    apiField: 'bt_scale_imei',

    formInput () {
      return getTextInputOptions(
        this,
        'bodyTraceScaleImei',
        'BodyTrace scale ID number',
        false,
        'text',
        undefined,
        bodytraceScaleTooltip
      )
    },
    readOnlyFormInput () {
      return getTextInputOptions(
        this,
        'bodyTraceScaleImei',
        'BodyTrace scale ID number',
        true,
        'text',
        undefined,
        bodytraceScaleTooltip
      )
    }
  },

  bodyTraceBpImei: {
    optional: true,
    apiField: 'bt_bp_imei',

    formInput () {
      return getTextInputOptions(
        this,
        'bodyTraceBpImei',
        'BodyTrace BP cuff ID number',
        false,
        'text',
        undefined,
        bodytraceBpTooltip
      )
    },
    readOnlyFormInput () {
      return getTextInputOptions(
        this,
        'bodyTraceBpImei',
        'BodyTrace BP cuff ID number',
        true,
        'text',
        undefined,
        bodytraceBpTooltip
      )
    }
  },

  bioStickerId: {
    optional: true,
    apiField: 'biosticker_id',

    formInput () {
      return getTextInputOptions(
        this,
        'bioStickerId',
        'Bio ID number',
        false,
        'text',
        undefined,
        biostickerTooltip
      )
    },
    readOnlyFormInput () {
      return getTextInputOptions(
        this,
        'bioStickerId',
        'Bio ID number',
        true,
        'text',
        undefined,
        biostickerTooltip
      )
    }
  },

  bioHubId: {
    optional: true,
    apiField: 'biohub_id',

    formInput () {
      return getTextInputOptions(
        this,
        'bioHubId',
        'BioHub ID number',
        false,
        'text',
        undefined,
        biohubTooltip
      )
    },
    readOnlyFormInput () {
      return getTextInputOptions(
        this,
        'bioHubId',
        'BioHub ID number',
        true,
        'text',
        undefined,
        biohubTooltip
      )
    }
  },

  iGlucoseId: {
    optional: true,
    apiField: 'iglucose_id',

    formInput () {
      return getTextInputOptions(
        this,
        'iGlucoseId',
        'iGlucose ID number',
        false,
        'text',
        undefined,
        iGlucoseTooltip
      )
    },

    readOnlyFormInput () {
      return getTextInputOptions(
        this,
        'iGlucoseId',
        'iGlucose ID number',
        true,
        'text',
        undefined,
        iGlucoseTooltip
      )
    }
  },

  communicationPreference: {
    apiField: 'communication',
    formInput () {
      return getSelectInputOptions(
        this,
        'communicationPreference',
        'Communication preference',
        COMMUNICATION_PREFERENCE_MAP
      )
    }
  }
}

/* *****************************************************************************
 * Member fields (when displaying member's profile)
 *
 * Every field should have the following properties:
 *    - {string} fieldDisplayStr
 *    - {user => string} accessor
 * *****************************************************************************/

export const baseMemberProfileFields: IDisplayFields = {
  NAME: {
    fieldDisplayStr: 'Name',
    accessor: (user: IMember) => getSimpleDisplayName(user)
  },
  MRN: {
    // way #1 to display medical_id
    fieldDisplayStr: 'MRN',
    accessor: (user: IMember) => user.medical_id ?? ''
  },
  STUDY_ID: {
    // way #2 to display medical_id
    fieldDisplayStr: 'Study ID',
    accessor: (user: IMember) => user.medical_id ?? ''
  },
  MEMBER_ID: {
    // way #3 to display medical_id
    fieldDisplayStr: 'Member ID',
    accessor: (user: IMember) => user.medical_id ?? ''
  },
  STARTED: {
    fieldDisplayStr: 'Joined Ejenta',
    accessor: (user: IMember) => {
      if (user.signed_tos) return moment(user.signed_tos).format('MMM D, Y')
      else return generateEmptySpan('Pending')
    }
  },
  STATUS: {
    fieldDisplayStr: 'Status',
    accessor: (user: IMember) => {
      if (user.archived_since) {
        return `Archived ${moment(user.archived_since).format('MMM D, Y')}`
      } else if (isMemberPaused(user.pauses)) {
        return 'Suspended'
      } else if (!user.signed_tos) {
        return generateEmptySpan('Pending')
      }

      return 'Active'
    }
  },
  DOB: {
    fieldDisplayStr: 'DOB',
    accessor: (user: IMember) =>
      // Parse the date of birth in UTC timezone instead of browser timezone, since the server
      // passes down the date with a superfluous timestamp
      `${moment
        .utc(user.date_of_birth)
        .format('MMM D, Y')} (age ${getDisplayAge(user)})`
  },
  HEIGHT: {
    fieldDisplayStr: 'Height',
    accessor: (user: IMember) => getDisplayHeight(user)
  },
  EMAIL: {
    fieldDisplayStr: 'Email',
    accessor: (user: IMember) => getDisplayEmail(user.email)
  },
  PHONE: {
    fieldDisplayStr: 'Phone',
    accessor: (user: IMember) => getDisplayPhoneNumber(user.phone)
  },
  TIMEZONE: {
    fieldDisplayStr: 'Timezone',
    accessor: (user: IMember) => getDisplayTimezone(user.timezone)
  },
  FITBIT: {
    fieldDisplayStr: 'Fitbit account',
    accessor: (user: IMember) => getDisplaySyncStatus(user.has_fitbit)
  },
  DEVICE_PLATFORM: {
    fieldDisplayStr: 'Device platform',
    accessor: (user: IMember) => getDisplayPlatform(user.device_platform)
  },
  BODYTRACE_SCALE: {
    fieldDisplayStr: 'BodyTrace scale',
    accessor: (user: IMember) => getDisplayBodyTraceScaleStatus(user)
  },
  BODYTRACE_BP: {
    fieldDisplayStr: 'BodyTrace BP',
    accessor: (user: IMember) => getDisplayBodyTraceBpStatus(user)
  },
  IGLUCOSE: {
    fieldDisplayStr: 'iGlucose',
    accessor: (user: IMember) => getDisplayIGlucoseStatus(user)
  },
  BIOSTICKER: {
    fieldDisplayStr: 'Bio',
    accessor: (user: IMember) => getDisplayBioStickerStatus(user)
  },
  STREET_ADDRESS: {
    fieldDisplayStr: 'Address',
    accessor: getDisplayStreetAddress
  },
  CAREGIVER_NAME: {
    fieldDisplayStr: 'Caregiver',
    accessor: (user: IMember) => getDisplayCaregiverName(user)
  },
  CAREGIVER_EMAIL: {
    fieldDisplayStr: '- Email',
    isSubfield: true,
    accessor: (user: IMember) => getDisplayCaregiverEmail(user)
  },
  CAREGIVER_PHONE: {
    fieldDisplayStr: '- Phone',
    isSubfield: true,
    accessor: (user: IMember) => getDisplayPhoneNumber(user.caregiver_phone)
  },
  GENDER: {
    fieldDisplayStr: 'Sex',
    accessor: (user: IMember) => (GENDER_MAP as Record<string, string>)[user.gender ?? 'MALE']
  },
  LIFESTYLE_COACH: {
    fieldDisplayStr: 'Lifestyle coach',
    accessor: (user: IMember) => COACH_MAP[user.lifestyle_coach]
  },
  CELL: {
    fieldDisplayStr: 'Cell',
    accessor: (user: IMember) => getDisplayPhoneNumber(user.phone)
  },
  HOME_PHONE: {
    fieldDisplayStr: 'Home',
    accessor: (user: IMember) => getDisplayPhoneNumber(user.home_phone)
  },
  PCP: {
    fieldDisplayStr: 'PCP',
    accessor: (user: IMember) => user.pcp ?? generateEmptySpan()
  },
  PCP_PHONE: {
    fieldDisplayStr: 'PCP phone',
    accessor: (user: IMember) => getDisplayPhoneNumber(user.pcp_phone)
  },
  IN_CARE_MANAGEMENT: {
    fieldDisplayStr: 'In CM',
    accessor: (user: IMember) => {
      return (IN_CM_MAP as Record<number, string>)[user.in_care_management ?? 0]
    }
  },
  CARE_MANAGER: {
    fieldDisplayStr: 'Care Manager',
    accessor: (user: IMember, careManagers?: INameID []) => {
      if (user.care_manager_id && careManagers?.length) {
        return (
          getCareManagerOptions(careManagers)[user.care_manager_id] ?? 'Unknown'
        )
      } else return generateEmptySpan()
    }
  },
  LCU: {
    fieldDisplayStr: 'LCU',
    accessor: (user: IMember) => user.lcu ?? generateEmptySpan()
  },
  EMPLOYER_GROUP: {
    fieldDisplayStr: 'Employer group',
    accessor: (user: IMember) => user.employer_group ?? generateEmptySpan()
  }
}

export const getGroupSelectOptions = (allowedGroups: IGroup []): Record<string, string> => {
  const allOptions: Record<string, string> = {}
  allowedGroups?.forEach(group => {
    allOptions[group.name] = group.description
  })

  return allOptions
}

export const getCareManagerOptions = (careManagers: INameID []): Record<string, string> => {
  const allOptions: Record<string, string> = {
    OPTION_NONE: 'None'
  }

  if (careManagers?.length) {
    careManagers.forEach(option => {
      allOptions[option.id] = option.name
    })
  }
  return allOptions
}

export const getProfileSidebarFields = (): IDisplayField [] => {
  const displayFields: IDisplayField[] = []
  productConfig().profile.sidebarFields?.forEach((field: string) => {
    displayFields.push(baseMemberProfileFields[field])
  })

  return displayFields
}

export const getProfileDeviceFields = (): IDisplayField[] => {
  const deviceFields: IDisplayField[] = []
  const devices = productConfig().profile.devices
  if (devices == null) return deviceFields

  if (devices.hasLinkedAccounts) { deviceFields.push(baseMemberProfileFields.FITBIT) }
  if (devices.hasBodyTraceScale) { deviceFields.push(baseMemberProfileFields.BODYTRACE_SCALE) }
  if (devices.hasBodyTraceBP) { deviceFields.push(baseMemberProfileFields.BODYTRACE_BP) }
  if (devices.hasIGlucose) deviceFields.push(baseMemberProfileFields.IGLUCOSE)
  if (devices.hasBiosticker) { deviceFields.push(baseMemberProfileFields.BIOSTICKER) }

  return deviceFields
}

export const getDefaultMemberFieldValuesFromConfig = (clinicianUser?: IUser, activeGroup?: string): DefaultFormFieldValues => {
  const fieldValues = { ...productConfig().profile.defaultFormFieldValues }
  fieldValues.group = activeGroup
  if (fieldValues.timezone) fieldValues.timezone = clinicianUser?.timezone ?? fieldValues.timezone
  return fieldValues
}

export const getProfileMemberFields = (): IProfileFields => {
  const baseMemberFieldsToRemove = productConfig().profile.baseMemberFieldsToRemove
  return _.merge(
    _.omit({ ...baseMemberFields }, baseMemberFieldsToRemove),
    getCustomMemberFields()
  )
}

export const getHasValidConsentDocs = (user: IMember): boolean => {
  if (productConfig().profile.hasCustomTOSRules) {
    return !!user.signed_tos && (user.clinician === 1 || !!user.signed_consent)
  } else return !!user.signed_tos
}
