import React from 'react'
import BloodPressureRangeItem from '../RangeItems/BloodPressureRangeItem'
import { Box, Divider } from '@mui/material'
import { type ICarePlanCardProps } from '../../../../interfaces/careplan.interface'

export default function BloodPressureCard (props: ICarePlanCardProps): JSX.Element {
  const sectionData = props.sectionData

  const renderDivider = (shouldShow): JSX.Element => {
    return (<>{shouldShow && !props.shouldRenderCondensed && <Divider className='care-plan-item__divider' />}</>)
  }

  return (
    <div className='care-plan__section' data-testid='care-plan__section__bp'>
      <div className='care-plan__section-header'>Blood Pressure and Heart Rate</div>
      {sectionData?.rangeSubTypes?.map((item, i, allElements) => {
        return (
          <Box key={`bloodpressurerangeitem__${item}`}>
            <BloodPressureRangeItem {...props} subtype={item} />
            {renderDivider(i < allElements.length - 1)}
          </Box>
        )
      })}
    </div>
  )
}
