import React, { useState } from 'react'
import getSleepChartOptions from '../../../utils/charts/baseSleepChart'
import ChartModule from './ChartModule'
import TabPanel from '../../layout/TabPanel'
import SleepTable from '../Tables/SleepTable'
import { Box, Card, CardContent, Tab, Tabs, Typography } from '@mui/material'
import { a11yProps } from '../../../constants/constants'
import { type IChartOptionsParams } from '../../../interfaces/chart.interface'

export default function SleepChart (props: IChartOptionsParams): JSX.Element {
  const [selectedSleepTab, setSelectedSleepTab] = useState(0)
  const pagePrefix = 'sleep-metric__tab-'

  const renderChartPanel = (): JSX.Element => {
    return (
      <Card className='chart-card' id='sleep-card'>
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'end', m: 0 }}>
            <Typography variant='h6' color='#7f7f7f'> Sleep </Typography>
            <Tabs
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              value={selectedSleepTab}
              onChange={(_e, val) => { setSelectedSleepTab(val) }}
              aria-label='Profile tabs'
            >
              <Tab data-testid='sleep__charts-tab' label='Graph' {...a11yProps(pagePrefix, 0)} />
              <Tab data-testid='sleep__table-tab' label='Table' {...a11yProps(pagePrefix, 1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedSleepTab} index={0} noPadding>
            <ChartModule
              hideHeader
              hideBorder
              showChartActions
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
              metricType='sleep'
              getChartOptions={getSleepChartOptions}
              showMemberAlerts={props.showMemberAlerts}
              handleAlertClick={props.handleAlertClick} // eslint-disable-line react/jsx-handler-names
            />
          </TabPanel>
          <TabPanel value={selectedSleepTab} index={1} noPadding>
            <SleepTable
              hideHeader
              hideBorder
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
            />
          </TabPanel>
        </CardContent>
      </Card>
    )
  }

  return renderChartPanel()
}
