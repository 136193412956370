import { dispatchWithException, resolvePromise } from './baseUtils'
import { setUser } from '../store/userSession'

/* ********************************************************************************
 * Endpoints for updating the user's own account.
 * ********************************************************************************/
const accountEndpoints = {
  signToS: {
    url: '/users/:id',
    options: { method: 'post', body: JSON.stringify({ signed_tos: 1 }) },

    postfetch: [
      ({ data, dispatch }) => {
        dispatchWithException(setUser(data), dispatch)
      }
    ]
  },

  signConsent: {
    url: '/users/:id',
    options: { method: 'post', body: JSON.stringify({ signed_consent: 1 }) },

    postfetch: [
      ({ data, dispatch }) => {
        dispatchWithException(setUser(data), dispatch)
      }
    ]
  },

  firstTimePassword: {
    url: '/users/:id/create_password',
    options: { method: 'post' },

    postfetch: [
      resolvePromise,
      ({ data, dispatch }) => {
        dispatchWithException(setUser(data), dispatch)
      }
    ]
  },

  /*
   * Account settings
   */
  updateAccount: {
    url: '/users/:id',
    options: { method: 'post' },

    postfetch: [resolvePromise]
  },

  changePassword: {
    url: '/users/:id/change',
    options: { method: 'post' },

    postfetch: [resolvePromise]
  },

  /*
   * Password reset
   */
  sendResetPasswordEmail: {
    url: '/forgot',
    options: { method: 'post' },

    postfetch: [resolvePromise]
  },

  verifyResetPasswordToken: {
    url: '/password_request/:token',
    options: { method: 'get' }
  },

  resetPassword: {
    url: '/password_reset',
    options: { method: 'post' },

    postfetch: [resolvePromise]
  },

  /*
   * Phone login
   */
  sendPhoneLoginCode: {
    url: '/send_phone_code',
    options: { method: 'post' },

    postfetch: [resolvePromise]
  }
}

export default accountEndpoints
