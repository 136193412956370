import moment from 'moment'
import React from 'react'
import { preciseRound } from '../../utils/unitConversionUtils'
import { productConfig } from '../../config/config-service'
import { getDisplayDelta, getHoursMinutes } from '../../utils/baseStringUtils'
import { getSleepAverage, getCaloriesAverage } from '../../utils/baseMemberAnalyticsUtils'
import { getWeightGainInPregnancy, getActivityMinutesInRange } from '../../utils/member/analyticsUtilityHelper'

import {
  getWeightDeltaInRange,
  getWeightSpreadInRange,
  getSystolicDeltaInRange,
  getDiastolicDeltaInRange,
  getBloodPressureHeartRateDeltaInRange,
  getBloodGlucoseDeltaInRange,
  getActivityStepsInRange,
  getActivityMinutesAverageInRange,
  getActivityStepsAverageInRange,
  getWeightGainInRange,
  getIOMRangeOnExpectedDeliveryDate
} from '../../utils/member/analyticsUtils'

export default function MetricsSidebar (props): JSX.Element | null {
  const hasPregnancyWeight = productConfig().profile.hasPregnancyCharts

  const renderWeightSidebar = (): JSX.Element => {
    if (props.member.weight.data.length) {
      const weightDelta = getWeightDeltaInRange(props.member)
      const validWeightDelta = Number.isFinite(weightDelta)
      const { maxWeight, minWeight } = getWeightSpreadInRange(props.member)

      return (
        <div className='metrics-sidebar metrics-sidebar--weight' data-testid='metrics-sidebar__weight'>
          <section>
            <div className='metrics-sidebar__caption'>Change from previous period</div>
            <div className='metrics-sidebar__statistic'>
              {validWeightDelta && <span>{getDisplayDelta(Number(weightDelta))} lb.</span>}
              {!validWeightDelta && <span>—</span>}
            </div>
          </section>
          {props.member.user.dry_weight &&
            <section>
              <div className='metrics-sidebar__caption'>Dry weight</div>
              <div className='metrics-sidebar__statistic'>
                {props.member.user.dry_weight}
              </div>
            </section>}
          <section>
            <div className='metrics-sidebar__caption'>
              Highest weight
            </div>
            <div className='metrics-sidebar__statistic'>
              {maxWeight.value} lb.
              <div className='metrics-sidebar__aside'>({moment(maxWeight.date).format('M/D/YY')})</div>
            </div>
          </section>
          <section>
            <div className='metrics-sidebar__caption'>
              Lowest weight
            </div>
            <div className='metrics-sidebar__statistic'>
              {minWeight.value} lb.
              <div className='metrics-sidebar__aside'>({moment(minWeight.date).format('M/D/YY')})</div>
            </div>
          </section>
        </div>
      )
    }

    return <div className='metrics-sidebar metrics-sidebar--weight' />
  }

  const renderBloodPressureSidebar = (): JSX.Element => {
    const systolicDelta = getSystolicDeltaInRange(props.member)
    const diastolicDelta = getDiastolicDeltaInRange(props.member)
    const heartRateDelta = getBloodPressureHeartRateDeltaInRange(props.member)

    const validBloodPressureDelta = Number.isFinite(systolicDelta) &&
      Number.isFinite(diastolicDelta) && Number.isFinite(heartRateDelta)

    const thisPeriod = props.member.blood_pressure.information.periods[0]

    return (
      <div className='metrics-sidebar metrics-sidebar--blood_pressure' data-testid='metrics-sidebar__blood-pressure'>
        <section>
          <div className='metrics-sidebar__caption'>Average</div>
          <div className='metrics-sidebar__statistic'>
            <div className='metrics-sidebar__bp-stats'>
              <span className='metrics-sidebar__column'>
                <span className='metrics-sidebar__bp'>{thisPeriod.avg_systolic}/{thisPeriod.avg_diastolic}</span>
              </span>
              <span className='metrics-sidebar__secondary'>
                <span className='metrics-sidebar__heart-rate'>{thisPeriod.avg_heart_rate} bpm</span>
              </span>
            </div>
          </div>
        </section>
        <section>
          <div className='metrics-sidebar__caption'>Change from previous period</div>
          <div className='metrics-sidebar__statistic'>
            <div className='metrics-sidebar__bp-stats'>
              <span className='metrics-sidebar__column'>
                <span className='metrics-sidebar__bp'>
                  {validBloodPressureDelta && <span>{getDisplayDelta(Number(systolicDelta))}/{getDisplayDelta(Number(diastolicDelta))}</span>}
                  {!validBloodPressureDelta && <span>—</span>}
                </span>
              </span>
              <span className='metrics-sidebar__secondary'>
                <span className='metrics-sidebar__heart-rate'>{getDisplayDelta(Number(heartRateDelta))} bpm</span>
              </span>
            </div>
          </div>
        </section>
      </div>
    )
  }

  const renderBloodGlucoseSidebar = (): JSX.Element => {
    const bgDelta = getBloodGlucoseDeltaInRange(props.member)
    const validBgDelta = Number.isFinite(bgDelta)

    const thisPeriod = props.member.blood_glucose.information.periods[0]

    return (
      <div className='metrics-sidebar metrics-sidebar--blood_glucose'>
        <section>
          <div className='metrics-sidebar__caption'>Average</div>
          <div className='metrics-sidebar__statistic'>
            <div className='metrics-sidebar__bg-stats'>
              <span className='metrics-sidebar__column'>
                <span className='metrics-sidebar__bg'>{thisPeriod.avg_blood_glucose} mg/dL</span>
              </span>
            </div>
          </div>
        </section>
        <section>
          <div className='metrics-sidebar__caption'>Change from previous period</div>
          <div className='metrics-sidebar__statistic'>
            <div className='metrics-sidebar__bg'>
              {validBgDelta && <span>{getDisplayDelta(Number(bgDelta))} mg/dL</span>}
              {!validBgDelta && <span>—</span>}
            </div>
          </div>
        </section>
      </div>
    )
  }

  const renderPregnancyWeightSidebar = (): JSX.Element => {
    const gainRateObj = props.member.weight.information.gain_rate
    const gainRate = gainRateObj ? `${preciseRound(gainRateObj.value, 1)}` : '—'
    const weightGainInPregnancy = getWeightGainInPregnancy(props.member)
    const weightGainInRange = getWeightGainInRange(props.member)
    const { min: minExpectedDeliveryDateIOM, max: maxExpectedDeliveryDateIOM } = getIOMRangeOnExpectedDeliveryDate(props.member)

    return (
      <div className='metrics-sidebar metrics-sidebar--weight'>
        <section>
          <div className='metrics-sidebar__caption'>2-week weight gain trend</div>
          <div className='metrics-sidebar__statistic'>
            {gainRate}
            <span className='metrics-sidebar__unit'>lb/wk</span>
          </div>
        </section>
        <section>
          <div className='metrics-sidebar__caption'>Weight gain this period</div>
          <div className='metrics-sidebar__statistic'>
            {weightGainInRange ?? '—'}
            <span className='metrics-sidebar__unit'>lb</span>
          </div>
        </section>
        <section>
          <div className='metrics-sidebar__caption'>Total pregnancy weight gain</div>
          <div className='metrics-sidebar__statistic'>
            {weightGainInPregnancy ?? '—'}
            <span className='metrics-sidebar__unit'>lb</span>
          </div>
        </section>
        <section>
          <div className='metrics-sidebar__caption'>IOM range on expected delivery date</div>
          <div className='metrics-sidebar__statistic'>
            {`${minExpectedDeliveryDateIOM}—${maxExpectedDeliveryDateIOM}`}
            <span className='metrics-sidebar__unit'>lb</span>
          </div>
        </section>
      </div>
    )
  }

  const renderActivitySidebar = (): JSX.Element => {
    const dailyActivityGoal = props.member.careplan.find(c => c.type === 'activity_target' && c.subtype === 'daily')
    const renderActivityGoal = (): JSX.Element => {
      if (!dailyActivityGoal) return <> - </>
      if (dailyActivityGoal.disabled) return <div>Disabled</div>
      else {
        return (
        <div>
          {dailyActivityGoal.value}
          <span className='metrics-sidebar__unit'>{dailyActivityGoal.unit} <em>(moderate/intense)</em></span>
        </div>
        )
      }
    }

    return (
      <div className='metrics-sidebar metrics-sidebar--activity'>
        <section className='metrics-sidebar__multi-stat-section'>
          <div className='metrics-sidebar__caption'>Total activity</div>
          <div className='metrics-sidebar__statistic'>
            {getActivityStepsInRange(props.member).toLocaleString()}
            <span className='metrics-sidebar__unit'>steps</span>
          </div>
          <div className='metrics-sidebar__statistic'>
            {getActivityMinutesInRange(props.member).toLocaleString()}
            <span className='metrics-sidebar__unit'>min <em>(moderate/intense)</em></span>
          </div>
        </section>

        <hr />

        <section className='metrics-sidebar__multi-stat-section'>
          <div className='metrics-sidebar__caption'>
            Daily average
          </div>
          <div className='metrics-sidebar__statistic'>
            {getActivityStepsAverageInRange(props.member).toLocaleString()}
            <span className='metrics-sidebar__unit'>steps</span>
          </div>
          <div className='metrics-sidebar__statistic'>
            {getActivityMinutesAverageInRange(props.member)}
            <span className='metrics-sidebar__unit'>min <em>(moderate/intense)</em></span>
          </div>
        </section>

        <hr />

        <section className='metrics-sidebar__multi-stat-section'>
          <div className='metrics-sidebar__caption'>
            Daily activity goal
          </div>
          <div className='metrics-sidebar__statistic'> {renderActivityGoal()} </div>
        </section>
      </div>
    )
  }

  const renderSleepSidebar = (): JSX.Element => {
    const {
      hours: sleepHours,
      minutes: sleepMins
    } = getHoursMinutes(Number(getSleepAverage(props.member)))

    return (
      <div className='metrics-sidebar metrics-sidebar--sleep'>
        <section>
          <div className='metrics-sidebar__caption'>Average sleep per night</div>
          <div className='metrics-sidebar__statistic'>
            {sleepHours} <span className='metrics-sidebar__unit'>hr</span>
            {sleepMins} <span className='metrics-sidebar__unit'>min</span>
          </div>
        </section>
      </div>
    )
  }

  const renderFoodSidebar = (): JSX.Element => {
    const calorieGoal = props.member.careplan.find(c => c.type === 'calorie_target')
    const avgCalories = getCaloriesAverage(props.member)
    const twoWeekAvgCalories = props.member.food.information.periods[0].two_week_avg_calories

    return (
      <div className='metrics-sidebar metrics-sidebar--food'>
        <section>
          <div className='metrics-sidebar__caption'>2-week daily calorie average</div>
          <div className='metrics-sidebar__statistic'>
            {twoWeekAvgCalories ?? '—'}
            <span className='metrics-sidebar__unit'>kcal</span>
          </div>
        </section>

        <section>
          <div className='metrics-sidebar__caption'>Daily calorie average this period</div>
          <div className='metrics-sidebar__statistic'>
            {avgCalories ?? '—'}
            <span className='metrics-sidebar__unit'>kcal</span>
          </div>
        </section>

        <section>
          <div className='metrics-sidebar__caption'>Daily calorie goal</div>
          <div className='metrics-sidebar__statistic'>
            {calorieGoal.disabled
              ? <div>Disabled</div>
              : <div>{calorieGoal.value} <span className='metrics-sidebar__unit'>{calorieGoal.unit}</span></div>}
          </div>
        </section>
      </div>
    )
  }

  const renderMetricsSidebar = (): JSX.Element | null => {
    switch (props.sidebarType) {
      case 'WEIGHT':
        return hasPregnancyWeight ? renderPregnancyWeightSidebar() : renderWeightSidebar()
      case 'ACTIVITY':
        return renderActivitySidebar()
      case 'SLEEP':
        return renderSleepSidebar()
      case 'FOOD':
        return renderFoodSidebar()
      case 'BLOOD_PRESSURE':
        return renderBloodPressureSidebar()
      case 'BLOOD_GLUCOSE':
        return renderBloodGlucoseSidebar()
      default:
        return null
    }
  }

  return renderMetricsSidebar()
}
