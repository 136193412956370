
import React from 'react'

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { type IAlertRows } from '../../../interfaces/alert.interface'
import Pagination from '../../elements/Pagination'
import { type IGenericTableHeaderColumn } from '../../../interfaces/ejenta.interface'

interface IAlertTableProps {
  rows: IAlertRows
  columns: IGenericTableHeaderColumn[]
  page: number
  pageStart: number
  pageEnd: number
  total: number
  viewActions: any
  fetchPagingAlerts: () => void
  handleItemClick: (id: string) => void
}

export default function AlertTable (props: IAlertTableProps): JSX.Element {
  const { rows, columns } = props
  if (!Object.keys(rows).length) return <></>

  const handleChangePage = (page: number): void => {
    props.viewActions.setPage(page)
    props.fetchPagingAlerts()
  }

  const handlePagePrev = (): void => {
    props.viewActions.pagePrev()
    props.fetchPagingAlerts()
  }

  const handlePageNext = (): void => {
    props.viewActions.pageNext()
    props.fetchPagingAlerts()
  }

  const renderPaginator = (): JSX.Element => {
    return (
      <Pagination
        pageStart={props.pageStart + 1}
        pageEnd={props.pageEnd}
        itemCount={props.total ?? 0}
        handlePagePrev={handlePagePrev}
        handlePageNext={handlePageNext}
        page={props.page ?? 1}
        setPage={handleChangePage}
        custom
      />)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {renderPaginator()}
      <TableContainer sx= {{ maxHeight: '75vh' }}>
        <Table stickyHeader aria-label="alerts table">
          <TableHead sx={{ position: 'sticky' }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth, padding: 0 }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(rows).map((alertId) =>
              <TableRow hover role="checkbox" tabIndex={-1} key={alertId} className='alert-row'>
                {columns.map((column) => {
                  const row = rows[alertId]
                  const onClick = column.label ? () => { props.handleItemClick(alertId) } : () => {}
                  const className = column.label ? 'alert-row__item-data' : ''

                  return (<TableCell className={className} onClick={onClick} key={column.id} style={{ padding: 0, cursor: 'pointer' }}> {row[column.id]} </TableCell>)
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {renderPaginator()}
    </Paper>
  )
}
