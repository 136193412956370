import moment, { type MomentInput, type Moment } from 'moment'
import React, { useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { ANCHOR_RIGHT } from 'react-dates/constants'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { type IDateRange } from '../../interfaces/ejenta.interface'

interface IDateRangeProps {
  startDate: Moment
  endDate: Moment
  onRangeSelect: (startDate: MomentInput, endDate: MomentInput) => void
}

/* *****************************************************************************
 * Wrapper around third-party react-dates component.
 * *****************************************************************************/
function DateRangeController (props: IDateRangeProps): JSX.Element {
  const [focusedInput, setFocusedInput] = useState(null)
  const [startDate, setStartDate] = useState<MomentInput>(props.startDate ? props.startDate : moment().subtract(7, 'days'))
  const [endDate, setEndDate] = useState<MomentInput>(props.endDate ? props.endDate : moment())

  const handleDatesChange = ({ startDate, endDate }: IDateRange): void => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const handleFocusChange = (newFocus: any): void => {
    const newEndDate = !newFocus && !endDate ? startDate : endDate
    setFocusedInput(newFocus)
    setEndDate(newEndDate)
    if (!newFocus) props.onRangeSelect(startDate, newEndDate)
  }

  const renderDateRangePicker = (): JSX.Element => {
    return (
      <div className='date-range-picker'>
        <DateRangePicker
          isOutsideRange={(d: Moment) => d.isAfter(moment())}
          onDatesChange={handleDatesChange}
          onFocusChange={handleFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          initialVisibleMonth={() => moment().subtract(1, 'month')}
          showDefaultInputIcon
          customInputIcon={<DateRangeIcon />}
          displayFormat='MMM D, Y'
          anchorDirection={ANCHOR_RIGHT}
          hideKeyboardShortcutsPanel
          startDatePlaceholderText='Select start date'
          endDatePlaceholderText='Select end date'
        />
      </div>
    )
  }

  return renderDateRangePicker()
}

export default DateRangeController
