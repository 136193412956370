import _ from 'lodash'
import moment from 'moment'
import { getIOMRange, getIOMRangesForPregnancy } from '../member/analyticsUtilityHelper'
import {
  getBaseChartOptions,
  getBaseSeriesOptions,
  addPausePlotBands,
  getPointDateString,
  getEmptyChart
} from './baseChartUtils'
import { baseWeightChartOptions, getWeightAlertsSeries, getDeletedWeightMeasurementsSeries } from './baseWeightChart'
import { preciseRound } from '../unitConversionUtils'
import { getXAxisForRange, getMarkerRadius } from './chartUtils'
import { type SeriesArearangeOptions, type Options, type SeriesLineOptions } from 'highcharts'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'
import { type IWeightClick, type IWeightData } from '../../interfaces/weight-summary.interface'
import { type ILineSeries, type IAreaRangeSeries, type IChartOptionsParams } from '../../interfaces/chart.interface'

const weightChartOptions = _.assign(baseWeightChartOptions, {
  tooltip: {
    headerFormat: null,
    pointFormatter: function format () {
      const point = this as any
      return `
        <strong>${point.name as string}</strong><br/>
        ${point.dataString as string ?? ''}
      `
    },
    useHTML: true,
    shared: true,
    stickOnContact: true,
    borderWidth: 0
  }
})

const getWeightTooltipContent = (memberObj: IMemberSummary, measurement: IWeightData): string => {
  const { min, max } = getIOMRange(memberObj, measurement.timestamp)

  const iomFraction = (measurement.weight.value - min) / (max - min)
  let iomColor = '🟢 Green'
  if (iomFraction >= 1) iomColor = '🔴 Red'
  else if (iomFraction >= 0.75) iomColor = '🟡 Yellow'

  const minRounded = preciseRound(min, 0)
  const maxRounded = preciseRound(max, 0)
  const threeQuartersRounded = preciseRound(min + (0.75 * (max - min)), 0)

  return '<div>' +
    `Weight: ${measurement.weight.value} ${memberObj.weight?.information.units.weight ?? ''} (${iomColor})<br/>` +
    `🟢 Green: ${minRounded}—${threeQuartersRounded}<br/>` +
    `🟡 Yellow: ${threeQuartersRounded}—${maxRounded}<br/>` +
    `🔴 Red: Over ${maxRounded}<br/>` +
    `<small>Source: ${measurement.source ?? 'Unknown'}</small>` +
    '<small style=\'justify-content: right; width: 100%; display: flex\'>Click to delete</small>' +
    '</div>'
}

export function getPregnancyWeightMeasurementsSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore, handleDeleteWeight: IWeightClick): Options {
  const newOptions = _.cloneDeep(options)

  // Set marker radius
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))
  const weightData = memberObj.weight ? [...memberObj.weight.data] : []

  const weightMeasurements = _.reverse(weightData).map((m) => {
    const point = {
      x: +moment(m.timestamp),
      y: m.weight.value,
      name: getPointDateString(moment(m.timestamp), true),
      useHtml: true,
      dataString: m,
      events: {
        click: () => {
          if (handleDeleteWeight) {
            handleDeleteWeight(m.timestamp)
          }
        }
      }
    }
    return point
  })

  const weightMeasurementsSeries: SeriesLineOptions = _.extend({
    name: 'Weight',
    data: weightMeasurements,
    zIndex: 1,
    tooltip: {
      useHtml: true,
      headerFormat: '',
      pointFormatter: function format () {
        const point = this as any
        return `
          <strong>${point.name as string}</strong><br/>
          ${getWeightTooltipContent(memberObj, point.dataString)}
        `
      }
    },
    type: 'line' as ILineSeries,
    className: 'series-weightMeasurements'
  }, getBaseSeriesOptions())

  newOptions.series?.push(weightMeasurementsSeries)
  return newOptions
}

const highlights = ['#F98076', '#F3E154', '#95D542']

function getIOMRangeSeries (memberObj: IMemberSummary, options: Options): Options {
  const newOptions: Options = _.cloneDeep(options)

  const ranges = getIOMRangesForPregnancy(memberObj)
  const recommendedRangesFrom0To75 = ranges.map(r => {
    const bottom = r.ranges.min
    const top = r.ranges.max
    const diff25 = ((bottom - top) / 4)
    const p25 = top + diff25
    return [+moment(r.date), bottom, p25]
  })
  const recommendedRangesFrom75To100 = ranges.map(r => {
    const bottom = r.ranges.min
    const top = r.ranges.max
    const diff25 = ((bottom - top) / 4)
    const p25 = top + diff25
    return [+moment(r.date), p25, top]
  })

  const areaRange0To75: SeriesArearangeOptions = _.extend({
    name: 'IOM 0 - 75%',
    data: recommendedRangesFrom0To75,

    type: 'arearange' as IAreaRangeSeries,
    marker: { enabled: false },
    className: 'series-weightIOM',
    color: highlights[2],

    enableMouseTracking: false
  }, getBaseSeriesOptions())

  const areaRange75To100: SeriesArearangeOptions = _.extend({
    name: 'IOM 75 - 100%',
    data: recommendedRangesFrom75To100,

    type: 'arearange' as IAreaRangeSeries,
    marker: { enabled: false },
    className: 'series-weightIOM',
    color: highlights[1],

    enableMouseTracking: false
  }, getBaseSeriesOptions())

  newOptions.series?.push(areaRange0To75)
  newOptions.series?.push(areaRange75To100)
  return newOptions
}

function getYAxisForRanges (memberObj: IMemberSummary, options: any): Options {
  /*
    THIS ASSUMES THAT THE FIRST SERIES OF THE WEIGHT CHART CONTAINS WEIGHT MEASUREMENTS!
    Set the bounds for the yAxis, since for some reason it won't automatically adjust as of 5.0.10
  */
  const newOptions = _.cloneDeep(options)

  // Get min/max weight measurement
  const minMeasurement = Math.min(...options.series[0].data.map(i => i.y))
  const maxMeasurement = Math.max(...options.series[0].data.map(i => i.y))

  // Get min/max of recommended range
  const minRangeBound = getIOMRange(memberObj, options.xAxis.min).min
  const maxRangeBound = getIOMRange(memberObj, options.xAxis.max).max

  // Set min/max for yAxis
  if (newOptions.yAxis) {
    newOptions.yAxis.min = Math.round(Math.min(minMeasurement, minRangeBound) * 0.98)
    newOptions.yAxis.max = Math.round(Math.max(maxMeasurement, maxRangeBound) * 1.02)
  }
  return newOptions
}

export default function getPregnancyWeightChartOptions ({
  memberObj, dashboardViewStore, isMobile,
  showMemberAlerts, handleAlertClick,
  handleDeleteWeight, handleRestoreWeight
}: IChartOptionsParams): Options {
  if (!memberObj.weight) return getEmptyChart()

  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, isMobile ?? false) }

  let options: Options = _.merge(
    getBaseChartOptions(),
    weightChartOptions,
    xAxisOptions
  )

  // Add individual series
  if (handleDeleteWeight) options = getPregnancyWeightMeasurementsSeries(options, memberObj, dashboardViewStore, handleDeleteWeight)
  if (handleRestoreWeight) options = getDeletedWeightMeasurementsSeries(options, memberObj, dashboardViewStore, handleRestoreWeight)
  options = getIOMRangeSeries(memberObj, options)
  options = getYAxisForRanges(memberObj, options)
  if (showMemberAlerts && !!handleAlertClick) options = getWeightAlertsSeries(memberObj, options, handleAlertClick)
  options = addPausePlotBands(memberObj.user.pauses ?? [], options, false)

  return options
}
