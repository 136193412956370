import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

export default function SelfSignupFooter (props: { width?: string }): JSX.Element {
  return (
    <Box sx={{ maxWidth: '75vw', width: props.width ?? '100%', mb: 2 }} className='self-signup__footer login-form self-signup'>
      <Box sx={{ margin: 'auto' }}>
        <Box sx={{ width: '100%', alignItems: 'center', display: 'inline-flex' }}>
          <QuestionAnswerIcon sx={{ mr: 1 }} />
          <Box>
            Need help? Contact us by email at <Link underline='none' color='white' href='mailto:help@ejenta.com'>help@ejenta.com</Link>&nbsp;
            or by phone at <Link underline='none' color='white' href='tel:4154830304'>415-483-0304</Link>
          </Box>
        </Box>
        <Typography variant='caption'>
          Please note that this is NOT AN EMERGENCY RESPONSE UNIT AND IS NOT MONITORED 24/7.
          If at any time you feel you are experiencing an immediate medical emergency, call 911 and go to the nearest emergency room.
        </Typography>
      </Box>
    </Box>
  )
}
