import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { ALERT_SORTS } from '../../utils/baseAlertUtils'
import { renderFilters } from '../../utils/baseListUtils'
import { CARE_MANAGER_DEFAULT_OPTIONS } from '../../constants/constants'
import { chatsViewSlice, fetchPagingChats } from '../../store/chatsView'
import { productConfig } from '../../config/config-service'
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material'
import ContentSidebar from '../layout/ContentSidebar'
import SearchBar from '../elements/SearchBar'
import { type ICareManager } from '../../interfaces/store/sessionStore.interface'
import { type DefaultFilterState } from '../../interfaces/product-config.interface'
import { CHATS_VIEW } from '../../utils/chatUtils'
import { type RootState, type AppDispatch } from '../..'

interface IChatSidebarProps extends PropsFromRedux {
  resetView: () => void
}

function RefineChatsSidebar (props: IChatSidebarProps): JSX.Element {
  const [careManagerOptions, setCareManagerOptions] = useState<ICareManager []>([])
  const [selectedCareManager, setSelectedCareManager] = useState('')
  const [isDefaultState, setIsDefaultState] = useState(true)
  const config = productConfig().chats.refineSidebar

  const onSearchChange = (searchQuery: string): void => {
    props.chatsViewActions.setSearchQuery(searchQuery)
    props.fetchChats()
  }

  const handleSortChange = (e): void => {
    props.chatsViewActions.setSort(e.target.value)
    props.fetchChats()
  }

  const handleFilterChange = (filterId: string): void => {
    props.chatsViewActions.setFilter(filterId)
    props.fetchChats()
  }

  const handleCareManagerChange = (e): void => {
    props.chatsViewActions.setCareManagerId(e.target.value)
    setSelectedCareManager(e.target.value)
    props.fetchChats()
  }

  useEffect(() => {
    const isReset = props.chatsViewStore?.searchQueryReset
    if (isReset && careManagerOptions?.length > 0) setSelectedCareManager(careManagerOptions[0].id)
  }, [props.chatsViewStore?.searchQueryReset])

  useEffect(() => {
    const { careManagerId, careManagerFilters, filters, searchQuery, sortBy } = config.defaultFilterState as DefaultFilterState
    const storeFilters = [...props.chatsViewStore?.filters].sort()

    const matches =
      _.isMatch(props.chatsViewStore, { careManagerId, careManagerFilters, searchQuery, sortBy }) &&
      _.isEqual(storeFilters.sort(), filters?.sort())

    setIsDefaultState(matches)
  }, [props.chatsViewStore?.filters, props.chatsViewStore?.searchQuery])

  useEffect(() => {
    const fetchedCareManagers = props.userSessionStore?.resources?.care_managers ?? []
    const constructedOptions = [...CARE_MANAGER_DEFAULT_OPTIONS, ...fetchedCareManagers]
    const filters = props.chatsViewStore?.careManagerFilters

    if (filters && filters?.length > 0) setSelectedCareManager(filters[0])
    else setSelectedCareManager(constructedOptions[0]?.id)

    setCareManagerOptions(constructedOptions)
  }, [])

  const renderResetFilterButton = (): JSX.Element => {
    return (
      <Box sx={{ width: '100%', textAlign: 'right', minHeight: '24.5px' }}>
        {!isDefaultState && <Button sx={{ padding: 0 }} onClick={props.resetView}>Reset Filters</Button>}
      </Box>
    )
  }

  const renderSearchBar = (): JSX.Element => {
    return (
      <section className='list-controls__section'>
        <Typography variant='subtitle2'>Search</Typography>

        <SearchBar
          handleChange={onSearchChange}
          placeholder='Member name or study ID'
          value={props.chatsViewStore?.searchQuery }
          resetSearch={props.chatsViewStore?.searchQueryReset }
        />
      </section>
    )
  }

  const renderSortSection = (): JSX.Element => {
    const defaultDisplayStrMap = {
      [ALERT_SORTS.SORT_STATUS]: 'Chat status (open/closed)',
      [ALERT_SORTS.DATE_DESC]: 'Date (newest first)',
      [ALERT_SORTS.DATE_ASC]: 'Date (oldest first)'
    }

    const options = Object.keys(defaultDisplayStrMap).map(key =>
      <MenuItem value={key} key={key}>{defaultDisplayStrMap[key]}</MenuItem>
    )

    return (
      <section className='list-controls__section'>
        <Typography variant='subtitle2'>Sort by</Typography>

        <TextField
          fullWidth
          select
          size='small'
          id='refine-chats__sort-by'
          value={props.chatsViewStore?.sortBy}
          onChange={handleSortChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </TextField>
      </section>
    )
  }

  const renderCareManagerSection = (): JSX.Element => {
    const options = careManagerOptions.map(s =>
      <MenuItem key={s.id ?? s.name} value={s.id ?? s.name}>{s.name}</MenuItem>
    )

    return (
      <section className='list-controls__section' data-testid='list-controls__care-manager-section'>
        <Typography variant='subtitle2'>Care Manager</Typography>

        <TextField
          fullWidth
          select
          size='small'
          color='primary'
          id='refine-chats__care-manager-select'
          data-testid='refine-chats__care-manager-select'
          value={selectedCareManager}
          onChange={handleCareManagerChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </TextField>
      </section>
    )
  }

  const renderRefineChatsSidebar = (): JSX.Element => {
    return (
      <ContentSidebar drawerWidth={285}>
        {renderResetFilterButton()}

        <div className='content-sidebar__module'>
          {renderSearchBar()}

          {renderSortSection()}

          {config.hasCareManagerFilter && renderCareManagerSection()}

          <section>
            {renderFilters(
              CHATS_VIEW.FILTER_GROUPS,
              props.chatsViewStore?.filters,
              props.chatsViewStore?.filterCounts as Record<string, number>,
              handleFilterChange
            )}
          </section>
        </div>

      </ContentSidebar>
    )
  }

  return renderRefineChatsSidebar()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    userSessionStore: state.userSession,
    chatsViewStore: state.chatsView
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    chatsViewActions: bindActionCreators(chatsViewSlice.actions, dispatch),
    fetchChats: bindActionCreators(fetchPagingChats, dispatch)
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(RefineChatsSidebar)
