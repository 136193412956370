import React, { useEffect, useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { EjentaForm, renderField, renderInput } from '../../elements/EjentaForm'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import LoadingIndicator from '../../elements/LoadingIndicator'
import restApi from '../../../api'
import BasicFrame from '../../layout/BasicFrame'
import { Form } from 'react-final-form'
import { useLocation } from 'react-router'
import { type RootState, type AppDispatch } from '../../..'

/* ******************************************************************************
 * Reset password form
 * https://dev.ejenta.com:7443/reset?token=12345678
 * ******************************************************************************/
function ResetPasswordForm (props: PropsFromRedux): JSX.Element {
  const [isDone, setIsDone] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (!location) return

    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    props.verifyResetPasswordToken(token)
  }, [location])

  const handleFormSubmit = async (data): Promise<any> => {
    const submitPromise = new Promise((resolve, reject) => {
      const formData = {
        newpassword: data.newpassword,
        reset_id: props.verifyState?.data.id,
        token: props.verifyState?.data.token
      }

      props.submitResetPassword(
        { promise: { resolve, reject } },
        { body: JSON.stringify(formData) }
      )
    })

    return await submitPromise
      .then(() => { setIsDone(true) })
      .catch(error => {
        setIsDone(false)
        return error
      })
  }

  const renderFormButton = ({ pristine, submitting, errors }): JSX.Element => {
    const hasErrors = Object.keys(errors)?.length > 0
    if (submitting) {
      return (<Button variant='contained' color='secondary' disabled> Updating password... </Button>)
    } else return (<Button variant='contained' type='submit' disabled={pristine || hasErrors}> Update password </Button>)
  }

  const renderForm = (): JSX.Element => {
    const newPasswordField = {
      name: 'newpassword',
      type: 'password',
      label: 'New password',
      component: renderInput
    }

    const newPasswordConfirmField = {
      name: 'newPasswordConfirm',
      type: 'password',
      label: 'Confirm new password',
      component: renderInput
    }

    return (
      <Box>
        <Typography textAlign='center' variant='h6' mb={1}>Create new password</Typography>
        <Form
          onSubmit={handleFormSubmit}
          validate={values => {
            const errors: { newPasswordConfirm?: string } = {}
            if (values.newpassword !== values.newPasswordConfirm) errors.newPasswordConfirm = 'Passwords must match'
            return errors
          }}
        >
          {({ handleSubmit, pristine, submitting, submitError, errors }) => (
            <EjentaForm onSubmit={handleSubmit}>

              {submitError && <Alert severity='error'>{submitError}</Alert>}
              <Stack spacing={2}>
                {renderField({}, newPasswordField)}
                {renderField({}, newPasswordConfirmField)}
              </Stack>
              <Box mt={2} />
              {renderFormButton({ pristine, submitting, errors })}
            </EjentaForm>
          )}
        </Form>
      </Box>
    )
  }

  const renderDone = (): JSX.Element => {
    return (
      <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', mb: 1 }}>
        <Typography variant='h6' mb={2}>Create new password</Typography>
        <Typography mb={2}> You've successfully updated your password.</Typography>
        {!!props.resetState?.data.clinician && <Button variant='contained' fullWidth href='/login'>Continue to sign in</Button>}
      </Box>
    )
  }

  const renderError = (): JSX.Element => {
    return (
      <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', mb: 1 }}>
        <Typography variant='h6' mb={2}>Oops!</Typography>
        <Typography mb={2}> Your link to create a new password is either expired or invalid.&nbsp; </Typography>
        <Button variant='contained' fullWidth href='/forgot'>Send a new link</Button>.
      </Box>
    )
  }

  const renderResetPasswordForm = (): JSX.Element => {
    if (!props.verifyState?.sync) return <LoadingIndicator sidebarVisible />

    let body = renderDone()
    if (!props.verifyState?.data.id) body = renderError()
    else if (!props.resetState?.sync || !isDone) body = renderForm()

    return (<BasicFrame><Box className='login-form'> {body} </Box></BasicFrame>)
  }

  return renderResetPasswordForm()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    verifyState: state.api.verifyResetPasswordToken,
    resetState: state.api.resetPassword
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    verifyResetPasswordToken (token) {
      dispatch(restApi.actions.verifyResetPasswordToken({ token }))
    },
    submitResetPassword (pathvars, params) {
      dispatch(restApi.actions.resetPassword(pathvars, params))
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ResetPasswordForm)
