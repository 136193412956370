import React from 'react'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { productConfig } from '../../../../config/config-service'
import { renderLevel } from '../../../../utils/alertUtils'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { Box } from '@mui/material'

export default class PulseOxRangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'pulse_ox_range'
    this.config = productConfig()
  }

  rangeOxygenSaturationMinInputElement (): JSX.Element {
    return this.getInputElement(
      75, 99, 1, //       75, 99, 1, for KPCovid19
      CAREPLAN_VALUE_TYPES.SPO2_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.SPO2_MIN.set(val => val)
    )
  }

  isDisabled (): boolean {
    const minOxygen = this.getValue(CAREPLAN_VALUE_TYPES.SPO2_MIN.get(val => val))
    const disabled = minOxygen <= 75
    return disabled
  }

  renderGoal (): JSX.Element {
    return <div>Keep SpO₂ in range</div>
  }

  renderClinicianTrigger (): JSX.Element {
    const disabled = this.isDisabled()
    return <div className={disabled ? 'disabled' : ''}>If SpO₂ is below {this.rangeOxygenSaturationMinInputElement()}%.</div>
  }

  renderCondensed (): JSX.Element {
    const className: string = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    if (this.isDisabled()) return <div className='disabled'>Low SpO₂ alerts</div>
    return <Box className={className}>{renderLevel(this.state.itemData?.level as number, true)} SpO₂ &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.SPO2_MIN.get(val => val))}%</Box>
  }
}
