import React from 'react'
import { connect } from 'react-redux'
import { type RootState } from '../..'

/*
 * Higher-order component that provides the base component with a prop, featureGates, that
 * contains an object with the same keys as FEATURE_GATES (in constants/constants.js)
 *
 * Example usage:
 *
 *     function MyCoolComponent(props) {
 *       // Selectively render UI based on this.props.featureGates
 *     }
 *
 *     export default includeFeatureGates(MyCoolComponent)
 */

function includeFeatureGates (BaseComponent: any): any {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function mapState (state: RootState) {
    return {
      userSession: state.userSession
    }
  }

  function FeaturedGatedComponent (props: any): JSX.Element {
    return <BaseComponent {...props} featureGates={props.userSession.featureGates} />
  }

  const connector = connect(mapState)
  // type PropsFromRedux = ConnectedProps<typeof connector>
  return connector(FeaturedGatedComponent)
}

export default includeFeatureGates
