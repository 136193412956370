import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const BloodPressureIcon = React.forwardRef(function BloodPressureIcon (props, ref) {
  const viewBox = '5 0 50 70'

  return (
    // @ts-expect-error TS doesn't like ... object spread
    <SvgIcon viewBox={viewBox} {...props} ref={ref}>
      <rect x='45' width='19' height='45' rx='5' ry='5' />
      <path d='M30 32a10 10 0 1 0 10 10 10 10 0 0 0-10-10zm4.447 7.2l-2.7 4.491-.288.2-4.259 2.555a1.2 1.2 0 0 1-1.647-1.646l2.7-4.491.288-.2 4.264-2.56a1.2 1.2 0 0 1 1.642 1.651z' />
      <path d='M22 5v6h-4.5A9.511 9.511 0 0 0 8 20.5V39H7a2 2 0 0 0-2 2v1.692A10.381 10.381 0 0 0 1 51a10.026 10.026 0 0 0 5.57 9.237.708.708 0 0 1 .43.641A3.272 3.272 0 0 0 10 64a3.007 3.007 0 0 0 3-3v-.122a.708.708 0 0 1 .43-.641A10.026 10.026 0 0 0 19 51a10.381 10.381 0 0 0-4-8.308V41a2 2 0 0 0-2-2h-2V20.5a6.508 6.508 0 0 1 6.5-6.5H22v19.081A11.98 11.98 0 0 1 42 42c0 .338-.023.669-.051 1H43V2H25a3 3 0 0 0-3 3z' />
    </SvgIcon>
  )
})

export default BloodPressureIcon
