/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import LoadingIndicator from '../LoadingIndicator'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { type IChartTableProps } from '../../../interfaces/chart-table.interface'
import { mapProfileDispatch } from '../../../constants/reduxMappers'

function EmptyTable (props: IChartTableProps): JSX.Element {
  const renderTable = (): JSX.Element => {
    if (props.dashboardViewStore.isRangeLoading) {
      return (<div className='metrics-section__inner'> <LoadingIndicator /> </div>)
    }

    return (
        <Box>
          <Typography variant='body1' color='#7f7f7f' marginTop={1.5}>Table data coming soon</Typography>
        </Box>
    )
  }

  return renderTable()
}

const connector = connect(null, mapProfileDispatch)
export default connector(EmptyTable)
