import React from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import LastPageIcon from '@mui/icons-material/LastPage'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import { AppFooter } from './Footer'
import { IconButton, Tooltip } from '@mui/material'
import { type ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sidebarSlice } from '../../store/sidebarView'
import { dashboardViewSlice } from '../../store/dashboardView'
import { type IDefaultProps } from '../../interfaces/ejenta.interface'
import { type AppDispatch, type RootState } from '../..'

export const drawerWidth = 300

interface IContentSidebarProps extends IDefaultProps, PropsFromRedux {
  drawerWidth?: number
  dropsSidebar?: boolean
}

function ContentSidebar (props: IContentSidebarProps): JSX.Element {
  const sidebarWidth = props.drawerWidth ?? drawerWidth

  const toggleSidebar = (): void => {
    props.sidebarActions.toggleSidebarOpen()

    // Force any open charts to redraw
    props.dashboardActions.setRangeLoading(true)
    setTimeout(() => { props.dashboardActions.setRangeLoading(false) }, 300)
  }

  const sidebarContent = (): JSX.Element => {
    return (
      <Box
        sx={{
          height: '100%',
          padding: '0 .75rem 1rem .75rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        data-testid='profile-sidebar'
      >
        <Box> {props.children} </Box>
        <AppFooter />
      </Box>
    )
  }

  const renderResponsiveSidebar = (): JSX.Element => {
    const sidebarOpen = props.sidebarStore.sidebarOpen
    const buttonOnlyWidth = `${props.sidebarStore.buttonOnlyWidth}px`
    const tooltipTitle = sidebarOpen ? 'Close sidebar' : 'Open sidebar'

    return (
      <Drawer
        variant='permanent' open={sidebarOpen}
        sx={{
          width: sidebarOpen ? sidebarWidth : buttonOnlyWidth,
          '& .MuiDrawer-paper': {
            backgroundColor: '#F5F5F5',
            width: sidebarOpen ? sidebarWidth : buttonOnlyWidth,
            boxSizing: 'border-box'
          }
        }}
      >
        {!props.epicStore?.isEmbedded && <Toolbar sx={{ mb: 1 }} />}
        <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip title={tooltipTitle}>
            <IconButton
              size='large'
              aria-label='toggle sidebar open state'
              onClick={toggleSidebar}
              data-testid='toggle-sidbar__button'
            >
              {sidebarOpen && <FirstPageIcon />}
              {!sidebarOpen && <LastPageIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        {sidebarOpen && sidebarContent()}
      </Drawer>
    )
  }

  return renderResponsiveSidebar()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    sidebarStore: state.sidebar,
    dashboardStore: state.dashboardView,
    epicStore: state.epic
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    sidebarActions: bindActionCreators(sidebarSlice.actions, dispatch),
    dashboardActions: bindActionCreators(dashboardViewSlice.actions, dispatch)
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ContentSidebar)
