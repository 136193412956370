import React from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../layout/ContentMain'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
import { type IGroup } from '../../interfaces/group.interface'
import { type RootState } from '../..'
import { mapApiDispatch } from '../../constants/reduxMappers'

function GroupPickerPage (props: PropsFromRedux): JSX.Element {
  const navigate = useNavigate()

  const handleGroupClick = (selectedGroup: IGroup): void => {
    const formData = { body: JSON.stringify({ activeGroupName: selectedGroup.name }) }
    props.apiActions.setActiveGroup(null, formData)

    const url = new URL(window.location.href)
    if (url.searchParams.has('group')) {
      url.searchParams.set('group', selectedGroup.name)
      navigate(`${url.pathname}${url.search}`, { replace: true })
    }
  }

  const renderGroups = (allowedGroups: IGroup[]): JSX.Element[] => {
    return allowedGroups.map((allowedGroup) => {
      return (
        <Button
          fullWidth
          size='large'
          variant='outlined'
          color='secondary'
          key={allowedGroup.id}
          sx={{ justifyContent: 'flex-start', p: 2, mt: 2, mb: 2 }}
          onClick={() => { handleGroupClick(allowedGroup) }}
        >
          {allowedGroup.description}
        </Button>
      )
    })
  }

  const renderGroupPicker = (): JSX.Element => {
    return (
      <div className='u-top-page-margin'>
        {props.user && renderGroups(props.user.allowedGroups ?? [])}
      </div>
    )
  }

  const renderGroupPickerPage = (): JSX.Element => {
    return (
      <ContentMain dropsSidebar>
        <ContentMainIntro>
          <ContentMainIntroHeader title='Select Group' />
        </ContentMainIntro>

        <ContentMainBody>
          {renderGroupPicker()}
        </ContentMainBody>
      </ContentMain>
    )
  }

  return renderGroupPickerPage()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    user: state.userSession.user,
    activeGroup: state.userSession.activeGroup // todo indicate active group on page
  }
}

const connector = connect(mapState, mapApiDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(GroupPickerPage)
