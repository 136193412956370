import _ from 'lodash'
import React, { useEffect } from 'react'
import restApi from '../../../api'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { alertsViewSlice, fetchPagingAlerts } from '../../../store/alertsView'
import { ALERTS_VIEW, getHeaderColumns, getRowFromConfig, renderProfileCol } from '../../../utils/alertUtils'
import {
  ALERT_URL_FILTERS,
  DEFAULT_ALERT_FILTERS
} from '../../../utils/baseAlertUtils'
import { getDisplayName } from '../../../utils/baseStringUtils'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../layout/ContentMain'
import LoadingIndicator from '../../elements/LoadingIndicator'

import RefineAlertsSidebar from './RefineAlertsSidebar'
import AlertDetailModal from './AlertDetailModal'
import { humanizeAlertType } from '../../../constants/strings'
import { Alert, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'
import { ALERTS_PAGE_SIZE } from '../../../constants/constants'
import { type IAlert, type IAlertRow, type IAlertRows } from '../../../interfaces/alert.interface'
import { type RootState, type AppDispatch } from '../../..'
import AlertTable from './AlertTable'
import { type IGenericTableHeaderColumn } from '../../../interfaces/ejenta.interface'

function ClinicianAlertsPage (props: PropsFromRedux): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()

  const presetCarePlanItems = (): void => {
    const { carePlanItems } = props
    if (carePlanItems?.length) props.alertsViewActions.setCarePlanFilter(carePlanItems)

    props.fetchPagingAlerts()
  }

  useEffect(() => {
    if (props.alertsViewStore?.searchQueryReset) presetCarePlanItems()
  }, [props.alertsViewStore?.searchQueryReset])

  useEffect(() => {
    if (props.carePlanItems?.length) presetCarePlanItems()
  }, [props.carePlanItems])

  useEffect(() => {
    const { carePlanItems } = props
    if (carePlanItems?.length > 0) props.alertsViewActions.setCarePlanFilter(carePlanItems)
    else props.getDefaultCarePlan()

    props.alertsViewActions.setTotalFilterOptions(ALERTS_VIEW.FILTER_GROUPS.ALERT_TYPE.filters?.length)
    props.alertsViewActions.setUrlFilters(location.search)
  }, [])

  const handleClearSearch = (): void => {
    // Resets the search query, sorts, and filters
    props.alertsViewActions.resetView(false)
    navigate('/alerts') // reload this page, but without the query string
    props.fetchPagingAlerts()
  }

  const handleAlertDetailModalClose = (): void => {
    props.alertsViewActions.setSelectedAlert(null)
  }

  const renderCurrentQuery = (): JSX.Element => {
    // only display the information box when the filter comes from the URL
    const urlFilters: Record<string, string> = props.alertsViewStore?.urlFilters ?? {}
    if (!location.search) return <></>

    const filters = props.alertsViewStore?.filters
    let filterStr: JSX.Element = <></>
    if (filters) {
      if (filters.includes('FILTER/WITH_NOTE')) filterStr = <strong> with notes </strong>
      // add more descriptions for more filters
    }

    let userQueryStr: JSX.Element = <></>
    if (urlFilters[ALERT_URL_FILTERS.USER_ID] && props.alertsViewStore?.currentPage?.length > 0) {
      const filterUser = getDisplayName(props.alertsViewStore?.currentPage[0].user)
      userQueryStr = <span> for <strong>{filterUser}</strong></span>
    }

    let dateQueryStr: JSX.Element = <></>
    const startDate = urlFilters[ALERT_URL_FILTERS.START_DATE]
    const endDate = urlFilters[ALERT_URL_FILTERS.END_DATE]
    if (startDate && endDate) {
      if (startDate === endDate) {
        dateQueryStr = <span> based on data from <strong>{startDate}</strong></span>
      } else {
        dateQueryStr = <span> based on data from <strong>{startDate}</strong> to <strong>{endDate}</strong></span>
      }
    } else if (startDate) {
      dateQueryStr = <span> based on data from after <strong>{startDate}</strong></span>
    } else if (endDate) {
      dateQueryStr = <span> based on data from before <strong>{endDate}</strong></span>
    }

    let typeQueryStr
    const filterType = urlFilters[ALERT_URL_FILTERS.ALERT_TYPE]
    if (filterType && filters.includes(`FILTER/${filterType.toUpperCase()}`)) {
      typeQueryStr = <span> about <strong>{humanizeAlertType(filterType).toLowerCase()}</strong></span>
    }

    return (
      <Alert
        sx={{ mt: 1, mb: 1 }}
        severity='warning'
        action={<Button color='inherit' size='small' onClick={handleClearSearch}> Reset Filters </Button>}
      >
        Showing all alerts{filterStr}{userQueryStr}{dateQueryStr}{typeQueryStr}.&nbsp;
      </Alert>
    )
  }

  const getRows = (headerColumns: IGenericTableHeaderColumn []): IAlertRows => {
    const rows: IAlertRows = {}

    props.alertsViewStore?.currentPage?.forEach((alert: IAlert) => {
      const row: IAlertRow = {
        member: renderProfileCol(alert)
      }

      headerColumns.forEach(column => {
        if (column.label) row[column.id] = getRowFromConfig(column.label ?? '', alert)
      })

      rows[alert.id] = row
    })

    return rows
  }

  const renderBody = (): JSX.Element => {
    if (props.alertsViewStore?.loadingAlerts) return <LoadingIndicator sidebarVisible />

    const hasDefaultFilters = _.isEqual(props.alertsViewStore.filters, DEFAULT_ALERT_FILTERS)
    if (props.alertsViewStore?.total === 0 && hasDefaultFilters && !props.alertsViewStore.searchQuery) {
      return (<div className='clinician-alerts__message'> There are no alerts. </div>)
    } else if (props.alertsViewStore?.total === 0) {
      return (
        <Alert
          sx={{ mt: 1 }}
          severity='warning'
          action={<Button color='inherit' size='small' onClick={() => { handleClearSearch() }}> Reset Filters </Button>}
        >
          No alerts matched your search.
        </Alert>
      )
    }

    const columns = getHeaderColumns()
    const rows = getRows(columns)

    return <AlertTable
      rows={rows}
      columns={columns}
      pageStart={props.pageStart}
      pageEnd={props.pageEnd}
      page={props.alertsViewStore?.page ?? 1}
      total={props.alertsViewStore?.total ?? 0}
      viewActions={props.alertsViewActions}
      fetchPagingAlerts={props.fetchPagingAlerts}
      handleItemClick={props.alertsViewActions.setSelectedAlert}
      ></AlertTable>
  }

  const renderClinicianAlertsPage = (): JSX.Element => {
    if (!props.clinicianAlertsApi?.sync) return <LoadingIndicator sidebarVisible />

    const selectedAlert = props.alertsViewStore?.currentPage.find(
      a => a.id === props.alertsViewStore?.selectedItemId
    )

    return (
      <>
        <AlertDetailModal
          maxWidth='md'
          selectedAlert={selectedAlert}
          onModalClose={handleAlertDetailModalClose}
        />

        <RefineAlertsSidebar resetView={handleClearSearch} />
        <ContentMain drawerWidth={285}>
          <ContentMainIntro>
            <ContentMainIntroHeader title='Alerts' />
            {renderCurrentQuery()}
          </ContentMainIntro>

          <ContentMainBody> {renderBody()} </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderClinicianAlertsPage()
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    carePlanItems: state.clinicianCarePlan?.carePlanItems,
    clinicianAlertsApi: state.api.clinicianAlerts,
    alertsViewStore: state.alertsView,
    pageStart: (state.alertsView.page - 1) * ALERTS_PAGE_SIZE,
    pageEnd: state.alertsView.page * ALERTS_PAGE_SIZE
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    alertsViewActions: bindActionCreators(alertsViewSlice.actions, dispatch),
    fetchPagingAlerts: bindActionCreators(fetchPagingAlerts, dispatch),
    getDefaultCarePlan: () => {
      dispatch(restApi.actions.getClinicianDefaultCarePlan())
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ClinicianAlertsPage)
