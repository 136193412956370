import _ from 'lodash'
import restApi from '../../../api'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { dashboardViewSlice, setRange } from '../../../store/dashboardView'
import { profileViewSlice } from '../../../store/profileView'
import { userSessionSlice } from '../../../store/userSession'
import { productConfig } from '../../../config/config-service'
import { getDisplayName } from '../../../utils/baseStringUtils'
import {
  isMemberStarred,
  isMemberPaused,
  getCurrentOrFuturePause,
  getPauseDescription
} from '../../../utils/member/baseProfileUtils'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../layout/ContentMain'
import { Alert, Button, Box, Link, Tab, Tabs, Typography } from '@mui/material'
import renderProfileCharts from '../../../utils/charts/chartUtils'
import ChatModule from '../../elements/Chat/ChatModule'
import DateRange from '../../elements/DateRange/DateRange'
import PregnancyDateRange from '../../elements/DateRange/PregnancyDateRange'
import LoadingIndicator from '../../elements/LoadingIndicator'
import NoteRow from '../../elements/Notes/NoteRow'
import Star from '../../elements/Star'
import AlertDialog from '../../elements/AlertDialog'
import TabPanel from '../../layout/TabPanel'
import AlertDetailModal from '../AlertsPage/AlertDetailModal'
import ProfileSidebar from './ProfileSidebar'
import EditMemberProfileModal from './EditMemberProfileModal'
import AccountLinkedModal from './AccountLinkedModal'
import NotesView from './NotesView'
import { useParams } from 'react-router'
import { a11yProps } from '../../../constants/constants'
import { type ISessionStore } from '../../../interfaces/store/sessionStore.interface'
import { type ISuspension } from '../../../interfaces/suspension.interface'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'
import { type IUser } from '../../../interfaces/user.interface'
import { type INote } from '../../../interfaces/note.interface'
import { type AppDispatch, type RootState } from '../../..'

interface IProfilePageProps extends PropsFromRedux {
  memberSummaryApi: any
  member: IMemberSummary
  userSession: ISessionStore
}

function ProfilePage (props: IProfilePageProps): JSX.Element | null {
  const config = productConfig()
  const params = useParams()
  const pagePrefix = 'profile-page__tab'
  const hasChat = config.router.hasChat
  const hasPregnancyInfo = config.profile.showPregnancyInfo
  const [once, setOnce] = useState(false)
  const [value, setValue] = useState(0)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteTimestamp, setDeleteTimestamp] = useState(null)
  const [dialogTitle] = useState('Delete Weight Measurement?')
  const [dialogMessage] = useState('Are you sure you want to delete this weight measurement? Weights can be restored later.')

  useEffect(() => {
    if (props.memberSummaryApi.sync) {
      const memberUser = props.member?.user
      const session = props.userSession
      if (memberUser?.group && session.user?.allowedGroups?.length as number > 1 && !once) {
        // member data fetch completed, set session active group to member's group if applicable
        const noActiveGroup = !session.activeGroup
        const differentGroup = session.activeGroup?.name !== memberUser.group
        if (noActiveGroup || differentGroup) {
          setOnce(true)
          const formData = { body: JSON.stringify({ activeGroupName: memberUser.group }) }
          props.apiActions.setActiveGroup(null, formData)
        }
      }
    }
  }, [props.memberSummaryApi.sync])

  useEffect(() => {
    restApi.actions.memberSummary.reset()
    props.profileViewActions.handleClearSelectedAlert()

    props.profileViewActions.resetTabView()
    props.dashboardViewActions.resetDashboardView()

    props.apiActions.fetchInitialMemberSummary(params.id)
    props.apiActions.fetchMemberCarePlan(params.id)

    props.profileViewActions.setMemberId({ memberId: params.id })
  }, [])

  const handleTabChange = (event, newValue): void => {
    props.asyncActions.setActiveTab({ newValue })
    setValue(newValue)
  }

  const handleAlertOpen = (alertObj): void => {
    props.profileViewActions.setSelectedAlert(alertObj.id)
  }

  const handleDeleteWeightConfirmed = (isConfirmed: boolean): void => {
    if (isConfirmed) {
      props.apiActions.deleteWeight({
        userId: props.member.user.id,
        timestamp: deleteTimestamp
      })
    }
    setDeleteTimestamp(null)
    setDeleteDialogOpen(false)
  }

  const handleClickDeleteWeight = (timestamp): void => {
    if (timestamp) {
      setDeleteTimestamp(timestamp)
      setDeleteDialogOpen(true)
    }
  }

  const handleClickRestoreWeight = (timestamp): void => {
    if (timestamp) {
      props.apiActions.restoreWeight({
        userId: props.member.user.id,
        timestamp
      })
    }
  }

  const onRangeZoomChange = (rangeZoom): void => {
    props.asyncActions.setRange({ memberObj: props.member, rangeZoom })
  }

  const onRangeStartChange = (rangeStart): void => {
    props.asyncActions.setRange({ memberObj: props.member, rangeStart: rangeStart?.valueOf() })
  }

  const renderAlertDetailModal = (): JSX.Element | null => {
    if (!props.profileViewStore?.selectedAlertId && !props.profileViewStore?.selectedAlert) return null

    let selectedAlert
    if (props.profileViewStore.selectedAlertId && !props.profileViewStore.selectedAlert) { // used when clicking an alert on a chart
      selectedAlert = props.member?.alerts?.find(a => a.id === props.profileViewStore?.selectedAlertId)
    } else { // used on the Notes page
      selectedAlert = props.profileViewStore.selectedAlert
    }

    return (
      <AlertDetailModal
        maxWidth='md'
        selectedAlert={selectedAlert}
        onModalClose={props.profileViewActions.handleClearSelectedAlert}
      />
    )
  }

  const renderSuspensionAlert = (pause: ISuspension): JSX.Element => {
    return (
      <div className='profile__small-top-margin'>
        <Alert
          severity='info'
          action={
            <Button
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => props.profileViewActions.setOpenModal({ name: 'editMemberProfile', tabLabel: 'status' })}
            >
              Edit
            </Button>
          }
        >
          {getPauseDescription(pause)}
        </Alert>
      </div>
    )
  }

  const handleStarClick = (): void => {
    const currentlyStarred = isMemberStarred(props.clinicianId as string, props.member?.user?.stars ?? [])

    if (currentlyStarred) props.apiActions.unstarUser()
    else props.apiActions.starUser()
  }

  const renderProfilePage = (): JSX.Element | null => {
    if (!props.memberSummaryApi.sync) return <LoadingIndicator sidebarVisible />

    const memberObj = props.member
    const session = props.userSession
    const noActiveGroup = !session?.activeGroup
    const differentGroup = session?.activeGroup?.name !== memberObj?.user?.group
    const isMultiGroup = session?.allowedGroups?.length as number > 1

    if (isMultiGroup && (noActiveGroup || differentGroup)) return null

    const memberStarred = isMemberStarred(props.clinicianId as string, memberObj?.user?.stars ?? [])

    // Pause info
    const memberPaused = isMemberPaused(_.get(memberObj, ['user', 'pauses']))
    const pause = getCurrentOrFuturePause(_.get(memberObj, ['user', 'pauses']))
    const starredNotes = memberObj?.user?.starred_notes ?? null

    if (!props.memberSummaryApi.sync) return <LoadingIndicator />

    // This person doesn't exist...
    // Warning: memberObj is mutated asynchronously (race conditions)
    if (memberObj?.careplan && !memberObj?.user) {
      return (
        <Box sx={{ m: 10 }}>
          <Alert severity='info'>
            Can't find a member with id <strong>{params.id}</strong>.<br />
            <Link href='/members' underline='none'>Back to members list</Link>
          </Alert>
        </Box>
      )
    }

    // Wait for data fetch (weight, activity, sleep, blood pressure, etc.)
    // Warning: memberObj is mutated asynchronously (race conditions)
    if ((memberObj?.user === undefined ||
      memberObj?.careplan === undefined) || (
      memberObj &&
        memberObj.activity === undefined &&
        memberObj.weight === undefined &&
        memberObj.sleep === undefined &&
        memberObj.blood_pressure === undefined &&
        memberObj.pulse_ox === undefined &&
        memberObj.temperature === undefined &&
        memberObj.heart_rate === undefined &&
        memberObj.respiratory_rate === undefined)) {
      return <LoadingIndicator />
    }

    const headerText = (
      <Box component='span' className='profile__header-span'>
        <Star filled={memberStarred} className='profile__star' onClick={() => { handleStarClick() }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '-webkit-fill-available' }}>
          <Typography component='span' fontSize={24} fontWeight={400}>{getDisplayName(memberObj.user, true, 'primary')}</Typography>
          {memberPaused && <Typography component='span' fontSize={24} fontWeight={500} className='profile__archived-text'>SUSPENDED</Typography>}
          {memberObj.user.archived_since && <Typography component='span' fontSize={24} fontWeight={500} className='profile__archived-text'>ARCHIVED</Typography>}
        </Box>
      </Box>
    )

    const DateRangeComponent = hasPregnancyInfo ? PregnancyDateRange : DateRange

    return (
      <React.Fragment key={memberObj.user.id}>
        <AccountLinkedModal
          queryKey='accountLinked'
          memberObj={memberObj}
        />

        <EditMemberProfileModal
          memberObj={memberObj}
          profileViewStore={props.profileViewStore }
          apiActions={props.apiActions}
        />

        {renderAlertDetailModal()}

        <ProfileSidebar memberObj={memberObj} careplan={memberObj.careplan} careManagers={props.userSession.resources?.care_managers ?? []} />

        <ContentMain>
          <ContentMainIntro>
            <ContentMainIntroHeader title={headerText} />
            <Box sx={{ borderBottom: 1, width: '100%', borderColor: 'divider' }} className='no-print'>
              <Tabs value={value} onChange={handleTabChange} aria-label='Profile tabs'>
                <Tab data-testid='profile-tabs__charts-tab' label='Charts' {...a11yProps(pagePrefix, 0)} />
                <Tab label='Notes' data-testid='profile-tabs__notes-tab' {...a11yProps(pagePrefix, 1)} />
              </Tabs>
            </Box>
          </ContentMainIntro>

          <ContentMainBody>
            <Box>
              {pause && renderSuspensionAlert(pause)}

              {starredNotes != null &&
                <Box>
                  {starredNotes.map((noteDatum: INote) => {
                    return (
                      <NoteRow
                        key={noteDatum.id as string}
                        noteData={noteDatum}
                        callout
                      />
                    )
                  })}
                </Box>}
              <TabPanel value={value} index={0} noPadding>
                <AlertDialog
                  isOpen={deleteDialogOpen}
                  title={dialogTitle}
                  message={dialogMessage}
                  handleDialogClose={handleDeleteWeightConfirmed}
                />

                <div className='profile__small-top-margin'>
                  <DateRangeComponent
                    memberObj={memberObj}
                    dashboardViewStore={props.dashboardViewStore }
                    handleRangeZoomChange={onRangeZoomChange}
                    handleRangeStartChange={onRangeStartChange}
                  />
                </div>
                {renderProfileCharts(memberObj, props.dashboardViewStore, handleAlertOpen, handleClickDeleteWeight, handleClickRestoreWeight)}
              </TabPanel>
              <TabPanel value={value} index={1} noPadding>
                <NotesView memberObj={memberObj} />
              </TabPanel>
            </Box>

            {hasChat && <ChatModule user={props.user as IUser} memberObj={memberObj} />}
          </ContentMainBody>
        </ContentMain>
      </React.Fragment>
    )
  }

  return renderProfilePage()
}

function getIdFromParams (locationPath: string): { id: string } {
  const userId = locationPath.substring(locationPath.lastIndexOf('/') + 1)
  return { id: userId }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState, ownProps) {
  const params = getIdFromParams(ownProps.location.pathname)
  return {
    memberSummaryApi: state.api.memberSummary,
    membersCache: state.membersCache,
    member: state.membersCache.entities[params.id],
    dashboardViewStore: state.dashboardView,
    profileViewStore: state.profileView,
    membersViewStore: state.membersView,
    alertsViewStore: state.alertsView,
    user: state.userSession.user,
    clinicianId: state.userSession?.user?.id,
    userSession: state.userSession
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch, ownProps) {
  const params = getIdFromParams(ownProps.location.pathname)
  return {
    dashboardViewActions: bindActionCreators(dashboardViewSlice.actions, dispatch),
    profileViewActions: bindActionCreators(profileViewSlice.actions, dispatch),
    userSessionActions: bindActionCreators(userSessionSlice.actions, dispatch),

    asyncActions: {
      // @ts-expect-error args
      setRange: (...args: any []) => { dispatch(setRange(...args)) },
      // @ts-expect-error args
      setActiveTab: (...args: any []) => dispatch(profileViewSlice.actions.setActiveTab(...args)),
      // @ts-expect-error args
      setLoginTarget: (...args) => dispatch(userSessionSlice.actions.setLoginTarget(...args))
    },

    apiActions: {
      ...bindActionCreators(restApi.actions, dispatch),

      fetchInitialMemberSummary: id => dispatch(restApi.actions.memberSummary({ id })),
      fetchMemberCarePlan: id => dispatch(restApi.actions.getMemberCarePlan({ id })),

      updateUser: (pathVars, apiFields) => {
        dispatch(restApi.actions.updateUser(pathVars, { body: JSON.stringify(apiFields) }))
      },

      linkDevice: () => {
        dispatch(restApi.actions.linkDevice({ id: params.id }))
      },

      removeDevice: () => {
        dispatch(restApi.actions.removeDevice({
          id: params.id,
          sensorType: ownProps.params.sensorType
        }))
      },

      starUser: () => {
        dispatch(restApi.actions.starUser({ id: params.id }))
      },

      unstarUser: () => {
        dispatch(restApi.actions.unstarUser({ id: params.id }))
      },

      deleteWeight: ({ userId, timestamp }) => {
        dispatch(restApi.actions.deleteWeight({ id: userId, timestamp }))
      },

      restoreWeight: ({ userId, timestamp }) => {
        dispatch(restApi.actions.restoreWeight({ id: userId, timestamp }))
      }
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ProfilePage)
