import { createSlice } from '@reduxjs/toolkit'
import { type ICareManager, type IFeatureGates, type ISessionStore } from '../interfaces/store/sessionStore.interface'
import { type IUser } from '../interfaces/user.interface'
import { FEATURE_GATES } from '../constants/constants'

const initialState: ISessionStore = {
  loginTarget: '',
  activeGroup: undefined,
  user: null,
  resources: null,
  sessionExpireTime: null,
  isLoggedIn: false,
  isFirstTimeUser: false,
  isClinician: false,
  loggingInWithPhone: false,
  showSessionExpireWarning: false,
  showSessionExpireMessage: false,
  featureGates: {}
}

const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {
    setActiveGroup: (state: ISessionStore, action) => {
      state.activeGroup = action.payload
      state.resources = null
    },
    setResources: (state: ISessionStore, action: { payload: { care_managers: ICareManager [] } }) => {
      state.resources = action.payload
    },
    sessionExpire: (state: ISessionStore) => {
      state.showSessionExpireMessage = true
    },
    setLoginTarget: (state: ISessionStore, action) => {
      state.loginTarget = action.payload
    },
    setLoggingInWithPhone: (state: ISessionStore, action) => {
      state.loggingInWithPhone = action.payload
    },
    setSessionExpireTime: (state: ISessionStore, action: { payload: number }) => {
      state.sessionExpireTime = action.payload
      state.showSessionExpireWarning = false
    },
    warnSessionExpire: (state: ISessionStore) => {
      state.showSessionExpireWarning = true
    },
    updateUser: (state: ISessionStore, action: { payload: IUser }) => {
      if (action.payload?.id) {
        state.user = { ...state.user, ...action.payload }
        state.featureGates = getFeatureGates(action.payload)
      }
    },
    setUser: (state: ISessionStore, action: { payload: IUser }) => {
      if (action.payload?.id) {
        state.isLoggedIn = true
        state.isClinician = action.payload.clinician === 1
        state.user = action.payload
        state.featureGates = getFeatureGates(action.payload)
        state.showSessionExpireMessage = false
      } else {
        state.isLoggedIn = false
        state.user = null
      }
    }
  }
})

function getFeatureGates (user: IUser): IFeatureGates {
  // If non-Ejenta CHF_KP_MAS clinicians are using the app, only show analytics
  const showOnlyAnalytics = (user.group === 'CHF_KP_MAS') && (!user.email?.endsWith('ejenta.com'))

  return {
    [FEATURE_GATES.SHOW_ONLY_ANALYTICS]: showOnlyAnalytics
  }
}

export const {
  setUser,
  updateUser,
  setActiveGroup,
  setResources,
  sessionExpire,
  setLoginTarget,
  setLoggingInWithPhone,
  setSessionExpireTime,
  warnSessionExpire
} = userSessionSlice.actions
export { userSessionSlice }
