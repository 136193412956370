import React, { useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Tooltip from '../../../elements/Tooltip'
import getAdherenceChartOptions from '../charts/adherenceChart'
import AnalyticsChart from '../layout/AnalyticsChart'
import { ExpandMore } from '@mui/icons-material'
import { mapAnalyticsDispatch, mapAnalyticsState } from '../../../../constants/reduxMappers'

function AnalyticsAdherenceSection (props: PropsFromRedux): JSX.Element {
  const adherenceTooltip = 'Click on the legend to drill into adherence levels for a specific sensor.'
  const [adherenceExpanded, setAdherenceExpanded] = useState(true)

  const handleChange = (callback: (isExpanded: boolean) => void) => (event, isExpanded) => {
    callback(isExpanded)
  }

  const renderAxAdherenceSection = (): JSX.Element => {
    return (
      <div className='ax-subsection' data-testid='ax-subsection__adherence'>
        <div className='ax-subsection__header'>Tracker and scale usage</div>
        <Accordion expanded={adherenceExpanded} onChange={handleChange(setAdherenceExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} id="accordion-adherence">
            Adherence
            <Tooltip tooltipId='ax-module__adherence__tooltip' content={adherenceTooltip} />
          </AccordionSummary>
          <AccordionDetails className='ax-chart-container'>
            <AnalyticsChart
              containerId='ax-adherence'
              chartOptions={getAdherenceChartOptions(props.analyticsStore.onboardedUsers, props.analyticsActions)}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  return renderAxAdherenceSection()
}

const connector = connect(mapAnalyticsState, mapAnalyticsDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AnalyticsAdherenceSection)
