import React from 'react'
import moment from 'moment'
import {
  getDisplayDelta,
  getDeltaArrow,
  momentCalendarDateConfig
} from '../../../utils/baseStringUtils'
import {
  getBloodGlucoseDeltaInRange
} from '../../../utils/member/analyticsUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { type IMemberSummary } from '../../../interfaces/member-summary.interface'

export default function BloodGlucoseCell (props: { memberObj: IMemberSummary }): JSX.Element {
  if (!props.memberObj.user.has_bg && !props.memberObj.blood_glucose) {
    return <EmptyCell message='Device not set up' />
  }

  let latestCol = <></>
  const latestBgData = props.memberObj.blood_glucose?.latest_measurement

  if (latestBgData) {
    const lastBgMeasurement = moment(latestBgData.timestamp)
    latestCol = (
      <Box>
        <div className='member-row__latest'> {latestBgData.blood_glucose} </div>
        <div className='member-row__latest-timestamp'> {lastBgMeasurement.calendar(null, momentCalendarDateConfig)} </div>
      </Box>
    )
  }

  const bgDelta7d = getBloodGlucoseDeltaInRange(props.memberObj) as number
  const deltaArrow = bgDelta7d !== 0 ? getDeltaArrow(bgDelta7d, false) : undefined
  const deltaCol = (<span className='member-row__delta'> {getDisplayDelta(bgDelta7d)} {deltaArrow} </span>)

  return (
    <Grid container>
      <Grid item xs={6}>{latestCol}</Grid>
      <Grid item xs={6}>{deltaCol}</Grid>
    </Grid>
  )
}
