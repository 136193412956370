import _ from 'lodash'
import { type IAlert } from '../interfaces/alert.interface'
import { type ISidebarFilters } from '../interfaces/ejenta.interface'

export function countHumanNotes (alertObj: IAlert): number {
  const humanNotes = _.filter(alertObj.notes, (n) => {
    if (n.author === 'Ejenta Agent') return false

    const actions = ['READ', 'OPEN', 'CLOSED']
    if (_.includes(actions, n.provider_content.toUpperCase())) return false

    return true
  })

  return humanNotes.length
}

const BP_SUBMETRICS_REGEX = /systolic|diastolic/
const BP_VALUE_REGEX = /(\d+)\/(\d+)/
const HR_VALUE_REGEX = /(\d+) bpm/

function findBPString (content: string, regex: RegExp): string | null {
  const result = regex.exec(content)
  if (result?.[0]) {
    return result[0]
  }
  return null
}

export function getBPRangeAlertDisplay (alert: IAlert): string {
  if (BP_SUBMETRICS_REGEX.test(alert.provider_content)) {
    const bpString = findBPString(alert.provider_content, BP_VALUE_REGEX)
    if (bpString) return bpString
  }

  if (alert.provider_content.includes('heart rate')) {
    const hrString = findBPString(alert.provider_content, HR_VALUE_REGEX)
    if (hrString) return hrString
  }

  return `${Math.round(alert.alert_value)}` // fail gracefully if the regex fails
}

export const MEMBER_STATUS_FILTERS: ISidebarFilters = {
  displayTitle: 'Member status',
  filters: [
    {
      id: 'FILTER/STARRED',
      displayTitle: 'Starred'
    }
  ]
}

export const ALERT_NOTES_FILTERS: ISidebarFilters = {
  displayTitle: 'Notes',
  filters: [
    {
      id: 'FILTER/WITH_NOTE',
      displayTitle: 'Alerts with notes'
    }
  ]
}

export const ALERT_STATUS_FILTERS: ISidebarFilters = {
  displayTitle: 'Alert status',
  filters: [
    {
      id: 'FILTER/OPEN_UNREAD',
      displayTitle: 'Unread'
    },
    {
      id: 'FILTER/OPEN',
      displayTitle: 'Open'
    },
    {
      id: 'FILTER/CLOSED',
      displayTitle: 'Closed'
    }
  ]
}

export const DEFAULT_ALERT_FILTERS = ['FILTER/OPEN_UNREAD', 'FILTER/OPEN']

export const ALERT_SORTS = {
  SORT_STATUS: 'STATUS',
  SORT_TYPE: 'TYPE',
  DATE_DESC: 'DATE_DESC',
  DATE_ASC: 'DATE_ASC',
  LEVEL: 'LEVEL'
}

export const ALERT_URL_FILTERS = {
  USER_ID: 'user',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  ALERT_TYPE: 'type',
  FILTERS: 'filters'
}

export const ALERT_LEVEL_COLORS = {
  0: 'Default',
  1: 'Yellow',
  2: 'Orange',
  3: 'Red'
}
