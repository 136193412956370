import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { clinicianCarePlanSlice } from '../../store/clinicianCarePlan'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../layout/ContentMain'
import { Alert } from '@mui/material'
import restApi from '../../api'
import LoadingIndicator from '../elements/LoadingIndicator'
import SettingsSidebar from '../layout/SettingsSidebar'
import CarePlanHeader from '../elements/CarePlan/CarePlanHeader'
import CarePlanRows from '../elements/CarePlan/CarePlanRows'
import { type ICarePlanItem } from '../../interfaces/careplan.interface'
import { type RootState, type AppDispatch } from '../..'

function ClinicianCarePlanPage (props: PropsFromRedux): JSX.Element {
  const [carePlanItems, setCarePlanItems] = useState<ICarePlanItem []>([])

  useEffect(() => {
    props.storeActions.resetCarePlan()
    props.apiActions.getDefaultCarePlan()
  }, [])

  useEffect(() => {
    setCarePlanItems(props.storeCarePlanItems)
  }, [props.storeCarePlanItems])

  const renderCarePlanPage = (): JSX.Element => {
    if (!props.defaultCarePlanApi.sync || !props.storeCarePlanItems || !props.storeCarePlanItems?.length) {
      return (<LoadingIndicator />)
    }

    return (
      <>
        <SettingsSidebar />

        <ContentMain drawerWidth={220}>

          <ContentMainIntro>
            <ContentMainIntroHeader title='Group care plan' />
            <Alert severity='info' sx={{ mt: 1, mb: 2 }}>
              Updating the group care plan will update the default care plan for <strong>all</strong> current members.
            </Alert>
            <CarePlanHeader isDefault />
          </ContentMainIntro>

          <ContentMainBody>
            <CarePlanRows
              isDefault
              carePlanItems={carePlanItems}
              updateCarePlan={props.apiActions.updateDefaultCarePlan}
            />

          </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderCarePlanPage()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    storeCarePlanItems: state.clinicianCarePlan.carePlanItems,
    defaultCarePlanApi: state.api.getClinicianDefaultCarePlan
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    storeActions: bindActionCreators(clinicianCarePlanSlice.actions, dispatch),
    apiActions: {
      getDefaultCarePlan: () => {
        dispatch(restApi.actions.getClinicianDefaultCarePlan())
      },
      updateDefaultCarePlan: (body: any) => {
        dispatch(restApi.actions.updateClinicianDefaultCarePlan({}, { body: JSON.stringify(body) }))
      }
    }
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ClinicianCarePlanPage)
