import React from 'react'
import moment from 'moment'
import { isMemberStarred, isMemberPaused } from './member/baseProfileUtils'
import {
  type ISidebarFilters,
  type ISidebarSort
} from '../interfaces/ejenta.interface'
import { type IMemberHeaderColumn, type IMemberSummary } from '../interfaces/member-summary.interface'
import { productConfig } from '../config/config-service'
import { Box, Tooltip } from '@mui/material'
import ProfileCell from '../components/elements/ProfileCell/ProfileCell'
import ActivityCell from '../components/elements/MemberCell/ActivityCell'
import BloodGlucoseCell from '../components/elements/MemberCell/BloodGlucoseCell'
import BloodOxygenCell from '../components/elements/MemberCell/BloodOxygenCell'
import BloodPressureCell from '../components/elements/MemberCell/BloodPressureCell'
import HeartRateCell from '../components/elements/MemberCell/HeartRateCell'
import PregnancyWeightCell from '../components/elements/MemberCell/PregnancyWeightCell'
import ProgramStepCell from '../components/elements/MemberCell/ProgramStepCell'
import RespiratoryRateCell from '../components/elements/MemberCell/RespiratoryRateCell'
import SurveyResponseCell from '../components/elements/MemberCell/SurveyResponseCell'
import TemperatureCell from '../components/elements/MemberCell/TemperatureCell'
import WeightCell from '../components/elements/MemberCell/WeightCell'
import _ from 'lodash'
import EmptyCell from '../components/elements/MemberCell/EmptyCell'
import { momentCalendarConfig, getDeltaArrow, getDisplayDelta } from './baseStringUtils'
import { getSystolicDeltaInRange, getDiastolicDeltaInRange, getBpDisplayString } from './member/analyticsUtils'

export const MEMBER_SORTS: ISidebarSort[] = [
  {
    id: 'LAST_NAME',
    displayTitle: 'Last name',
    compare: compareLastName
  },
  {
    id: 'JOIN_DATE',
    displayTitle: 'Date joined',
    compare: compareJoinDate
  },
  {
    id: 'CREATED_DATE',
    displayTitle: 'Date created',
    compare: compareCreatedDate
  }
]

export const MEMBER_STATUS_FILTERS: ISidebarFilters = {
  displayTitle: 'Member status',
  filters: [
    {
      id: 'FILTER/STARRED',
      displayTitle: 'Starred',
      test: (userEntity, clinicianId = null) =>
        !!clinicianId && isMemberStarred(clinicianId, userEntity.user.stars ?? [])
    },
    {
      id: 'FILTER/ACTIVE',
      displayTitle: 'Active',
      test: obj =>
        !(!!obj.user.archived_since || isMemberPaused(obj.user.pauses))
    },
    {
      id: 'FILTER/PAUSED',
      displayTitle: 'Suspended',
      test: obj => isMemberPaused(obj.user.pauses) && !obj.user.archived_since
    },
    {
      id: 'FILTER/ARCHIVED',
      displayTitle: 'Archived',
      test: obj => !!obj.user.archived_since
    }
  ]
}

export function hasRecentWeightData (member: IMemberSummary): boolean {
  return !!member.weight?.data?.length
}

export function hasRecentActivityData (member: IMemberSummary): boolean {
  return !!member.activity?.data?.length
}

export function hasRecentBpData (member: IMemberSummary): boolean {
  return !!member.blood_pressure?.data?.length
}

export function hasRecentBgData (member: IMemberSummary): boolean {
  return !!member.blood_glucose?.data?.length
}

export function hasRecentPulseOxData (member: IMemberSummary): boolean {
  return !!member.pulse_ox?.data?.length
}

/* ******************************************************************************
 * Sorting
 * ******************************************************************************/

export function compareMemberStatus (
  a: IMemberSummary,
  b: IMemberSummary
): number {
  if (a.user.archived_since) return 1
  if (b.user.archived_since) return -1
  return 0
}

export function compareJoinDate (a: IMemberSummary, b: IMemberSummary): number {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  if (!a.user.signed_tos) return 1
  if (!b.user.signed_tos) return -1

  if (moment(a.user.signed_tos).isBefore(b.user.signed_tos)) return 1
  if (moment(b.user.signed_tos).isBefore(a.user.signed_tos)) return -1

  return 0
}

export function compareCreatedDate (
  a: IMemberSummary,
  b: IMemberSummary
): number {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  if (!a.user.created) return 1
  if (!b.user.created) return -1

  if (moment(a.user.created).isBefore(b.user.created)) return 1
  if (moment(b.user.created).isBefore(a.user.created)) return -1

  return 0
}

export function compareLastName (a: IMemberSummary, b: IMemberSummary): number {
  if (a.user.last_name && b.user.last_name) return a.user.last_name < b.user.last_name ? -1 : 1
  return 0
}

export function compareDeviceStatus (
  a: IMemberSummary,
  b: IMemberSummary
): number {
  // If there's no device status for either member, sort by date the member joined
  if (!a.user.bt_scale_imei && !b.user.bt_scale_imei) {
    return compareJoinDate(a, b)
  }
  if (!a.user.bt_scale_imei) return 1
  if (!b.user.bt_scale_imei) return -1

  return 0
}

/* ******************************************************************************
 * URL filtering
 * ******************************************************************************/

export const MEMBERS_URL_FILTERS = {
  PAUSED: 'paused'
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MembersListFilterUtils {
  static [MEMBERS_URL_FILTERS.PAUSED] (
    obj: IMemberSummary,
    isPaused: string
  ): boolean {
    if (!+isPaused) return true // If isPaused is undefined or '0', stop here
    else return isMemberPaused(obj.user.pauses) // only user objects with a 'paused' value
  }
}

export const getMemberHeaderColumns = (): { primaryRow: IMemberHeaderColumn[], secondaryRow: IMemberHeaderColumn[] } => {
  const membersHeaderRows = productConfig().members.mainPage?.dataRow
  const COL_MULTIPLIER = 25
  const memberInfoPlaceholder = {
    id: 'member',
    label: '',
    colSpan: 4,
    minWidth: 60,
    maxWidth: 200
  }
  const primaryRowColumns: IMemberHeaderColumn[] = [memberInfoPlaceholder]
  const secondaryRowColumns: IMemberHeaderColumn[] = [memberInfoPlaceholder]

  membersHeaderRows?.forEach((item, index) => {
    const colSpan = item.columns.length > 1 ? item.columns.length : 1
    primaryRowColumns.push({
      id: item.metricType ?? '',
      label: item.name,
      colSpan,
      minWidth: (item.columnSize ?? 3) * COL_MULTIPLIER
    })

    item.columns?.forEach((secondaryItem, index) => {
      secondaryRowColumns.push({
        id: `${item.metricType ?? ''}_${index}`,
        label: secondaryItem,
        colSpan: 1,
        minWidth: 60
      })
    })
  })

  return { primaryRow: primaryRowColumns, secondaryRow: secondaryRowColumns }
}

export const renderMemberProfileCol = (member: IMemberSummary, clinicianId: string, handleStarClick: (event, user) => void): JSX.Element => {
  const memberStarred = isMemberStarred(clinicianId ?? '', member.user.stars ?? [])

  return (
    <Box sx={{ padding: '.5rem 0 .5rem', width: '100%' }}>
      <ProfileCell memberStarred={memberStarred} handleStarClick={e => { handleStarClick(e, member.user) }} user={member.user} memberObj={member} />
    </Box>
  )
}

export const renderColumnMetric = (metricType: string | undefined, member: IMemberSummary): JSX.Element => {
  switch (metricType) {
    case 'pregnancyWeight':
      return (<div className='members-list__weight-col'><PregnancyWeightCell memberObj={member} /></div>)
    case 'weight':
      return (<div className='members-list__weight-col'><WeightCell memberObj={member} /></div>)
    case 'bloodPressure':
      return (<div className='members-list__bp-col'><BloodPressureCell memberObj={member} /></div>)
    case 'bloodGlucose':
      return (<div className='members-list__bg-col'><BloodGlucoseCell memberObj={member} /></div>)
    case 'activity':
      return (<div className='members-list__activity-col'><ActivityCell memberObj={member} /></div>)
    case 'programStep':
      return (<div className='members-list__activity-col'><ProgramStepCell memberObj={member} /></div>)
    case 'pulseOxygen':
      return (<div className='members-list__pulseox-col'><BloodOxygenCell memberObj={member} /></div>)
    case 'survey':
      return (<div className='members-list__survey_response-col'><SurveyResponseCell memberObj={member} /></div>)
    case 'temperature':
      return (<div className='members-list__temp-col'><TemperatureCell memberObj={member} /></div>)
    case 'heartRate':
      return (<div className='members-list__hr-col'><HeartRateCell memberObj={member} /></div>)
    case 'respiratoryRate':
      return (<div className='members-list__rr-col'><RespiratoryRateCell memberObj={member} /></div>)

    default:
      return <></>
  }
}

export const renderExtendedBPMetric = (columnId: string, member: IMemberSummary): JSX.Element => {
  if (!member.user.has_bp || !member.blood_pressure) {
    const message = !member.user.bt_bp_imei ? 'Device not set up' : 'No BP data'

    // Only show emptyCell if middle column, else show nothing
    if (columnId === 'previousBP') return <EmptyCell message={message} />
    else return <></>
  }

  const latestBpData = _.get(member, ['blood_pressure', 'latest_measurement'])
  const previousBpData = _.get(member, ['blood_pressure', 'penultimate_measurement'])
  const systolicDelta = getSystolicDeltaInRange(member) as number
  const diastolicDelta = getDiastolicDeltaInRange(member) as number
  const lastBpMeasurement = moment(latestBpData.timestamp)
  const thisPeriod = member.blood_pressure.information.periods[0]
  const numMeasurementsThisPeriod: number = _.get(member, ['blood_pressure', 'data', 'length'])
  const avgBpTooltipId = `tooltip-avgbp-${member.user.id as string}`
  let avgBpTooltipContent = `Based on ${numMeasurementsThisPeriod} measurement`
  const deltaArrow = systolicDelta !== 0 ? getDeltaArrow(systolicDelta, false) : getDeltaArrow(diastolicDelta, false)

  switch (columnId) {
    case 'latestBP':
      return (
        <Box className='members-list__bp-col'>
          <div className='member-row__latest'> {getBpDisplayString(latestBpData)} </div>
          <div className='member-row__latest-timestamp'> {lastBpMeasurement.calendar(null, momentCalendarConfig)} </div>
        </Box>
      )
    case 'previousBP':
      return (
        <Box className='members-list__bp-col'>
          <div className='member-row__latest'> {getBpDisplayString(previousBpData)} </div>
          <div className='member-row__latest-timestamp'> {previousBpData && moment(previousBpData.timestamp).calendar(null, momentCalendarConfig)} </div>
        </Box>
      )
    case 'latestHR':
      return (
        <Box className='members-list__bp-col'>
          <div className='member-row__latest'> {`${Math.round(latestBpData.heart_rate)}`} </div>
        </Box>
      )
    case 'averageBP':
      if (numMeasurementsThisPeriod !== 1) avgBpTooltipContent += 's'
      return (
        <Tooltip className='members-list__bp-col' title={avgBpTooltipContent} data-testid={avgBpTooltipId} id={avgBpTooltipId} placement='bottom' arrow>
          <div className='member-row__latest'>
            {!!thisPeriod && `${thisPeriod.avg_systolic}/${thisPeriod.avg_diastolic}`}
            {!thisPeriod && '—/—'}
          </div>
        </Tooltip>
      )
    case 'deltaBP':
      return (
        <Box className='members-list__bp-col'>
          <span className='member-row__delta'> {getDisplayDelta(systolicDelta)}/{getDisplayDelta(diastolicDelta)} {deltaArrow} </span>
        </Box>
      )

    default:
      return <></>
  }
}
