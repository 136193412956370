import React from 'react'
import { Link } from 'react-router-dom'
import BasicFrame from '../layout/BasicFrame'
import SignpostIcon from '@mui/icons-material/Signpost'
import { Typography } from '@mui/material'

const NotFoundPage = (): JSX.Element => {
  return (
    <BasicFrame>
      <div className='notfound-page'>
        <SignpostIcon fontSize='large' color='secondary' sx={{ fontSize: '8rem' }} />

        <Typography variant='body2' fontSize='1.5rem'>
          Whoops! We couldn't find that page.
          <br />
          Try returning to the <span className='u-strong'><Link to='/'>Ejenta homepage</Link></span>.
        </Typography>

        <Typography variant='caption'>
          If you need further assistance, contact <span className='u-strong'><a href='mailto:help@ejenta.com'>help@ejenta.com</a></span>
        </Typography>
      </div>
    </BasicFrame>
  )
}

export default NotFoundPage
