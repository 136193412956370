import { Box } from '@mui/material'
import React from 'react'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'

export default class HealthStatusChangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'health_status_change'
  }

  renderGoal (): JSX.Element {
    return (<div> Health Status </div>)
  }

  renderClinicianTrigger (): JSX.Element {
    return (<div> If a health status change is submitted. </div>)
  }

  renderCondensed (): JSX.Element {
    const className: string = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    return <Box className={className}>Health status changes</Box>
  }
}
