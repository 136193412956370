import _ from 'lodash'
import { getInitialState } from '../utils/baseListUtils'
import restApi from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ALERTS_PAGE_SIZE } from '../constants/constants'
import { type IChatsStore } from '../interfaces/store/chatsStore.interface'
import { type ISidebarFilters, type ISidebarFilter } from '../interfaces/ejenta.interface'
import { CHATS_VIEW, DEFAULT_CHAT_FILTERS, DEFAULT_CHAT_SORT } from '../utils/chatUtils'

const initialState: IChatsStore = {
  ...getInitialState({
    sortBy: DEFAULT_CHAT_SORT,
    filters: DEFAULT_CHAT_FILTERS
  }),
  totalFilterOptions: 0,
  loadingChats: true,
  total: 0,
  careManagerId: 'OPTION_ALL',
  careManagerFilters: [],
  carePlanFilters: [],
  urlFilters: {}
}

const getData = createAsyncThunk('chatsView/fetchChats', async (params: { dispatch: any, getState: any }) => {
  const { dispatch, getState } = params
  const { searchQuery, sortBy, filters, urlFilters, page, careManagerFilters } = getState().chatsView
  const urlParams = {
    alert_type: 'new_chat', // IMPORTANT!!!
    per_page: ALERTS_PAGE_SIZE,
    page,
    search_query: searchQuery,
    alert_sorting: sortBy.toLowerCase(),
    care_manager_filters: []
  }

  // Loop through all possible filters
  _.forOwn(CHATS_VIEW.FILTER_GROUPS, (value: ISidebarFilters | Record<string, any>, filterType: string): void => {
    const availableFilters: string [] = value.filters.map((f: ISidebarFilter) => {
      return f.id
    })
    let enabledFilters: string [] = _.intersection(availableFilters, filters)

    enabledFilters = _.map(enabledFilters, (filter: string) => {
      // e.g. FILTER/ACTIVE => active
      return filter.replace('FILTER/', '').toLowerCase()
    })

    urlParams[`${filterType.toLowerCase()}_filters`] = enabledFilters
  })

  if (careManagerFilters.length) urlParams.care_manager_filters = careManagerFilters

  const additionalUrlParams = _.mapKeys(urlFilters, (value, key) => {
    const transformations = {
      user: 'user',
      start_date: 'start',
      end_date: 'end',
      type: 'strict_alert_type_filter'
    }
    return transformations[key]
  })

  _.merge(urlParams, additionalUrlParams)
  await dispatch(restApi.actions.clinicianChats(urlParams))
})

export const fetchPagingChats = (): any => {
  return (dispatch, getState) => {
    dispatch(getData({ dispatch, getState }))
  }
}

const chatsViewSlice = createSlice({
  name: 'chatsView',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state: IChatsStore, action) => {
      state.loadingChats = false
    })
    builder.addCase(getData.rejected, (state: IChatsStore, action) => {
      state.loadingChats = false
    })
    builder.addCase(getData.pending, (state: IChatsStore, action) => {
      state.loadingChats = true
    })
  },
  reducers: {
    setLoadingChats: (state: IChatsStore, action) => {
      state.loadingChats = action.payload
    },
    resetView: (state: IChatsStore, action) => {
      state.careManagerFilters = initialState.careManagerFilters
      state.sortBy = initialState.sortBy
      state.filters = [...initialState.filters].sort()
      state.careManagerId = initialState.careManagerId
      state.searchQuery = ''
      state.searchQueryReset = action.payload ?? true
      state.urlFilters = initialState.urlFilters
      state.page = 1
    },
    setSearchQuery: (state: IChatsStore, action) => {
      state.searchQuery = action.payload
      state.searchQueryReset = false
      state.page = 1
    },
    setSort: (state: IChatsStore, action) => {
      state.sortBy = action.payload
      state.page = 1
      state.searchQueryReset = false
    },
    setFilter: (state: IChatsStore, action) => {
      if (state.filters.includes(action.payload)) {
        state.filters = _.without(state.filters, action.payload)
        state.page = 1
      } else {
        state.filters = state.filters.concat(action.payload).sort()
        state.page = 1
      }

      state.searchQueryReset = false
    },
    setTotalFilterOptions: (state: IChatsStore, action) => {
      state.totalFilterOptions = action.payload
    },
    setPage: (state: IChatsStore, action) => {
      const maxPage = Math.ceil(state.total / ALERTS_PAGE_SIZE)

      if (action.payload < 1) state.page = 1
      else if (action.payload > maxPage) state.page = maxPage
      else state.page = action.payload
    },
    pagePrev: (state) => {
      state.page = Math.max(1, state.page - 1)
    },
    pageNext: (state) => {
      const maxPage = Math.ceil(state.total / ALERTS_PAGE_SIZE)
      state.page = Math.min(maxPage, state.page + 1)
    },
    setCareManagerId: (state: IChatsStore, action) => {
      const careManagerId = action.payload
      state.careManagerId = careManagerId

      let careManagerFilters = [careManagerId]
      if (careManagerId === 'OPTION_ALL' || careManagerId === null) careManagerFilters = []

      state.careManagerFilters = careManagerFilters
      state.searchQueryReset = false
    },
    populateChats: (state: IChatsStore, action) => {
      const filterCounts = {}

      _.forOwn(CHATS_VIEW.FILTER_GROUPS, (value, filterType) => {
        _.forOwn(action.payload.filters[filterType.toLowerCase()], (count, key) => {
          filterCounts[`FILTER/${key.toUpperCase()}`] = count
        })
      })

      state.currentPage = action.payload.alerts
      state.total = action.payload.total
      state.filterCounts = filterCounts
    },
    setSelectedChat: (state: IChatsStore, action) => {
      state.selectedItemId = action.payload
    },
    setLocationKey: (state: IChatsStore, action) => {
      state.prevLocationKey = action.payload
    },
    updateChat: (state: IChatsStore, action) => {
      if (state.currentPage) {
        // Find original chat in chat array
        const itemIndex = _.findIndex(state.currentPage, item => item.id === action.payload.alert.id)

        if (itemIndex >= 0) {
          const updatedItem = { ...action.payload.alert } // Define new chat
          state.currentPage[itemIndex] = updatedItem // Update chat array
        }
      }
    }
  }
})

export { chatsViewSlice }
export const {
  setLoadingChats,
  resetView,
  setSearchQuery,
  setSort,
  setFilter,
  setCareManagerId,
  pagePrev,
  pageNext,
  setPage,
  populateChats,
  setSelectedChat,
  setLocationKey,
  updateChat
} = chatsViewSlice.actions
