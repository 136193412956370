import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert, { type AlertColor } from '@mui/material/Alert'

interface ISnackbarProps {
  isOpen: boolean
  dataKey: string
  message: string
  severity: AlertColor
  duration?: number
  veriticalAnchor?: 'bottom' | 'top'
  horizontalAnchor?: 'center' | 'left' | 'right'
  handleClose: () => void
}

export default function EjentaSnackbar (props: ISnackbarProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <Snackbar
      open={isOpen}
      onClose={props.handleClose}
      key={props.dataKey}
      data-testid={props.dataKey}
      autoHideDuration={props.duration ?? 6000}
      anchorOrigin={{ vertical: props.veriticalAnchor ?? 'bottom', horizontal: props.horizontalAnchor ?? 'center' }}
    >
      <Alert onClose={props.handleClose} severity={props.severity} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}
