import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { ALERT_SORTS } from '../../../utils/baseAlertUtils'
import { ALERTS_VIEW } from '../../../utils/alertUtils'
import { renderFilters } from '../../../utils/baseListUtils'
import { CARE_MANAGER_DEFAULT_OPTIONS } from '../../../constants/constants'
import { alertsViewSlice, fetchPagingAlerts } from '../../../store/alertsView'
import { productConfig } from '../../../config/config-service'
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material'
import ContentSidebar from '../../layout/ContentSidebar'
import SearchBar from '../../elements/SearchBar'
import { type ICareManager } from '../../../interfaces/store/sessionStore.interface'
import { type DefaultFilterState } from '../../../interfaces/product-config.interface'
import { type RootState, type AppDispatch } from '../../..'

interface IAlertSidebarProps extends PropsFromRedux {
  resetView: () => void
}

function RefineAlertsSidebar (props: IAlertSidebarProps): JSX.Element {
  const [careManagerOptions, setCareManagerOptions] = useState<ICareManager []>([])
  const [selectedCareManager, setSelectedCareManager] = useState('')
  const [isDefaultState, setIsDefaultState] = useState(true)
  const config = productConfig().alerts.refineSidebar

  const onSearchChange = (searchQuery: string): void => {
    props.alertsViewActions.setUrlFilters(null)
    props.alertsViewActions.setSearchQuery(searchQuery)
    props.fetchAlerts()
  }

  const handleSortChange = (e): void => {
    props.alertsViewActions.setSort(e.target.value)
    props.fetchAlerts()
  }

  const handleFilterChange = (filterId: string): void => {
    props.alertsViewActions.setFilter(filterId)
    props.fetchAlerts()
  }

  const handleCareManagerChange = (e): void => {
    props.alertsViewActions.setCareManagerId(e.target.value)
    setSelectedCareManager(e.target.value)
    props.fetchAlerts()
  }

  useEffect(() => {
    const isReset = props.alertsViewStore?.searchQueryReset
    if (isReset && careManagerOptions?.length > 0) setSelectedCareManager(careManagerOptions[0].id)
  }, [props.alertsViewStore?.searchQueryReset])

  useEffect(() => {
    const { careManagerId, careManagerFilters, filters, searchQuery, sortBy } = config.defaultFilterState as DefaultFilterState
    const storeFilters = [...props.alertsViewStore?.filters].sort()

    const defaultFiltersWithCareplan = [...filters as string [], ...props.alertsViewStore?.carePlanFilters].sort()
    const matches =
      _.isMatch(props.alertsViewStore, { careManagerId, careManagerFilters, searchQuery, sortBy }) &&
      _.isEqual(storeFilters, defaultFiltersWithCareplan)
    setIsDefaultState(matches)
  }, [props.alertsViewStore?.filters, props.alertsViewStore?.searchQuery, props.alertsViewStore?.careManagerFilters])

  useEffect(() => {
    const fetchedCareManagers = props.userSessionStore?.resources?.care_managers ?? []
    const constructedOptions = [...CARE_MANAGER_DEFAULT_OPTIONS, ...fetchedCareManagers]
    const filters = props.alertsViewStore?.careManagerFilters

    if (filters && filters?.length > 0) setSelectedCareManager(filters[0])
    else setSelectedCareManager(constructedOptions[0]?.id)

    setCareManagerOptions(constructedOptions)
  }, [props.userSessionStore?.resources])

  const renderResetFilterButton = (): JSX.Element => {
    return (
      <Box sx={{ width: '100%', textAlign: 'right', minHeight: '24.5px' }}>
        {!isDefaultState && <Button sx={{ padding: 0 }} onClick={props.resetView}>Reset Filters</Button>}
      </Box>
    )
  }

  const renderSearchBar = (): JSX.Element => {
    return (
      <section className='list-controls__section'>
        <Typography variant='subtitle2'>Search</Typography>

        <SearchBar
          handleChange={onSearchChange}
          placeholder='Member name or study ID'
          value={props.alertsViewStore?.searchQuery }
          resetSearch={props.alertsViewStore?.searchQueryReset }
        />
      </section>
    )
  }

  const renderSortSection = (): JSX.Element => {
    let displayStrMap = {}
    const defaultDisplayStrMap = {
      [ALERT_SORTS.SORT_STATUS]: 'Alert status (open/closed)',
      [ALERT_SORTS.SORT_TYPE]: 'Alert type',
      [ALERT_SORTS.DATE_DESC]: 'Date (newest first)',
      [ALERT_SORTS.DATE_ASC]: 'Date (oldest first)'
    }

    if (config.hasLevelSort) {
      displayStrMap[ALERT_SORTS.LEVEL] = 'Alert level (red/orange/yellow)'
    }
    displayStrMap = { ...displayStrMap, ...defaultDisplayStrMap }

    const options = Object.keys(displayStrMap).map(key =>
      <MenuItem value={key} key={key}>{displayStrMap[key]}</MenuItem>
    )

    return (
      <section className='list-controls__section'>
        <Typography variant='subtitle2'>Sort by</Typography>

        <TextField
          fullWidth
          select
          size='small'
          id='refine-alerts__sort-by'
          value={props.alertsViewStore?.sortBy}
          onChange={handleSortChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </TextField>
      </section>
    )
  }

  const renderCareManagerSection = (): JSX.Element => {
    const options = careManagerOptions.map(s =>
      <MenuItem key={s.id ?? s.name} value={s.id ?? s.name}>{s.name}</MenuItem>
    )

    return (
      <section className='list-controls__section' data-testid='list-controls__care-manager-section'>
        <Typography variant='subtitle2'>Care Manager</Typography>

        <TextField
          fullWidth
          select
          size='small'
          color='primary'
          id='refine-alerts__care-manager-select'
          data-testid='refine-alerts__care-manager-select'
          value={selectedCareManager}
          onChange={handleCareManagerChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </TextField>
      </section>
    )
  }

  const renderRefineAlertsSidebar = (): JSX.Element => {
    return (
      <ContentSidebar drawerWidth={285}>
        {renderResetFilterButton()}

        <div className='content-sidebar__module'>
          {renderSearchBar()}

          {renderSortSection()}

          {config.hasCareManagerFilter && renderCareManagerSection()}

          <section>
            {renderFilters(
              ALERTS_VIEW.FILTER_GROUPS,
              props.alertsViewStore?.filters,
              props.alertsViewStore?.filterCounts as Record<string, number>,
              handleFilterChange
            )}
          </section>
        </div>

      </ContentSidebar>
    )
  }

  return renderRefineAlertsSidebar()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    userSessionStore: state.userSession,
    alertsViewStore: state.alertsView
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    alertsViewActions: bindActionCreators(alertsViewSlice.actions, dispatch),
    fetchAlerts: bindActionCreators(fetchPagingAlerts, dispatch)
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(RefineAlertsSidebar)
