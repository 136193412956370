
import React from 'react'
import { MS_IN_A_DAY } from '../../../../constants/constants'
import { renderLevel } from '../../../../utils/alertUtils'
import { productConfig } from '../../../../config/config-service'
import CarePlanItem, { type ICarePlanItemProps } from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { Box } from '@mui/material'

export default class WeightRangeItem extends CarePlanItem {
  constructor (props: ICarePlanItemProps) {
    super(props)
    this.type = 'weight_delta'
    this.config = productConfig()
  }

  getValueInput (): JSX.Element {
    return this.getInputElement(
      1, 10, 1,
      CAREPLAN_VALUE_TYPES.VALUE.get(),
      CAREPLAN_VALUE_TYPES.VALUE.set()
    )
  }

  getTimeInput (): JSX.Element {
    return this.getInputElement(
      1, 14, 1,
      CAREPLAN_VALUE_TYPES.TIME.get(val => val as number / MS_IN_A_DAY),
      CAREPLAN_VALUE_TYPES.TIME.set(val => val as number * MS_IN_A_DAY)
    )
  }

  renderGoal (): JSX.Element {
    return <div>Maintain weight</div>
  }

  renderClinicianTrigger (): JSX.Element {
    const numDays = this.getValue(CAREPLAN_VALUE_TYPES.TIME.get(val => val as number / MS_IN_A_DAY))
    return (
      <div>
        If weight increases {this.getValueInput()} lb. or more over {this.getTimeInput()} day{numDays === 1 ? '' : 's'}.
      </div>
    )
  }

  renderCondensed (): JSX.Element {
    const lbs = this.getValueElement(CAREPLAN_VALUE_TYPES.VALUE.get())
    const getNumDays = CAREPLAN_VALUE_TYPES.TIME.get((val) => val as number / MS_IN_A_DAY)
    const numDays = this.getValue(getNumDays)
    const days = this.getValueElement(getNumDays)
    const level = this.state.itemData?.level ? renderLevel(this.state.itemData.level, true) : ''
    const className: string = this.state.itemData?.disabled === 1 ? 'disabled' : ''
    return <Box className={className}>{level} +{lbs} lb. over {days} day{numDays === 1 ? '' : 's'}</Box>
  }
}
