import moment, { type CalendarSpec } from 'moment'
import React from 'react'
import { formatNumber } from 'libphonenumber-js'
import { preciseRound } from './unitConversionUtils'
import { Box, Link, Typography, Tooltip, styled, tooltipClasses } from '@mui/material'
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material'
import SyncIcon from '@mui/icons-material/Sync'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'
import { type IMember } from '../interfaces/user.interface'
import { type INote } from '../interfaces/note.interface'
import { type IWeightBMI } from '../interfaces/weight-summary.interface'
import { type IAnalyticsInfo } from '../interfaces/store/analyticsStore.interface'

export const TIMEZONE_DISPLAY_MAP = {
  'America/Los_Angeles': '(UTC-08:00) US Pacific',
  'America/Denver': '(UTC-07:00) US Mountain',
  'America/Chicago': '(UTC-06:00) US Central',
  'America/New_York': '(UTC-05:00) US Eastern',
  'Canada/Atlantic': '(UTC-04:00) Atlantic',
  'Etc/GMT': '(UTC+00:00) Greenwich',
  'Europe/Paris': '(UTC+01:00) Central European',
  Japan: '(UTC+09:00) Japan'
}

export const DEVICE_PLATFORM_MAP = {
  IOS: 'iOS',
  ANDROID: 'Android',
  OTHER: 'Other'
}

export const GENDER_MAP = {
  FEMALE: 'Female',
  MALE: 'Male'
}

export const COMMUNICATION_PREFERENCE_MAP = {
  push: 'Push notification (app required)',
  text: 'Text message',
  email: 'Email',
  none: 'None'
}

export const momentCalendarConfig: CalendarSpec = {
  sameDay: '[Today], h:mm A',
  lastDay: '[Yesterday], h:mm A',
  lastWeek: 'MMM D, h:mm A',
  sameElse: function (m: any) {
    const itemDate = moment(this as any)
    const sameYear = itemDate?.isSame(m, 'year') ?? false
    if (sameYear) return 'MMM D, h:mm A'
    else return 'MMM D, YYYY  h:mm A'
  },
  nextDay: '[Tomorrow]', // Not used
  nextWeek: 'dddd' // Not used
}

export const momentCalendarDateConfig: CalendarSpec = {
  sameDay: '[Today]',
  lastDay: '[Yesterday]',
  lastWeek: 'MMM D',
  sameElse: function (m: any, now) {
    const sameYear = m?.isSame(now, 'year') || false
    if (sameYear) return 'MMM D'
    else return 'M/D/YY'
  },
  nextDay: '[Tomorrow]', // Not used
  nextWeek: 'dddd' // Not used
}

export const generateRandomId = (): string => {
  return Math.random().toString(36).substr(2, 9)
}

/* *****************************************************************************
 * Simple transforms
 * *****************************************************************************/

const getDisplayWeight = (kg: number): string => {
  const lbs = preciseRound(kg * 2.20462, 2)
  return `${lbs} lbs`
}

export const getDisplayBMI = (bmi: number, weightCondition: IWeightBMI): string => {
  return `${preciseRound(bmi, 1)} (${weightCondition})`
}

export const getDisplayEmail = (email?: string): JSX.Element => {
  if (email) {
    return <Link sx={{ wordBreak: 'break-all' }} underline='none' color='primary' title={email} href={`mailto:${email}`}>{email}</Link>
  } else return generateEmptySpan()
}

export const getDisplayPhoneNumber = (p?: string): string | JSX.Element => {
  if (p) {
    try {
      return formatNumber({ country: 'US', phone: p }, 'NATIONAL')
    } catch (err) {
      return ''
    }
  } else return generateEmptySpan()
}

export const getDisplayDelta = (d: number): string => {
  if (d === 0) return '0'
  if (d > 0) return (`+${d}`)
  if (d < 0) return (`−${-1 * d}`)
  return '—'
}

export const getDeltaArrow = (d: number, defaultOrder: boolean): JSX.Element | null => {
  const [upClass, downClass] = defaultOrder ? ['green', 'red'] : ['red', 'green']

  if (d < 0) return <span className={`delta-arrow delta-arrow--${downClass}`}><ArrowDownwardRounded fontSize='small' /></span>
  if (d > 0) return <span className={`delta-arrow delta-arrow--${upClass}`}><ArrowUpwardRounded fontSize='small' /></span>
  return null
}

export const getDisplayWeightGain = (weightGain: number): JSX.Element => {
  if (weightGain === null) {
    return <span className='no-data'>Not enough data</span>
  }

  return (
    <span>
      <strong>{getDisplayDelta(weightGain)}</strong> lb
    </span>
  )
}

export const getDisplayTimezone = (t?: string): string => {
  const timezone: string = t ? TIMEZONE_DISPLAY_MAP[t] : TIMEZONE_DISPLAY_MAP['America/Los_Angeles']
  const parenIndex = timezone.indexOf(')')
  if (parenIndex >= 0) {
    return timezone.slice(parenIndex + 1).trim()
  } else return timezone
}

export const getDisplayPlatform = (t: string): string | JSX.Element => {
  return t ? DEVICE_PLATFORM_MAP[t] : generateEmptySpan('Unknown')
}

export const getDisplaySyncStatus = (fitbitStatus: 0 | 1 | undefined): JSX.Element => {
  if (fitbitStatus === 1) {
    return (<Box sx={{ display: 'inline-flex', alignItems: 'center' }}><SyncIcon fontSize='small' /> Linked</Box>)
  }

  return (<Box sx={{ display: 'inline-flex', alignItems: 'center' }}><SyncDisabledIcon fontSize='small' /> Unlinked</Box>)
}

export const generateEmptySpan = (emptyText = 'None'): JSX.Element => {
  return <span className='is-empty'>{emptyText}</span>
}

export const getDisplayBodyTraceScaleStatus = (user: IMember): string | JSX.Element => {
  if (user.bt_scale_imei) return 'Linked'
  return generateEmptySpan('No device')
}

export const getDisplayBodyTraceBpStatus = (user: IMember): string | JSX.Element => {
  if (user.bt_bp_imei) return 'Linked'
  return generateEmptySpan('No device')
}

export const getDisplayIGlucoseStatus = (user: IMember): string | JSX.Element => {
  if (user.iglucose_id) return 'Linked'
  return generateEmptySpan('No device')
}

export const getDisplayBioStickerStatus = (user: IMember): string | JSX.Element => {
  if (user.biosticker_id) {
    if (user.biohub_id) {
      return 'Button/Sticker and hub'
    }
    return 'Button/Sticker only'
  }
  // note that having a hub without a sticker is impossible
  return generateEmptySpan('No devices')
}

export const getHoursMinutes = (h: number): { hours: number, minutes: number } => {
  return {
    hours: Math.floor(h),
    minutes: Math.round((h % 1) * 60)
  }
}

export const secondsToTime = (totalSeconds: number): string => {
  const h = Math.floor(totalSeconds / 3600).toString().padStart(2, '0')
  const m = Math.floor(totalSeconds % 3600 / 60).toString().padStart(2, '0')
  const s = Math.floor(totalSeconds % 60).toString().padStart(2, '0')

  return `${h}:${m}:${s}`
}
/* *****************************************************************************
 * User transforms - all of the below expect a standard {userObj}
 * *****************************************************************************/

export const getDisplayName = (userObj: IMember, includeMedicalId = true, nameColor = 'rgba(0, 0, 0, 0.87)'): JSX.Element | null => {
  if (!userObj) return null
  const lastName = userObj.last_name ? ` ${userObj.last_name}` : ''
  const fullName = `${userObj.first_name ?? ''}${lastName}`

  return (
    <Box component='span' sx={{ wordBreak: 'break-all', color: nameColor }}>
      {fullName} {includeMedicalId && <Typography variant='subtitle2' component='span' color='secondary' data-testid='medical-id'>#{userObj.medical_id}</Typography>}
    </Box>
  )
}

export const getInitials = (userObj: IMember): string => {
  return `${userObj.first_name ? userObj.first_name[0] : ''}${userObj.last_name ? userObj.last_name[0] : ''}`
}

export const getSimpleDisplayName = (userObj: IMember | IAnalyticsInfo): string => {
  const lastName = userObj.last_name ? `${userObj.last_name}` : ''
  const firstName = userObj.first_name ? `${userObj.first_name}` : ''
  const fullName = `${firstName} ${lastName}`
  return fullName
}

export const getDisplayCaregiverName = (userObj: IMember): string | JSX.Element => {
  if (userObj.caregiver_name) return userObj.caregiver_name
  else return generateEmptySpan()
}

export const getDisplayStreetAddress = (userObj: IMember): JSX.Element => {
  if (userObj.street_address_1) {
    return <span>{userObj.street_address_1} {userObj.street_address_2} <br />{userObj.city}, {userObj.state} {userObj.zip_code}</span>
  }
  return generateEmptySpan()
}

export const getDisplayCaregiverEmail = (userObj: IMember): JSX.Element => {
  return getDisplayEmail(userObj.caregiver_email)
}

export const getDisplayHeight = (userObj: IMember): string => {
  const cm = userObj.height_in_cm ?? 0
  const inchesTotal = cm / 2.54
  const feet = Math.floor(inchesTotal / 12)
  const inches = preciseRound(inchesTotal % 12, 2)

  const feetDisplay = `${feet} ft`
  const inchesDisplay = inches ? `${inches} in` : ''

  return `${feetDisplay} ${inchesDisplay}`
}

export const getDisplayAge = (userObj: IMember): string => {
  const ageInYears = moment().diff(userObj.date_of_birth, 'years')
  return `${ageInYears}`
}

export const getDisplayStartWeight = (userObj: IMember): string | JSX.Element => {
  if (!userObj.start_weight_in_kg) return generateEmptySpan('Unknown')
  else return getDisplayWeight(userObj.start_weight_in_kg)
}

export const getDisplayDryWeight = (userObj: IMember): string | JSX.Element => {
  if (!userObj.dry_weight_in_kg) return generateEmptySpan('Unknown')
  return getDisplayWeight(userObj.dry_weight_in_kg)
}

export const getDisplayGestationalWeeks = (userObj: IMember): string => {
  const numPregWeeks = moment().diff(moment(userObj.conception_date), 'weeks')
  const numPregWeeksStr = (numPregWeeks === 1) ? '1 week' : `${numPregWeeks} weeks`
  return numPregWeeksStr
}

export const cleanNoteContent = (note: INote): JSX.Element => {
  let providerContent: JSX.Element
  switch (note.provider_content) {
    case 'STATUS:READ':
    case 'Read':
      providerContent = <em>Read this alert</em>
      break
    case 'STATUS:CLOSED':
    case 'CLOSED':
      providerContent = <em>Closed this alert</em>
      break
    case 'STATUS:OPEN':
    case 'OPEN':
      providerContent = <em>Reopened this alert</em>
      break
    default:
      providerContent = <> {note.provider_content.split('\n').map(
        (l, i) => (<span key={i}>{l} <br /></span>)
      )} </>
  }

  return providerContent
}

// @ts-expect-error styled not in react 18
export const HtmlTooltip = styled(({ className, ...props }) => (
  // @ts-expect-error props passed down
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 240,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))
