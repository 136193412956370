import React, { useEffect, useState } from 'react'
import { FormControl, FormHelperText, Grid, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { type IUser } from '../../../../interfaces/user.interface'
import { TIMEZONE_DISPLAY_MAP } from '../../../../utils/baseStringUtils'

interface IAccountInformationProps {
  onChange: (user: Record<string, string | number>) => void
  onboardingUser: IUser
}

export default function OnboardAccountInfo (props: IAccountInformationProps): JSX.Element {
  const [firstName, setFirstName] = useState(props.onboardingUser.first_name ?? '')
  const [lastName, setLastName] = useState(props.onboardingUser.last_name ?? '')
  const [email, setEmail] = useState(props.onboardingUser.email ?? '')
  const [phone, setPhone] = useState(props.onboardingUser.phone ?? '')
  const [timezone, setTimezone] = useState(props.onboardingUser.timezone ?? '')

  useEffect(() => {
    const updatedUser = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      timezone
    }

    props.onChange(updatedUser)
  }, [firstName, lastName, email, phone, timezone])

  const renderAccountInformation = (): JSX.Element => {
    const selectOptions = Object.keys(TIMEZONE_DISPLAY_MAP).map(key => {
      return { key, value: TIMEZONE_DISPLAY_MAP[key] }
    })
    const selectedOption = selectOptions.find(option => option.key === timezone)?.key ?? ''

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth size='small' variant='outlined' error={!firstName}>
            <Typography variant='body1' id='firstName_label'>First name *</Typography>
            <OutlinedInput id='firstName' data-testid='onboarding-firstName' value={firstName} onChange={e => { setFirstName(e.target.value) }} inputProps={{ 'aria-labelledby': 'firstName_label' }} />
            {!firstName && <FormHelperText id='form__error-text' error>First name is required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth size='small' variant='outlined' error={!lastName}>
            <Typography variant='body1' id='lastName_label'>Last name *</Typography>
            <OutlinedInput id='lastName' data-testid='onboarding-lastName' value={lastName} onChange={e => { setLastName(e.target.value) }} inputProps={{ 'aria-labelledby': 'lastName_label' }} />
            {!lastName && <FormHelperText id='form__error-text' error>Last name is required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth size='small' variant='outlined' error={!email}>
            <Typography variant='body1' id='email_label'>Email *</Typography>
            <OutlinedInput id='email' data-testid='onboarding-email' value={email} onChange={e => { setEmail(e.target.value) }} inputProps={{ 'aria-labelledby': 'email_label' }} />
            {!email && <FormHelperText id='form__error-text' error>Email is required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth size='small' variant='outlined'>
            <Typography variant='body1' id='homePhone_label'>Phone</Typography>
            <OutlinedInput id='homePhone' data-testid='onboarding-homePhone' value={phone} onChange={e => { setPhone(e.target.value) }} inputProps={{ 'aria-labelledby': 'homePhone_label' }} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth size='small'>
            <Typography variant='body1' id='timezone_label'>Timezone *</Typography>

            <Select value={selectedOption} onChange={e => { setTimezone(e.target.value) }} id='timezone' data-testid='onboarding-timezone'>
              {selectOptions.map(item => <MenuItem key={item.key} value={item.key}>{item?.value}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

      </Grid>
    )
  }

  return renderAccountInformation()
}
