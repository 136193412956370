import React, { useEffect, useState } from 'react'
import {
  logGAModalOpen,
  logGAModalClose
} from '../../utils/googleAnalyticsUtils'

/* *****************************************************************************
 * Modal element
 * *****************************************************************************/

import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { IconButton } from '@mui/material'
import { useLocation } from 'react-router'
import { type IMember } from '../../interfaces/user.interface'

interface Props {
  isOpen: boolean
  name: string
  maxWidth?: 'sm' | 'md' | 'lg'
  modalTitle: string | JSX.Element
  children: any
  hideCloseIcon?: boolean
  targetUser?: IMember
  onModalClose: () => void
}

const Modal = (props: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setIsOpen(props.isOpen)
    if (props.isOpen) logGAModalOpen(props.name, props.targetUser ?? {})
  }, [props.isOpen])

  const handleModalBoxClick = (): void => {
    setIsOpen(false)
    logGAModalClose(location)
    props.onModalClose()
  }

  return (
    <Dialog
      closeAfterTransition
      fullWidth
      maxWidth={props.maxWidth ?? 'lg'}
      open={isOpen}
      onClose={handleModalBoxClick}
      id={`${props.name}__${Date.now()}`}
      sx={{
        '& .MuiDialog-paper': {
          paddingBottom: '1rem'
        }
      }}
    >
      <DialogTitle data-testid='modal__title' sx={{ justifyContent: 'space-between', display: 'inline-flex', color: theme => theme.palette.primary.main }}>
        <span>{props.modalTitle}</span>
        {!props.hideCloseIcon && <IconButton data-testid='modal__close-x' onClick={handleModalBoxClick}><CloseIcon sx={{ color: 'black' }} /></IconButton>}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        {props.children}
      </DialogContent>
    </Dialog>
  )
}

export default Modal
