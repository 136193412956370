import React, { useEffect, useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { getSimpleDisplayName } from '../../../utils/baseStringUtils'
import { getDefaultMemberFieldValuesFromConfig, getMemberProfileFormErrors, getProfileMemberFields, validateMemberForm } from '../../../utils/member/baseProfileUtils'
import restApi from '../../../api'
import Modal from '../../elements/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Link from '@mui/material/Link'

import { type ICareManager } from '../../../interfaces/store/sessionStore.interface'
import { type DefaultFormFieldValues } from '../../../interfaces/product-config.interface'
import { type IUser } from '../../../interfaces/user.interface'
import { getMemberApiBody, getSSOPatientFormInitialValues } from '../../../constants/constants'
import { type RootState, type AppDispatch } from '../../..'
import { type IEpicPatient } from '../../../interfaces/store/epicStore.interface'
import { epicSlice } from '../../../store/epic'
import { bindActionCreators } from 'redux'
import { Step, StepContent, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material'
import MemberInformationFields from '../../controllers/forms/AddMember/MemberInformationFields'
import AddMemberFooter from '../../controllers/forms/AddMember/AddMemberFooter'
import { productConfig } from '../../../config/config-service'
import MemberOptionalFields from '../../controllers/forms/AddMember/MemberOptionalFields'
import MemberDeviceOrderFields from '../../controllers/forms/AddMember/MemberDeviceOrderFields'
import MemberTermsFields from '../../controllers/forms/AddMember/MemberTerms'
import { memberFormSlice } from '../../../store/memberForm'
import { EjentaForm } from '../../elements/EjentaForm'
import { Form, FormSpy } from 'react-final-form'
import { getMemberAddressFields, getMemberOptionalFields } from '../../../constants/customMemberFields'
import _ from 'lodash'
import { type FormApi } from 'final-form'

interface IAddMemberProps extends PropsFromRedux {
  isOpen: boolean
  onModalClose: (newMemberId?: string) => void
  epicPatient?: IEpicPatient
}

function AddMemberModal (props: IAddMemberProps): JSX.Element {
  const memberFields = getProfileMemberFields()
  const [activeStep, setActiveStep] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const theme = useTheme()
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'))
  const profileConfig = productConfig().profile

  useEffect(() => {
    handleFormReset()
    return () => { sendAnalytics(true, { pageIndex: activeStep }) }
  }, [])

  useEffect(() => {
    if (props.epicPatient && props.createMemberApi.data.id) {
      props.epicActions.populateEpicPatientId(props.createMemberApi.data.id)
    }
  }, [props.createMemberApi.data.id])

  useEffect(() => {
    props.memberFormActions.setFormValidity(isValid)
  }, [isValid])

  const sendAnalytics = (unloadFlag, updatedData): void => {
    // TODO should also work on analytics for a page in progress, so we can track how often the signup process is completed
    if (props.createMemberApi.data.id) {
      props.apiActions.sendAnalytics({ id: props.createMemberApi.data.id },
        {
          body: JSON.stringify({
            type: 'event',
            key: `add-member_screen${unloadFlag ? '_unload' : '_data'}`,
            value: JSON.stringify({ activeStep, ...updatedData })
          })
        })
    }
  }

  const getDefaultConfiguration = (): DefaultFormFieldValues => {
    let defaultFormValues = getDefaultMemberFieldValuesFromConfig(props.userSession.user as IUser, props.userSession.activeGroup?.name)
    if (props.epicPatient) defaultFormValues = getSSOPatientFormInitialValues(memberFields, defaultFormValues, props.epicPatient)
    return defaultFormValues
  }

  const getInitialValues = (): DefaultFormFieldValues => {
    return { ...getDefaultConfiguration, ...props.memberForm.fieldValues }
  }

  const getAddMemberSteps = (form?: FormApi<Record<string, any>, Partial<Record<string, any>>>): Array<{ page: string, label: string, component: JSX.Element }> => [
    {
      page: 'profile',
      label: 'Enter member information',
      component: (<MemberInformationFields
        careManagers={props.userSession.resources?.care_managers as ICareManager[]}
        allowedGroups={props.userSession.user?.allowedGroups}
        change={form?.change}
      />)
    },
    {
      page: 'optional',
      label: 'Enter optional information',
      component: (<MemberOptionalFields />)
    },
    {
      page: 'devices',
      label: 'Choose devices',
      component: (<MemberDeviceOrderFields memberFormState={props.memberForm} setOrderScale={props.memberFormActions.setOrderScale} setOrderBloodPressure={props.memberFormActions.setOrderBloodPressure} />)
    },
    {
      page: 'agreements',
      label: 'Accept terms', // After this, submit the data to the backend
      component: (<MemberTermsFields memberFormState={props.memberForm} setTermsAcceptance={props.memberFormActions.setTermsAcceptance} />)
    }
  ]

  const getSteps = (form?: FormApi<Record<string, any>, Partial<Record<string, any>>>): Array<{ page: string, label: string, component: JSX.Element }> => {
    let steps = getAddMemberSteps(form)
    if (profileConfig.optionalFormFields.length === 0) steps = steps.filter(step => step.page !== 'optional')
    if (!profileConfig.termsCheckbox) {
      steps = steps.filter(step => step.page !== 'agreements')
    }
    return steps
  }

  const renderHorizontalSteps = (formClass: string, form: FormApi<Record<string, any>, Partial<Record<string, any>>>): JSX.Element => {
    const steps = getSteps(form)
    return (
      <Box className={formClass}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((stepData, index) => {
            return (
              <Step key={stepData.label}>
                <StepLabel>{stepData.label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box sx={{ marginLeft: '5%', marginRight: '5%' }}>
          {steps[activeStep].component}
        </Box>
      </Box>
    )
  }

  const renderVerticalSteps = (formClass: string, form: FormApi<Record<string, any>, Partial<Record<string, any>>>): JSX.Element => {
    const steps = getSteps(form)
    return (
      <Box className={formClass}>
        <Stepper
          activeStep={activeStep}
          sx={{ mb: 4 }}
          orientation='vertical'
        >
          {steps.map((stepData, index) => {
            return (
              <Step key={stepData.label}>
                <StepLabel>{stepData.label}</StepLabel>
                <StepContent> {steps[activeStep].component} </StepContent>
                {(activeStep === index) && renderProgressionButtons(form)}
              </Step>
            )
          })}
        </Stepper>
      </Box>
    )
  }

  const renderProgressionButtons = (form: FormApi<Record<string, any>, Partial<Record<string, any>>>): JSX.Element => {
    const steps = getSteps(form)
    const isLastStep = activeStep === steps.length - 1
    const canContinue = props.memberForm.isFormValid // if this form is valid and all required fields are provided

    return (<Box sx={{ display: 'flex', marginLeft: '5%', marginRight: '5%', flexDirection: 'row', pt: 2, mb: 2 }}>
      <Button color="inherit" disabled={activeStep === 0} onClick={handleDecrementPage} sx={{ mr: 1 }}> Back </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      {!isLastStep && <Button variant='contained' onClick={handleIncrementPage} type='button' sx={{ mr: 1 }} disabled={!canContinue}>Next</Button>}
      {isLastStep && <Button variant='contained' type='submit' sx={{ mr: 1 }} disabled={!canContinue}>Submit</Button>}
    </Box>)
  }

  const handleIncrementPage = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleDecrementPage = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleFormSubmit = async (): Promise<any> => {
    let apiFormData = { ...props.memberForm.fieldValues }

    const submitPromise = new Promise((resolve, reject) => {
      apiFormData = _.omit(apiFormData, ['signedConsentAndTos', 'signedConsent', 'orderBodyTraceScale', 'orderBodyTraceBloodPressure'])
      const group = props.userSession.activeGroup ?? props.userSession.user?.group
      const fields = { ...memberFields, ...getMemberAddressFields(), ...getMemberOptionalFields() }
      const apiBody = getMemberApiBody(fields, apiFormData, group as string)

      // the interaction service will turn these into timestamps
      if (props.memberForm.orderBodyTraceScale) apiBody.orderBodyTraceScale = 1
      if (props.memberForm.orderBodyTraceBloodPressure) apiBody.orderBodyTraceBloodPressure = 1
      if (props.memberForm.fieldValues.signed_consent) apiBody.signed_consent = 1
      if (props.memberForm.fieldValues.signedConsentAndTos) {
        apiBody.signed_tos = 1
        apiBody.signed_consent = 1
      }

      if (props.callCreateMemberApi) {
        props.callCreateMemberApi({
          promise: { resolve, reject },
          promiseTransformer: (errors: Record<string, string>) => getMemberProfileFormErrors(errors, memberFields)
        }, apiBody)
      }
    })

    return await submitPromise // TODO will fail on 500 api error and need a refresh
  }

  const handleFormReset = (): void => {
    props.memberFormActions.resetForm()
    props.memberFormActions.setDefaultValues(getDefaultConfiguration())
    if (props.resetCreateMemberApi) props.resetCreateMemberApi()
  }

  const handleModalClose = (): void => {
    props.memberFormActions.resetForm()
    props.onModalClose(props.createMemberApi.data.id as string)
    if (props.resetCreateMemberApi) props.resetCreateMemberApi()
  }

  const renderForm = (): JSX.Element => {
    const formClass = 'add-member-form'
    const steps = getSteps()

    return (
      <Form
        onSubmit={handleFormSubmit}
        initialValues={getInitialValues()}
        validate={values => {
          const shouldCheckTOS = activeStep === steps.length - 1 && profileConfig.termsCheckbox !== ''
          const shouldCheckAddress = (props.memberForm.orderBodyTraceBloodPressure || props.memberForm.orderBodyTraceScale) && steps[activeStep].page === 'devices'
          const baseFields = shouldCheckAddress && !shouldCheckTOS ? getMemberAddressFields() : getProfileMemberFields()
          return validateMemberForm(values, baseFields, true, shouldCheckTOS)
        }}>
        {({ handleSubmit, form, submitError, errors }) => (
          <EjentaForm onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ valid: true, errors: false }}
              onChange={formProps => {
                if (formProps.valid !== props.memberForm.isFormValid) setIsValid(formProps.valid)
              }}
            />
            {isDesktopView && <>
              {renderHorizontalSteps(formClass, form)}
              {renderProgressionButtons(form)}
            </>
            }
            {!isDesktopView && renderVerticalSteps(formClass, form)}

            <AddMemberFooter />
          </EjentaForm>
        )}
      </Form>
    )
  }

  const renderFormSuccess = (): JSX.Element => {
    const memberData = props.createMemberApi.data
    const memberName = getSimpleDisplayName(memberData)
    const memberId = memberData.id as string

    return (
      <div data-testid='add-member__success'>
        <Box data-testid='add-member__confirmation'>
          Successfully added new member <Link href={`/profile/${memberId}`} data-testid='nw-NewMemberProfile'>{memberName}</Link>.
        </Box>

        <DialogActions data-testid='add-member__success-buttons'>
          <Button variant='contained' onClick={handleFormReset}>
            Add another member
          </Button>
          <Button variant='outlined' onClick={handleModalClose}>
            Back to members list
          </Button>
        </DialogActions>
      </div>
    )
  }

  const renderModal = (): JSX.Element => {
    const isMemberAdded = !!props.createMemberApi.data.id

    return (
      <Modal
        name='addMember'
        maxWidth='lg'
        hideCloseIcon={!!props.epicPatient}
        isOpen={props.isOpen}
        onModalClose={handleModalClose}
        modalTitle='Add member'
      >
        {!isMemberAdded && renderForm()}
        {isMemberAdded && renderFormSuccess()}
      </Modal>
    )
  }

  return renderModal()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    userSession: state.userSession,
    createMemberApi: state.api.createMember,
    epicStore: state.epic,
    memberForm: state.memberForm
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    callCreateMemberApi: (pathVars, apiFields) => {
      dispatch(restApi.actions.createMember(pathVars, { body: JSON.stringify(apiFields) }))
    },
    resetCreateMemberApi: () => {
      dispatch(restApi.actions.createMember.reset())
    },
    epicActions: bindActionCreators(epicSlice.actions, dispatch),
    memberFormActions: bindActionCreators(memberFormSlice.actions, dispatch),
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AddMemberModal)
