import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import { getBaseSeriesOptions, getPointDateString } from './baseChartUtils'
import { getDefaultChartOptions, getMarkerRadius } from './chartUtils'
import { isAlertOpen } from '../../constants/constants'
import { type SeriesLineOptions, type Options, type SeriesScatterOptions, type YAxisOptions } from 'highcharts'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'
import { type IWeightClick } from '../../interfaces/weight-summary.interface'
import { type IScatterSeries, type ILineSeries, type IAreaRangeSeries, type IMarker } from '../../interfaces/chart.interface'
import { type IAlertClick } from '../../interfaces/alert.interface'

export const baseWeightChartOptions = {
  ...getDefaultChartOptions('weight-chart'),
  tooltip: {
    headerFormat: '',
    pointFormatter: function format () {
      const point = this as any
      return `<strong>${point.name as string}</strong><br/>
               ${point.dataString as string}`
    },
    useHTML: true,
    shared: true,
    stickOnContact: true,
    borderWidth: 0
  },

  yAxis: [{
    title: { text: '' },
    labels: { format: '{value} lb' }
  }]
}

export function getWeightMeasurementsSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore, handleDeleteWeight: IWeightClick): Options {
  if (!memberObj.weight?.data.length) return options

  const newOptions = _.cloneDeep(options)

  // Set marker radius
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))

  const weightMeasurements = _.reverse(_.cloneDeep(memberObj.weight.data)).map((m) => {
    const point = {
      name: getPointDateString(moment(m.timestamp), true),
      x: +moment(m.timestamp),
      y: m.weight.value,
      useHTML: true,
      dataString: m.timestamp,
      dataSource: m.source,
      events: {
        click: () => {
          if (handleDeleteWeight) handleDeleteWeight(m.timestamp)
        }
      }
    }
    return point
  })

  const weightMeasurementsSeries: SeriesLineOptions = _.extend({
    name: 'Weight',
    data: weightMeasurements,
    zIndex: 1,
    marker: { symbol: 'circle', lineWidth: 0 },
    type: 'line' as ILineSeries,
    cursor: 'pointer',
    className: 'series-weightMeasurements',
    turboThreshold: 10000,
    tooltip: {
      useHtml: true,
      headerFormat: '',
      pointFormatter: function format () {
        const point = this as any

        return `
            <strong>${point.name as string}</strong><br/>
            <span>Weight: ${point.y as number} ${memberObj.weight?.information.units.weight ?? ''}</span><br />
            <small>Source: ${point.dataSource as string ?? 'Unknown'}</small>
            <small style='justify-content: right; width: 100%; display: flex'>Click to delete</small>
          `
      }
    }
  }, getBaseSeriesOptions())

  newOptions.series?.push(weightMeasurementsSeries)
  return newOptions
}

export function getDeletedWeightMeasurementsSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore, handleRestoreWeight: IWeightClick): Options {
  const deletedWeightData = memberObj.deleted_weight?.data ?? []
  const newOptions = _.cloneDeep(options)

  const deletedMeasurements = _.reverse(_.cloneDeep(deletedWeightData)).map((m) => {
    const point = {
      name: getPointDateString(moment(m.timestamp), true),
      x: +moment(m.timestamp),
      y: m.weight.value,
      useHtml: true,
      dataString: m.timestamp,
      dataSource: m.source,
      events: {
        click: () => {
          if (handleRestoreWeight) handleRestoreWeight(m.timestamp)
        }
      }
    }
    return point
  })

  let markerRadius = getMarkerRadius(dashboardViewStore)
  if (!markerRadius || markerRadius < 2) markerRadius = 2

  const deletedWeightsSeries: SeriesScatterOptions = _.extend({
    name: 'Deleted Weights',
    data: deletedMeasurements,
    zIndex: 1,
    color: '#a1a1a1',
    marker: { symbol: 'circle', radius: markerRadius, lineWidth: 0 },
    type: 'scatter' as IScatterSeries,
    cursor: 'pointer',
    className: 'series-deletedWeights',
    tooltip: {
      useHtml: true,
      headerFormat: '',
      pointFormatter: function format () {
        const point = this as any

        return `
            <strong>${point.name as string}</strong><br/>
            <span>Weight: ${point.y as number} ${memberObj.deleted_weight?.information.units.weight as string}</span><br />
            <small>Source: ${point.dataSource as string ?? 'Unknown'}</small>
            <small style='justify-content: right; width: 100%; display: flex'>Click to restore</small>
          `
      }
    }
  }, getBaseSeriesOptions())

  newOptions.series?.push(deletedWeightsSeries)
  return newOptions
}

export function get2LbRangeSeries (memberObj: IMemberSummary, options: Options): Options {
  if (!memberObj.weight?.data.length) return options

  const newOptions: Options = { ...options }
  const sortedWeightData = _.sortBy(memberObj.weight.data, w => w.timestamp)
  const rangeData: number [][] = []

  // Use the previous period's average weight to get the "first" weight for calculating this range
  // (non-ideal, but we don't have easy access to the previous period's measurements T___T).
  if (memberObj.weight.information.periods[1]) {
    rangeData.push([
      +moment(sortedWeightData[0].timestamp).subtract(1, 'day'),
      memberObj.weight.information.periods[1].avg_weight.value - 2,
      memberObj.weight.information.periods[1].avg_weight.value + 2
    ])
  }

  _.each(sortedWeightData, (w, wIndex) => {
    const nextW = sortedWeightData[wIndex + 1]
    if (nextW && moment(nextW.timestamp).isBefore(moment(w.timestamp).add(1, 'day'))) {
      rangeData.push([+moment(nextW.timestamp) - 1, w.weight.value - 2, w.weight.value + 2])
    } else {
      rangeData.push([+(moment(w.timestamp).add(1, 'day')) - 1, w.weight.value - 2, w.weight.value + 2])
    }
  })

  if (rangeData.length >= 2) {
    const rangeSeries = _.extend({
      name: 'Within 2 lbs/day',
      data: rangeData,
      yAxis: 1,
      color: '#95D542',

      type: 'arearange' as IAreaRangeSeries,
      marker: { enabled: false },
      className: 'series-weight2Lb',
      zIndex: 0,

      enableMouseTracking: false
    }, getBaseSeriesOptions())

    const yAxis = newOptions.yAxis as YAxisOptions []
    newOptions.series?.push(rangeSeries)
    yAxis?.push({ title: { text: null }, labels: { enabled: false }, linkedTo: 0 })
  }
  return newOptions
}

export function get5LbLastWeekSeries (memberObj: IMemberSummary, options: Options): Options {
  if (!memberObj.weight?.data.length) return options

  const newOptions = _.cloneDeep(options)
  const lastWeekAvg = _.get(memberObj.weight, ['information', 'periods', '1', 'avg_weight', 'value'])
  newOptions.series?.push(_.extend({
    className: 'series-weight5lbLastWeek',
    name: '5 lbs from last week',
    type: 'line' as ILineSeries,
    marker: { enabled: false },
    data: lastWeekAvg ? [[0, +lastWeekAvg + 5], [+moment().add(1, 'week'), +lastWeekAvg + 5]] : []
  }, getBaseSeriesOptions()))

  return newOptions
}

export function getWeightAlertsSeries (memberObj: IMemberSummary, options: Options, handleAlertClick: IAlertClick): Options {
  if (!memberObj.alerts?.length) return options

  const weightAlerts = _.filter(memberObj.alerts, a => a.metric_type === 'weight' || (a.metric_type === 'multi' && a.member_content.includes('weight')))
  if (!weightAlerts.length) return options

  const alertsData = weightAlerts.map((alertObj) => {
    /*
     * Get the Y-value of the marker
     */
    let pointY = 0
    const weightMeasurement = memberObj.weight?.data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display the alert marker above the actual weight
    if (weightMeasurement) {
      pointY = weightMeasurement.weight.value * 1.02
    } else if (memberObj.weight?.data[0]) { // Otherwise just use the first weight (or deleted weight) value we have, but be more generous about padding
      pointY = memberObj.weight?.data[0].weight.value * 1.05
    } else if (memberObj.deleted_weight?.data[0]) {
      pointY = memberObj.deleted_weight?.data[0].weight.value * 1.05
    } else {
      pointY = 100 // we have no weights or deleted weights in this range; shouldn't happen, but in case it does, just use a Y-axis value of 100
    }

    const isOpen = isAlertOpen(alertObj)
    const marker: IMarker = {}
    if (alertObj.type === 'weight_tracking' || alertObj.type === 'multi_tracking') marker.symbol = 'circle'

    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'weight_tracking' || alertObj.type === 'multi_tracking'
    })

    const point = {
      name: 'Weight alert',
      description: alertObj.provider_content,
      x: +moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,
      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) handleAlertClick(alertObj)
        }
      }
    }

    return point
  })

  const alertsSeries: SeriesScatterOptions = _.extend({
    name: 'Weight alerts',
    type: 'scatter' as IScatterSeries,
    className: 'series-weightAlerts',
    lineWidth: 0,
    color: '#EF6C00',
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },

    tooltip: {
      useHTML: true,
      headerFormat: '',
      // do not use pointFormat. Despite documentation, it does not allow access to all point object properties.
      pointFormatter: function format () {
        const point = this as any

        return `
          <strong>${point.name as string}</strong><br/>
          ${point.description as string}
        `
      }
    },

    data: alertsData
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series?.push(alertsSeries)
  return newOptions
}
