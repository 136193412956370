import React from 'react'
import Grid from '@mui/material/Grid'
import { getProfileMemberFields } from '../../../utils/member/baseProfileUtils'

export default function MemberPregnancyFormFields (): JSX.Element {
  const memberFields = getProfileMemberFields()
  const renderPregnancyFields = (): JSX.Element => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>{memberFields.conceptionDate.formInput ? memberFields.conceptionDate.formInput() : <></>}</Grid>
        <Grid item xs={12} md={6}>{memberFields.deliveryDate.formInput ? memberFields.deliveryDate.formInput() : <></>}</Grid>
        <Grid item xs={12} md={6}>{memberFields.startWeight.formInput ? memberFields.startWeight.formInput() : <></>}</Grid>
      </Grid>
    )
  }

  return renderPregnancyFields()
}
