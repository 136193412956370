import React, { useEffect, useState } from 'react'
import restApi from '../../../api'
import formurlencoded from 'form-urlencoded'
import BasicFrame from '../../layout/BasicFrame'
import { bindActionCreators } from 'redux'
import { type ConnectedProps, connect } from 'react-redux'
import { EjentaForm, renderField, renderInput } from '../../elements/EjentaForm'
import { getDisplayPhoneNumber } from '../../../utils/baseStringUtils'
import { setLoggingInWithPhone } from '../../../store/userSession'
import { Box, Typography, Link, Button, Alert, Stack } from '@mui/material'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import CheckIcon from '@mui/icons-material/Check'
import { type RootState, type AppDispatch } from '../../..'

function PhoneLoginForm (props: PropsFromRedux): JSX.Element {
  const [phoneLoginNumber, setPhoneLoginNumber] = useState('')

  useEffect(() => {
    if (props.setLoggingInWithPhone) props.setLoggingInWithPhone(true)
    return () => {
      if (props.setLoggingInWithPhone) props.setLoggingInWithPhone(false)
      if (props.resetSendPhoneLoginCodeApi) props.resetSendPhoneLoginCodeApi()
    }
  }, [])

  const handleSubmitPhoneNumber = async (formData): Promise<any> => {
    setPhoneLoginNumber(formData.phone)
    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.sendPhoneLoginCode(
        { promise: { resolve, reject } },
        { body: JSON.stringify({ phone: formData.phone }) }
      )
    })

    return await submitPromise.catch(data => {
      return { [FORM_ERROR]: data }
    })
  }

  const handleSubmitCode = async (formData): Promise<any> => {
    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.login(
        { promise: { resolve, reject } },
        { body: formurlencoded({ code: formData.code }) }
      )
    })

    return await submitPromise.catch(data => {
      return { [FORM_ERROR]: data }
    })
  }

  const renderSendCodeButton = ({ pristine, submitting, hasValidationErrors }): JSX.Element => {
    if (submitting) {
      return (<Button variant='contained' color='secondary' disabled type='submit'> Sending... </Button>)
    }

    return (
      <Button variant='contained' disabled={pristine || hasValidationErrors} type='submit'>
        Send code via text message
      </Button>
    )
  }

  const renderLoginButton = ({ pristine, submitting, hasValidationErrors }): JSX.Element => {
    if (submitting) {
      return (<Button variant='contained' color='secondary' disabled type='submit'> Signing in... </Button>)
    }
    return (<Button variant='contained' type='submit' disabled={pristine || hasValidationErrors}> Sign in </Button>)
  }

  const renderEnterPhoneNumberForm = (): JSX.Element => {
    const phoneField = {
      name: 'phone',
      type: 'tel',
      placeholder: '555-555-5555',
      label: 'Cell phone number',
      testId: 'login-form__phone',
      component: renderInput
    }

    return (
      <Box sx={{ mt: 3 }}>
        <Typography mb={2}>Enter your cellular phone number below. You'll receive a code via text message that can be used to sign in.</Typography>
        <Form
          onSubmit={handleSubmitPhoneNumber}
          validate={values => {
            const errors: { phone?: string } = {}
            if (!values.phone) errors.phone = 'Must enter a phone number'
            return errors
          }}
        >
          {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
            <EjentaForm onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {renderField({}, phoneField)}
                {renderSendCodeButton({ pristine, submitting, hasValidationErrors })}
              </Stack>
            </EjentaForm>
          )}
        </Form>
      </Box>
    )
  }

  const renderEnterCodeForm = (): JSX.Element => {
    const codeField = {
      name: 'code',
      type: 'number',
      label: 'Code',
      testId: 'login-form__code',
      component: renderInput
    }

    return (
      <div>
        <Box className='login-form__intro'>
          <Box sx={{ display: 'inline-flex', alignItems: 'center', width: '95%', justifyContent: 'center', m: 1 }}>
            <CheckIcon color='primary' />
            <Typography color='primary' fontWeight={500}> Code sent to {getDisplayPhoneNumber(phoneLoginNumber)} </Typography>
          </Box>

          <Typography mb={2} textAlign='center'>To sign in, enter the code you received in the text message.</Typography>
        </Box>

        <Form
          onSubmit={handleSubmitCode}
          validate={values => {
            const errors: { code?: string } = {}
            if (!values.code) errors.code = 'Must enter a code. Check your text messages.'
            return errors
          }}
        >
          {({ handleSubmit, pristine, submitting, submitError, hasValidationErrors }) => (
            <EjentaForm onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {submitError && <Alert severity='error'>{submitError}</Alert>}
                {renderField({}, codeField)}
                {renderLoginButton({ pristine, submitting, hasValidationErrors })}
                <Typography variant='caption'>
                  Didn't receive a code? Contact <Link href='mailto:help@ejenta.com'>help@ejenta.com</Link>
                </Typography>
              </Stack>

            </EjentaForm>
          )}
        </Form>
      </div>
    )
  }

  const renderPhoneLoginForm = (): JSX.Element => {
    return (
      <BasicFrame>
        <Box className='login-form'>
          <Typography variant='h6' textAlign='center'>Cell phone sign-in</Typography>

          {!props.sendPhoneLoginCodeApi?.sync && renderEnterPhoneNumberForm()}
          {props.sendPhoneLoginCodeApi?.sync && renderEnterCodeForm()}

          <Typography variant='caption'>
            <span>By signing in, you agree to Ejenta's </span>
            <Link href='//ejenta.com/terms.pdf' underline='none' target='_blank' data-testid='login-form__terms-of-service' rel='noreferrer'>Terms of Service</Link>
            <span> and </span>
            <Link href='//ejenta.com/privacy.pdf' underline='none' target='_blank' data-testid='login-form__privacy-policy' rel='noreferrer'>Privacy Policy</Link>.
          </Typography>
        </Box>
      </BasicFrame>
    )
  }

  return renderPhoneLoginForm()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapState (state: RootState) {
  return {
    sendPhoneLoginCodeApi: state.api.sendPhoneLoginCode
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatch (dispatch: AppDispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    setLoggingInWithPhone: bindActionCreators(setLoggingInWithPhone, dispatch),
    resetSendPhoneLoginCodeApi: bindActionCreators(
      restApi.actions.sendPhoneLoginCode.reset,
      dispatch
    )
  }
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(PhoneLoginForm)
