import allConfigs from './product-config.json'
import _ from 'lodash'
import { type IProductConfig } from '../interfaces/product-config.interface'
declare const PRODUCT_NAME: string

const ConfigService = {
  productConfig: (): IProductConfig => {
    const defaultConfig: IProductConfig = allConfigs.DEFAULT

    switch (PRODUCT_NAME) {
      case 'CHF':
        return _.merge({}, defaultConfig, allConfigs.CHF)
      case 'HTN':
        return _.merge({}, defaultConfig, allConfigs.HTN)
      case 'IMPACT':
        return _.merge({}, defaultConfig, allConfigs.IMPACT)
      case 'OBIMPACT':
        return _.merge({}, defaultConfig, allConfigs.OBIMPACT)
      case 'POINT32CHF':
        return _.merge({}, defaultConfig, allConfigs.POINT32CHF)
      case 'PREGNANCY':
        return _.merge({}, defaultConfig, allConfigs.PREGNANCY)
      case 'KPCOVID19':
        return _.merge({}, defaultConfig, allConfigs.KPCOVID19)
      default:
        return defaultConfig
    }
  }
}

export const {
  productConfig
} = ConfigService
