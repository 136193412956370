import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import {
  getEmptyChart,
  getBaseChartOptions,
  addPausePlotBands,
  getBaseSeriesOptions,
  getPointDateString
} from './baseChartUtils'
import { getXAxisForRange, getMarkerRadius, getDefaultChartOptions } from './chartUtils'
import { isAlertOpen } from '../../constants/constants'
import { type SeriesScatterOptions, type Options, type SeriesLineOptions } from 'highcharts'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IAlertClick } from '../../interfaces/alert.interface'
import { type IScatterSeries, type IMarker, type ILineSeries, type IChartOptionsParams } from '../../interfaces/chart.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'

const basePulseOxChartOptions = {
  ...getDefaultChartOptions('pulse_ox-chart'),

  tooltip: {
    headerFormat: '',
    pointFormatter: function format () {
      const point = this as any
      return `<strong>${point.name as string}</strong><br/>
              ${point.description as string}`
    },
    useHTML: true,
    padding: 8,
    borderColor: 'rgb(0, 0, 0, 0)',
    borderWidth: 0
  }
}

function getBloodOxygenAlertsSeries (memberObj: IMemberSummary, options: Options, handleAlertClick: IAlertClick): Options {
  const pulseOxAlerts = _.filter(memberObj.alerts, a => a.metric_type === 'pulse_ox')
  if (!pulseOxAlerts.length || !memberObj.alerts?.length) return options

  const alertsData = pulseOxAlerts.map((alertObj) => {
    /*
     * Get the Y-value of the marker
     */
    let pointY = 0
    const pulseOxMeasurement = memberObj.pulse_ox.data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display alert marker above the higher submetric
    if (pulseOxMeasurement) {
      pointY = _.max([pulseOxMeasurement.oxygen_saturation, pulseOxMeasurement.heart_rate]) * 1.07
    } else pointY = memberObj.pulse_ox.data[0].oxygen_saturation * 1.07
    // Otherwise just use the first oxygen_saturation value we have

    const isOpen = isAlertOpen(alertObj)
    const marker: IMarker = {}
    if (alertObj.type === 'pulse_ox_tracking') marker.symbol = 'circle'

    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'pulse_ox_tracking'
    })

    const point = {
      name: alertObj.provider_content,
      x: +moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,
      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) handleAlertClick(alertObj)
        }
      }
    }

    return point
  })

  const alertsSeries: SeriesScatterOptions = _.extend({
    name: 'SpO₂ alerts',
    type: 'scatter' as IScatterSeries,
    className: 'series-pulseOxAlerts',
    lineWidth: 0,
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<strong>SpO₂ alert</strong>',
      pointFormat: '<div class="pulse_ox-alert">{point.name}</div>'
    },
    data: alertsData,
    zIndex: 2 // so the alerts are on top of the data
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series?.push(alertsSeries)
  return newOptions
}

function getPulseOxSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore, pulseOxType: string, markerSymbol: string): Options {
  const newOptions = _.cloneDeep(options)
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))

  const pulseOxMeasurements = _.reverse(
    _.cloneDeep(memberObj.pulse_ox.data)).map((m) => {
    const unit = pulseOxType === 'heart_rate' ? ' beats/min' : '%'

    const point = {
      x: +moment(m.timestamp),
      y: m[pulseOxType],
      name: getPointDateString(moment(m.timestamp), true),
      description: `${m[pulseOxType] as number}${unit}`
    }
    return point
  })

  const pulseOxMeasurementsSeries: SeriesLineOptions = _.extend({
    name: pulseOxType === 'heart_rate' ? 'Heart rate' : 'SpO₂',
    data: pulseOxMeasurements,
    zIndex: 1,
    marker: {
      symbol: markerSymbol
    },
    type: 'line' as ILineSeries,
    className: 'series-oxygenSaturationMeasurements'
  }, getBaseSeriesOptions())

  newOptions.series?.push(pulseOxMeasurementsSeries)
  return newOptions
}

function getOxygenSaturationSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): Options {
  let newOptions = _.cloneDeep(options)
  newOptions = getPulseOxSeries(newOptions, memberObj, dashboardViewStore, 'oxygen_saturation', 'square')
  return newOptions
}

function getHeartRateSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): Options {
  let newOptions = _.cloneDeep(options)
  newOptions = getPulseOxSeries(newOptions, memberObj, dashboardViewStore, 'heart_rate', 'circle')
  return newOptions
}

export default function getBloodOxygenChartOptions ({
  memberObj, dashboardViewStore, showMemberAlerts,
  handleAlertClick
}: IChartOptionsParams): Options {
  if (!memberObj.pulse_ox) return getEmptyChart()

  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, false) }

  let options: Options = _.merge(
    getBaseChartOptions(),
    basePulseOxChartOptions,
    xAxisOptions
  )

  options = getOxygenSaturationSeries(options, memberObj, dashboardViewStore)
  options = getHeartRateSeries(options, memberObj, dashboardViewStore)
  if (showMemberAlerts && handleAlertClick) options = getBloodOxygenAlertsSeries(memberObj, options, handleAlertClick)
  options = addPausePlotBands(memberObj.user.pauses ?? [], options, true)

  return options
}
