import _ from 'lodash'
import { type IBloodPressureData } from '../../interfaces/bp-summary.interface'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IMember } from '../../interfaces/user.interface'
import { type IRange } from '../../interfaces/ejenta.interface'
import { type ISurveyQuestion } from '../../interfaces/survey.interface'
import { type ISurveyData } from '../../interfaces/survey-response.interface'
import { getDeliveryDate } from '../baseDateUtils'
import { preciseRound } from '../unitConversionUtils'
import { getIOMRange } from './analyticsUtilityHelper'
import { type IWeightBMI } from '../../interfaces/weight-summary.interface'

type IBPSubmetric = 'avg_systolic' | 'avg_diastolic' | 'avg_heart_rate'

interface IWeightSpread {
  maxWeight: {
    value: number
    date?: number
  }
  minWeight: {
    value: number
    date?: number
  }
}

export const getWeightDeltaInRange = (member: IMemberSummary): number | null => {
  const periods = _.get(member, ['weight', 'information', 'periods'])

  if (!periods || periods.length !== 2 || _.includes(periods, null)) return null

  const weightThisWeek = periods[0].avg_weight.value
  const weightLastWeek = periods[1].avg_weight.value

  return Math.round((weightThisWeek - weightLastWeek) * 10) / 10
}

export const getWeightSpreadInRange = (member: IMemberSummary): IWeightSpread => {
  const maxObj = _.maxBy(member.weight?.data, d => d.weight.value)
  const minObj = _.minBy(member.weight?.data, d => d.weight.value)

  return {
    maxWeight: {
      value: Math.round((maxObj?.weight?.value ?? 0) * 10) / 10,
      date: maxObj?.timestamp
    },
    minWeight: {
      value: Math.round((minObj?.weight?.value ?? 0) * 10) / 10,
      date: minObj?.timestamp
    }
  }
}

export const getWeightGainInRange = (memberObj: IMemberSummary): number | null => {
  if (!memberObj.weight?.data.length) return null

  const weights = _.sortBy(memberObj.weight.data, d => d.timestamp) // Sort weight data
  const startWeight = _.head(weights)?.weight.value
  const endWeight = _.last(weights)?.weight.value

  if (startWeight && endWeight) {
    return preciseRound(endWeight - startWeight, 2)
  }
  return null
}

export const getMemberBMI = (userObj: IMember): number | null => {
  if (!userObj.height_in_cm || !userObj.start_weight_in_kg) return null

  const kg = userObj.start_weight_in_kg
  const mSquared = Math.pow(userObj.height_in_cm / 100, 2)

  return kg / mSquared
}

/**
 * NOTE: This is a coarse-grained classification for the pregnancy app; if we need to use BMI
 * elsewhere this method should be fleshed out more to include underweight BMI &c.
 */
export const getMemberWeightCondition = (userObj: IMember): IWeightBMI => {
  const bmi = getMemberBMI(userObj)

  if (!bmi || bmi <= 25) return 'Norm.'
  if (bmi > 25 && bmi <= 30) return 'OW'
  return 'OB'
}

export const getBpDisplayString = (bpMeasurement: IBloodPressureData): string => {
  if (!bpMeasurement) {
    return '—'
  }

  return `${Math.round(bpMeasurement.systolic)}/${Math.round(bpMeasurement.diastolic)}`
}

const getBloodPressureDeltaInRange = (memberObj: IMemberSummary, submetric: IBPSubmetric): number | null => {
  const periods = _.get(memberObj, ['blood_pressure', 'information', 'periods'])

  if (!periods || periods.length !== 2 || _.includes(periods, null)) return null

  const valueThisPeriod = periods[0][submetric]
  const valueLastPeriod = periods[1][submetric]

  return Math.round(valueThisPeriod - valueLastPeriod)
}

export const getSystolicDeltaInRange = (memberObj: IMemberSummary): number | null => {
  return getBloodPressureDeltaInRange(memberObj, 'avg_systolic')
}

export const getDiastolicDeltaInRange = (memberObj: IMemberSummary): number | null => {
  return getBloodPressureDeltaInRange(memberObj, 'avg_diastolic')
}

export const getBloodPressureHeartRateDeltaInRange = (memberObj: IMemberSummary): number | null => {
  return getBloodPressureDeltaInRange(memberObj, 'avg_heart_rate')
}

/* ******************************************************************************
 * Blood glucose measurements
 * ******************************************************************************/
export const getBloodGlucoseDeltaInRange = (memberObj: IMemberSummary): number | null => {
  const periods = _.get(memberObj, ['blood_glucose', 'information', 'periods'])

  if (!periods || periods.length !== 2 || _.includes(periods, null)) return null

  const bgThisWeek = periods[0].avg_blood_glucose
  const bgLastWeek = periods[1].avg_blood_glucose

  return Math.round((bgThisWeek - bgLastWeek) * 10) / 10
}

export const getActivityStepsAverageInRange = (memberObj: IMemberSummary): number => {
  const mean = _.meanBy(memberObj.activity?.data, d => d.steps)
  return Math.round(mean)
}

export const getActivityMinutesAverageInRange = (memberObj: IMemberSummary): number => {
  const mean = _.meanBy(memberObj.activity?.data, (d) => {
    const moderateSeconds = d.intensities.moderate.value
    const intenseSeconds = d.intensities.intense.value
    return (moderateSeconds + intenseSeconds) / 60
  })

  return Math.round(mean)
}

/*
 * Returns TOTAL active steps in range
 */
export const getActivityStepsInRange = (memberObj: IMemberSummary): number => {
  return _.sumBy(memberObj.activity?.data, d => d.steps)
}

export const getIOMRangeOnExpectedDeliveryDate = (memberObj: IMemberSummary): IRange => {
  const expectedDeliveryDate = getDeliveryDate(memberObj.user)
  const expectedDeliveryDateIOMRange = getIOMRange(memberObj, expectedDeliveryDate)

  return {
    min: preciseRound(expectedDeliveryDateIOMRange.min, 0),
    max: preciseRound(expectedDeliveryDateIOMRange.max, 0)
  }
}

/* ******************************************************************************
 * Survey responses
 * ******************************************************************************/

const getFriendlyQuestionType = (question: ISurveyQuestion): string => {
  const friendlyQuestionTypeMap = {
    sob_day: 'SOB (during day)',
    sob_laying_down: 'SOB (laying down)',
    chest_pain: 'Chest pain',
    leg_swelling: 'Leg/ankle swelling',
    difficulty_sleeping: 'Difficulty sleeping',
    dizziness: 'Dizziness/balance'
  }

  const friendlyType = friendlyQuestionTypeMap[question.type]
  if (!friendlyType) return question.type
  return friendlyType
}

const getFriendlyScore = (score: number): string => {
  const friendlyScoreMap = {
    0: 'None',
    2: 'Slight',
    3: 'Moderate',
    4: 'Severe'
  }

  const friendlyScore = friendlyScoreMap[score]
  if (!friendlyScore) return ''
  return friendlyScore
}

const computeScoreForQuestions = (questions?: ISurveyQuestion []): number => {
  if (!questions) return 0 // base case

  let score = 0

  for (const question of questions) {
    const answerScore = question.answer.score ?? 0
    score += answerScore + computeScoreForQuestions(question.answer.questions)
  }

  return score
}

export const getSurveyResponseTooltipContent = (surveyResponse: ISurveyData): string => {
  let content = ''
  if (!surveyResponse.questions) return content

  for (const question of surveyResponse.questions) {
    const answerScore = question.answer.score ?? 0
    const score = answerScore + computeScoreForQuestions(question.answer.questions)
    content += `${getFriendlyQuestionType(question)}: ${getFriendlyScore(score)} (${score})<br/>`
  }

  return content
}

export const getNoDataString = (user: IMember, deviceIsLinked: boolean, deviceType: string): string => {
  const latestOrderStatus = _.maxBy(user.order_statuses?.[deviceType], s => s.timestamp)?.status

  if (deviceIsLinked) {
    switch (latestOrderStatus) {
      case 'Pending':
      case 'Submitted':
      case 'Processing':
        return 'Device manually added' // unlikely
      case 'Fulfilled': return 'Device in transit'
      case 'Exception': return 'Shipment exception'
      case 'Delivered': return 'Device delivered; no data yet'
      default: return 'No data yet'
    }
  } else {
    switch (latestOrderStatus) {
      case 'Pending': return 'Order pending'
      case 'Submitted': return 'Order submitted'
      case 'Processing': return 'Order processing'
      case 'Exception': return 'Shipment exception'
      case 'Fulfilled':
      case 'Delivered':
        return 'Device manually unlinked' // unlikely
      default: return 'No device added or ordered'
    }
  }
}
