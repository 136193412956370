import moment from 'moment'
import { dispatchWithException } from './baseUtils'
import { insertSummaries, membersCacheSlice } from '../store/membersCache'
import { populateMembersFromCache } from '../store/membersView'
import { setLastUpdated } from '../store/dashboardView'
import { populateAlerts, alertsViewSlice } from '../store/alertsView'
import { populateCarePlan, updateCarePlanItem } from '../store/clinicianCarePlan'
import { populateAnalytics } from '../store/analytics'
import { setSelectedAlertObject } from '../store/profileView'
import { populateChats } from '../store/chatsView'

/* ********************************************************************************
 * Endpoints for reading/updating data about the entire population.
 * ********************************************************************************/
const populationEndpoints = {
  memberSummaries: {
    url: '/summaries',
    options: {
      method: 'get'
    },

    transformer (data) {
      if (!data) return []
      return data
    },

    postfetch: [
      ({ data, dispatch }) => {
        dispatchWithException(insertSummaries({ membersCache: data }), dispatch)
      },
      ({ dispatch, getState }) => {
        dispatchWithException(
          populateMembersFromCache(getState().membersCache),
          dispatch
        )
      }
    ]
  },

  clinicianAlerts: {
    url: '/alerts/paging',
    options: {
      method: 'get'
    },
    urlOptions: {
      delimiter: '&',
      arrayFormat: 'brackets'
    },
    transformer (data) {
      if (!data) return {}
      return data
    },

    postfetch: [({ data, dispatch }) => {
      dispatchWithException(populateAlerts({ ...data }), dispatch)
    }]
  },

  clinicianChats: {
    url: '/alerts/paging',
    options: {
      method: 'get'
    },
    urlOptions: {
      delimiter: '&',
      arrayFormat: 'brackets'
    },
    transformer (data) {
      return !data ? {} : data
    },

    postfetch: [({ data, dispatch }) => {
      dispatchWithException(populateChats({ ...data }), dispatch)
    }]
  },

  updateAlert: {
    url: '/alerts/:alertId/notes',
    options: {
      method: 'post'
    },

    postfetch: [
      ({ data, dispatch }) => {
        dispatchWithException(alertsViewSlice.actions.updateAlert({ alert: data }), dispatch)
        dispatchWithException(setSelectedAlertObject(data), dispatch)
      },
      ({ data, dispatch }) => {
        dispatchWithException(membersCacheSlice.actions.updateAlert({ alert: data }), dispatch)
        dispatchWithException(setLastUpdated({ lastUpdated: moment().valueOf() }), dispatch)
      }
    ]
  },

  getClinicianDefaultCarePlan: {
    url: '/users/default/careplan',
    options: { method: 'get' },

    postfetch: [({ data, dispatch }) => {
      dispatchWithException(populateCarePlan({ data }), dispatch)
    }]
  },

  updateClinicianDefaultCarePlan: {
    url: '/users/default/careplan',
    options: { method: 'post' },

    postfetch: [({ request, data, dispatch }) => {
      const itemId = JSON.parse(request.params.body).id
      dispatchWithException(updateCarePlanItem({ itemId, data }), dispatch)
    }]
  },

  getAnalyticsOverview: {
    url: '/analytics/members?startdate=(:startDate)&enddate=(:endDate)',
    options: { method: 'get' },

    postfetch: [({ data, dispatch }) => {
      dispatchWithException(populateAnalytics({ data }), dispatch)
    }]
  }
}

export default populationEndpoints
