import React, { useState } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import Tooltip from '../../../elements/Tooltip'
import AnalyticsChart from '../layout/AnalyticsChart'
import getAlertTypeChartOptions from '../charts/alertTypeChart'
import getAlertDayChartOptions from '../charts/alertDayChart'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { mapAnalyticsDispatch, mapAnalyticsState } from '../../../../constants/reduxMappers'

function AnalyticsAlertsSection (props: PropsFromRedux): JSX.Element {
  const [alertByTypeExpanded, setAlertByTypeExpanded] = useState(true)
  const [alertByDOWExpanded, setAlertByDOWExpanded] = useState(true)

  const handleChange = (callback: (isExpanded: boolean) => void) => (event, isExpanded) => {
    callback(isExpanded)
  }
  const renderAxAlertsSection = (): JSX.Element => {
    const alertDayTooltip = 'Click on the legend to drill down into a specific alert type.'

    return (
      <div className='ax-subsection' data-testid='ax-subsection__alerts'>
        <div className='ax-subsection__header'>Alerts</div>

        <Accordion expanded={alertByTypeExpanded} onChange={handleChange(setAlertByTypeExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} id="accordion-alerts-by-type">
            Alerts by type
          </AccordionSummary>
          <AccordionDetails className='ax-chart-container'>
            <AnalyticsChart
              containerId='ax-alerts-by-type'
              chartOptions={getAlertTypeChartOptions(props.analyticsStore.allAlerts)}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={alertByDOWExpanded} onChange={handleChange(setAlertByDOWExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} id="accordion-adherence">
            Alerts by day of week
            <Tooltip tooltipId='ax-module__alerts-by-dow__tooltip' content={alertDayTooltip} />
          </AccordionSummary>
          <AccordionDetails className='ax-chart-container'>
            <AnalyticsChart
              containerId='ax-alerts-by-dow'
              chartOptions={getAlertDayChartOptions(props.analyticsStore.allAlerts)}
            />
          </AccordionDetails>
        </Accordion>

      </div>
    )
  }

  return renderAxAlertsSection()
}

const connector = connect(mapAnalyticsState, mapAnalyticsDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AnalyticsAlertsSection)
