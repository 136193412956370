import _ from 'lodash'
import moment, { type MomentInput, type Moment } from 'moment'
import 'moment-duration-format'
import React, { useEffect, useState } from 'react'
import { formatDuration } from '../../../utils/baseDateUtils'
import { RANGE_VIEWS, getRangeBounds } from '../../../utils/member/dateUtils'
import { DATE_DAY_FORMAT_SANS_YEAR } from '../../../constants/constants'
import { Box, Card, CardContent, IconButton, MenuItem, Select, Typography } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

const RANGE_VIEWS_TO_SHOW = [
  RANGE_VIEWS.ONE_DAY,
  RANGE_VIEWS.THREE_DAYS,
  RANGE_VIEWS.WEEK,
  RANGE_VIEWS.TWO_WEEKS,
  RANGE_VIEWS.THREE_WEEKS,
  RANGE_VIEWS.MONTH,
  RANGE_VIEWS.THREE_MONTHS,
  RANGE_VIEWS.SIX_MONTHS,
  RANGE_VIEWS.YEAR
]

function DateRange (props): JSX.Element {
  const options = _.map(RANGE_VIEWS_TO_SHOW, key => ({
    value: key,
    label: formatDuration(moment.duration(key))
  }))
  const [selectedOption, setSelectedOption] = useState(options[0].value)

  useEffect(() => {
    const foundZoom = options.find(x => x.value === props.dashboardViewStore.rangeZoom)
    setSelectedOption(foundZoom?.value ?? options[0].value)
  }, [])

  const getRangeStart = (): Moment | null => {
    const startVal = props.dashboardViewStore.rangeStart
    return startVal ? moment(startVal) : null
  }

  const handleRangeStartChange = (direction: number, rangeStart: MomentInput): void => {
    const rangeZoom = props.dashboardViewStore.rangeZoom
    let nextRangeStart

    if (direction === -1) {
      // Go backward in time
      nextRangeStart = moment(rangeStart).subtract(moment.duration(rangeZoom))
    } else {
      // Go forward in time
      const addRangeSlice = moment(rangeStart).add(moment.duration(rangeZoom))
      nextRangeStart = addRangeSlice.isSameOrBefore(moment())
        ? addRangeSlice
        : rangeStart
    }

    props.handleRangeStartChange(nextRangeStart)
  }

  const renderRangeZoom = (): JSX.Element => {
    const { startDate, endDate } = getRangeBounds(
      props.memberObj,
      props.dashboardViewStore.rangeZoom,
      getRangeStart() as Moment
    )

    const isStartDateCurrentYear = moment(startDate).isSame(moment(), 'year')
    const isEndDateCurrentYear = moment(endDate).isSame(moment(), 'year')

    const formatStr =
      props.dashboardViewStore.rangeZoom === RANGE_VIEWS.YEAR || !isStartDateCurrentYear || !isEndDateCurrentYear
        ? 'MMM D, Y'
        : DATE_DAY_FORMAT_SANS_YEAR

    const startDateString = moment(startDate).format(formatStr)
    const endDateString = moment(endDate).format(formatStr)

    return (
      <Box sx={{ display: 'flex' }}>
        <IconButton color='secondary' aria-label='arrow backward' onClick={() => { handleRangeStartChange(-1, startDate) }}>
          <ChevronLeft />
        </IconButton>

        <Typography variant='subtitle2' data-testid='date-range__carousel-current' sx={{ p: 2 }}>
          {startDateString === endDateString ? startDateString : `${startDateString} – ${endDateString}`}
        </Typography>

        {!moment(endDate).isSame(moment(), 'day') && (
          <IconButton color='secondary' aria-label='arrow forward' onClick={() => { handleRangeStartChange(1, startDate) }}>
            <ChevronRight />
          </IconButton>
        )}
      </Box>
    )
  }

  const onInputElementChange = (e): void => {
    setSelectedOption(e.target.value)
    props.handleRangeZoomChange(e.target.value)
  }

  const renderRangeZoomSelector = (): JSX.Element => {
    const selectOptions = options.map(v =>
      <MenuItem className='care-plan-item__select-item' key={v.value} value={v.value}>{v.label}</MenuItem>
    )

    return (
      <Box data-testid='date-range__dropdown' sx={{ p: 1 }}>
        <Select
          size='small'
          value={selectedOption}
          onChange={onInputElementChange}
        >
          {selectOptions}
        </Select>
      </Box>
    )
  }

  const renderDateRange = (): JSX.Element => {
    return (
      <section>
        <Card sx={{ width: '100%', mt: 1, mb: 1, alignContent: 'center' }} variant='outlined'>
          <CardContent sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 !important' }}>
            {renderRangeZoom()}
            {renderRangeZoomSelector()}
          </CardContent>
        </Card>
      </section>
    )
  }

  return renderDateRange()
}

export default DateRange
