import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { DAYS_OF_WEEK } from '../../../../constants/constants'
import { getAlertDayCounts } from './alertChartConfig'
import { type IAnalyticsAlert } from '../../../../interfaces/store/analyticsStore.interface'
import { type Options } from 'highcharts'

export default function getAlertDayChartOptions (alerts: IAnalyticsAlert []): Options {
  const alertDayCounts = getAlertDayCounts(alerts)

  const alertDayChartOptions: Options = {
    chart: {
      type: 'column'
    },

    xAxis: {
      categories: DAYS_OF_WEEK,
      labels: {
        formatter: function format () {
          const point = this as any
          return point.value.slice(0, 2)
        }
      },
      tickLength: 0
    },

    yAxis: {
      title: {
        text: '# of alerts',
        margin: 24
      },
      min: 1
    },

    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },

    tooltip: {
      headerFormat: '',
      useHTML: true,
      borderColor: 'none',
      pointFormatter: function format () {
        const point = this as any
        return `
          <span class="ax-text-bold">${point.category as string} - ${point.series.name as string}</span><br/>
          Alerts: <em>${point.y as number}</em>
        `
      }
    },

    series: _.map(alertDayCounts, c => ({
      name: c.name,
      type: 'column',
      data: c.value,
      className: c.className
    }))
  }

  return _.merge(_.cloneDeep(baseChartOptions), alertDayChartOptions)
}
