import _ from 'lodash'
import moment from 'moment'
import { getBaseSeriesOptions, getPointDateString, getBaseChartOptions, addPausePlotBands } from './baseChartUtils'
import { getHoursMinutes } from '../baseStringUtils'
import { getColumnWidth, getDefaultChartOptions, getXAxisForRange } from './chartUtils'
import { type SeriesColumnOptions, type Options } from 'highcharts'
import { type IMemberSummary } from '../../interfaces/member-summary.interface'
import { type IDashboardStore } from '../../interfaces/store/dashboardStore.interface'
import { type IChartOptionsParams, type IColumnSeries } from '../../interfaces/chart.interface'

export const baseSleepChartOptions: Options = {
  ...getDefaultChartOptions('sleep-chart'),
  tooltip: {
    headerFormat: '',
    useHTML: true,
    shared: true,
    stickOnContact: true,
    borderWidth: 0
  },
  yAxis: [{
    title: { text: '' },
    labels: {
      format: '{value} h'
    },

    maxPadding: 0.1,
    allowDecimals: false
  }]
}

function getSleepDurationSeries (options: Options, memberObj: IMemberSummary, dashboardViewStore: IDashboardStore): Options {
  const newOptions = _.cloneDeep(options)
  const sleepData = memberObj.sleep ? [...memberObj.sleep?.data] : []

  const durationMeasurements = _.reverse(sleepData).map((m) => {
    const point = {
      x: +moment(m.date),
      y: m.duration.value,
      dataSource: m.source
    }
    return point
  })

  const durationSeries: SeriesColumnOptions = _.extend({
    name: 'Sleep time',
    type: 'column' as IColumnSeries,
    data: durationMeasurements,
    className: 'sleep-series',
    color: '#CAA8EF',
    zIndex: 1,
    pointWidth: getColumnWidth(dashboardViewStore, false),
    tooltip: {
      headerFormat: '',
      pointFormatter: function format () {
        const point = this as any

        const { hours, minutes } = getHoursMinutes(point.y)
        const durationStr = `${hours} hr ${minutes} min`
        return `
        <strong>${getPointDateString(moment(point.x), false)}</strong><br/>
        ${durationStr} <br/>
        <small>Source: ${point.dataSource as string ?? 'Unknown'}</small>
        `
      }
    }
  }, getBaseSeriesOptions())

  newOptions.series?.push(durationSeries)
  return newOptions
}

export default function getSleepChartOptions ({ memberObj, dashboardViewStore }: IChartOptionsParams): Options {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore, false) }

  let options: Options = _.merge(
    getBaseChartOptions(),
    baseSleepChartOptions,
    xAxisOptions
  )

  options = getSleepDurationSeries(options, memberObj, dashboardViewStore)
  options = addPausePlotBands(memberObj.user.pauses ?? [], options, true)

  return options
}
