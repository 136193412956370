/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import LoadingIndicator from '../LoadingIndicator'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import TableSortLabel from '@mui/material/TableSortLabel'
import NotificationImportantRounded from '@mui/icons-material/NotificationImportantRounded'
import { getComparator } from '../../../constants/constants'
import { type IChartTableProps, type IChartTableHeaderProps } from '../../../interfaces/chart-table.interface'
import { type IBloodGlucoseSummary, type IBloodGlucoseData } from '../../../interfaces/bg-summary.interface'
import { type IAlert } from '../../../interfaces/alert.interface'
import { mapProfileDispatch } from '../../../constants/reduxMappers'

function GlucoseHeader (props: IChartTableHeaderProps): JSX.Element {
  const numberArray = [...Array(props.columnCount).keys()]
  const order = props.order

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property)
  }

  const renderHeader = (): JSX.Element => {
    return (
      <TableHead>
        <TableRow>
          <TableCell key='date'>
            <TableSortLabel active direction={order} onClick={createSortHandler('date')}>
              Date
            </TableSortLabel>
          </TableCell>

          {numberArray.map((index) => {
            const className = index % 2 === 0 ? 'odd-col' : ''
            return (
              <React.Fragment key={`meal_${index}`}>
                <TableCell align='center' className={className}>Before meal</TableCell>
                <TableCell align='center' className={className}>After meal</TableCell>
              </React.Fragment>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  return renderHeader()
}

function GlucoseCell (props): JSX.Element {
  const handleAlertDetailModalOpen = (alert): void => {
    props.actions.setSelectedAlert(alert.id)
  }

  const renderCell = (): JSX.Element => {
    const m = props.measurement

    if (!m) return <></>
    const matchingAlert = props.alerts.find((item: IAlert) => item.measurement_timestamp === m.timestamp)

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography fontWeight={500} fontSize='1.1rem' className='bg-cell__glucose'>{m.blood_glucose}</Typography>
            <Typography variant='body2'>{moment(m.timestamp).format('h:mm A')}</Typography>
          </Box>
          {matchingAlert &&
            <IconButton aria-label='open alert' size='small' color='primary' onClick={() => { handleAlertDetailModalOpen(matchingAlert) }}>
              <NotificationImportantRounded fontSize='small' />
            </IconButton>}
        </Box>
      </>
    )
  }

  return renderCell()
}

interface IBloodGlucoseRowProps {
  data: IBloodGlucoseGroup
  columnCount: number
  date: string | number
  actions?: any
}

function GlucoseRow (props: IBloodGlucoseRowProps): JSX.Element {
  const numberArray = [...Array(props.columnCount).keys()]
  const dateAsMoment = moment(props.date)
  const formattedDate = moment().isSame(dateAsMoment, 'year') ? dateAsMoment.format('MMM DD') : dateAsMoment.format('MMM DD, YYYY')

  const renderRow = (): JSX.Element => {
    return (
      <>
        <TableRow sx={{ backgroundColor: theme => `${theme.palette.background.paper}!important` }}>
          <TableCell>
            <Typography display='flex'> {formattedDate} </Typography>
          </TableCell>
          {numberArray.map((index) => {
            const className = index % 2 === 0 ? 'odd-col' : ''
            return (
              <React.Fragment key={`meal_${index}`}>
                <TableCell align='center' className={className}><GlucoseCell measurement={props.data?.beforeMeals[index]} actions={props.actions} alerts={props.data?.alerts} /></TableCell>
                <TableCell align='center' className={className}><GlucoseCell measurement={props.data?.afterMeals[index]} actions={props.actions} alerts={props.data?.alerts} /></TableCell>
              </React.Fragment>
            )
          })}
        </TableRow>
      </>
    )
  }

  return renderRow()
}

type IGroupedData = Record<string, IBloodGlucoseGroup>

interface IBloodGlucoseGroup {
  beforeMeals: IBloodGlucoseData []
  afterMeals: IBloodGlucoseData []
  alerts: IAlert []
}

function BloodGlucoseTable (props: IChartTableProps): JSX.Element {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const handleRequestSort = (): void => { setOrder(order === 'asc' ? 'desc' : 'asc') }

  const groupByDateAndMealStatus = (data: IBloodGlucoseData [], alerts: IAlert []): IGroupedData => {
    const bloodGlucoseAlerts = alerts.filter(item => item.metric_type === 'blood_glucose')
    const groupedData: IGroupedData = {}

    data.forEach((d: IBloodGlucoseData) => {
      if (!groupedData[d.date]) groupedData[d.date] = { beforeMeals: [], afterMeals: [], alerts: [] }

      const matchingAlerts = bloodGlucoseAlerts.filter(alert => alert.measurement_date === d.date) ?? []
      groupedData[d.date][d.before_meal ? 'beforeMeals' : 'afterMeals'].unshift(d)
      groupedData[d.date].alerts = matchingAlerts.length ? matchingAlerts : []
    })

    return groupedData
  }

  const getMaxColumns = (groupedData: IGroupedData): number => {
    let maxMeasurementsPerDay = 3 // show only 3 before/after meals by default

    Object.keys(groupedData).forEach(key => {
      const item = groupedData[key]
      if (item.afterMeals.length > maxMeasurementsPerDay) maxMeasurementsPerDay = item.afterMeals.length
      if (item.beforeMeals.length > maxMeasurementsPerDay) maxMeasurementsPerDay = item.beforeMeals.length
    })

    return maxMeasurementsPerDay
  }

  const renderTable = (): JSX.Element => {
    if (props.dashboardViewStore.isRangeLoading) {
      return (<div className='metrics-section__inner'> <LoadingIndicator /> </div>)
    }

    const dataSection = props.memberObj.blood_glucose as IBloodGlucoseSummary

    if (!dataSection?.data?.length) {
      return (
        <Box>
          <Typography variant='body1' color='#7f7f7f' marginTop={1.5}>No blood glucose data for this period</Typography>
        </Box>
      )
    }

    const dataByDate = groupByDateAndMealStatus(dataSection.data, props.memberObj.alerts as IAlert [])
    const columnCount = getMaxColumns(dataByDate)
    const sectionClass = !props.hideBorder ? 'metrics-section' : ''

    return (
      <TableContainer className={sectionClass} sx={{ height: 424 }}>
        {!props.hideHeader && <h3 className='metrics-section__header'>Blood Glucose</h3>}
        <Table size='small' className='glucose-table__container' stickyHeader>
          <GlucoseHeader columnCount={columnCount} order={order} onRequestSort={handleRequestSort} />
          <TableBody>
            {Object.keys(dataByDate).slice().sort(getComparator(order)).map((date, i) => {
              return <GlucoseRow date={date} data={dataByDate[date]} key={i} columnCount={columnCount} actions={props.profileViewActions} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return renderTable()
}

const connector = connect(null, mapProfileDispatch)
export default connector(BloodGlucoseTable)
